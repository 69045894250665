import { FilterKeys, TChangesPayout, TPayout } from '@core/redux/models/payouts';

export const isTChangesPayout = (data: TChangesPayout | TPayout): data is TChangesPayout =>
  Boolean(
    typeof data === 'object' &&
      data !== null &&
      'finalCommissionChanges' in data &&
      typeof data.finalCommissionChanges === 'string',
  );

export const isFilterKey = (key: string): key is FilterKeys => key in FilterKeys;
