import React, { useEffect } from 'react';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import * as styles from './HistoryModal.module.scss';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import { usePartershipUser } from '@core/hooks/currentUser';
import Spinner from '@components/Spinner/Spinner';

export default function HistoryModal() {
  const { payoutRow } = useAppSelector((state) => state.payoutsReducer);
  const partnership = usePartershipUser();
  const [trigger, { data, isLoading }] = payoutsAPI.useLazyGetHistoryQuery();

  useEffect(() => {
    if (partnership && payoutRow) {
      trigger({ partnership_id: partnership.id, invoice: payoutRow.invoice });
    }
  }, [partnership, payoutRow]);

  const list = data && JSON.parse(data).history;

  const { setHistoryModal } = useActions();
  return (
    <ModalPage
      className={styles.modal}
      header={<div className={styles.header}>History</div>}
      setIsShown={setHistoryModal}
      mode={ModalPageMode.bottomSheet}
      closeable
    >
      <div className={styles.wrap}>
        {isLoading ? (
          <Spinner size={'large'} />
        ) : (
          <>
            {list ? (
              <div className={styles.block} dangerouslySetInnerHTML={{ __html: list }} />
            ) : (
              <div>Nothing found</div>
            )}
          </>
        )}
      </div>
    </ModalPage>
  );
}
