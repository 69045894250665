import React from 'react';
import * as styles from './TimeErrorModal.module.scss';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { useActions } from '@core/hooks/actions';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';

export default function TimeErrorModal() {
  const { setIsTimeError } = useActions();

  return (
    <ModalPage
      header={<p className={styles.title}>Diagnostic time required</p>}
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={() => setIsTimeError(false)}
      overlayColor={ModalPageOverlayColor.standard}
      closeable={false}
    >
      <p className={styles.errorMessage}>Please enter diagnostic time before saving.</p>
      <Button
        bgColor={ButtonBgColor.red}
        className={styles.button}
        onClick={() => setIsTimeError(false)}
      >
        OK
      </Button>
    </ModalPage>
  );
}
