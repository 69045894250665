// extracted by mini-css-extract-plugin
var _1 = "Header-module__content__iDpev";
var _2 = "Header-module__header__gPJlu";
var _3 = "Header-module__title__o7zlG";
export { _1 as "content", _2 as "header", _3 as "title" }

    if(module.hot) {
      // 1733466103617
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  