export enum InputType {
  text = 'text',
  number = 'number',
  email = 'email',
  password = 'password',
}

export enum InputMode {
  standard = 'standard',
  group = 'group',
}

export enum SeparatorMode {
  all = 'all',
  short = 'short',
}
