// extracted by mini-css-extract-plugin
var _1 = "Partnership-module__disabled__bGoTN";
var _2 = "Partnership-module__spinner__N8s0d";
var _3 = "Partnership-module__wrapper__4cv_k";
export { _1 as "disabled", _2 as "spinner", _3 as "wrapper" }

    if(module.hot) {
      // 1733466103613
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  