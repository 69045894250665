import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import {
  TApplianceGroups,
  TTechniciansWorkTypesResponse,
  TApplianceTypes,
  TWorkTypesWithAppliances,
  TWorkTypesResponse,
  TTechniciansWorkTypesV2,
  TUpdateTechniciansWorkTypesRequest,
} from '../models/jobTypes';

enum JobTypesApiPaths {
  JOB_TYPES = '/api/workflow/jobtypes/',
  TECH_JOB_TYPES = '/api/workflow/tech-job-types/',
  TECH_DADS = '/api/workflow/tech-dads/',
  APPLIANCE_TYPES = '/api/workflow/appliance-types/',
  APPLIANCE_GROUPS = '/api/workflow/appliance-groups/',
  WORK_TYPES = '/api/workflow/work-types/',
  TECHNICIANS_WORK_TYPES = '/api/technicians/technician_id/work-types/',
  WORK_TYPES_V2 = '/api/workflow/work-types/v2/',
  TECHNICIANS_WORK_TYPES_V2 = '/api/technicians/technician_id/work-types/type_id/',
}

export const jobTypesService = (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
  getApplianceGroups: build.query<TApplianceGroups, void>({
    query: () => JobTypesApiPaths.APPLIANCE_GROUPS,
  }),
  getApplianceTypes: build.query<TApplianceTypes, void>({
    query: () => JobTypesApiPaths.APPLIANCE_TYPES,
  }),
  getWorkTypesV2: build.query<TWorkTypesWithAppliances, void>({
    query: () => JobTypesApiPaths.WORK_TYPES_V2,
    transformResponse: (response: TWorkTypesResponse): TWorkTypesWithAppliances => {
      return response.map((item) => ({
        id: item.id,
        title: `${item.title} ${item.kind}`,
        types: item.appliance_types.map((type) => ({ id: type.id, title: type.title })),
      }));
    },
  }),
  getTechniciansWorkTypesV2: build.query<
    TTechniciansWorkTypesV2,
    { technician_id: number; type_id: number }
  >({
    query: ({ technician_id, type_id }) => {
      const options: Record<string, string> = {
        technician_id: String(technician_id),
        type_id: String(type_id),
      };
      const url = JobTypesApiPaths.TECHNICIANS_WORK_TYPES_V2.replace(
        /technician_id|type_id/gi,
        (matched: string) => {
          return options[matched];
        },
      );

      return url;
    },
    transformResponse: (
      response: TTechniciansWorkTypesResponse,
      _,
      arg: { technician_id: number; type_id: number },
    ): TTechniciansWorkTypesV2 => {
      return {
        items: response.map((item) => ({
          id: item.id,
          workType: item.work_type,
          deleteTimeout: item.delete_timeout,
          isDeleting: item.is_deleting,
          isActive: item.is_active,
          dad: item.dad,
          typeId: arg.type_id,
        })),
        isActiveList: response[0].is_active ?? false,
        isDeletingList: response[0].is_deleting ?? false,
        deleteTimeoutList: response[0].delete_timeout,
      };
    },
  }),
  updateTechniciansWorkTypesV2: build.mutation<
    TTechniciansWorkTypesV2,
    TUpdateTechniciansWorkTypesRequest
  >({
    query: ({ technician_id, type_id, workTypeList }) => {
      const options: Record<string, string> = {
        technician_id: String(technician_id),
        type_id: String(type_id),
      };

      const url = JobTypesApiPaths.TECHNICIANS_WORK_TYPES_V2.replace(
        /technician_id|type_id/gi,
        (matched: string) => {
          return options[matched];
        },
      );
      return {
        url,
        method: 'PATCH',
        body: workTypeList,
      };
    },
  }),
});
