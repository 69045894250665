import React, { MouseEvent, useCallback, useState } from 'react';
import cn from 'classnames';
import Arrow from '@assets/images/arrow-down.svg';
import * as styles from './CustomSelect.module.scss';
import { SelectOption } from '@pages/Profile/components/BankDetails/BankDetailsForm/BankDetailsForm';

type CustomSelectProps = {
  options: SelectOption[];
  hasPlaceholder?: string;
  value: SelectOption;
  onChange: (item: string) => void;
  className?: string;
  classNameSelect?: string;
  classNameList?: string;
  isActive?: boolean;
};

export default function CustomSelect(props: CustomSelectProps) {
  const {
    value,
    onChange,
    options,
    hasPlaceholder = false,
    className,
    classNameSelect,
    classNameList,
    isActive = true,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleListDisplay = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    (event: MouseEvent) => {
      if (event !== null) {
        onChange(event.currentTarget.getAttribute('data-name') as string);
      }
    },
    [value],
  );

  const customPlaceholder = hasPlaceholder ? hasPlaceholder : 'Select...';

  return (
    <div className={cn(styles.select, className)} onClick={handleListDisplay}>
      <input type='hidden' name='' />
      {
        <div className={cn(styles.selectHead, { [styles.open]: isOpen }, classNameSelect)}>
          {hasPlaceholder && !value ? (
            <span className={styles.placeholder}>{customPlaceholder}</span>
          ) : (
            value
          )}
        </div>
      }
      <Arrow
        className={cn(styles.icon, { [styles.iconRotated]: isOpen && isActive })}
        fill={isActive ? 'var(--light-accent-main)' : 'var(--light_label_tertiary)'}
      />
      <ul
        className={cn(
          styles.selectList,
          { [styles.open]: isOpen && isActive },
          !isOpen && styles.hidden,
          classNameList,
        )}
      >
        {options.map((item: SelectOption) => (
          <li
            key={item}
            className={styles.selectItem}
            data-name={item}
            onClick={(event: MouseEvent) => handleChange(event)}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
