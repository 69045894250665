// extracted by mini-css-extract-plugin
var _1 = "Card-module__card__vxmgO";
var _2 = "Card-module__icon__Nb3fn";
var _3 = "Card-module__text__pS3oU";
var _4 = "Card-module__title__f2_DO";
var _5 = "Card-module__titleBlock__o5DON";
export { _1 as "card", _2 as "icon", _3 as "text", _4 as "title", _5 as "titleBlock" }

    if(module.hot) {
      // 1733466106317
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  