import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { profileAPI } from '@core/redux/services/profileService';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import Button from '@components/Button/Button';
import DownloadIcon from '@assets/images/download.svg';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { Status, TDocument, TDocumentType } from '@core/redux/models/documents';
import DataPicker from '@components/DatePicker/DatePicker';
import {
  DATE_PLACEHOLDER,
  ONLY_LATIN_CHAR_PATTERN,
  W9_DOCUMENT_LINK,
  FORM_TYPE,
} from '@core/constants';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import { useCurrentUser } from '@core/hooks/currentUser';
import FileUploader from '@components/FileInput/FileInput';
import * as styles from './NewDocumentModal.module.scss';
import Input from '@components/Input/Input';
import { InputMode, InputType } from '@components/Input/types';
import {
  TDocumentFormData,
  isTDocumentIdFormData,
  isTDocumentCompanyRegistrationFormData,
  isTDocumentW9IndividualOrSsnFormData,
  isTDocumentRepairLicenseFormData,
  isTDocumentW9BusinessFormData,
} from '../../types';
import CompanyRegistrationField from './ components/CompanyRegistrationField/CompanyRegistrationField';
import { getDefaultFormValue, getDocumentTitle, useDocumentType } from '../../utils';
import {
  getPatterError,
  parseHookFormMessage,
  parseServerError,
  THookErrors,
  TServerError,
} from '@core/utils/error';
import PhoneInput from '@components/Input/PhoneInput';

type NewDocumentModalProps = {
  setIsShown: (val: boolean) => void;
  type: TDocumentType | undefined;
};

export default function NewDocumentModal(props: NewDocumentModalProps) {
  const { setIsShown, type } = props;
  const { technicianId } = useCurrentUser();
  const { documents } = useAppSelector((state) => state.documentsReducer);
  const { setDocuments } = useActions();

  if (!type?.type) {
    throw new Error('Document type undefined!');
  }

  const currentType = FORM_TYPE[type?.type];
  const { isId, isW9Business, isW9Individual, isSsn, isRepairLicense, isProofCompanyRegistration } =
    useDocumentType(currentType);

  const [minDate, setMinDate] = useState<string | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<TDocumentFormData>({
    defaultValues: getDefaultFormValue({
      isId,
      isW9Business,
      isW9Individual,
      isSsn,
      isRepairLicense,
      isProofCompanyRegistration,
    }),
  });

  const [handleAddDocumentId, { isLoading: isLoadingDocumentId, error: documentIdError }] =
    profileAPI.useAddDocumentIdMutation();
  const [
    handleCheckCompanyAdress,
    { isLoading: isLoadingCompanyAddress, error: CompanyAddressError },
  ] = profileAPI.useUpdateAddressMutation();
  const [
    handleAddDocumentCompanyRegistration,
    { isLoading: isLoadingDocumentCompanyRegistration, error: documentCompanyRegistrationError },
  ] = profileAPI.useAddDocumentCompanyRegistrationMutation();
  const [
    handleAddDocumentRepairLicense,
    { isLoading: isLoadingDocumentRepairLicense, error: documentRepairLicenseError },
  ] = profileAPI.useAddDocumentRepairLicenseMutation();
  const [
    handleAddDocumentW9Individual,
    { isLoading: isLoadingDocumentW9Individual, error: documentW9IndividualError },
  ] = profileAPI.useAddDocumentW9IndividualMutation();
  const [
    handleAddDocumentW9Business,
    { isLoading: isLoadingDocumentW9Business, error: documentW9BusinessError },
  ] = profileAPI.useAddDocumentW9BusinessMutation();
  const [handleAddDocumentSsn, { isLoading: isLoadingDocumentSsn, error: documentSsnError }] =
    profileAPI.useAddDocumentSsnMutation();

  let isLoading;
  let serverError;

  switch (true) {
    case isId:
      isLoading = isLoadingDocumentId;
      serverError = documentIdError;
      break;
    case isProofCompanyRegistration:
      isLoading = isLoadingCompanyAddress && isLoadingDocumentCompanyRegistration;
      serverError = CompanyAddressError || documentCompanyRegistrationError;
      break;
    case isRepairLicense:
      isLoading = isLoadingDocumentRepairLicense;
      serverError = documentRepairLicenseError;
      break;
    case isW9Business:
      isLoading = isLoadingDocumentW9Business;
      serverError = documentW9BusinessError;
      break;
    case isW9Individual:
      isLoading = isLoadingDocumentW9Individual;
      serverError = documentW9IndividualError;
      break;
    case isSsn:
      isLoading = isLoadingDocumentSsn;
      serverError = documentSsnError;
      break;
  }

  const onSubmit: SubmitHandler<TDocumentFormData> = async (formData) => {
    try {
      if (isTDocumentIdFormData(formData, isId)) {
        const result = await handleAddDocumentId({
          ...formData,
          status: Status.pending,
          technician_id: technicianId,
          type_id: type.id,
        });

        if ('data' in result) {
          setDocuments({
            type: currentType,
            documents: [...documents[currentType], result.data as TDocument],
          });
          setIsShown(false);
        }
      } else if (isTDocumentCompanyRegistrationFormData(formData, isProofCompanyRegistration)) {
        const address = await handleCheckCompanyAdress({
          street: formData.street,
          apartment: formData.apartment,
          city: formData.city,
          stateCode: formData.stateCode,
          postalCode: formData.postalCode,
        });

        if ('data' in address && address.data.id) {
          const result = await handleAddDocumentCompanyRegistration({
            ...formData,
            status: Status.pending,
            technician_id: technicianId,
            type_id: type.id,
            address_id: address.data.id,
          });

          if ('data' in result) {
            setDocuments({
              type: currentType,
              documents: [...documents[currentType], result.data as TDocument],
            });
            setIsShown(false);
          }
        }
      } else if (isTDocumentW9IndividualOrSsnFormData(formData, isW9Individual)) {
        const result = await handleAddDocumentW9Individual({
          ...formData,
          status: Status.pending,
          technician_id: technicianId,
          type_id: type.id,
        });

        if ('data' in result) {
          setDocuments({
            type: currentType,
            documents: [...documents[currentType], result.data as TDocument],
          });
          setIsShown(false);
        }
      } else if (isTDocumentW9IndividualOrSsnFormData(formData, isSsn)) {
        const result = await handleAddDocumentSsn({
          ...formData,
          status: Status.pending,
          technician_id: technicianId,
          type_id: type.id,
        });

        if ('data' in result) {
          setDocuments({
            type: currentType,
            documents: [...documents[currentType], result.data as TDocument],
          });
          setIsShown(false);
        }
      } else if (isTDocumentRepairLicenseFormData(formData, isRepairLicense)) {
        const result = await handleAddDocumentRepairLicense({
          ...formData,
          status: Status.pending,
          technician_id: technicianId,
          type_id: type.id,
        });

        if ('data' in result) {
          setDocuments({
            type: currentType,
            documents: [...documents[currentType], result.data as TDocument],
          });
          setIsShown(false);
        }
      } else if (isTDocumentW9BusinessFormData(formData, isW9Business)) {
        const result = await handleAddDocumentW9Business({
          ...formData,
          status: Status.pending,
          technician_id: technicianId,
          type_id: type.id,
        });

        if ('data' in result) {
          setDocuments({
            type: currentType,
            documents: [...documents[currentType], result.data as TDocument],
          });
          setIsShown(false);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const hasFormErrors = !!Object.keys(formErrors).length;

  return (
    <ModalPage
      header={
        <>
          <div className={styles.title}>{getDocumentTitle(type?.type)}:</div>
          <span className={styles.separator}></span>
        </>
      }
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {(isW9Business || isW9Individual) && (
          <div className={styles.separatorBlock}>
            <a className={styles.docLink} href={W9_DOCUMENT_LINK} target='_blank' rel='noreferrer'>
              <span>Download form</span>
              <DownloadIcon />
            </a>
            <span className={styles.separator}></span>
          </div>
        )}

        <div className={styles.picker}>
          <Controller
            name='startDate'
            control={control}
            rules={{ required: 'Start date is required' }}
            render={({ field: { ref, ...field } }) => (
              <div className={styles.picker}>
                <DataPicker
                  placeholder={DATE_PLACEHOLDER}
                  top={'Document start date'}
                  {...field}
                  value={field.value}
                  maxdate={maxDate ? new Date(maxDate) : undefined}
                  setDate={setMinDate}
                />
                {!isW9Business && !isProofCompanyRegistration && (
                  <span className={styles.separator}></span>
                )}
              </div>
            )}
          />
          {!isW9Business && !isW9Individual && !isSsn && !isProofCompanyRegistration && (
            <Controller
              name='expirationDate'
              control={control}
              rules={{ required: 'Expiration date is required' }}
              render={({ field: { ref, ...field } }) => (
                <DataPicker
                  placeholder={DATE_PLACEHOLDER}
                  top={'Document expiration date'}
                  {...field}
                  value={field.value}
                  mindate={minDate ? new Date(minDate) : undefined}
                  setDate={setMaxDate}
                />
              )}
            />
          )}
          <span className={styles.separator}></span>
        </div>
        <div className={styles.separatorBlock}>
          <Controller
            name='file'
            control={control}
            rules={{ required: 'File is required' }}
            render={({ field: { value, onChange, ref, ...field } }) => (
              <FileUploader
                {...field}
                onChange={(event) => {
                  if (!event.target.files) return;
                  onChange(event.target.files[0]);
                }}
              />
            )}
          />
          <span className={styles.separator}></span>
        </div>

        {isW9Business && (
          <div className={styles.separatorBlock}>
            <Controller
              name='ein'
              control={control}
              rules={{
                required: 'EIN is required',
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  mask='99-9999999'
                  className={styles.input}
                  type={InputType.text}
                  mode={InputMode.standard}
                  placeholder='EIN'
                  error={!!error}
                  classNameError={styles.error}
                />
              )}
            />
            <span className={styles.separator}></span>
          </div>
        )}
        {(isProofCompanyRegistration || isW9Business) && (
          <div className={styles.separatorBlock}>
            <Controller
              name='companyName'
              control={control}
              rules={{
                required: 'Company name is required',
                pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Business name'),
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Input
                  {...field}
                  className={styles.input}
                  type={InputType.text}
                  mode={InputMode.standard}
                  placeholder='Business name'
                  error={!!error}
                  classNameError={styles.error}
                />
              )}
            />
          </div>
        )}
        {(isSsn || isW9Individual) && (
          <div className={styles.separatorBlock}>
            <Controller
              name='ssn'
              control={control}
              rules={{
                required: 'SSN is required',
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <PhoneInput
                  {...field}
                  mask='999-99-9999'
                  className={styles.input}
                  type={InputType.text}
                  mode={InputMode.standard}
                  placeholder='SSN'
                  error={!!error}
                  classNameError={styles.error}
                />
              )}
            />
            <span className={styles.separator}></span>
          </div>
        )}
        {(isId || isSsn || isW9Individual) && (
          <div className={styles.separatorBlock}>
            <Controller
              name='firstName'
              control={control}
              rules={{
                required: 'First name is required',
                pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'First name'),
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Input
                  {...field}
                  className={styles.input}
                  type={InputType.text}
                  mode={InputMode.standard}
                  placeholder='First name'
                  error={!!error}
                  classNameError={styles.error}
                />
              )}
            />
            <Controller
              name='lastName'
              control={control}
              rules={{
                required: 'First name is required',
                pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'First name'),
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Input
                  {...field}
                  className={styles.input}
                  type={InputType.text}
                  mode={InputMode.standard}
                  placeholder='Last name'
                  error={!!error}
                  classNameError={styles.error}
                />
              )}
            />
            <span className={styles.separator}></span>
          </div>
        )}
        {isId && (
          <div className={styles.separatorBlock}>
            <Controller
              name='birthDate'
              control={control}
              render={({ field: { ref, ...field } }) => (
                <div className={styles.picker}>
                  <DataPicker
                    placeholder={DATE_PLACEHOLDER}
                    top={'Date of birth'}
                    {...field}
                    value={field.value}
                  />
                </div>
              )}
            />
            <span className={styles.separator}></span>
          </div>
        )}
        {isProofCompanyRegistration && <CompanyRegistrationField control={control} />}
        <div className={styles.footer}>
          {hasFormErrors && (
            <div className={styles.errorMessage}>
              {parseHookFormMessage(formErrors as THookErrors)}
            </div>
          )}
          {!!serverError && (
            <div className={styles.errorMessage}>
              {parseServerError(serverError as TServerError)}
            </div>
          )}
          <Button
            className={styles.submit}
            size={ButtonSize.m}
            bgColor={ButtonBgColor.green}
            type='submit'
            loading={isLoading}
          >
            Send
          </Button>
        </div>
      </form>
    </ModalPage>
  );
}
