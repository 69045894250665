import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Input from '@components/Input/Input';
import Done from '@assets/images/done20.svg';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import Dollar from '@assets/images/dollar.svg';
import ArrowDown from '@assets/images/arrowDown.svg';
import * as styles from './BankDetailsForm.module.scss';
import { AccountType, FinanceType, TBankDetails } from '@core/redux/models/bankDetails';
import { InputMode, InputType } from '@components/Input/types';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { useModuleActionType } from '@core/utils/utils';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import { getFormValidation } from '../../GeneralInfo/utils';
import { ONLY_NUMBERS } from '@core/constants';

export type SelectOption = string;

const OPTIONS = [AccountType.business, AccountType.personal];

type BankDetailsFormProps = {
  bankDetails: TBankDetails;
  isFetching: boolean;
};

export default function BankDetailsForm(props: BankDetailsFormProps) {
  const { bankDetails, isFetching } = props;

  const { bankDetailsAction, bankDetailsFormData } = useAppSelector(
    (state) => state.bankDetailsReducer,
  );
  const { isSend } = useModuleActionType(bankDetailsAction);

  const {
    setBankDetailsFormData,
    setBankDetailsError,
    setAccountType,
    setFinanceType,
    setAccountNumber,
    setRoutingNumber,
  } = useActions();

  const [isOpenBankDetails, setIsOpenBankDetails] = useState<boolean>(false);

  useEffect(() => {
    setBankDetailsFormData(bankDetails);
  }, [isFetching]);

  const validation = useMemo(() => {
    return getFormValidation(bankDetailsFormData);
  }, [bankDetailsFormData]);

  useEffect(() => {
    setBankDetailsError(getFormValidation(bankDetailsFormData));
  }, [bankDetailsFormData]);

  const toggleBankDetails = useCallback(() => {
    setIsOpenBankDetails(!isOpenBankDetails);
  }, [isOpenBankDetails]);

  return (
    <div>
      <Button
        className={cn(styles.button, isOpenBankDetails && styles.buttonOpen)}
        mode={ButtonMode.standard}
        bgColor={ButtonBgColor.white}
        onClick={toggleBankDetails}
      >
        <div className={styles.wrapper}>
          <Dollar fill='var(--light-accent-main)' />
          <p className={styles.text}>Bank details</p>
        </div>
        <ArrowDown
          fill='var(--light-accent-main)'
          className={cn(styles.arrow, isOpenBankDetails && styles.arrowRotated)}
        />
      </Button>
      <div
        className={cn(
          styles.form,
          {
            [styles.formDisabled]: isSend,
          },
          {
            [styles.hidden]: !isOpenBankDetails,
          },
        )}
      >
        <div>
          <span className={styles.topPlaceholder}>Account type:</span>
          <CustomSelect
            className={styles.selector}
            classNameSelect={cn(styles.selectorHeader, {
              [styles.disabled]: isSend,
            })}
            value={bankDetailsFormData.accountType}
            options={OPTIONS}
            onChange={(e) => setAccountType(e as AccountType)}
            isActive={!isSend}
          />
        </div>
        <div className={styles.financeBlock}>
          <label className={styles.financeLabel}>
            <span>{FinanceType.checking}</span>
            <input
              className={styles.radioInput}
              type='radio'
              name='FinanceType-checking'
              value={FinanceType.checking}
              onChange={() => {
                setFinanceType(FinanceType.checking);
              }}
              checked={bankDetailsFormData.financeType === FinanceType.checking}
            />
            <span
              className={cn(styles.customRadio, {
                [styles.disabled]: isSend,
              })}
            >
              <Done fill='var(--light-background-primary)' />
            </span>
          </label>
          <label className={styles.financeLabel}>
            <span>{FinanceType.savings}</span>
            <input
              className={styles.radioInput}
              type='radio'
              name='FinanceType-saving'
              value={FinanceType.savings}
              onChange={() => {
                setFinanceType(FinanceType.savings);
              }}
              checked={bankDetailsFormData.financeType === FinanceType.savings}
            />
            <span
              className={cn(styles.customRadio, {
                [styles.disabled]: isSend,
              })}
            >
              <Done fill='var(--light-background-primary)' />
            </span>
          </label>
        </div>
        <div className={styles.block}>
          <Input
            top={<span className={styles.topPlaceholder}>Account number:</span>}
            value={bankDetailsFormData.accountNumber}
            className={cn(styles.input, {
              [styles.disabled]: isSend,
            })}
            name='accountNumber'
            placeholder='0000000000'
            type={InputType.text}
            onChange={(e) => {
              if (ONLY_NUMBERS.test(e.target.value)) setAccountNumber(e.target.value);
            }}
            mode={InputMode.standard}
            classNameError={styles.inputError}
            error={validation.accountNumber}
          />
        </div>
        <div className={styles.block}>
          <Input
            top={<span className={styles.topPlaceholder}>Routing number:</span>}
            value={bankDetailsFormData.routingNumber}
            className={cn(styles.input, {
              [styles.disabled]: isSend,
            })}
            name='routingNumber'
            placeholder='0000000000'
            type={InputType.text}
            onChange={(e) => {
              if (ONLY_NUMBERS.test(e.target.value)) setRoutingNumber(e.target.value);
            }}
            mode={InputMode.standard}
            classNameError={styles.inputError}
            error={validation.routingNumber}
          />
        </div>
      </div>
    </div>
  );
}
