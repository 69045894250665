import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import ArrowUpIcon from '@assets/images/arrow-up.svg';
import * as styles from './ClaimModal.module.scss';
import { useCurrentUser } from '@core/hooks/currentUser';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPenalty } from '@core/redux/models/partnership';

type TProps = {
  setClaimModal: (val: boolean) => void;
  partnership: TPartnershipElement;
  penalty: TPenalty;
};

export default function ClaimModal(props: TProps) {
  const { setClaimModal, partnership, penalty } = props;
  // const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { userId } = useCurrentUser();
  const [value, setValue] = useState<string>('');

  const [trigger, { data }] = partnershipAPI.useLazyGetClaimsMessagesQuery({
    pollingInterval: 10000,
  });

  // const [isActiveClaim] = useState<boolean>(activeClaim);

  useEffect(() => {
    trigger({
      partnership_id: partnership.id,
      penalty_id: penalty.id,
    });
  }, [partnership, penalty]);

  const [sendMessage, { error: sendMessageError, isLoading: isLoadingSendMessage }] =
    partnershipAPI.useSendClaimMessageMutation();
  // const [closeClaim, { isLoading: isLoadingCloseClaim }] = partnershipAPI.useCloseClaimMutation();

  const handleSendMessage = useCallback(async () => {
    if (!partnership || !value) {
      return;
    }
    try {
      const result = await sendMessage({
        partnership_id: partnership.id,
        penalty_id: penalty.id,
        text: value.trim(),
        created_by_id: userId,
      });
      setValue('');
      return result;
    } catch (e) {
      console.log(e);
    }
  }, [partnership, value, penalty]);

  // const handleConfirm = useCallback(async () => {
  //   if (!partnership || !data) {
  //     return;
  //   }
  //   try {
  //     const claimId = data[0].claimId;
  //     const result = await closeClaim({
  //       partnership_id: partnership.id,
  //       is_active: false,
  //       claim_id: claimId,
  //     });
  //     if ('data' in result) {
  //       setConfirmModal(false);
  //       setClaimModal(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [partnership, data]);

  return (
    <ModalPage
      className={styles.modal}
      header={
        <div className={styles.header}>
          <div className={styles.title}>Claim for {penalty.invoice}</div>
          {/* <Button
            className={styles.modalButton}
            size={ButtonSize.s}
            disabled={!data?.length || !data[0].claimId || !isActiveClaim}
            bgColor={ButtonBgColor.grey}
            onClick={() => setConfirmModal(true)}
          >
            {isActiveClaim ? 'Close claim' : 'Closed'}
          </Button> */}
        </div>
      }
      setIsShown={setClaimModal}
      mode={ModalPageMode.bottomSheet}
      closeable
    >
      <div className={styles.chat}>
        <div className={styles.messages}>
          {!!data &&
            !!data.length &&
            data.map((item) => (
              <div key={`message-${item.createdBy}-${Math.random()}`}>
                <div className={cn(styles.text, { [styles.autor]: item.createdBy === userId })}>
                  <div className={styles.textTitle}>{item.createdByName}</div>
                  {item.text}
                </div>
              </div>
            ))}
          {sendMessageError && <div>Message was not send</div>}
        </div>
        <div className={styles.messageBlock}>
          <textarea
            className={styles.message}
            placeholder='Message...'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {!!value.length && (
            <Button
              mode={ButtonMode.icon}
              bgColor={ButtonBgColor.green}
              onClick={handleSendMessage}
              disabled={isLoadingSendMessage}
            >
              <ArrowUpIcon fill='var(--light-background-primary)' />
            </Button>
          )}
        </div>
      </div>
      {/* {confirmModal && (
        <ConfirmModalPage
          setIsShown={setConfirmModal}
          text='Are you sure you want to close the claim?'
          header={`Close claim for ${currentInvoiceNumber}?`}
          handleConfirm={handleConfirm}
          confirmLoading={isLoadingCloseClaim}
        />
      )} */}
    </ModalPage>
  );
}
