// extracted by mini-css-extract-plugin
var _1 = "NewDocumentModal-module__docLink__Yz2Ou";
var _2 = "NewDocumentModal-module__emptyMargin__qtBsb";
var _3 = "NewDocumentModal-module__error__KZ9ll";
var _4 = "NewDocumentModal-module__errorMessage__fz_Fx";
var _5 = "NewDocumentModal-module__fileInput__h8kSP";
var _6 = "NewDocumentModal-module__footer__f8XEx";
var _7 = "NewDocumentModal-module__form__piEFo";
var _8 = "NewDocumentModal-module__header__zXNvx";
var _9 = "NewDocumentModal-module__input__dk6dX";
var _a = "NewDocumentModal-module__modal__bRzHa";
var _b = "NewDocumentModal-module__picker__bFEtq";
var _c = "NewDocumentModal-module__separator__WnbIg";
var _d = "NewDocumentModal-module__separatorBlock__WH5PI";
var _e = "NewDocumentModal-module__submit__VcWoS";
var _f = "NewDocumentModal-module__title__Lihtn";
var _10 = "NewDocumentModal-module__upload__eHBDi";
export { _1 as "docLink", _2 as "emptyMargin", _3 as "error", _4 as "errorMessage", _5 as "fileInput", _6 as "footer", _7 as "form", _8 as "header", _9 as "input", _a as "modal", _b as "picker", _c as "separator", _d as "separatorBlock", _e as "submit", _f as "title", _10 as "upload" }

    if(module.hot) {
      // 1733466108080
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  