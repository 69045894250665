import React from 'react';
import cn from 'classnames';
import Spinner from '@components/Spinner/Spinner';
import Kpi from './components/Kpi/Kpi';
import Penalty from './components/Penalty/Penalty';
import Documents from './components/Documents/Documents';
import Status from './components/Status/Status';

import * as styles from './Partnership.module.scss';
import Info from './components/Info/Info';
import { usePartershipUser } from '@core/hooks/currentUser';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { PartnershipStatus } from '@core/redux/models/partnership';

export default function Partnership() {
  const partnership = usePartershipUser();
  const { data: weeks } = partnershipAPI.useGetWeeksQuery();

  if (!partnership || !weeks) {
    return (
      <div className={styles.spinner}>
        <Spinner size='large' />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Status partnership={partnership} />
      <div
        className={cn({
          [styles.disabled]: partnership.status === PartnershipStatus.deactivatedByExpert,
        })}
      >
        <Info partnership={partnership} />
        <Kpi partnership={partnership} weeks={weeks} />
        <Penalty partnership={partnership} />
        <Documents partnership={partnership} />
      </div>

      <ControlPanel partnership={partnership} />
    </div>
  );
}
