// extracted by mini-css-extract-plugin
var _1 = "Container-module__container__D8jkT";
var _2 = "Container-module__notMainPage__GUnh8";
export { _1 as "container", _2 as "notMainPage" }

    if(module.hot) {
      // 1733466102298
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  