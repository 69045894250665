import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import { Skeleton } from '@mui/material';
import Plus from '@assets/images/plus.svg';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import { useCurrentUser } from '@core/hooks/currentUser';
import { profileAPI } from '@core/redux/services/profileService';
import MainButton from '../MainButton/MainButton';
import DocumentsList from './components/DocumentsList/DocumentsList';
import NewDocumentModal from './components/NewDocumentModal/NewDocumentModal';
import { ModuleAction, WorkingAs } from '@core/redux/models/profile';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import * as styles from './Documents.module.scss';
import { TDocumentType } from '@core/redux/models/documents';
import { useModuleActionType } from '@core/utils/utils';
import { getDocumentTitle } from './utils';

export default function Documents() {
  const { technicianId } = useCurrentUser();
  const { documentsAction } = useAppSelector((state) => state.documentsReducer);
  const { needDisabled } = useAppSelector((state) => state.profileReducer);
  const { isSend } = useModuleActionType(documentsAction);

  const { setDocumentsAction, setWorkingAs } = useActions();
  const { workingAs } = useAppSelector((state) => state.generalInfoReducer);

  const { data: technicianData, isLoading: technicianIsLoading } =
    profileAPI.useGetTechnicianDataQuery({ id: technicianId });

  const [trigger, { data: documentTypesList, isLoading }] =
    profileAPI.useLazyGetDocumentTypesQuery();

  const [updateWorkingAs, { isLoading: updateWorkingAsLoading }] =
    profileAPI.useUpdateWorkingAsDataMutation();

  useEffect(() => {
    if (technicianData?.workingAs) {
      setWorkingAs(technicianData?.workingAs);
    }
  }, [technicianData]);

  const [newDocumentModal, setNewDocumentModal] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<TDocumentType>();

  const handleNewDocumentModal = useCallback((type: TDocumentType) => {
    if (type) {
      setNewDocumentModal(true);
      setDocumentType(type);
    }
  }, []);

  useEffect(() => {
    if (workingAs) {
      trigger({
        type: workingAs,
      });
    }
  }, [workingAs]);

  const handleClickBusiness = useCallback(async () => {
    setWorkingAs(WorkingAs.Business);
    await updateWorkingAs({ id: technicianId, workingAs: WorkingAs.Business });
  }, [workingAs]);
  const handleClickIndividual = useCallback(async () => {
    setWorkingAs(WorkingAs.Individual);
    await updateWorkingAs({ id: technicianId, workingAs: WorkingAs.Individual });
  }, [workingAs]);

  return (
    <section className={styles.wrapper}>
      <TitleWithButton
        title={'Documents:'}
        disabled={needDisabled && documentsAction === ModuleAction.send}
      >
        <MainButton
          refreshDataFunction={() => {}}
          resetIsLoading={false}
          sendDataFunction={() => {}}
          setAction={setDocumentsAction}
          action={documentsAction}
          noSendDataMode
        />
      </TitleWithButton>
      <div className={styles.workingAsHeader}>
        {technicianIsLoading ? (
          <Skeleton
            height={76}
            width='100%'
            variant='rectangular'
            className={styles.skeletonWorking}
          />
        ) : (
          <div className={styles.working}>
            <div className={styles.workingTitle}>Working as:</div>
            <div className={styles.workingButtons}>
              <Button
                onClick={handleClickBusiness}
                size={ButtonSize.s}
                bgColor={
                  workingAs === WorkingAs.Business ? ButtonBgColor.grey : ButtonBgColor.white
                }
                className={cn(styles.workingButton, { [styles.buttonDisabled]: isSend })}
                disabled={isSend || updateWorkingAsLoading}
                loading={!!(updateWorkingAsLoading && workingAs === WorkingAs.Business)}
              >
                Business
              </Button>
              <Button
                onClick={handleClickIndividual}
                size={ButtonSize.s}
                bgColor={
                  workingAs === WorkingAs.Individual ? ButtonBgColor.grey : ButtonBgColor.white
                }
                className={cn(styles.workingButton, { [styles.buttonDisabled]: isSend })}
                disabled={isSend || updateWorkingAsLoading}
                loading={!!(updateWorkingAsLoading && workingAs === WorkingAs.Individual)}
              >
                Individual
              </Button>
            </div>
          </div>
        )}
        <span className={styles.separator}></span>
      </div>

      {isLoading ? (
        <Skeleton height={120} width='100%' variant='rectangular' className={styles.skeletonItem} />
      ) : (
        <ul className={styles.documentsWrapper}>
          {documentTypesList?.map((type) => (
            <li key={`${type.type}`} className={styles.documentType}>
              <div className={styles.header}>
                <div className={styles.wrapperTitle}>
                  <h2 className={styles.title}>{getDocumentTitle(type.type)}</h2>
                </div>
                <Button
                  mode={ButtonMode.icon}
                  bgColor={ButtonBgColor.transparent}
                  onClick={() => handleNewDocumentModal(type)}
                  className={cn(styles.button, { [styles.buttonHidden]: isSend })}
                >
                  <Plus fill='var(--light_accent_main)' />
                </Button>
              </div>
              <DocumentsList type={type} />
            </li>
          ))}
        </ul>
      )}
      {newDocumentModal && workingAs && (
        <NewDocumentModal setIsShown={setNewDocumentModal} type={documentType} />
      )}
    </section>
  );
}
