// extracted by mini-css-extract-plugin
var _1 = "Footer-module__blockLogo__ygVQQ";
var _2 = "Footer-module__footer__T21AN";
var _3 = "Footer-module__item__c6ZK1";
var _4 = "Footer-module__list__MIDB7";
var _5 = "Footer-module__nav__iyzTl";
var _6 = "Footer-module__text__zxdPv";
var _7 = "Footer-module__wrapper__snHjM";
export { _1 as "blockLogo", _2 as "footer", _3 as "item", _4 as "list", _5 as "nav", _6 as "text", _7 as "wrapper" }

    if(module.hot) {
      // 1733466104081
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  