// extracted by mini-css-extract-plugin
var _1 = "Claims-module__button__JvHzw";
var _2 = "Claims-module__link__jBtFq";
var _3 = "Claims-module__wrap__gqq2k";
export { _1 as "button", _2 as "link", _3 as "wrap" }

    if(module.hot) {
      // 1733466104437
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  