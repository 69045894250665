export type TAgencyRegistrationForm = {
  type: 'agency' | 'expert';
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export type TExpertRegistrationForm = {
  type: 'agency' | 'expert';
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export enum TAccountType {
  expert = 'expert',
  agency = 'agency',
}

export type TCompanyAgencyField = {
  placeholder: string;
  name: 'name' | 'email';
};

export type TAdminAgencyField = {
  placeholder: string;
  name: 'firstName' | 'lastName' | 'phone';
};

export type TExpertField = {
  placeholder: string;
  name: 'firstName' | 'lastName' | 'phone' | 'email';
};
