// extracted by mini-css-extract-plugin
var _1 = "MainInfoWeb-module__button__bd07p";
var _2 = "MainInfoWeb-module__inert__aeYLw";
var _3 = "MainInfoWeb-module__link__tz3h4";
var _4 = "MainInfoWeb-module__mainText__ETvd3";
var _5 = "MainInfoWeb-module__pictureBlock__VUzwT";
var _6 = "MainInfoWeb-module__supplemental__gGsu9";
var _7 = "MainInfoWeb-module__textBlock__YpyYC";
var _8 = "MainInfoWeb-module__title__iqojQ";
var _9 = "MainInfoWeb-module__wrapper__Odwo2";
export { _1 as "button", _2 as "inert", _3 as "link", _4 as "mainText", _5 as "pictureBlock", _6 as "supplemental", _7 as "textBlock", _8 as "title", _9 as "wrapper" }

    if(module.hot) {
      // 1733466104064
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  