import { EMAIL_PATTERN, ONLY_LATIN_CHAR_PATTERN, ONLY_NUMBERS } from '@core/constants';

export type TServerError = {
  status: number;
  data: TErrorData;
};

export type THookErrors = Record<string, { type: string; message: string }>;

type TErrorData = {
  detail: string | Record<string, string>;
};

export const parseServerError = (error: TServerError): string => {
  if (typeof error.data?.detail === 'object') {
    return Object.entries(error.data?.detail)[0].join(' ') ?? 'Something went wrong!';
  }
  return error.data?.detail ?? 'Something went wrong!';
};

export const parseHookFormMessage = (errors: THookErrors): string => {
  return Object.values(errors)[0].message;
};

export const getPatterError = (
  pattern: RegExp,
  field: string,
): { value: RegExp; message: string } => {
  if (pattern === EMAIL_PATTERN) {
    return { value: EMAIL_PATTERN, message: `${field} is not valid` };
  } else if (pattern === ONLY_NUMBERS) {
    return {
      value: ONLY_NUMBERS,
      message: `${field} must contain only numbers`,
    };
  } else {
    return {
      value: ONLY_LATIN_CHAR_PATTERN,
      message: `${field} must contain only Latin characters`,
    };
  }
};
