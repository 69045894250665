import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery, customFetchBaseQuery } from './mainService';
import {
  ContractStatus,
  TClaimMessage,
  TContract,
  TContractResponse,
  TDocument,
  TDocumentData,
  TDocumentResponse,
  TMessageResponse,
  TPenaltyList,
  TPenaltyListResponse,
  TKpi,
  TKpiResponse,
  TWeeks,
  TWeek,
  TApplianceType,
  TAppliances,
  TWorkType,
  TContacts,
  TPartnershipStatus,
  TpartnershipStatusResponse,
} from '../models/partnership';
import { Status } from '../models/documents';
import { getDdMmYyyyFormatDate } from '@core/utils/date';
import { TWeeksResponse } from '../models/payouts';
import { payoutsAPI } from './payoutsService';

enum ApiPaths {
  GET_PENALTY = '/api/partnerships/partnership_id/penalties/',
  GET_CONTRACTS = '/api/partnerships/partnership_id/contracts/',
  CREATE_SIGN_URL = '/api/external/get_embedded_sign_url/',
  INSURANCE_API = '/api/partnerships/partnership_id/docs/insurance/',
  PENALTY_CLAIM = '/api/partnerships/partnership_id/penalties/pen_id/evidences/',
  KPI_GRAPH = '/api/partnerships/partnership_id/metrics:kpi/',
  WEEK_LIST = '/api/timing/weeks/?include_actual=true',
  APPLIANCE_TYPES = '/api/workflow/appliance-types/',
  APPLIANCES = '/api/workflow/appliances/',
  WORK_TYPE = '/api/workflow/work-types/',
  CONTACTS = '/api/companies/company_id/contacts/',
  STATUSES = '/api/partnerships/statuses/',
  DEACTIVATE = '/api/partnerships/partnership_id/status/',
}

export const partnershipAPI = createApi({
  reducerPath: 'partnershipAPI',
  baseQuery: customFetchBaseQuery(baseQuery),
  endpoints: (build) => ({
    getWeeks: build.query<TWeeks, void>({
      query: () => ApiPaths.WEEK_LIST,
      transformResponse: (response: TWeeksResponse) =>
        response.map((item) => ({
          id: item.id,
          end: item.end,
          start: item.start,
        })),
    }),
    getContacts: build.query<TContacts, { company_id: number }>({
      query: ({ company_id }) => `${ApiPaths.CONTACTS.replace(/company_id/gi, String(company_id))}`,
    }),
    getKpigraph: build.query<
      TKpi,
      { partnership_id: number; weeksList: TWeek[]; type_id?: number }
    >({
      query: ({ partnership_id, weeksList, type_id }) => {
        const weeks = `week_id=${weeksList[0].id}&week_id=${weeksList[1].id}&week_id=${weeksList[2].id}`;
        const type = type_id ? `&type_id=${type_id}` : '';
        return `${ApiPaths.KPI_GRAPH.replace(/partnership_id/gi, String(partnership_id))}?${weeks}${type}`;
      },
      transformResponse: (response: TKpiResponse) => ({
        self: {
          dataset: response.self.dataset,
          nickname: response.self.nickname,
          isCluster: response.self.is_cluster,
        },
        lagging: {
          dataset: response.lagging.dataset,
          nickname: response.lagging.nickname,
          isCluster: response.lagging.is_cluster,
        },
        leading: {
          dataset: response.leading.dataset,
          nickname: response.leading.nickname,
          isCluster: response.leading.is_cluster,
        },
        clusterPlace: response.percent_info.cluster_place,
        currentPlace: response.percent_info.current_place,
        dates: response.dates,
      }),
    }),
    getPenaltyList: build.query<TPenaltyList, { partnership_id: number }>({
      query: ({ partnership_id }) =>
        `${ApiPaths.GET_PENALTY.replace(/partnership_id/gi, String(partnership_id))}`,
      transformResponse: (response: TPenaltyListResponse) =>
        response.map((item) => ({
          id: item.id,
          penalty: item.template.text,
          invoice: item.invoice_number,
          expirationDate: item.expiration_date,
          status: item.is_active,
          description: item.description,
        })),
    }),
    getDocumentsList: build.query<TDocument[], { partnership_id: number; url: string }>({
      query: ({ partnership_id, url }) => `${url.replace(/{id}/gi, String(partnership_id))}`,
      transformResponse: (response: TDocumentResponse[]): TDocument[] => {
        return response.map((item) => {
          const status = item.status.toLowerCase();
          return {
            id: item.id,
            typeId: item.type.id,
            fileName: item.file.name,
            startDate: item.start_date,
            status: status as Status,
            type: item.type.type,
            expirationDate: item.expiration_date,
            companyName: item.company_name,
            uploadedBy: item.uploaded_by,
            company: item.company,
            partnership: item.partnership,
          };
        });
      },
    }),
    addDocumentInsurance: build.mutation<TDocument, TDocumentData>({
      query: (data) => {
        const formData = new FormData();
        formData.set('document', data.file, data.file.name);
        formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
        formData.set(
          'expiration_date',
          data.expirationDate ? getDdMmYyyyFormatDate(data.expirationDate) : '',
        );
        formData.set('type_id', JSON.stringify(data.type_id));
        formData.set('partnership', JSON.stringify(data.partnership));
        formData.set('company_name', JSON.stringify(data.companyName));
        formData.set('uploaded_by', JSON.stringify(data.uploaded_by));
        formData.set('status', 'Pending');
        return {
          url: `${ApiPaths.INSURANCE_API.replace(/partnership_id/gi, String(data.partnership))}`,
          method: 'POST',
          formData: true,
          body: formData,
        };
      },
      transformResponse: (response: TDocumentResponse): TDocument => {
        const status = response.status.toLowerCase();
        return {
          id: response.id,
          typeId: response.type.id,
          fileName: response.file.name,
          startDate: response.start_date,
          expirationDate: response.expiration_date,
          status: status as Status,
          type: response.type.type,
          companyName: response.company_name,
          uploadedBy: response.uploaded_by,
          company: response.company,
          partnership: response.partnership,
        };
      },
    }),
    deleteDocument: build.mutation<void, { id: number; url: string; partnership_id: number }>({
      query: ({ id, url, partnership_id }) => {
        return {
          url: `${url.replace(/{id}/gi, String(partnership_id))}${id}`,
          method: 'DELETE',
        };
      },
    }),
    getContractList: build.query<TContract[], { partnership_id: number }>({
      query: ({ partnership_id }) =>
        `${ApiPaths.GET_CONTRACTS.replace(/partnership_id/gi, String(partnership_id))}`,
      transformResponse: (response: TContractResponse[]): TContract[] => {
        return response.map((item) => {
          const status = item.status.toLowerCase();
          return {
            id: item.id,
            fileName: item?.file?.name ?? '',
            signUrl: item.sign_url,
            startDate: item.signed_on,
            createdAt: item.created_at,
            status: status as ContractStatus,
            name: item?.file?.name ?? '',
          };
        });
      },
    }),
    createSignUrl: build.mutation<{ sign_url: string }, { signUrl: string }>({
      query: ({ signUrl }) => {
        return {
          url: ApiPaths.CREATE_SIGN_URL,
          method: 'POST',
          body: { url: signUrl },
        };
      },
    }),
    getClaimsMessages: build.query<TClaimMessage[], { partnership_id: number; penalty_id: number }>(
      {
        query: ({ partnership_id, penalty_id }) => {
          const options: Record<string, string> = {
            partnership_id: String(partnership_id),
            pen_id: String(penalty_id),
          };
          const url = ApiPaths.PENALTY_CLAIM.replace(
            /partnership_id|pen_id/gi,
            (matched: string) => {
              return options[matched];
            },
          );
          return `${url}`;
        },
        transformResponse: (response: TMessageResponse[]) => {
          return response.map((item) => ({
            claimId: item.claim_id,
            createdBy: item.created_by,
            createdByName: item.created_by_name,
            createdAt: item.created_at,
            text: item.text,
          }));
        },
      },
    ),
    sendClaimMessage: build.mutation<
      TClaimMessage,
      { partnership_id: number; penalty_id: number; text: string; created_by_id: number }
    >({
      query: ({ partnership_id, penalty_id, text, created_by_id }) => {
        const options: Record<string, string> = {
          partnership_id: String(partnership_id),
          pen_id: String(penalty_id),
        };
        const url = ApiPaths.PENALTY_CLAIM.replace(/partnership_id|pen_id/gi, (matched: string) => {
          return options[matched];
        });

        return {
          url: url,
          method: 'POST',
          body: {
            penalty_id,
            created_by_id,
            text,
          },
        };
      },
      async onQueryStarted({ penalty_id, partnership_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: message } = await queryFulfilled;
          dispatch(
            partnershipAPI.util.updateQueryData(
              'getClaimsMessages',
              { penalty_id, partnership_id },
              (draft) => {
                return [message, ...draft];
              },
            ),
          );
        } catch (e) {
          console.log(e);
        }
      },
      transformResponse: (response: TMessageResponse): TClaimMessage => {
        return {
          claimId: response.claim_id,
          createdBy: response.created_by,
          createdByName: response.created_by_name,
          createdAt: response.created_at,
          text: response.text,
        };
      },
    }),
    getApplianceTypes: build.query<TApplianceType[], void>({
      query: () => ApiPaths.APPLIANCE_TYPES,
    }),
    getAppliances: build.query<TAppliances[], void>({
      query: () => ApiPaths.APPLIANCES,
    }),
    getWorkType: build.mutation<TWorkType, { appliance_id: number; applianceType_id: number }>({
      query: ({ appliance_id, applianceType_id }) => {
        const url = ApiPaths.WORK_TYPE;
        return {
          url: url,
          method: 'POST',
          body: {
            appliance_id,
            appliance_type_id: applianceType_id,
          },
        };
      },
    }),
    getStatuses: build.query<TPartnershipStatus[], void>({
      query: () => ApiPaths.STATUSES,
    }),
    deactivated: build.mutation<
      TPartnershipStatus,
      { partnership_id: number; technician_id: number; status: string }
    >({
      query: ({ partnership_id, status }) => {
        const url = `${ApiPaths.DEACTIVATE.replace(/partnership_id/gi, String(partnership_id))}`;
        return {
          url: url,
          method: 'PATCH',
          body: {
            status,
          },
        };
      },
      transformResponse: (response: TpartnershipStatusResponse): TPartnershipStatus => {
        return response.status;
      },
      async onQueryStarted({ technician_id, partnership_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: status } = await queryFulfilled;
          dispatch(
            payoutsAPI.util.updateQueryData('getPartnerships', { technician_id }, (draft) => {
              return draft.map((item) => {
                if (item.id === partnership_id) {
                  return {
                    ...item,
                    status,
                  };
                } else {
                  return item;
                }
              });
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),
  }),
});
