import {
  TAddressData,
  TAddressResponse,
  TTechnician,
  TTechnicianResponse,
} from '@core/redux/models/profile';

export const createTechnician = (response: TTechnicianResponse): TTechnician => {
  return {
    expertId: response.id,
    nickname: response.nickname,
    firstName: response.first_name,
    lastName: response.last_name,
    phone: response.phone,
    email: response.email,
  };
};

export const createAddress = (response?: TAddressResponse): TAddressData => {
  return {
    id: response?.id || NaN,
    street: response?.street || '',
    apartment: response?.apartment || '',
    city: response?.zip_code.city.name || '',
    stateCode: response?.zip_code.city.state || '',
    postalCode:
      response?.zip_code.code && response?.zip_code.suffix
        ? `${response?.zip_code.code}-${response?.zip_code.suffix}`
        : '',
    googleUid: response?.zip_code.google_uid || '',
  };
};
