import React, { useRef, useState } from 'react';
import * as styles from './MainPage.module.scss';
import cn from 'classnames';
import { TypeWork } from './types';
import Intro from './components/Intro/Intro';
import PageInfo from './components/PageInfo/PageInfo';

export default function MainPage() {
  const pageInfoRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<TypeWork | null>(null);
  const [hidden, setHidden] = useState<TypeWork | null>(null);

  const handleClick = (type: TypeWork) => {
    if (!!open && pageInfoRef.current) {
      pageInfoRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setOpen(type);
    setHidden(type);
    // setTimeout(() => {
    //   setHidden(type);
    // }, 300);
  };

  const handleClickFooter = (type: TypeWork) => {
    setOpen(type);
    setTimeout(() => {
      setHidden(type);
    }, 300);
    if (pageInfoRef.current) {
      pageInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickBack = () => {
    setOpen(null);
    setHidden(null);
  };

  return (
    <div className={styles.wrapper}>
      <Intro
        open={open}
        hidden={hidden}
        handleClick={handleClick}
        handleClickBack={handleClickBack}
      />
      <div className={cn({ [styles.hidden]: !open })}>
        {!!open && (
          <PageInfo ref={pageInfoRef} typeWork={open} handleClickFooter={handleClickFooter} />
        )}
      </div>
    </div>
  );
}
