import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import { ROUTES } from '@routes/constants';
import * as styles from './Claims.module.scss';

export default function Claims() {
  return (
    <div className={styles.wrap}>
      <NavLink to={ROUTES.CLAIMS} className={styles.link}>
        <Button size={ButtonSize.s} bgColor={ButtonBgColor.blue} className={styles.button}>
          Claims
        </Button>
      </NavLink>
    </div>
  );
}
