import React, { useCallback, useMemo, useState } from 'react';
import * as styles from './DrumDiagnosticDuration.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import Drum from '@components/Drum/Drum';

const IS_LOOPED = false;
const MIN_VALUE_INDEX = 3;

type TDrumDiagnosticDurationProps = {
  type: number;
  setIsShown: (val: boolean) => void;
};

export default function DrumDiagnosticDuration(props: TDrumDiagnosticDurationProps) {
  const { drumOption } = useAppSelector((state) => state.jobTypesReducer);
  const { type, setIsShown } = props;

  const { indexTime, workId } = drumOption;
  const { updateWorkDAD } = useActions();
  const [selectedValueIndex, setSelectedValueIndex] = useState<number>(
    IS_LOOPED ? indexTime : indexTime + MIN_VALUE_INDEX,
  );

  const adjustedValues = useMemo<number[]>(
    () => [
      ...Array(MIN_VALUE_INDEX).fill(0),
      ...Array.from({ length: 291 }, (_, index) => index + 10),
      ...Array(MIN_VALUE_INDEX).fill(0),
    ],
    [],
  );

  const handleSaveTime = useCallback(() => {
    updateWorkDAD({
      dad: {
        workId,
        dad: adjustedValues[Math.floor(selectedValueIndex)],
      },
      type: type,
    });
    setIsShown(false);
  }, [adjustedValues, selectedValueIndex, type]);

  return (
    <div className={styles.selector}>
      <Drum
        adjustedValues={adjustedValues}
        isLooped={IS_LOOPED}
        setSelectedValueIndex={setSelectedValueIndex}
        selectedValueIndex={selectedValueIndex}
        indexTime={indexTime}
        displayText={(value) => (value === 0 ? '' : `${value} min`)}
      />
      <div className={styles.buttonWrapper}>
        <Button bgColor={ButtonBgColor.grey} className={styles.button} onClick={handleSaveTime}>
          Save the time
        </Button>
      </div>
    </div>
  );
}
