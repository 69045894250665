import React, { useCallback, useState } from 'react';
import * as styles from './BankDetails.module.scss';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import MainButton from '../MainButton/MainButton';
import { TServerError, parseServerError } from '@core/utils/error';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import BankDetailsForm from './BankDetailsForm/BankDetailsForm';
import { DiscardText } from '@core/utils/utils';
import { ModuleAction } from '@core/redux/models/profile';

export default function BankDetails() {
  const { technicianId } = useCurrentUser();

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const { setBankDetailsAction, setBankDetailsFormData } = useActions();
  const { needDisabled } = useAppSelector((state) => state.profileReducer);
  const { bankDetailsAction, bankDetailsFormData, hasBankDetailsFormError } = useAppSelector(
    (state) => state.bankDetailsReducer,
  );

  const {
    data: bankDetailsData,
    isLoading: isBankDetailsLoading,
    refetch,
    isFetching,
  } = profileAPI.useGetBankDetailsQuery({
    id: technicianId,
  });

  const handleConfirm = useCallback(() => {
    setConfirmModal(false);
    refetch();
  }, []);

  const [handleUpdateBankDetails, { error: bankDetailsUpdateError, isLoading: isLoadingUpdate }] =
    profileAPI.useUpdateBankDetailsMutation();

  const submitBankDetails = useCallback(async () => {
    try {
      const result = await handleUpdateBankDetails({ id: technicianId, ...bankDetailsFormData });
      if (result && 'error' in result) {
        setErrorModal(true);
        return;
      }
      setBankDetailsFormData({
        accountType: result.data.accountType,
        financeType: result.data.financeType,
        accountNumber: result.data.accountNumber,
        routingNumber: result.data.routingNumber,
      });
    } catch (e) {
      console.log(e);
    }
  }, [bankDetailsFormData, bankDetailsData]);

  return (
    <>
      <section className={styles.wrapper}>
        <TitleWithButton
          title={'Bank details:'}
          disabled={needDisabled && bankDetailsAction === ModuleAction.send}
        >
          {isBankDetailsLoading ? (
            <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
          ) : (
            <MainButton
              action={bankDetailsAction}
              setAction={setBankDetailsAction}
              refreshDataFunction={() => setConfirmModal(true)}
              resetIsLoading={isFetching}
              isLoading={isLoadingUpdate}
              sendDataFunction={submitBankDetails}
              isSendError={!!bankDetailsUpdateError}
              sendDisabled={hasBankDetailsFormError}
            />
          )}
        </TitleWithButton>
        {isBankDetailsLoading ? (
          <Skeleton
            width='100%'
            height={42}
            variant='rectangular'
            className={styles.skeletonForm}
          />
        ) : (
          <>
            {!!bankDetailsData && (
              <BankDetailsForm bankDetails={bankDetailsData} isFetching={isFetching} />
            )}
          </>
        )}
      </section>
      {!!bankDetailsUpdateError && errorModal && (
        <ErrorModal
          setIsShown={setErrorModal}
          errorMessage={parseServerError(bankDetailsUpdateError as TServerError)}
        />
      )}
      {confirmModal && (
        <ConfirmModalPage
          header={'Discard editing bank details?'}
          headerClassName={styles.modalHeader}
          text={DiscardText('bank details')}
          textClassName={styles.modalText}
          handleConfirm={handleConfirm}
          setIsShown={() => setConfirmModal(false)}
          confirmButtonText={'Discard'}
          cancelButtonText={'Cancel'}
        />
      )}
    </>
  );
}
