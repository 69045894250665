import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';

type TAuthState = {
  token: string;
};
const cookies = new Cookies();

const initialState: TAuthState = {
  token: cookies.get('ehb_t') || '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<string>) {
      state.token = action.payload;
      cookies.set('ehb_t', action.payload, { path: '/' });
    },
    logOut(state, action: PayloadAction<string>) {
      state.token = action.payload;
      cookies.remove('ehb_t', { path: '/' });
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
