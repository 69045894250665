import React, { ReactNode } from 'react';
import { InputType, InputMode, SeparatorMode } from '@components/Input/types';
import InputMask from 'react-input-mask';
import Input from '@components/Input/Input';
import * as styles from './Input.module.scss';

type PhoneInputProps = {
  type: InputType;
  label?: string;
  value?: string | number;
  name: string;
  mask?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  mode?: InputMode;
  innerRef?: (ref: HTMLInputElement) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  classNameError?: string;
  top?: ReactNode;
  separator?: boolean;
  separatorMode?: SeparatorMode;
  beforeIcon?: ReactNode;
};

export default function PhoneInput(props: PhoneInputProps) {
  const {
    error,
    top,
    innerRef,
    mode = InputMode.standard,
    separator,
    separatorMode = SeparatorMode.all,
    className,
    classNameError,
    beforeIcon,
    mask = '+1 (999) 999 9999',
    ...commonProps
  } = props;

  const getInput = (inputProps: PhoneInputProps) => {
    return (
      <Input
        {...inputProps}
        className={className}
        classNameError={classNameError}
        mode={mode}
        separator={separator}
        beforeIcon={beforeIcon}
        separatorMode={separator ? separatorMode : undefined}
        error={error}
      />
    );
  };
  return (
    <InputMask className={styles.phoneInput} mask={mask} maskChar={'0'} {...commonProps}>
      {
        // @ts-ignore
        () => getInput(commonProps)
      }
    </InputMask>
  );
}
