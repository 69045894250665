import React from 'react';
import * as styles from './DiagnosticDurationModal.module.scss';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import DrumDiagnosticDuration from './DrumDiagnosticDuration/DrumDiagnosticDuration';

type TDiagnosticDurationProps = {
  type: number;
  setIsShown: (val: boolean) => void;
};

export default function DiagnosticDurationModal(props: TDiagnosticDurationProps) {
  const { type, setIsShown } = props;

  return (
    <ModalPage
      header={<p className={styles.title}>Diagnostic duration</p>}
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={() => setIsShown(false)}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
      closeable={false}
    >
      <DrumDiagnosticDuration type={type} setIsShown={setIsShown} />
    </ModalPage>
  );
}
