import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  TAuthData,
  TAuthResponse,
  TAgencyRegistrationResponse,
  TExpertRegistrationResponse,
  TExpertRegistrationData,
  TAgencyRegistrationData,
  TPasswordResetResponse,
  TPasswordResetData,
  TPasswordChangeResponse,
  TPasswordChangeData,
  TPasswordConfirmResponse,
  TPasswordConfirmData,
} from '../models/auth';
import { baseQuery, customFetchBaseQuery } from './mainService';
import { getTrimedValue } from '@core/utils/common';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: customFetchBaseQuery(baseQuery),

  endpoints: (build) => ({
    login: build.mutation<TAuthResponse, TAuthData>({
      query: (data) => {
        return {
          url: '/api/auth/login/',
          method: 'POST',
          body: { login: getTrimedValue(data.username), password: getTrimedValue(data.password) },
        };
      },
    }),
    logout: build.query<void, void>({
      query: () => {
        return {
          url: '/api/auth/logout/',
          method: 'GET',
        };
      },
    }),
    registrationAgency: build.mutation<TAgencyRegistrationResponse, TAgencyRegistrationData>({
      query: (data) => {
        return {
          url: '/api/auth/register/agency/',
          method: 'POST',
          body: {
            title: data.name,
            user: {
              first_name: getTrimedValue(data.firstName),
              last_name: getTrimedValue(data.lastName),
              phone: getTrimedValue(data.phone),
              email: getTrimedValue(data.email),
            },
          },
        };
      },
    }),
    registrationExpert: build.mutation<TExpertRegistrationResponse, TExpertRegistrationData>({
      query: (data) => {
        return {
          url: '/api/auth/register/expert/',
          method: 'POST',
          body: {
            first_name: getTrimedValue(data.firstName),
            last_name: getTrimedValue(data.lastName),
            phone: getTrimedValue(data.phone),
            email: getTrimedValue(data.email),
          },
        };
      },
    }),
    passwordResetConfirm: build.mutation<TPasswordConfirmResponse, TPasswordConfirmData>({
      query: (data) => {
        return {
          url: '/api/auth/password-reset-confirm/',
          method: 'POST',
          body: {
            uidb64: data.id,
            token: data.token,
          },
        };
      },
    }),
    passwordReset: build.mutation<TPasswordResetResponse, TPasswordResetData>({
      query: (data) => {
        return {
          url: '/api/auth/password-reset/',
          method: 'POST',
          body: {
            email: getTrimedValue(data.email),
          },
        };
      },
    }),
    passwordChange: build.mutation<TPasswordChangeResponse, TPasswordChangeData>({
      query: (data) => {
        return {
          url: '/api/auth/password-change/',
          method: 'POST',
          body: {
            user_id: data.userId,
            password: getTrimedValue(data.password),
            reset_token: data.resetToken,
          },
        };
      },
    }),
  }),
});
