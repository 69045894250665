import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import * as styles from './Input.module.scss';
import InputError from '@assets/images/inputError.svg';
import { InputType, InputMode, SeparatorMode } from './types';

type InputProps = {
  type: InputType;
  label?: string;
  value?: string | number;
  name: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  mode?: InputMode;
  innerRef?: (ref: HTMLInputElement) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  classNameError?: string;
  top?: ReactNode;
  separator?: boolean;
  separatorMode?: SeparatorMode;
  beforeIcon?: ReactNode;
  readOnly?: boolean;
  pattern?: string;
};

export default function Input(props: InputProps) {
  const {
    error,
    top,
    innerRef,
    beforeIcon,
    mode = InputMode.standard,
    separator,
    separatorMode = SeparatorMode.all,
    className,
    classNameError,
    readOnly,
    ...commonProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const ua = navigator.userAgent;

  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);
  const isMac = /Macintosh/i.test(ua);

  return (
    <div
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={cn(styles.wrapper, styles[mode])}
    >
      {top && <div className={styles.top}>{top}</div>}
      {!!beforeIcon && <div className={styles.icon}>{beforeIcon}</div>}
      <input
        ref={innerRef}
        readOnly={readOnly}
        className={cn(styles.input, { [styles.beforeIcon]: beforeIcon }, className)}
        {...commonProps}
      />
      {mode === InputMode.group && separator && (
        <span className={cn(styles.separator, styles[separatorMode])}></span>
      )}
      {error && (
        <div
          className={cn(styles.error, classNameError, {
            [styles.errorFocused]: isSafari && isFocused && isMac,
          })}
        >
          <InputError fill='var(--light_accent_negative)' />
        </div>
      )}
    </div>
  );
}
