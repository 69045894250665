import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as styles from './ServiceAreas.module.scss';
import cn from 'classnames';
import { Skeleton } from '@mui/material';
import { Loader } from '@googlemaps/js-api-loader';
import Map from './components/Map/Map';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import Button from '@components/Button/Button';
import Cancel from '@assets/images/cancel.svg';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import { useActions } from '@core/hooks/actions';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import MainButton from '../MainButton/MainButton';
import ListCities from './components/ListCities/ListCities';
import { useAppSelector } from '@core/hooks/redux';
import { createWorkAreaForRequest } from './utils';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { TNewWorkArea, TWorkArea } from '@core/redux/models/serviceAreas';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { DiscardText, useModuleActionType, useWorkAreaStatusType } from '@core/utils/utils';
import Spinner from '@components/Spinner/Spinner';
import { ModuleAction } from '@core/redux/models/profile';

const API_KEY = 'AIzaSyAq6JQ57wiSeaF47mNGwn0W-xFTK6kxGII';

export default function ServiceAreas() {
  const { technicianId } = useCurrentUser();
  const {
    setWorkAreasData,
    setFullScreen,
    setServiceAreasAction,
    setInfoArea,
    setMapPosition,
    setZoom,
  } = useActions();
  const { isFullScreen, serviceAreasAction, cityPostals, mapWorkAreas, isLoadingArea } =
    useAppSelector((state) => state.serviceAreasReducer);
  const { needDisabled } = useAppSelector((state) => state.profileReducer);

  const [isLoadingMap, setIsLoadingMap] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const rectangleRef = useRef<HTMLDivElement>(null);

  const { isSend } = useModuleActionType(serviceAreasAction);

  const {
    data: workAreas,
    isLoading: zoneAreasIsLoading,
    refetch,
    isFetching,
  } = profileAPI.useGetWorkAreasQuery({
    id: technicianId,
  });

  const { data: mapPosition, isLoading: isLoadingMapPosition } = profileAPI.useGetMapPositionQuery({
    id: technicianId,
  });

  const [handleAddNewWorkArea, { isLoading: isLoadingNewWorkAreaData }] =
    profileAPI.useAddNewWorkAreaMutation();
  const [handleUpdateWorkArea, { isLoading: isLoadingUpdateWorkAreaData }] =
    profileAPI.useUpdateWorkAreaMutation();
  const [handleDeleteWorkArea, { isLoading: isLoadingDeleteWorkAreaData }] =
    profileAPI.useDeleteWorkAreaMutation();

  const closeFullScreen = useCallback(() => {
    setInfoArea(null);
    setFullScreen(false);
  }, []);

  const handleRefetch = useCallback(() => {
    setIsConfirmModal(true);
  }, []);

  const handleConfirm = useCallback(() => {
    setIsConfirmModal(false);
    refetch();
  }, []);

  const handleSendServiceAreas = useCallback(async () => {
    let error = false;

    const mapWorkAreaPromise = mapWorkAreas.map(
      async (workArea: TNewWorkArea | TWorkArea): Promise<TWorkArea | undefined> => {
        let response;
        const { isNewActive, isNewDelete } = useWorkAreaStatusType(workArea?.status);

        try {
          switch (true) {
            case isNewActive && workArea.isDeleted && 'id' in workArea:
              response = await handleUpdateWorkArea({ id: workArea.id });
              break;
            case isNewActive:
              response = await handleAddNewWorkArea(
                createWorkAreaForRequest(workArea, technicianId),
              );
              break;
            case isNewDelete && 'id' in workArea:
              response = await handleDeleteWorkArea({ id: workArea.id });
              break;
          }
        } catch (e) {
          error = true;
          console.warn(e);
        }

        if (response && 'error' in response) {
          error = true;
        } else {
          return response ? response.data : (workArea as TWorkArea);
        }
      },
    );

    const result = (await Promise.all(mapWorkAreaPromise)) as TWorkArea[];

    if (error) {
      setIsModalOpen(true);
      setIsError(true);
    } else {
      setWorkAreasData(result);
      setIsError(false);
    }
  }, [mapWorkAreas]);

  useEffect(() => {
    if (workAreas && !isFetching) {
      setWorkAreasData(workAreas);
    }
  }, [workAreas, isFetching]);

  useEffect(() => {
    if (mapPosition) {
      setMapPosition(mapPosition.center);
      setZoom(mapPosition.zoom);
    }
  }, [mapPosition]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: API_KEY,
      version: 'weekly',
    });

    try {
      loader.load().then(() => {
        if (window.google && window.google.maps) {
          setIsLoadingMap(false);
        }
      });
    } catch (e) {
      setIsError(true);
    }
  }, []);

  const isLoadingData = isLoadingMap || zoneAreasIsLoading || isLoadingMapPosition;
  const isLoading =
    isLoadingNewWorkAreaData || isLoadingDeleteWorkAreaData || isLoadingUpdateWorkAreaData;

  useEffect(() => {
    if (rectangleRef.current) {
      rectangleRef.current.ondblclick = () => {
        setFullScreen(true);
      };

      return () => {
        if (rectangleRef.current) rectangleRef.current.ondblclick = null;
      };
    }
  }, [isLoadingData]);

  useEffect(() => {
    if (isSend) {
      closeFullScreen();
    }
  }, [isSend]);

  return (
    <section className={styles.wrapper}>
      <TitleWithButton
        title={'Service areas:'}
        isStatic={isFullScreen}
        disabled={needDisabled && serviceAreasAction === ModuleAction.send}
      >
        {isLoadingData ? (
          <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
        ) : (
          <div className={cn({ [styles.mainButtonWrapper]: isFullScreen })}>
            <MainButton
              refreshDataFunction={handleRefetch}
              resetIsLoading={isFetching}
              isLoading={isLoading}
              sendDataFunction={handleSendServiceAreas}
              setAction={setServiceAreasAction}
              action={serviceAreasAction}
              isSendError={isError}
              classNameCancel={cn({ [styles.buttonCancelMainButton]: isSend && isFullScreen })}
            />
            {isFullScreen && isSend && (
              <Button
                bgColor={ButtonBgColor.white}
                mode={ButtonMode.icon}
                className={styles.buttonCancel}
                onClick={closeFullScreen}
              >
                <Cancel fill='var(--light_accent_main)' />
              </Button>
            )}
          </div>
        )}
      </TitleWithButton>
      {isLoadingData ? (
        <Skeleton width='100%' height={657} variant='rectangular' className={styles.skeletonForm} />
      ) : (
        <>
          <div className={isFullScreen ? styles.mapFullScreen : styles.map}>
            <Map />
            <div
              ref={rectangleRef}
              tabIndex={0}
              className={isFullScreen ? styles.rectangleDisable : styles.rectangle}
            >
              <p className={styles.rectangleText}>Double tap to open</p>
            </div>
            {isFullScreen && (
              <div className={styles.infoWrapper}>
                <p className={styles.textMode}>{isSend ? 'Viewing mode' : 'Edit mode'}</p>
                {isLoadingArea && (
                  <div className={styles.spinnerWrapper}>
                    <Spinner color='var(--light_accent_main)' />
                  </div>
                )}
              </div>
            )}
          </div>
          {Object.keys(cityPostals).length !== 0 && <ListCities />}
        </>
      )}
      {isConfirmModal && (
        <ConfirmModalPage
          header={'Discard editing service area?'}
          headerClassName={styles.modalHeader}
          text={DiscardText('service area')}
          textClassName={styles.modalText}
          handleConfirm={handleConfirm}
          setIsShown={setIsConfirmModal}
          confirmButtonText={'Discard'}
          cancelButtonText={'Cancel'}
        />
      )}
      {isModalOpen && isError && (
        <ErrorModal setIsShown={setIsModalOpen} errorMessage={'Try again'} />
      )}
    </section>
  );
}
