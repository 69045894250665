// extracted by mini-css-extract-plugin
var _1 = "ClaimModal-module__autor___fXzJ";
var _2 = "ClaimModal-module__chat__jkAsN";
var _3 = "ClaimModal-module__header__IeiaD";
var _4 = "ClaimModal-module__message__cI5k1";
var _5 = "ClaimModal-module__messageBlock__rfrC1";
var _6 = "ClaimModal-module__messages__yFWXM";
var _7 = "ClaimModal-module__modal__D0JUH";
var _8 = "ClaimModal-module__modalButton__vnOWC";
var _9 = "ClaimModal-module__text__aUbVi";
var _a = "ClaimModal-module__textTitle__vOwmx";
var _b = "ClaimModal-module__title__b87ka";
export { _1 as "autor", _2 as "chat", _3 as "header", _4 as "message", _5 as "messageBlock", _6 as "messages", _7 as "modal", _8 as "modalButton", _9 as "text", _a as "textTitle", _b as "title" }

    if(module.hot) {
      // 1733466107685
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  