import { ModuleAction } from './profile';

export enum WorkAreaStatus {
  active = 'active',
  delete = 'delete',
  newActive = 'newActive',
  newDelete = 'newDelete',
  answerGoogle = 'answerGoogle',
}

export type TZip = {
  city: {
    name: string;
    state: string;
  };
  code: string;
  longitude: number;
  latitude: number;
  google_uid: string;
};

export type TWorkAreasResponse = {
  id: number;
  zip: TZip;
  is_deleting: boolean;
  delete_timeout: string | null;
  created_at: string;
};

export type TWorkAreasPostRequest = {
  zip: TZip;
  technician_id: number;
};

export type TWorkAreasValidateRequest = {
  active_zips: TZip[];
  new_zip: TZip;
  technician_id: number;
};

export type TNewWorkArea = {
  cityName: string;
  state: string;
  code: string;
  googleUid: string;
  longitude: number;
  latitude: number;
  isDeleted: boolean;
  deleteTimeout: string | null;
  status: WorkAreaStatus;
};

export type TWorkArea = TNewWorkArea & {
  id: number;
  createdAt: string;
};

export type TWorkAreaValidateResponse = {
  postalCodes: {
    [key: string]: string[];
  };
  googleUidList: string[];
  amount: number;
  amountCities: number;
};

export type TCenterCoordinate = {
  lat: number;
  lng: number;
};

export type TMapPositionResponse = TCenterCoordinate & {
  zoom: number;
};

export type TMapPosition = {
  center: TCenterCoordinate;
  zoom: number;
};

export type TServiceAreasState = TMapPosition & {
  serviceAreasAction: ModuleAction;
  cityPostals: {
    [key: string]: {
      workAreas: TWorkArea[];
      isDelete: boolean;
    };
  };
  mapWorkAreas: (TNewWorkArea | TWorkArea)[];
  isFullScreen: boolean;
  infoArea: string | null;
  isLoadingArea: boolean;
};

export type TAfterValidate = {
  newWorkArea: TNewWorkArea;
  googleUidList: string[];
  status: WorkAreaStatus;
};

export type TChangeWorkAreaStatus = {
  workArea: TNewWorkArea;
  status: WorkAreaStatus;
};
