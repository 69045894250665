import { payoutsAPI } from '@core/redux/services/payoutsService';
import React, { useEffect, useState } from 'react';
import * as styles from './PaymentForm.module.scss';
import Button from '@components/Button/Button';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import cn from 'classnames';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import WeeksModal from './components/WeeksModal/WeeksModal';
import { Mode, TWeek, TPartnershipElement } from '@core/redux/models/payouts';
import PayoutsIcon from '@assets/images/payout.svg';
import { Skeleton } from '@mui/material';
import { numbersWithCurrency } from '@core/utils/numbers';
import { TServerError } from '@core/utils/error';

type TProps = {
  weeks: TWeek[] | undefined;
  partnership: TPartnershipElement;
  currentWeek: TWeek;
};

export default function PaymentForm(props: TProps) {
  const { weeks, partnership, currentWeek } = props;
  const { setMode, setIsFilterBlockActive, setActiveWeek, setOutstandingBalanceWeek, setError } =
    useActions();

  const { mode, isFilterBlockActive, outstandingBalanceWeek } = useAppSelector(
    (state) => state.payoutsReducer,
  );

  useEffect(() => {
    if (weeks) {
      const activeWeek = weeks.find((item) => item.isPaymentWeek);
      const outstandingBalanceWeekIndex = weeks.findIndex((item) => currentWeek?.id === item.id);
      setOutstandingBalanceWeek(weeks[outstandingBalanceWeekIndex + 1]);
      if (activeWeek) {
        setActiveWeek(activeWeek);
      }
    }
  }, [weeks, currentWeek]);

  const {
    data: payouts,
    isFetching: isFetchingPayouts,
    error: payoutsError,
  } = payoutsAPI.useGetPayoutsQuery({
    partnership_id: partnership.id,
    week_id: currentWeek.id,
    start: currentWeek.start,
    end: currentWeek.end,
  });

  const {
    data: changesPayouts,
    isFetching: isFetchingChanges,
    error: changesError,
  } = payoutsAPI.useGetChangesAmountQuery({
    partnership_id: partnership.id,
    week_id: currentWeek.id,
  });

  const {
    data: outstandingBalance,
    isFetching: isFetchingOutstandingBalance,
    error: outstandingBalanceError,
  } = payoutsAPI.useGetOutstandingBalanceQuery({
    partnership_id: partnership.id,
    week_id: outstandingBalanceWeek?.id || currentWeek.id,
  });
  const {
    data: payments,
    isFetching: isFetchingPayments,
    error: paymentsError,
  } = payoutsAPI.useGetPaymentsQuery({ partnership_id: partnership.id, week_id: currentWeek.id });

  useEffect(() => {
    if (payoutsError) {
      setError(payoutsError as TServerError);
    } else if (changesError) {
      setError(changesError as TServerError);
    } else if (outstandingBalanceError) {
      setError(outstandingBalanceError as TServerError);
    } else if (paymentsError) {
      setError(paymentsError as TServerError);
    }
  }, [payoutsError, changesError, outstandingBalanceError, paymentsError]);

  const [modal, setModal] = useState<boolean>(false);
  const paid =
    payments && payments.length && payments[0].amount
      ? numbersWithCurrency(payments[0].amount)
      : '$0';
  const balance =
    outstandingBalance && outstandingBalance.balance
      ? numbersWithCurrency(outstandingBalance.balance)
      : '$0';

  const getColor = (value: string) => {
    const num = Number(value);
    if (num === 0) {
      return styles.grey;
    } else if (num < 0) {
      return styles.red;
    } else {
      return styles.green;
    }
  };
  return (
    <>
      <div className={cn(styles.wrap, { [styles.wrapActive]: !isFilterBlockActive })}>
        <Button
          size={ButtonSize.s}
          bgColor={ButtonBgColor.grey}
          onClick={() => setModal(true)}
          className={styles.weekButton}
          beforeIcon={<span className={styles.weekIcon}>{currentWeek?.number}</span>}
        >
          {currentWeek ? `${currentWeek.start} - ${currentWeek.end}` : '-'}
        </Button>

        <div className={styles.total}>
          {isFetchingPayouts || !payouts ? (
            <Skeleton height={42} width={100} variant='rectangular' className={styles.skeleton} />
          ) : (
            <div
              onClick={() => setMode(Mode.total)}
              className={cn(styles.totalButton, { [styles.active]: mode === Mode.total })}
            >
              <span className={styles.title}>Total by week</span>
              <span className={styles.value}>
                {numbersWithCurrency(payouts.commissionSubtotal)}
              </span>
            </div>
          )}

          <div className={styles.separator}></div>
          {isFetchingChanges || !changesPayouts ? (
            <Skeleton height={42} width={100} variant='rectangular' className={styles.skeleton} />
          ) : (
            <div
              onClick={() => setMode(Mode.changes)}
              className={cn(styles.totalButton, { [styles.active]: mode === Mode.changes })}
            >
              <span className={styles.title}>Changes amount</span>
              <span className={styles.value}>
                {numbersWithCurrency(changesPayouts.changesSubtotal)}
              </span>
            </div>
          )}
        </div>
        <div className={styles.info}>
          {isFetchingOutstandingBalance || !outstandingBalance ? (
            <Skeleton height={18} width='100%' variant='rectangular' className={styles.skeleton} />
          ) : (
            <div className={styles.item}>
              <span className={styles.description}>Outstanding balance</span>
              <span className={styles.description}>{balance}</span>
            </div>
          )}
          {isFetchingPayouts ||
          !payouts ||
          isFetchingChanges ||
          !changesPayouts ||
          outstandingBalance?.balance === undefined ? (
            <Skeleton height={18} width='100%' variant='rectangular' className={styles.skeleton} />
          ) : (
            <div className={styles.item}>
              <span className={styles.description}>Total payment</span>
              <span className={styles.description}>
                {numbersWithCurrency(
                  payouts.commissionSubtotal +
                    changesPayouts.changesSubtotal +
                    outstandingBalance.balance,
                )}
              </span>
            </div>
          )}
          {isFetchingPayments || !payments ? (
            <Skeleton height={18} width='100%' variant='rectangular' className={styles.skeleton} />
          ) : (
            <div className={styles.item}>
              <span className={styles.description}>Paid</span>
              <span
                className={cn(
                  styles.description,
                  getColor(payments.length ? payments[0]?.amount : '0'),
                )}
              >
                {paid}
              </span>
            </div>
          )}
        </div>
        {modal && weeks && (
          <WeeksModal setIsShown={setModal} data={weeks} currentWeek={currentWeek} />
        )}
      </div>
      {isFilterBlockActive && (
        <Button
          className={styles.paymentButton}
          beforeIcon={<PayoutsIcon fill='var(--light_accent_main)' />}
          bgColor={ButtonBgColor.white}
          size={ButtonSize.s}
          onClick={() => setIsFilterBlockActive(false)}
        >
          Payment week
        </Button>
      )}
    </>
  );
}
