import React, { ReactNode } from 'react';
import Button from '@components/Button/Button';
import * as styles from './Card.module.scss';

type CardProps = {
  icon: ReactNode;
  title: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

export default function Card(props: CardProps) {
  const { icon, text, title, onClick, disabled } = props;
  return (
    <Button className={styles.card} onClick={onClick} disabled={disabled}>
      <div className={styles.titleBlock}>
        {icon}
        <title className={styles.title}>{title}</title>
      </div>
      <div className={styles.text}>{text}</div>
    </Button>
  );
}
