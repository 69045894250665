// extracted by mini-css-extract-plugin
var _1 = "Postal-module__postal__LhhlX";
export { _1 as "postal" }

    if(module.hot) {
      // 1733466109734
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  