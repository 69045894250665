import React from 'react';
import * as styles from './Footer.module.scss';
import FooterLogoWeb from '@assets/images/footerLogoWeb.svg';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import { TypeWork } from '@pages/MainPage/types';

type FooterWebProps = {
  handleClickFooter: (val: TypeWork) => void;
};

export default function FooterWeb(props: FooterWebProps) {
  const { handleClickFooter } = props;

  return (
    <div className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.blockLogo}>
          <FooterLogoWeb fill='var(--light-background-primary)' />
          <p className={styles.text}>© 2024 Expert Hub </p>
        </div>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            <li>
              <Button
                className={styles.item}
                bgColor={ButtonBgColor.transparent}
                onClick={() => handleClickFooter(TypeWork.EXPERT)}
              >
                For experts
              </Button>
            </li>
            <li>
              <Button
                className={styles.item}
                bgColor={ButtonBgColor.transparent}
                onClick={() => handleClickFooter(TypeWork.AGENCY)}
              >
                For agencies
              </Button>
            </li>
            <li>
              <NavLink to={ROUTES.LOGIN} className={styles.item}>
                Log in
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.REGISTRATION} className={styles.item}>
                Register
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
