// extracted by mini-css-extract-plugin
var _1 = "Info-module__block__WaH71";
var _2 = "Info-module__button__NDQwl";
var _3 = "Info-module__buttonBlock__XGV3X";
var _4 = "Info-module__header__hLZCq";
var _5 = "Info-module__key__UKvZh";
var _6 = "Info-module__line__Po9cE";
var _7 = "Info-module__link__m8c6M";
var _8 = "Info-module__linkButton__UCpQH";
var _9 = "Info-module__separator__Q6rdK";
var _a = "Info-module__text__kWbEd";
var _b = "Info-module__value__FXCKO";
var _c = "Info-module__wrap__fsQPN";
export { _1 as "block", _2 as "button", _3 as "buttonBlock", _4 as "header", _5 as "key", _6 as "line", _7 as "link", _8 as "linkButton", _9 as "separator", _a as "text", _b as "value", _c as "wrap" }

    if(module.hot) {
      // 1733466104627
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  