import {
  isIdDocumentsResponse,
  isProofCompanyRegistrationDocumentResponse,
  isSsnOrW9IndividualResponse,
  isW9BusinessResponse,
} from '../types/documents';
import { Status, TDocument, TDocumentResponse } from '../../models/documents';

export const getCurrentDocumentsList = (response: TDocumentResponse[]): TDocument[] => {
  if (isProofCompanyRegistrationDocumentResponse(response)) {
    return response.map((item) => {
      const status = item.status.toLowerCase();
      return {
        id: item.id,
        fileName: item.file.name,
        startDate: item.start_date,
        expirationDate: item.expiration_date,
        status: status as Status,
        type: item.type.type,
        ownerFirstName: item.owner_first_name,
        ownerLastName: item.owner_last_name,
        yourCompanyTitle: item.contractor_title,
        companyAddress: item.contractor_company?.address?.display_address ?? '',
        companyName: item.contractor_company.name,
        companyType: item.contractor_company.type,
      };
    });
  } else if (isW9BusinessResponse(response)) {
    return response.map((item) => {
      const status = item.status.toLowerCase();
      return {
        id: item.id,
        fileName: item.file.name,
        startDate: item.start_date,
        ein: item.ein,
        businessName: item.company_name,
        status: status as Status,
        type: item.type.type,
        expirationDate: item.expiration_date,
      };
    });
  } else if (isSsnOrW9IndividualResponse(response)) {
    return response.map((item) => {
      const status = item.status.toLowerCase();
      return {
        id: item.id,
        fileName: item.file.name,
        startDate: item.start_date,
        firstName: item.first_name,
        lastName: item.last_name,
        status: status as Status,
        type: item.type.type,
        expirationDate: item.expiration_date,
        ssn: item.ssn,
      };
    });
  } else if (isIdDocumentsResponse(response)) {
    return response.map((item) => {
      const status = item.status.toLowerCase();
      return {
        id: item.id,
        fileName: item.file.name,
        startDate: item.start_date,
        expirationDate: item.expiration_date,
        status: status as Status,
        type: item.type.type,
        firstName: item.first_name,
        lastName: item.last_name,
      };
    });
  } else {
    return response.map((item) => {
      const status = item.status.toLowerCase();
      return {
        id: item.id,
        fileName: item.file.name,
        startDate: item.start_date,
        status: status as Status,
        type: item.type.type,
        expirationDate: item.expiration_date,
      };
    });
  }
};
