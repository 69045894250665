import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input';
import TextLogo from '@assets/images/textLogo.svg';
import { ButtonSize } from '@components/Button/types';
import { authAPI } from '@core/redux/services/authService';
import { NavLink, useParams } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import InformationPage from '../InformationPage/InformationPage';
import * as styles from './Reset.module.scss';
import { InputType } from '@components/Input/types';
import {
  parseHookFormMessage,
  parseServerError,
  TServerError,
  THookErrors,
} from '@core/utils/error';
import SupportMessage from '../SupportMessage/SupportMessage';

type ResetForm = {
  password: string;
  confirmPassword: string;
};

export default function Reset() {
  const [isSend, setIsSend] = useState<boolean>(false);
  const { id, token } = useParams();
  const [handleReset, { isLoading, isSuccess, error: serverError }] =
    authAPI.usePasswordChangeMutation();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<ResetForm>();

  useEffect(() => {
    if (isSuccess) {
      setIsSend(true);
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<ResetForm> = (data) => {
    try {
      if (data && id && token) {
        handleReset({
          userId: Number(id),
          password: data.password,
          resetToken: token,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { ref: passwordRef, ...passwordField } = register('password', {
    required: 'Password is required field',
  });

  const { ref: confirmPasswordRef, ...confirmPasswordField } = register('confirmPassword', {
    required: 'Confirm password is required field',
    validate: (value) => {
      if (watch('password') != value) {
        return 'Your passwords do no match';
      }
    },
  });

  const hasFormErrors = !!Object.keys(formErrors).length;

  return (
    <>
      {!isSend ? (
        <div className={styles.wrapper}>
          <TextLogo fill='var(--light_label_primary)' />
          <div className={styles.content}>
            <title className={styles.title}>Reset your password</title>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Input
                {...passwordField}
                type={InputType.password}
                innerRef={passwordRef}
                placeholder='Password'
                error={Boolean(formErrors.password)}
              />
              <Input
                {...confirmPasswordField}
                type={InputType.password}
                innerRef={confirmPasswordRef}
                placeholder='Confirm password'
                error={Boolean(formErrors.confirmPassword)}
              />
              <Button size={ButtonSize.l} type='submit' loading={isLoading}>
                Reset password
              </Button>
              {hasFormErrors && (
                <div className={styles.error}>
                  <p>{parseHookFormMessage(formErrors as THookErrors)}</p>
                  <SupportMessage />
                </div>
              )}
              {!!serverError && !hasFormErrors && (
                <div className={styles.error}>{parseServerError(serverError as TServerError)}</div>
              )}
            </form>
          </div>
          <div className={styles.textBlock}>
            <NavLink to={ROUTES.LOGIN} className={cn(styles.text, styles.blue)}>
              Back to login
            </NavLink>
          </div>
        </div>
      ) : (
        <InformationPage
          text='Your password was successfully changed. Please, return to login.'
          linkText='Back to login'
          route={ROUTES.LOGIN}
        />
      )}
    </>
  );
}
