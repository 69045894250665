import React, { useState } from 'react';
import cn from 'classnames';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import IconArrowDown from '@assets/images/arrow-down.svg';
import IconMail from '@assets/images/mail.svg';
import IconPhone from '@assets/images/phone.svg';
import * as styles from './ContactsModal.module.scss';
import { TContact } from '@core/redux/models/partnership';

type TProps = {
  setIsShown: (arg: boolean) => void;
  list: TContact[];
};

export default function ContactsModal(props: TProps) {
  const { setIsShown, list } = props;
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <ModalPage
      className={styles.modal}
      header={<div className={styles.header}>Contacts:</div>}
      setIsShown={setIsShown}
      mode={ModalPageMode.modal}
      closeable
    >
      <div className={styles.wrap}>
        <span className={styles.separator}></span>
        <div className={styles.block}>
          {list.map((item, index) => (
            <div
              key={`${item}-${index}`}
              onClick={() => setShowMore(!showMore)}
              className={styles.item}
            >
              <span className={styles.title}>
                {item.full_name}
                <IconArrowDown fill='var(--light_label_secondary)' />
              </span>
              {showMore && (
                <div className={styles.contactBlock}>
                  {item.phone && (
                    <a className={styles.button} href={`tel:${item.phone}`} title={item.phone}>
                      <IconPhone fill='var(--light-background-primary)' />
                      <span className={styles.text}>{item.phone}</span>
                    </a>
                  )}

                  {item.email && (
                    <a
                      className={cn(styles.button, styles.email)}
                      href={`mailto:${item.email}`}
                      title={item.email}
                    >
                      <IconMail fill='var(--light-background-primary)' />
                      <span className={styles.text}>{item.email}</span>
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ModalPage>
  );
}
