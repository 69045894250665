import React, { useState } from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import * as styles from './PenaltyModal.module.scss';
import ChatIcon from '@assets/images/chat.svg';
import { TPenalty } from '@core/redux/models/partnership';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import ClaimModal from '../ClaimModal/ClaimModal';
import { TPartnershipElement } from '@core/redux/models/payouts';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';

type DocumentsInfoModalProps = {
  setIsShown: (val: boolean) => void;
  penalty: TPenalty;
  partnership: TPartnershipElement;
};

export default function PenaltyModal(props: DocumentsInfoModalProps) {
  const { setIsShown, penalty, partnership } = props;
  const [claimModal, setClaimModal] = useState<boolean>(false);

  return (
    <>
      <ModalPage
        header={
          <>
            <div className={styles.title}>Details:</div>
            <span className={styles.separator}></span>
          </>
        }
        headerClassName={styles.header}
        mode={ModalPageMode.modal}
        setIsShown={setIsShown}
        overlayColor={ModalPageOverlayColor.standard}
        className={styles.modal}
      >
        <div className={styles.block}>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Penalty:</span>
            <span className={styles.text}>{penalty.penalty}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Invoice:</span>
            <span className={styles.text}>{penalty.invoice}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Description:</span>
            <span className={styles.text}>{penalty.description}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Status:</span>
            <span className={styles.text}>{penalty.status ? 'Active' : 'Inactive'}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Claim:</span>
            <span className={styles.text}>
              <Button
                mode={ButtonMode.icon}
                bgColor={ButtonBgColor.transparent}
                onClick={() => setClaimModal(true)}
              >
                <ChatIcon fill='var(--light_accent_supplemental)' />
              </Button>
            </span>
          </div>
          <div className={styles.line}>
            <span className={styles.lineTitle}>Expiration date:</span>
            <span className={styles.text}>{getDdMonthYyyyFormatDate(penalty.expirationDate)}</span>
          </div>
        </div>
      </ModalPage>
      {claimModal && (
        <ClaimModal partnership={partnership} setClaimModal={setClaimModal} penalty={penalty} />
      )}
    </>
  );
}
