// extracted by mini-css-extract-plugin
var _1 = "Spinner-module__large__BA7el";
var _2 = "Spinner-module__medium__IKAEw";
var _3 = "Spinner-module__small__Au4G7";
var _4 = "Spinner-module__spin__fu7I_";
var _5 = "Spinner-module__spinner__dbNvJ";
var _6 = "Spinner-module__wrapper__JVQQA";
export { _1 as "large", _2 as "medium", _3 as "small", _4 as "spin", _5 as "spinner", _6 as "wrapper" }

    if(module.hot) {
      // 1733466105441
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  