import { TScheduleTime } from '@core/redux/models/schedule';
import { strToMins } from '@core/utils/date';

export const getMergedDaySchedule = (list: TScheduleTime[]): TScheduleTime[] => {
  let start: string;
  let end: string;
  const newList: TScheduleTime[] = [];

  if (list.length === 1) {
    return list;
  }
  list.forEach((item, index) => {
    if (item.isDeleted) {
      if (start && end) {
        newList.push({ start, end });
      }
      newList.push(item);
      start = '';
      end = '';
    } else if (!start && !end) {
      start = item.start;
      end = item.end;
      if (index === list.length - 1) {
        newList.push({ start, end });
      }
    } else if (strToMins(end) < strToMins(item.start)) {
      newList.push({ start, end });
      if (index === list.length - 1) {
        newList.push(item);
      }
      start = item.start;
      end = item.end;
    } else if (end === item.start) {
      end = item.end;
      if (index === list.length - 1) {
        newList.push({ start, end });
      }
    }
  });

  return newList;
};

export const getIsTimeBarrierTop = (topBarrier: string, start: string): boolean => {
  if (!topBarrier || !start) {
    return false;
  }
  return strToMins(topBarrier) >= strToMins(start);
};
