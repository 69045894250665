import React, { useState } from 'react';
import * as styles from './WorkInfo.module.scss';
import cn from 'classnames';
import WorkInfoOne from '@assets/images/WorkInfoOne.png';
import WorkInfoTwo from '@assets/images/WorkInfoTwo.png';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import WorkInfoExpert from './components/WorkInfoExpert';
import WorkInfoAgency from './components/WorkInfoAgency';

type WorkInfoProps = {
  isExpert: boolean;
};

const WORK_INFO_TEXT_EXPERT = 'Find local jobs NOW. Create your profile.';
const WORK_INFO_TEXT_AGENCY = 'Hire experts NOW. Create your profile.';

export default function WorkInfo(props: WorkInfoProps) {
  const { isExpert } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <section className={styles.wrapper}>
      <h2 className={cn(styles.title, isExpert ? styles.supplemental : styles.inert)}>
        How it works?
      </h2>
      <div className={styles.infoBlock}>
        <div
          className={styles.imageBlock}
          style={{
            backgroundImage: isExpert ? `url(${WorkInfoOne})` : `url(${WorkInfoTwo})`,
          }}
        ></div>
        {isExpert ? <WorkInfoExpert /> : <WorkInfoAgency />}
        <div
          className={cn(
            styles.registerBlock,
            isFocus ? styles.white : isExpert ? styles.supplemental : styles.inert,
          )}
        >
          <h3
            className={cn(
              styles.subtitle,
              isFocus ? (isExpert ? styles.supplemental : styles.inert) : styles.white,
            )}
          >
            {isExpert ? WORK_INFO_TEXT_EXPERT : WORK_INFO_TEXT_AGENCY}
          </h3>
          <NavLink to={ROUTES.REGISTRATION} className={styles.link}>
            <Button
              className={styles.button}
              bgColor={
                isFocus
                  ? isExpert
                    ? ButtonBgColor.supplemental
                    : ButtonBgColor.inert
                  : ButtonBgColor.white
              }
              onMouseEnter={() => setIsFocus(true)}
              onMouseLeave={() => setIsFocus(false)}
            >
              Register now
            </Button>
          </NavLink>
        </div>
      </div>
    </section>
  );
}
