export const numbersWithCurrency = (val: number | string): string => {
  const value = Number(val);
  if (!value) {
    return '$0';
  } else if (value < 0) {
    return '-$' + Math.abs(value).toFixed(2);
  } else {
    return '$' + Number(val).toFixed(2);
  }
};

export const finalComissionNumbers = (val: number | string): string => {
  const value = Number(val);
  if (!value) {
    return '$0';
  } else if (value < 0) {
    return '-$' + Math.abs(value).toFixed(2);
  } else {
    return '+$' + Number(val).toFixed(2);
  }
};
