export type TDocumentType = {
  id: number;
  type: string;
  url: string;
};

export type TDocumentId = TDefaultDocument & TIdSubInfo;
export type TDocumentproofCompanyRegistration = TDefaultDocument & TProofCompanyRegistrationSubInfo;
export type TW9IndividualDocument = TDefaultDocument & {
  ssn: string;
  firstName: string;
  lastName: string;
};
export type TSsnDocument = TW9IndividualDocument;
export type TW9BusinessDocument = TDefaultDocument & { ein: string; businessName: string };

export type TDocument =
  | TDocumentId
  | TDocumentproofCompanyRegistration
  | TW9IndividualDocument
  | TSsnDocument
  | TW9BusinessDocument
  | TDefaultDocument;

export type TDocumentDefaultResponse = {
  id: number;
  status: Status;
  file: {
    name: string;
  };
  start_date: string;
  expiration_date: string | null;
  type: {
    type: string;
  };
};

export type TIdDocumentResponse = TDocumentDefaultResponse & {
  first_name: string;
  last_name: string;
};

export type TDocumentProofCompanyRegistrationResponse = TDocumentDefaultResponse & {
  owner_first_name: string;
  owner_last_name: string;
  contractor_title: string;
  contractor_company: {
    address?: {
      display_address?: string;
    };
    name: string;
    type: string;
  };
};

export type TDocumentSsnResponse = TDocumentDefaultResponse & {
  ssn: string;
  first_name: string;
  last_name: string;
};

export type TDocumentW9IndividualResponse = TDocumentSsnResponse;
export type TDocumentW9BusinessResponse = TDocumentDefaultResponse & {
  ein: string;
  company_name: string;
};

export type TDocumentResponse =
  | TIdDocumentResponse
  | TDocumentProofCompanyRegistrationResponse
  | TDocumentDefaultResponse
  | TDocumentSsnResponse
  | TDocumentW9IndividualResponse
  | TDocumentW9BusinessResponse;

export enum Status {
  approved = 'approved',
  declined = 'declined',
  pending = 'pending',
  inactive = 'inactive',
}

type TDefaultDocument = {
  id?: number;
  status: Status;
  fileName: string;
  startDate: string;
  expirationDate: string | null;
  type?: string;
};

type TIdSubInfo = {
  firstName: string;
  lastName: string;
};

type TProofCompanyRegistrationSubInfo = {
  ownerFirstName: string;
  ownerLastName: string;
  companyName: string;
  yourCompanyTitle: string;
  companyAddress: string;
  companyType: string;
};

export type TDocumentIdData = {
  status: Status;
  file: File;
  startDate: string;
  expirationDate: string | null;
  technician_id: number;
  type_id: number;
  firstName: string;
  lastName: string;
  birthDate: string;
};

export type TDocumentCompanyRegistrationData = Partial<TDbaData> & {
  status: Status;
  file: File;
  startDate: string;
  technician_id: number;
  address_id: number;
  type_id: number;
  ownerFirstName: string;
  ownerLastName: string;
  companyName: string;
  titleInCompany: string;
  companyType: string;
  dba: boolean;
};

type TDbaData = {
  dbaFile: File;
  dbaStartDate: string;
  dbaExpirationDate: string;
  dbaStatus: string;
  dbaCompanyName: string;
};

export type TDbaDataRequest = {
  document: File;
  start_date: string;
  expiration_date: string;
  status: Status;
  name: string;
  uploaded_by: number;
};

export type TDocumentRepairLicenseData = {
  status: Status;
  file: File;
  startDate: string;
  expirationDate: string | null;
  technician_id: number;
  type_id: number;
};

export type TDocumentW9Business = {
  status: Status;
  file: File;
  startDate: string;
  ein: string;
  technician_id: number;
  type_id: number;
};

export type TDocumentW9Individual = {
  status: Status;
  file: File;
  startDate: string;
  firstName: string;
  lastName: string;
  ssn: string;
  technician_id: number;
  type_id: number;
};

export type TDocumentSsn = TDocumentW9Individual;

export type TDocumentInsurance = {
  status: Status;
  file: File;
  startDate: string;
  expirationDate: string | null;
  companyName: string;
  technician_id: number;
  type_id: number;
};
