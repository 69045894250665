import React, { useContext } from 'react';
import { PartnershipContext } from '@core/context/PartnerShipContext';
import ClaimsList from './ClaimsList/ClaimsList';
import Spinner from '@components/Spinner/Spinner';
import * as styles from './ClaimsWrapper.module.scss';

export default function ClaimsWrapper() {
  const partnership = useContext(PartnershipContext);

  if (!partnership) {
    return (
      <div className={styles.spinner}>
        <Spinner size='large' color='var(--light_accent_main)' />
      </div>
    );
  }
  return (
    <>
      <ClaimsList partnership={partnership} />
    </>
  );
}
