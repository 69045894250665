// extracted by mini-css-extract-plugin
var _1 = "FormMainInfo-module__disabled__HH47q";
var _2 = "FormMainInfo-module__error__N06UF";
var _3 = "FormMainInfo-module__field__Wl2yL";
var _4 = "FormMainInfo-module__input__v2T8g";
var _5 = "FormMainInfo-module__item__yG7bi";
var _6 = "FormMainInfo-module__mainSeparator__ULxbH";
var _7 = "FormMainInfo-module__nonChange__pkOs9";
var _8 = "FormMainInfo-module__separator__T6NVP";
var _9 = "FormMainInfo-module__separatorBig__iOz44";
var _a = "FormMainInfo-module__wrapper__QYrcX";
export { _1 as "disabled", _2 as "error", _3 as "field", _4 as "input", _5 as "item", _6 as "mainSeparator", _7 as "nonChange", _8 as "separator", _9 as "separatorBig", _a as "wrapper" }

    if(module.hot) {
      // 1733466107205
      var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  