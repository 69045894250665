// extracted by mini-css-extract-plugin
var _1 = "WorkInfo-module__button__BB5Wl";
var _2 = "WorkInfo-module__imageBlock__j28g3";
var _3 = "WorkInfo-module__inert__U5CK5";
var _4 = "WorkInfo-module__infoBlock__n8NXl";
var _5 = "WorkInfo-module__link__PrP9d";
var _6 = "WorkInfo-module__registerBlock__MIbN8";
var _7 = "WorkInfo-module__subtitle__tkFA3";
var _8 = "WorkInfo-module__supplemental__TN0yN";
var _9 = "WorkInfo-module__title__d7S0n";
var _a = "WorkInfo-module__white__blJGq";
var _b = "WorkInfo-module__wrapper__RO830";
export { _1 as "button", _2 as "imageBlock", _3 as "inert", _4 as "infoBlock", _5 as "link", _6 as "registerBlock", _7 as "subtitle", _8 as "supplemental", _9 as "title", _a as "white", _b as "wrapper" }

    if(module.hot) {
      // 1733466104077
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  