import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import TextLogo from '@assets/images/textLogo.svg';
import Person from '@assets/images/person.svg';
import Agency from '@assets/images/properties.svg';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import Card from './components/Card/Card';
import Form from './components/Form/Form';
import { TAccountType } from './types';
import InformationPage from '../InformationPage/InformationPage';
import * as styles from './Registration.module.scss';
import * as iconStyles from './components/Card/Card.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';

export default function Registration() {
  const [accountType, setAccountType] = useState<TAccountType | null>(null);
  const [isSend, setIsSend] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>('');

  const handleClickExpert = useCallback(() => {
    return setAccountType(TAccountType.expert);
  }, [accountType]);

  const handleClickAgency = useCallback(() => {
    return setAccountType(TAccountType.agency);
  }, [accountType]);

  const handleClickBackButton = useCallback(() => {
    setAccountType(null);
  }, [accountType]);

  return (
    <>
      {!isSend ? (
        <div className={styles.wrapper}>
          <NavLink to={ROUTES.WEB} className={styles.link}>
            <TextLogo fill='var(--light_label_primary)' />
          </NavLink>
          <title className={styles.title}>
            {accountType === null ? <>Join us as an agency or an expert</> : <>Registration</>}
          </title>
          {accountType === null && (
            <div className={styles.cards}>
              <Card
                icon={<Person className={iconStyles.icon} />}
                title='Expert'
                text='Looking for jobs'
                onClick={handleClickExpert}
              />
              <Card
                icon={<Agency className={iconStyles.icon} />}
                title='Agency'
                text='Looking for subcontractors'
                onClick={handleClickAgency}
                disabled
              />
            </div>
          )}
          {accountType !== null && (
            <Form setIsSend={setIsSend} type={accountType} setCurrentEmail={setCurrentEmail} />
          )}
          <div className={styles.textBlock}>
            {accountType !== null ? (
              <Button
                bgColor={ButtonBgColor.transparent}
                className={styles.blue}
                onClick={handleClickBackButton}
              >
                Back to previous step
              </Button>
            ) : (
              <NavLink to={ROUTES.LOGIN} className={cn(styles.text, styles.blue)}>
                Back to login
              </NavLink>
            )}
          </div>
        </div>
      ) : (
        <InformationPage
          text={`We have sent an email with a verification link to ${currentEmail}. Please check your inbox and follow the link within 30 days to complete your profile verification. If you do not verify within this period, your profile will be deactivated and you will need to register again.`}
          linkText='Back to login'
          route={ROUTES.LOGIN}
        />
      )}
    </>
  );
}
