import React, { useMemo, useState } from 'react';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import Input from '@components/Input/Input';
import { InputType } from '@components/Input/types';
import * as styles from './JobsModal.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';

type JobsModalProps = {
  setIsShown: (val: boolean) => void;
};

export default function JobsModal(props: JobsModalProps) {
  const { daySchedule } = useAppSelector((state) => state.scheduleReducer);
  const { setMaxJobs } = useActions();
  const { setIsShown } = props;
  const { jobs } = daySchedule;
  const [currentJobs, setCurrentJobs] = useState(String(jobs));
  const error = useMemo(() => {
    return (
      currentJobs === '' ||
      Number(currentJobs) <= 0 ||
      Number(currentJobs) > 20 ||
      !Number.isInteger(Number(currentJobs))
    );
  }, [currentJobs]);

  return (
    <ModalPage
      header={<div className={styles.title}>Max Jobs:</div>}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
    >
      <Input
        className={styles.input}
        classNameError={styles.inputError}
        name='jobs'
        type={InputType.number}
        error={error}
        value={currentJobs}
        onChange={(e) => setCurrentJobs(e.target.value)}
      />
      {error ? (
        <div className={styles.text}>
          <span className={styles.error}>
            The number of tasks per day should be from 0 to 20 and value must be an integer.
          </span>
        </div>
      ) : (
        <div className={styles.text}>
          The number of tasks per day should be from 0 to 20 and value must be an integer.
        </div>
      )}
      <Button
        className={styles.button}
        bgColor={ButtonBgColor.red}
        mode={ButtonMode.standard}
        size={ButtonSize.s}
        disabled={error}
        onClick={() => {
          void setMaxJobs(currentJobs);
          void setIsShown(false);
        }}
      >
        Confirm
      </Button>
    </ModalPage>
  );
}
