// extracted by mini-css-extract-plugin
var _1 = "MainButton-module__button__MhQry";
var _2 = "MainButton-module__buttonGroup__pyDYl";
var _3 = "MainButton-module__noSendButton__uF3CV";
var _4 = "MainButton-module__size__GFGdv";
export { _1 as "button", _2 as "buttonGroup", _3 as "noSendButton", _4 as "size" }

    if(module.hot) {
      // 1733466106906
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  