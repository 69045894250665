// extracted by mini-css-extract-plugin
var _1 = "Logout-module__button__MxJ2W";
var _2 = "Logout-module__header__EiM2K";
var _3 = "Logout-module__text__R2N9L";
var _4 = "Logout-module__wrapper__wIr6P";
export { _1 as "button", _2 as "header", _3 as "text", _4 as "wrapper" }

    if(module.hot) {
      // 1733466104314
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  