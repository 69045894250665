import React from 'react';
import * as styles from './Header.module.scss';
import LogoWeb from '@assets/images/LogoWeb.svg';
import LogoWebMobile from '@assets/images/LogoWebMobile.svg';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import cn from 'classnames';
import Button from '@components/Button/Button';
import ArrowBackWeb from '@assets/images/ArrowBackWeb.svg';
import { ButtonBgColor } from '@components/Button/types';
import { TypeWork } from '@pages/MainPage/types';
import { useMobileDetect } from '@core/hooks/windowSize';

type HeaderWebProps = {
  openWork: TypeWork | null;
  isFocus: boolean;
  clickBack: () => void;
};

export default function HeaderWeb(props: HeaderWebProps) {
  const { openWork, isFocus, clickBack } = props;
  const isMobile = useMobileDetect();

  return (
    <div
      className={cn(
        styles.wrapper,
        { [styles.opacity]: isFocus && !openWork },
        {
          [styles.positionChange]: openWork === TypeWork.EXPERT,
        },
      )}
    >
      <div className={styles.mainLine}>
        {isMobile ? <LogoWebMobile /> : <LogoWeb />}
        <NavLink to={ROUTES.LOGIN} className={styles.text}>
          Log In
        </NavLink>
        <NavLink to={ROUTES.REGISTRATION} className={styles.text}>
          Register
        </NavLink>
      </div>
      <Button
        beforeIcon={<ArrowBackWeb fill='var(--light-background-primary)' />}
        onClick={clickBack}
        bgColor={ButtonBgColor.transparent}
        className={cn(styles.button, { [styles.hidden]: !openWork })}
      >
        Back
      </Button>
    </div>
  );
}
