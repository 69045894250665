import React, { useCallback } from 'react';
import cn from 'classnames';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import { TWeeks, TWeek } from '@core/redux/models/payouts';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import * as styles from './WeeksModal.module.scss';
import { isFilterKey } from '../../../../types';

type TWeeksModal = {
  setIsShown: (val: boolean) => void;
  data: TWeeks;
  currentWeek: TWeek;
};

export default function WeeksModal(props: TWeeksModal) {
  const { setIsShown, data, currentWeek } = props;
  const { setCurrentWeek, setFilters, setIsActiveSearch } = useActions();
  const { filters } = useAppSelector((state) => state.payoutsReducer);
  const handleClick = useCallback(
    (item: TWeek) => {
      setCurrentWeek(item);
      setIsActiveSearch(false);
      for (const key of Object.keys(filters)) {
        if (isFilterKey(key)) {
          setFilters({ key: key, value: '' });
        }
      }
      setIsShown(false);
    },
    [filters],
  );

  return (
    <ModalPage
      className={styles.modal}
      mode={ModalPageMode.bottomSheet}
      setIsShown={setIsShown}
      header='Payment week'
      headerClassName={styles.header}
      closeable={false}
    >
      <div className={styles.list}>
        {data?.map((item) => (
          <div
            className={cn(styles.item, { [styles.currentWeek]: currentWeek.id === item.id })}
            key={`${item.start}-${item.end}`}
            onClick={() => handleClick(item)}
          >
            <span className={styles.weekIcon}>{item.number}</span>
            <span>
              {getDdMonthYyyyFormatDate(item.start)} - {getDdMonthYyyyFormatDate(item.end)}
            </span>
          </div>
        ))}
      </div>
    </ModalPage>
  );
}
