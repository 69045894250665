import React from 'react';
import { Controller, Control } from 'react-hook-form';
import Input from '@components/Input/Input';
import { InputMode, InputType, SeparatorMode } from '@components/Input/types';
import { TAgencyRegistrationForm, TExpertRegistrationForm } from '../../../types';
import * as styles from '../Form.module.scss';
import { EMAIL_PATTERN, ONLY_LATIN_CHAR_PATTERN } from '@core/constants';
import { getPatterError } from '@core/utils/error';

type AgencyFieldsProps = {
  control: Control<TAgencyRegistrationForm | TExpertRegistrationForm>;
};

export default function AgencyFields(props: AgencyFieldsProps) {
  const { control } = props;
  return (
    <>
      <div className={styles.inputsBlock}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'Agency name (Company name) is required',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Name'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              top='Company data'
              className={styles.input}
              {...field}
              name=''
              placeholder='Agency name (Company name)'
              type={InputType.text}
              innerRef={ref}
              mode={InputMode.group}
              error={!!error}
              separator
              separatorMode={SeparatorMode.short}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          rules={{
            required: 'Email address (admin login) is required',
            pattern: getPatterError(EMAIL_PATTERN, 'email'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              top='Company data'
              className={styles.input}
              {...field}
              name='email'
              placeholder='Email address (admin login)'
              type={InputType.text}
              innerRef={ref}
              mode={InputMode.group}
              error={!!error}
            />
          )}
        />
      </div>
      <div className={styles.inputsBlock}>
        <Controller
          name='firstName'
          control={control}
          rules={{
            required: 'First name is required',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'First Name'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              top='Admin data'
              className={styles.input}
              {...field}
              name='firstName'
              placeholder='First name'
              type={InputType.text}
              innerRef={ref}
              mode={InputMode.group}
              error={!!error}
              separator
              separatorMode={SeparatorMode.short}
            />
          )}
        />
        <Controller
          name='lastName'
          control={control}
          rules={{
            required: 'Last name is required',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Last Name'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              top='Admin data'
              className={styles.input}
              {...field}
              name='lastName'
              placeholder='Last name'
              type={InputType.text}
              innerRef={ref}
              mode={InputMode.group}
              error={!!error}
              separator
              separatorMode={SeparatorMode.all}
            />
          )}
        />
        <Controller
          name='phone'
          control={control}
          rules={{ required: 'Phone number is required' }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              top='Admin data'
              className={styles.input}
              {...field}
              name='phone'
              placeholder='Phone number'
              type={InputType.text}
              innerRef={ref}
              mode={InputMode.group}
              error={!!error}
            />
          )}
        />
      </div>
    </>
  );
}
