import React from 'react';
import * as styles from './Reviews.module.scss';
import cn from 'classnames';
import ReviewsExpert from './components/ReviewsExpert/ReviewsExpert';
import ReviewsAgency from './components/ReviewsAgency/ReviewsAgency';

type ReviewsProps = {
  isExpert: boolean;
};

const REVIEWS_TITLE_EXPERT = 'Focus on profit, we’ll handle the rest.';
const REVIEWS_TITLE_AGENCY = 'Focus on growing your business, we’ll handle the rest.';

export default function Reviews(props: ReviewsProps) {
  const { isExpert } = props;

  return (
    <section className={styles.wrapper}>
      <h2 className={cn(styles.title, isExpert ? styles.supplemental : styles.inert)}>
        {isExpert ? REVIEWS_TITLE_EXPERT : REVIEWS_TITLE_AGENCY}
      </h2>
      <div className={styles.reviewsBlock}>{isExpert ? <ReviewsExpert /> : <ReviewsAgency />}</div>
    </section>
  );
}
