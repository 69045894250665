import React, { useEffect } from 'react';
import cn from 'classnames';
import TextLogo from '@assets/images/textLogo.svg';
import ErrorLogo from '@assets/images/confirmPageError.svg';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { authAPI } from '@core/redux/services/authService';
import { ROUTES } from '@routes/constants';
import Success from '@assets/images/done.svg';
import Spinner from '@components/Spinner/Spinner';
import { parseServerError, TServerError } from '@core/utils/error';
import * as styles from './ConfirmPassword.module.scss';

export default function ConfirmPassword() {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [confirmUser, { data, isSuccess, isLoading, isError, error: serverError }] =
    authAPI.usePasswordResetConfirmMutation();

  useEffect(() => {
    if (id && token) {
      confirmUser({ id, token });
    }
  }, []);
  const userId = data?.user_id;
  useEffect(() => {
    if (userId) {
      navigate(`/auth/reset/${userId}/${token}`);
    }
  }, [userId]);

  return (
    <div className={styles.wrapper}>
      <TextLogo fill='var(--light_label_primary)' />
      {isLoading && (
        <div className={styles.successWrapper}>
          <Spinner size='medium' color='var(--light_label_secondary)' />
        </div>
      )}
      {isSuccess && (
        <div className={styles.successWrapper}>
          <Success />
          <div className={styles.title}>Success</div>
        </div>
      )}
      {isError && (
        <div className={styles.errorWrapper}>
          <ErrorLogo />
          <div className={styles.title}>
            {serverError ? parseServerError(serverError as TServerError) : 'Server error'}
          </div>
          <div className={styles.text}>Try again later</div>
        </div>
      )}
      <div className={styles.textBlock}>
        <NavLink to={ROUTES.LOGIN} className={cn(styles.text, styles.blue)}>
          Back to login
        </NavLink>
      </div>
    </div>
  );
}
