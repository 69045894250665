import React, { forwardRef } from 'react';
import * as styles from './PageInfo.module.scss';
import { TypeWork } from '@pages/MainPage/types';
import MainInfo from './components/MainInfo/MainInfoWeb';
import WorkInfo from './components/WorkInfo/WorkInfo';
import ExpertHubAgencyBanner from '@assets/images/ExpertHubAgencyBanner.png';
import ExpertHubExpertBanner from '@assets/images/ExpertHubExpertBanner.png';
import Reviews from './components/Reviews/Reviews';
import FinalBlock from './components/FinalBlock/FinalBlock';
import FooterWeb from './components/Footer/Footer';

type PageInfoProps = {
  typeWork: TypeWork;
  handleClickFooter: (val: TypeWork) => void;
};

const PageInfo = forwardRef<HTMLDivElement, PageInfoProps>((props: PageInfoProps, ref) => {
  const { typeWork, handleClickFooter } = props;
  const isExpert = typeWork === TypeWork.EXPERT;

  return (
    <div ref={ref} className={styles.wrapper}>
      <MainInfo isExpert={isExpert} />
      <WorkInfo isExpert={isExpert} />
      {isExpert ? (
        <img src={ExpertHubExpertBanner} alt='Expert Hub Expert Banner' className={styles.banner} />
      ) : (
        <img src={ExpertHubAgencyBanner} alt='Expert Hub Agency Banner' className={styles.banner} />
      )}
      <Reviews isExpert={isExpert} />
      <FinalBlock isExpert={isExpert} />
      <FooterWeb handleClickFooter={handleClickFooter} />
    </div>
  );
});

PageInfo.displayName = 'PageInfo';

export default PageInfo;
