import { AccountType, FinanceType, TBankDetailsForm } from '@core/redux/models/bankDetails';
import { ModuleAction } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TBankDetailsState = {
  bankDetailsAction: ModuleAction;
  bankDetailsFormData: TBankDetailsForm;
  hasBankDetailsFormError: boolean;
};

const initialState: TBankDetailsState = {
  bankDetailsAction: ModuleAction.send,
  bankDetailsFormData: {
    accountType: AccountType.business,
    financeType: FinanceType.checking,
    accountNumber: '',
    routingNumber: '',
  },
  hasBankDetailsFormError: false,
};

export const bankDetailsSlice = createSlice({
  name: 'bankDetails',
  initialState,
  reducers: {
    setBankDetailsAction(state, action: PayloadAction<ModuleAction>) {
      state.bankDetailsAction = action.payload;
    },
    setBankDetailsFormData(state, action: PayloadAction<TBankDetailsForm>) {
      state.bankDetailsFormData.accountType = action.payload.accountType;
      state.bankDetailsFormData.financeType = action.payload.financeType;
      state.bankDetailsFormData.accountNumber = action.payload.accountNumber;
      state.bankDetailsFormData.routingNumber = action.payload.routingNumber;
    },
    setBankDetailsError(state, action: PayloadAction<Record<string, boolean>>) {
      state.hasBankDetailsFormError = Boolean(
        Object.values(action.payload).find((item) => item === true),
      );
    },
    setAccountType(state, action: PayloadAction<AccountType>) {
      state.bankDetailsFormData.accountType = action.payload;
      state.bankDetailsAction = ModuleAction.change;
    },
    setFinanceType(state, action: PayloadAction<FinanceType>) {
      state.bankDetailsFormData.financeType = action.payload;
      state.bankDetailsAction = ModuleAction.change;
    },
    setAccountNumber(state, action: PayloadAction<string>) {
      state.bankDetailsFormData.accountNumber = action.payload;
      state.bankDetailsAction = ModuleAction.change;
    },
    setRoutingNumber(state, action: PayloadAction<string>) {
      state.bankDetailsFormData.routingNumber = action.payload;
      state.bankDetailsAction = ModuleAction.change;
    },
  },
});

export const bankDetailsActions = bankDetailsSlice.actions;
export const bankDetailsReducer = bankDetailsSlice.reducer;
