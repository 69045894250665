import React, { useCallback, useEffect, useState } from 'react';
import * as styles from './GeneralInfo.module.scss';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import Status from '../Status/Status';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import { authAPI } from '@core/redux/services/authService';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import FormMainInfo from './FormMainInfo/FormMainInfo';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import MainButton from '../MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import { TServerError, parseServerError } from '@core/utils/error';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { checkingChangesValue } from './utils';
import { DiscardText } from '@core/utils/utils';
import { ModuleAction } from '@core/redux/models/profile';

export default function GeneralInfo() {
  const { technicianId } = useCurrentUser();
  const { logOut } = useActions();
  const navigate = useNavigate();

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const { setGeneralInfoAction } = useActions();
  const { needDisabled } = useAppSelector((state) => state.profileReducer);
  const { generalInfoAction, generalInfoFormData, hasGeneralInfoFormError } = useAppSelector(
    (state) => state.generalInfoReducer,
  );

  const {
    data: technicianData,
    isLoading: technicianIsLoading,
    refetch,
    isFetching,
    error,
  } = profileAPI.useGetTechnicianDataQuery({ id: technicianId });
  const [trigger] = authAPI.useLazyLogoutQuery();

  useEffect(() => {
    if (error && 'status' in error && error.status === 401) {
      logOut('');
      trigger();
      navigate(ROUTES.LOGIN);
    }
  }, [error]);

  const handleConfirm = useCallback(() => {
    setConfirmModal(false);
    refetch();
  }, []);

  const [handleChangeTechnicianData, { error: technicianUpdateError, isLoading: isLoadingUpdate }] =
    profileAPI.useUpdateTechnicianDataMutation();

  const technician = technicianData?.technician;

  const submitGeneralInfo = useCallback(async () => {
    try {
      const changeTechnicianValue =
        technician && checkingChangesValue(generalInfoFormData, technician);
      if (!changeTechnicianValue) {
        return;
      }
      const result = await handleChangeTechnicianData({
        id: technician.expertId,
        body: {
          nickname: generalInfoFormData.nickname,
          first_name: generalInfoFormData.firstName,
          last_name: generalInfoFormData.lastName,
          phone: generalInfoFormData.phone,
          email: generalInfoFormData.email,
        },
      });
      if (result && 'error' in result) {
        setErrorModal(true);
        return;
      }
    } catch (e) {
      console.warn(e);
    }
  }, [generalInfoFormData, technician]);

  return (
    <>
      <Status />
      <section className={styles.wrapper}>
        <TitleWithButton
          title={'General information:'}
          disabled={needDisabled && generalInfoAction === ModuleAction.send}
        >
          {technicianIsLoading ? (
            <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
          ) : (
            <MainButton
              action={generalInfoAction}
              setAction={setGeneralInfoAction}
              refreshDataFunction={() => setConfirmModal(true)}
              resetIsLoading={isFetching}
              isLoading={isLoadingUpdate}
              sendDataFunction={submitGeneralInfo}
              isSendError={!!technicianUpdateError}
              sendDisabled={hasGeneralInfoFormError}
            />
          )}
        </TitleWithButton>
        {technicianIsLoading ? (
          <Skeleton
            width='100%'
            height={266}
            variant='rectangular'
            className={styles.skeletonForm}
          />
        ) : (
          <>{!!technician && <FormMainInfo technician={technician} isFetching={isFetching} />}</>
        )}

        {!!technicianUpdateError && errorModal && (
          <ErrorModal
            setIsShown={setErrorModal}
            errorMessage={parseServerError(technicianUpdateError as TServerError)}
          />
        )}
        {confirmModal && (
          <ConfirmModalPage
            header={'Discard editing general info?'}
            headerClassName={styles.modalHeader}
            text={DiscardText('general info')}
            textClassName={styles.modalText}
            handleConfirm={handleConfirm}
            setIsShown={() => setConfirmModal(false)}
            confirmButtonText={'Discard'}
            cancelButtonText={'Cancel'}
          />
        )}
      </section>
    </>
  );
}
