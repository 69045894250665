// extracted by mini-css-extract-plugin
var _1 = "JobTypes-module__buttonConfirmModal__RMfrk";
var _2 = "JobTypes-module__modalHeader__MU5J6";
var _3 = "JobTypes-module__modalText__hJ21H";
var _4 = "JobTypes-module__skeleton__RSK2e";
var _5 = "JobTypes-module__skeletonForm__tyBCA";
var _6 = "JobTypes-module__wrapper__t682f";
export { _1 as "buttonConfirmModal", _2 as "modalHeader", _3 as "modalText", _4 as "skeleton", _5 as "skeletonForm", _6 as "wrapper" }

    if(module.hot) {
      // 1733466105018
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  