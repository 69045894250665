import React, { useCallback, useState } from 'react';
import * as styles from './ListCities.module.scss';
import ListPostals from './ListPostals/ListPostals';
import { useAppSelector } from '@core/hooks/redux';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import cn from 'classnames';
import { isElementVisible } from './utils';

const LASTINDEXSHOWCITIES = 4;

export default function ListCities() {
  const { cityPostals } = useAppSelector((state) => state.serviceAreasReducer);
  const [isFullCityList, setIsFullCityList] = useState<boolean>(false);
  const [indexOpenList, setIndexOpenList] = useState<number | null>(null);

  const toggleFullCityList = useCallback(() => {
    setIsFullCityList(!isFullCityList);
  }, [isFullCityList]);

  const citiesList = Object.keys(cityPostals);

  return (
    <>
      <ul className={styles.wrapperChoicer}>
        {citiesList.map(
          (city: string, index: number): React.JSX.Element => (
            <li
              key={city}
              className={cn(
                styles.choicer,
                isElementVisible(index, isFullCityList, citiesList, indexOpenList)
                  ? styles.visible
                  : styles.hidden,
                { [styles.visibleOpen]: indexOpenList === index },
                {
                  [styles.choicerLastVisiblePosition]:
                    index === LASTINDEXSHOWCITIES && !isFullCityList,
                },
              )}
            >
              <ListPostals
                name={city}
                workAreas={cityPostals[city].workAreas}
                isDelete={cityPostals[city].isDelete}
                indexCity={index}
                setIndexOpenList={setIndexOpenList}
              />
            </li>
          ),
        )}
      </ul>
      {citiesList.length > 5 && (
        <div
          className={cn(styles.wrapperButton, {
            [styles.wrapperButtonHidden]: typeof indexOpenList === 'number',
          })}
        >
          <Button
            bgColor={ButtonBgColor.transparent}
            className={cn(
              styles.button,
              indexOpenList !== null ? styles.buttonHidden : styles.buttonVisible,
            )}
            onClick={toggleFullCityList}
            disabled={indexOpenList !== null}
          >
            {isFullCityList ? 'Show less' : 'Show more'}
          </Button>
        </div>
      )}
    </>
  );
}
