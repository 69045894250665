export enum ButtonBgColor {
  grey = 'grey',
  lightGrey = 'lightGrey',
  green = 'green',
  red = 'red',
  blue = 'blue',
  white = 'white',
  slightlyDarkWhite = 'slightlyDarkWhite',
  transparent = 'transparent',
  darkBlue = 'darkBlue',
  supplemental = 'supplemental',
  inert = 'inert',
}

export enum ButtonSize {
  l = 'l',
  m = 'm',
  s = 's',
}

export enum ButtonMode {
  standard = 'standard',
  icon = 'icon',
}
