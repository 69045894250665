import React, { useCallback } from 'react';
import Button from '@components/Button/Button';
import { useActions } from '@core/hooks/actions';
import ChatIcon from '@assets/images/chat.svg';
import * as styles from './Claim.module.scss';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import { TPayout } from '@core/redux/models/payouts';

type TProps = {
  row: TPayout;
};

export default function Claim(props: TProps) {
  const { row } = props;
  const { setClaimModal, setPayoutRow, setCustomInvoiceNumber } = useActions();

  const handleClick = useCallback(() => {
    setClaimModal(true);
    setPayoutRow(row);
    setCustomInvoiceNumber('');
  }, [row]);

  return (
    <>
      <Button
        onClick={handleClick}
        className={styles.button}
        mode={ButtonMode.icon}
        bgColor={ButtonBgColor.lightGrey}
      >
        <ChatIcon fill='var(--light_accent_supplemental)' />
      </Button>
    </>
  );
}
