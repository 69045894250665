import React, { useCallback, useState, useMemo } from 'react';
import cn from 'classnames';
import Input from '@components/Input/Input';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import SearchIcon from '@assets/images/search.svg';
import { InputType } from '@components/Input/types';
import * as styles from './Penalty.module.scss';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPartnershipElement } from '@core/redux/models/payouts';
import PenaltyModal from './components/PenaltyModal/PenaltyModal';
import DoneIcon from '@assets/images/done20.svg';

type TPenaltyProps = {
  partnership: TPartnershipElement;
};

export default function Penalty(props: TPenaltyProps) {
  const { partnership } = props;
  const [modal, setModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [penaltyId, setPenaltyId] = useState<number>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isFullList, setIsFullList] = useState<boolean>(false);

  const toggleFullCityList = useCallback(() => {
    setIsFullList(!isFullList);
  }, [isFullList]);

  const { data } = partnershipAPI.useGetPenaltyListQuery({ partnership_id: partnership.id });

  const list = useMemo(() => {
    if (!data?.length) {
      return;
    }
    return isActive
      ? data.filter((item) => item.invoice.includes(search) && item.status)
      : data.filter((item) => item.invoice.includes(search) && !item.status);
  }, [data, search, isActive]);

  const handleOpenModal = useCallback(
    (id: number) => {
      setModal(true);
      setPenaltyId(id);
    },
    [penaltyId],
  );

  const penaltyList = useMemo(() => {
    if (list && list.length < 5) {
      return list;
    }

    if (isFullList) {
      return list;
    } else {
      return list?.slice(0, 5);
    }
  }, [list, isFullList]);

  return (
    <div className={styles.wrap}>
      <span className={styles.separator}></span>
      <div className={styles.header}>
        <span className={styles.text}>Penalty:</span>
        <div
          className={cn(styles.switchBlock, { [styles.inactive]: !isActive })}
          onClick={() => setIsActive(!isActive)}
        >
          <span className={styles.switchIcon}>
            <DoneIcon
              fill={
                isActive ? 'var(--light-background-primary)' : 'var(--light_accent_supplemental)'
              }
            />
          </span>
          <span className={styles.switchText}>{isActive ? 'active' : 'inactive'}</span>
        </div>
      </div>
      <div className={styles.content}>
        <Input
          className={styles.search}
          type={InputType.text}
          name='search'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          beforeIcon={
            <SearchIcon className={styles.searchIcon} fill='var(--light_label_tertiary)' />
          }
        />
      </div>
      <div className={styles.list}>
        {penaltyList?.length ? (
          <>
            {penaltyList.map((item) => (
              <div
                className={styles.card}
                key={`${item.invoice}-${item.id}`}
                onClick={() => handleOpenModal(item.id)}
              >
                <div className={styles.line}>
                  <span>Penalty:</span>
                  <span className={styles.penalty} title={item.penalty}>
                    {item.penalty}
                  </span>
                </div>
                <span className={styles.cardSeparator}></span>
                <div className={styles.line}>
                  <span>Invoice:</span>
                  <span>{item.invoice}</span>
                </div>
                {item.id === penaltyId && modal && (
                  <PenaltyModal setIsShown={setModal} penalty={item} partnership={partnership} />
                )}
              </div>
            ))}
            <div>
              {list && list.length > 5 && (
                <div className={cn(styles.wrapperButton, {})}>
                  <Button
                    bgColor={ButtonBgColor.transparent}
                    className={cn(styles.button)}
                    onClick={toggleFullCityList}
                  >
                    {isFullList ? 'Show less' : 'Show more'}
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={styles.empty}>Nothing found!</div>
        )}
      </div>
    </div>
  );
}
