// extracted by mini-css-extract-plugin
var _1 = "DiagnosticDurationModal-module__header__EMrDM";
var _2 = "DiagnosticDurationModal-module__modal__EVQ0w";
var _3 = "DiagnosticDurationModal-module__title__cArQs";
export { _1 as "header", _2 as "modal", _3 as "title" }

    if(module.hot) {
      // 1733466109670
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  