import { MONTH_NAMES } from '@core/constants';
import { TScheduleTime } from '@core/redux/models/schedule';

export const formatDateToHhMm = (date: string): string => {
  const timeList = date.split(':');
  if (timeList && timeList.length === 3) {
    timeList.pop();
    return timeList.join(':');
  } else {
    throw new Error('invalid time format!');
  }
};

export const useCurrentDay = (): number => {
  const date = new Date();
  const day = date.getDay();
  return day === 0 ? 6 : day - 1;
};

export const strToMins = (time: string): number => {
  const timeList = time.split(':');
  return Number(timeList[0]) * 60 + Number(timeList[1]);
};

export const minsToStr = (time: number): string => {
  const hour = Math.trunc(time / 60);
  const result =
    (Math.abs(hour) < 10 ? '0' + Math.abs(hour) : Math.abs(hour)) +
    ':' +
    ('00' + (time % 60)).slice(-2);
  return time < 0 ? '-' + result : result;
};

export const useTimeNumberOdds = (start: string, end: string) => {
  return strToMins(end) - strToMins(start);
};
export const useTimeOdds = (start: string, end: string): string => {
  return minsToStr(useTimeNumberOdds(start, end));
};

export const getNewRangeList = (list: TScheduleTime[], item: TScheduleTime): TScheduleTime[] => {
  if (list.length === 0) {
    list.push(item);
    return list;
  }

  list.forEach((time, index) => {
    const nextRange = list[index + 1];
    const isFirstRange = index === 0 && strToMins(time.start) > strToMins(item.start);
    const isMiddleRange =
      strToMins(time.start) < strToMins(item.start) &&
      nextRange &&
      strToMins(nextRange.start) > strToMins(item.start);
    const isLastRange = index === list.length - 1 && strToMins(time.start) < strToMins(item.start);

    if (isFirstRange) {
      return list.splice(index, 0, item);
    } else if (isMiddleRange || isLastRange) {
      return list.splice(index + 1, 0, item);
    }
  });

  return list;
};

export const timeLeft = (deleteTimeout: string): string | number => {
  const time = new Date(deleteTimeout).getTime() - new Date().getTime();

  const days = Math.ceil(time / (1000 * 60 * 60 * 24));
  if (days > 1) {
    return `${days} days`;
  }
  const hours = Math.ceil(time / (1000 * 60 * 60));
  if (hours > 1) {
    return `${hours} hours`;
  }
  const minutes = Math.ceil(time / (1000 * 60));
  if (minutes <= 0) return 0;
  return minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
};

export const getDdMonthYyyyFormatDate = (date: string): string => {
  const currentDate = new Date(date);
  return `${currentDate.getDate()} ${
    MONTH_NAMES[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;
};

export const getDdMmYyyyFormatDate = (date: string): string => {
  const currentDate = new Date(date);
  const month = currentDate.getMonth() + 1;
  return `${currentDate.getFullYear()}-${
    month < 10 ? '0' + month : month
  }-${currentDate.getDate()}`;
};
