import React, { useEffect } from 'react';
import cn from 'classnames';
import Input from '@components/Input/Input';
import LocationIcon from '@assets/images/location24.svg';
import DoorIcon from '@assets/images/door.svg';
import CityIcon from '@assets/images/properties.svg';
import FlagIcon from '@assets/images/flag.svg';
import PostalCodeIcon from '@assets/images/mail-box.svg';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import { InputType, InputMode } from '@components/Input/types';
import * as styles from './AddressForm.module.scss';
import { TAddressData } from '@core/redux/models/profile';
import { useModuleActionType } from '@core/utils/utils';
import { getFormValidation } from '../../GeneralInfo/utils';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { STATE_CODE } from '@core/constants';
import { getState } from '../utils';

type AddressFormProps = {
  addressData: TAddressData;
  isFetching: boolean;
  validation: Record<string, boolean>;
};
export default function AddressForm(props: AddressFormProps) {
  const { addressData, isFetching, validation } = props;
  const { mailingAddressAction, mailingAddressFormData } = useAppSelector(
    (state) => state.mailingAddressReducer,
  );
  const { isSend } = useModuleActionType(mailingAddressAction);
  const {
    setMailingAddress,
    setStreet,
    setApartment,
    setCity,
    setStateCode,
    setPostalCode,
    setMailingAddressError,
  } = useActions();

  useEffect(() => {
    const { street, apartment, city, stateCode, postalCode } = addressData;
    setMailingAddress({
      street,
      apartment,
      city,
      stateCode: getState(stateCode),
      postalCode,
    });
  }, [isFetching]);

  useEffect(() => {
    const { apartment, ...checkFields } = mailingAddressFormData;
    setMailingAddressError(getFormValidation(checkFields));
  }, [mailingAddressFormData]);

  const { street, apartment, city, stateCode, postalCode } = mailingAddressFormData;

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <Input
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='street'
          placeholder='Street'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.street}
          classNameError={styles.error}
          beforeIcon={<LocationIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={cn(styles.item, { [styles.hidden]: isSend && apartment === '' })}>
        <Input
          value={apartment}
          onChange={(e) => setApartment(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='apartment'
          placeholder='Apartment'
          type={InputType.text}
          mode={InputMode.group}
          beforeIcon={<DoorIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={styles.item}>
        <Input
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='city'
          placeholder='City'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.city}
          classNameError={styles.error}
          beforeIcon={<CityIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={cn(styles.item, { [styles.selectDisabled]: isSend })}>
        <>
          <FlagIcon fill='var(--light_label_tertiary)' />
          <CustomSelect
            className={styles.stateSelect}
            classNameSelect={cn(styles.stateSelectHeader, {
              [styles.stateSelectHeaderActive]: !isSend,
            })}
            classNameList={styles.stateSelectList}
            options={Object.values(STATE_CODE)}
            value={stateCode}
            onChange={(e) => setStateCode(e)}
            isActive={!isSend}
            hasPlaceholder='State code'
          />
        </>
        <span className={styles.separator}></span>
      </div>
      <div className={styles.item}>
        <Input
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='postalCode'
          placeholder='Postal code'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.postalCode}
          classNameError={styles.error}
          beforeIcon={<PostalCodeIcon fill='var(--light_label_tertiary)' />}
        />
      </div>
    </div>
  );
}
