import React, { useCallback } from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import * as styles from './FilterModal.module.scss';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import Button from '@components/Button/Button';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { TApplianceType, TApplianceTypeOptions, TAppliances } from '@core/redux/models/partnership';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import Spinner from '@components/Spinner/Spinner';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';

type TProps = {
  setIsShown: (arg: boolean) => void;
};

export default function FilterModal(props: TProps) {
  const { data: applianceTypes, isLoading: isLoadingApplianceTypes } =
    partnershipAPI.useGetApplianceTypesQuery();
  const { data: appliances, isLoading: isLoadingAppliances } =
    partnershipAPI.useGetAppliancesQuery();

  const { setIsShown } = props;
  const { appliance, applianceType } = useAppSelector((state) => state.partnershipReducer);
  const { setAppliance, setApplianceType, setWorkTypeId } = useActions();
  const isLoading = isLoadingApplianceTypes || isLoadingAppliances;

  const [handleAccept, { isLoading: isAcceptLoading }] = partnershipAPI.useGetWorkTypeMutation();

  const handleSubmit = useCallback(async () => {
    const currentAppliance = appliances?.find((item) => item.title === appliance);
    const currentApplianceType = applianceTypes?.find((item) => item.title === applianceType);
    if (currentAppliance && currentApplianceType) {
      const result = await handleAccept({
        appliance_id: currentAppliance.id,
        applianceType_id: currentApplianceType.id,
      });

      if ('data' in result) {
        setWorkTypeId(result.data.id);
        setIsShown(false);
      } else {
        console.error('wrong work type error!');
      }
    }
  }, [appliances, applianceTypes, appliance, applianceType]);
  return (
    <ModalPage
      className={styles.modal}
      header={<div className={styles.header}>Filter:</div>}
      setIsShown={setIsShown}
      mode={ModalPageMode.modal}
      closeable
    >
      <div className={styles.wrap}>
        <span className={styles.separator}></span>
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <>
            {applianceTypes?.length && appliances?.length && (
              <div>
                <div className={styles.main}>
                  <span className={styles.text}>Job type:</span>
                  <CustomSelect
                    classNameSelect={styles.select}
                    classNameList={styles.selectList}
                    value={applianceType}
                    onChange={(e) => setApplianceType(e as TApplianceTypeOptions)}
                    hasPlaceholder='Select job type'
                    options={applianceTypes.map((item: TApplianceType) => item.title) as string[]}
                  />
                  <CustomSelect
                    classNameSelect={styles.select}
                    classNameList={styles.selectList}
                    value={appliance}
                    onChange={(e) => setAppliance(e as string)}
                    hasPlaceholder='Select appliance'
                    options={appliances.map((item: TAppliances) => item.title) as string[]}
                  />
                </div>
                <span className={styles.separator}></span>
                <div className={styles.buttonBlock}>
                  <Button
                    className={styles.button}
                    size={ButtonSize.m}
                    bgColor={ButtonBgColor.lightGrey}
                    onClick={() => {
                      setAppliance('');
                      setApplianceType('');
                      setWorkTypeId(NaN);
                      setIsShown(false);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    className={styles.button}
                    size={ButtonSize.m}
                    bgColor={ButtonBgColor.grey}
                    loading={isAcceptLoading}
                    disabled={Boolean(!appliance || !applianceType) || isAcceptLoading}
                    onClick={handleSubmit}
                  >
                    Accept
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalPage>
  );
}
