import React, { ReactNode } from 'react';
import * as style from './Container.module.scss';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ROUTES } from '@routes/constants';

type ContainerProps = {
  children: ReactNode;
};

function Container(props: ContainerProps) {
  const { children } = props;
  const isMainPage = useLocation().pathname === ROUTES.WEB;

  return (
    <div className={cn(style.container, { [style.notMainPage]: !isMainPage })}>{children}</div>
  );
}

export default Container;
