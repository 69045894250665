import { TKpi, TWeek, TWeeks } from '@core/redux/models/partnership';

export const weekSection = (array: TWeeks, count: number): TWeeks[] => {
  const list = array.reduce((acc: TWeeks[], c: TWeek, i: number) => {
    if (i % count === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(c);
    return acc;
  }, []);

  if (list[list.length - 1].length < count) {
    const diff = count - list[list.length - 1].length;
    let num = diff;
    while (num > 0) {
      list[list.length - 1] = [...list[list.length - 1], list[0][num]];
      num--;
    }
  }
  return list;
};

export const getChartData = (data: TKpi) => {
  return {
    labels: [...data.dates],
    datasets: [
      data.self.dataset && {
        label: `${data.self.dataset.map((item) => item.change)}:${data.self.nickname}`,
        data: data.self.dataset.map((item) => item.amount),
        backgroundColor: '#000',
        borderColor: '#000',
        pointHitRadius: 10,
        borderWidth: 1,
      },
      data.leading.dataset && {
        label: `${data.leading.dataset.map((item) => item.change)}`,
        data: data.leading.dataset.map((item) => item.amount),
        backgroundColor: '#CF5656',
        borderColor: '#CF5656',
        pointHitRadius: 10,
        borderWidth: 1,
      },
      data.lagging.dataset && {
        label: `${data.lagging.dataset.map((item) => item.change)}`,
        data: data.lagging.dataset.map((item) => item.amount),
        backgroundColor: '#66BB69',
        borderColor: '#66BB69',
        pointHitRadius: 10,
        borderWidth: 1,
      },
    ],
  };
};
