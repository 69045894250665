import {
  TDocument,
  TDocumentResponse,
  TDocumentType,
  TDocumentIdData,
  TDocumentCompanyRegistrationData,
  TDocumentRepairLicenseData,
  TDocumentW9Business,
  TDocumentW9Individual,
  TDocumentSsn,
  Status,
  TIdDocumentResponse,
  TDocumentProofCompanyRegistrationResponse,
  TDocumentDefaultResponse,
  TDocumentW9IndividualResponse,
  TDocumentSsnResponse,
} from '../models/documents';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { getDdMmYyyyFormatDate } from '@core/utils/date';
import { WorkingAs } from '../models/profile';
import { getCurrentDocumentsList } from './utils/documents';

enum DocumentsApiPaths {
  DOCUMENTS_TYPES = '/api/workflow/docs/types/',
  DOCUMENTS_COMPANY_REGISTRATION = '/api/workflow/docs/company-registration/',
  DOCUMENTS_ID = '/api/workflow/docs/id/',
  DOCUMENTS_INSURANCE = '/api/workflow/docs/insurance/',
  DOCUMENTS_REPAIR_LICENSE = '/api/workflow/docs/repair-license/',
  DOCUMENTS_W9_PHYSICAL = '/api/workflow/docs/w9-physical/',
  DOCUMENTS_W9_BUSINESS = '/api/workflow/docs/w9-business/',
  DOCUMENTS_SSN = '/api/workflow/docs/ssn/',
}

export const documentsService = (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
  getDocumentTypes: build.query<TDocumentType[], { type: WorkingAs }>({
    query: ({ type }) => `${DocumentsApiPaths.DOCUMENTS_TYPES}?group=${type.toLocaleLowerCase()}`,
  }),
  getDocumentsList: build.query<TDocument[], { id: number; url: string }>({
    query: ({ id, url }) => `${url}?uploaded_by=${id}`,
    transformResponse: (response: TDocumentResponse[]): TDocument[] => {
      return getCurrentDocumentsList(response);
    },
  }),
  addDocumentId: build.mutation<TDocument, TDocumentIdData>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set(
        'expiration_date',
        data.expirationDate ? getDdMmYyyyFormatDate(data.expirationDate) : '',
      );
      formData.set('birth_date', data.birthDate ? getDdMmYyyyFormatDate(data.birthDate) : '');
      formData.set('first_name', data.firstName);
      formData.set('last_name', data.lastName);
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      return {
        url: DocumentsApiPaths.DOCUMENTS_ID,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TIdDocumentResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        expirationDate: response.expiration_date,
        status: status as Status,
        type: response.type.type,
        firstName: response.first_name,
        lastName: response.last_name,
      };
    },
  }),
  addDocumentCompanyRegistration: build.mutation<TDocument, TDocumentCompanyRegistrationData>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      formData.set('contractor_company.address_id', JSON.stringify(data.address_id));
      formData.set('contractor_company.name', data.companyName);
      formData.set('contractor_company.type', data.companyType);

      //TODO: Жесткий костыль поправить!
      if (data.dba && data.dbaFile) {
        formData.set('dba.name', data.dbaCompanyName ? data.dbaCompanyName : '');
        formData.set(
          'dba.start_date',
          data.dbaStartDate ? getDdMmYyyyFormatDate(data.dbaStartDate) : '',
        );
        formData.set(
          'dba.expiration_date',
          data.dbaExpirationDate ? getDdMmYyyyFormatDate(data.dbaExpirationDate) : '',
        );
        formData.set('dba.document', data.dbaFile, data.dbaFile.name);
        formData.set('dba.upload_by', JSON.stringify(data.technician_id));
      }

      formData.set('contractor_title', data.titleInCompany);
      formData.set('owner_first_name', data.ownerFirstName);
      formData.set('owner_last_name', data.ownerLastName);

      return {
        url: DocumentsApiPaths.DOCUMENTS_COMPANY_REGISTRATION,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TDocumentProofCompanyRegistrationResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        expirationDate: response.expiration_date,
        status: status as Status,
        type: response.type.type,
        ownerFirstName: response.owner_first_name,
        ownerLastName: response.owner_last_name,
        yourCompanyTitle: response.contractor_title,
        companyAddress: response?.contractor_company.address?.display_address,
        companyName: response.contractor_company.name,
        companyType: response.contractor_company.type,
      };
    },
  }),
  addDocumentRepairLicense: build.mutation<TDocument, TDocumentRepairLicenseData>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set(
        'expiration_date',
        data.expirationDate ? getDdMmYyyyFormatDate(data.expirationDate) : '',
      );
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      return {
        url: DocumentsApiPaths.DOCUMENTS_REPAIR_LICENSE,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TDocumentDefaultResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        status: status as Status,
        type: response.type.type,
        expirationDate: response.expiration_date,
      };
    },
  }),
  addDocumentW9Business: build.mutation<TDocument, TDocumentW9Business>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set('ein', data.ein.split('-').join(''));
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      return {
        url: DocumentsApiPaths.DOCUMENTS_W9_BUSINESS,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TDocumentDefaultResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        status: status as Status,
        type: response.type.type,
        expirationDate: response.expiration_date,
      };
    },
  }),
  addDocumentW9Individual: build.mutation<TDocument, TDocumentW9Individual>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set('first_name', data.firstName);
      formData.set('last_name', data.lastName);
      formData.set('ssn', data.ssn.split('-').join(''));
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      return {
        url: DocumentsApiPaths.DOCUMENTS_W9_PHYSICAL,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TDocumentW9IndividualResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        status: status as Status,
        type: response.type.type,
        expirationDate: response.expiration_date,
        ssn: response.ssn,
      };
    },
  }),
  addDocumentSsn: build.mutation<TDocument, TDocumentSsn>({
    query: (data) => {
      const formData = new FormData();
      formData.set('document', data.file, data.file.name);
      formData.set('type_id', JSON.stringify(data.type_id));
      formData.set('start_date', getDdMmYyyyFormatDate(data.startDate));
      formData.set('first_name', data.firstName);
      formData.set('last_name', data.lastName);
      formData.set('ssn', data.ssn.split('-').join(''));
      formData.set('uploaded_by', JSON.stringify(data.technician_id));
      formData.set('status', 'Pending');
      return {
        url: DocumentsApiPaths.DOCUMENTS_SSN,
        method: 'POST',
        formData: true,
        body: formData,
      };
    },
    transformResponse: (response: TDocumentSsnResponse): TDocument => {
      const status = response.status.toLowerCase();
      return {
        id: response.id,
        fileName: response.file.name,
        startDate: response.start_date,
        status: status as Status,
        type: response.type.type,
        expirationDate: response.expiration_date,
        ssn: response.ssn,
      };
    },
  }),

  deleteDocument: build.mutation<void, { id: number; url: string }>({
    query: ({ id, url }) => {
      return {
        url: `${url}${id}`,
        method: 'DELETE',
      };
    },
  }),
});
