import { TWorkArea, TZip, TNewWorkArea, TCenterCoordinate } from '@core/redux/models/serviceAreas';
import { createZip } from '../../utils';
import * as styles from './Map.module.scss';
import { TGoogleUidLists, ValidateType } from './types';
import { useWorkAreaStatusType } from '@core/utils/utils';

export const createListActiveZips = (mapWorkAreas: (TWorkArea | TNewWorkArea)[]): TZip[] => {
  const defaultList: TZip[] = [];

  return mapWorkAreas.reduce((zips: TZip[], workArea: TWorkArea | TNewWorkArea): TZip[] => {
    const { isActive, isNewActive } = useWorkAreaStatusType(workArea.status);

    if (isActive || isNewActive) {
      zips = [...zips, createZip(workArea)];
    }
    return zips;
  }, defaultList);
};

export const removePopup = (): void => {
  const popup = document.querySelector('[aria-label="popup-on-map"]');
  popup?.parentElement?.remove();
};

export const addStyleForPopUp = (): void => {
  const popup = document.querySelector('[aria-label="popup-on-map"]');
  if (popup !== null) {
    popup.className = styles.popup;
    const textDiv = popup.children.item(1);
    if (textDiv !== null) {
      textDiv.className = styles.popupWrapper;
      const textDivTwo = textDiv.children.item(0);
      if (textDivTwo !== null) {
        textDivTwo.className = styles.popupText;
      }
    }
    const button = popup?.children.item(2);
    if (button !== null) {
      button.className = styles.popupButton;
    }
  }
};

export const createGoogleUidLists = (
  mapWorkAreas: (TNewWorkArea | TWorkArea)[],
): TGoogleUidLists => {
  const defaultGoogleUidLists: TGoogleUidLists = {
    listActiveGoogleUid: [],
    listDeleteGoogleUid: [],
    listNewActiveGoogleUid: [],
    listNewDeleteGoogleUid: [],
  };

  return mapWorkAreas.reduce(
    (lists: TGoogleUidLists, workArea: TNewWorkArea | TWorkArea): TGoogleUidLists => {
      const { googleUid } = workArea;
      const { isDelete, isNewActive, isNewDelete } = useWorkAreaStatusType(workArea.status);

      switch (true) {
        case isDelete:
          lists.listDeleteGoogleUid = [...lists.listDeleteGoogleUid, googleUid];
          break;
        case isNewActive:
          lists.listNewActiveGoogleUid = [...lists.listNewActiveGoogleUid, googleUid];
          break;
        case isNewDelete:
          lists.listNewDeleteGoogleUid = [...lists.listNewDeleteGoogleUid, googleUid];
          break;
        default:
          lists.listActiveGoogleUid = [...lists.listActiveGoogleUid, googleUid];
      }

      return lists;
    },
    defaultGoogleUidLists,
  );
};

export const useValidateType = (type?: ValidateType) => {
  const isDelete = type === ValidateType.delete;
  const isNewActive = type === ValidateType.newActive;
  const isNewDelete = type === ValidateType.newDelete;
  return { isDelete, isNewActive, isNewDelete };
};

export const createCoordinate = (newWorkArea: TNewWorkArea | TWorkArea): TCenterCoordinate => {
  return {
    lat: newWorkArea.latitude,
    lng: newWorkArea.longitude,
  };
};
