export type TDocumentIdFormData = {
  startDate: string;
  expirationDate: string;
  file: File;
  firstName: string;
  lastName: string;
  birthDate: string;
};
export type TDocumentW9BusinessFormData = { startDate: string; file: File; ein: string };
export type TDocumentW9IndividualFormData = {
  startDate: string;
  file: File;
  ssn: string;
  firstName: string;
  lastName: string;
};
export type TDocumentSsnFormData = TDocumentW9IndividualFormData;
export type TDocumentproofCompanyRegistration = {
  startDate: string;
  expirationDate: string;
  file: File;
  companyName: string;
  street: string;
  apartment: string;
  city: string;
  stateCode: string;
  postalCode: string;
  titleInCompany: string;
  ownerFirstName: string;
  ownerLastName: string;
  companyType: string;
  dba: boolean;
  dbaCompanyName: string;
  dbaStartDate: string;
  dbaExpiration: string;
  dbaFile: File;
};
export type TDocumentRepairLicenseForm = {
  startDate: string;
  expirationDate: string;
  file: File;
};
export type TDocumentInsuranceForm = {
  startDate: string;
  expirationDate: string;
  file: File;
  companyName: string;
};

export type TDocumentFormData =
  | TDocumentIdFormData
  | TDocumentW9BusinessFormData
  | TDocumentW9IndividualFormData
  | TDocumentSsnFormData
  | TDocumentproofCompanyRegistration
  | TDocumentRepairLicenseForm
  | TDocumentInsuranceForm;

export enum CompanyType {
  llc = 'LLC',
  corporation = 'Corporation',
}

export enum DocumentType {
  w9Business = 'w9-business',
  w9Individual = 'w9-individual',
  repairLicense = 'appliance-repair-license',
  id = 'id',
  ssn = 'ssn',
  companyRegistration = 'proof-of-company-registration',
  insurance = 'general-liability-insurance',
}

export const isTDocumentIdFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentIdFormData =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'firstName' in formData &&
      typeof formData.firstName === 'string' &&
      'lastName' in formData &&
      typeof formData.lastName === 'string',
  );

export const isTDocumentCompanyRegistrationFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentproofCompanyRegistration =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'ownerFirstName' in formData &&
      typeof formData.ownerFirstName === 'string' &&
      'ownerLastName' in formData &&
      typeof formData.ownerLastName === 'string' &&
      'street' in formData &&
      typeof formData.street === 'string' &&
      'apartment' in formData &&
      typeof formData.apartment === 'string',
  );

export const isTDocumentInsuranceFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentInsuranceForm =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'companyName' in formData &&
      typeof formData.companyName === 'string' &&
      'ownerLastName' in formData &&
      typeof formData.ownerLastName === 'string',
  );

export const isTDocumentRepairLicenseFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentRepairLicenseForm =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'startDate' in formData &&
      typeof formData.startDate === 'object' &&
      'expirationDate' in formData &&
      typeof formData.expirationDate === 'object',
  );

export const isTDocumentW9BusinessFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentW9BusinessFormData =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'startDate' in formData &&
      typeof formData.startDate === 'object' &&
      'ein' in formData &&
      typeof formData.ein === 'string',
  );

export const isTDocumentW9IndividualOrSsnFormData = (
  formData: TDocumentFormData,
  type: boolean,
): formData is TDocumentW9IndividualFormData | TDocumentSsnFormData =>
  Boolean(
    type &&
      typeof formData === 'object' &&
      formData !== null &&
      'ssn' in formData &&
      typeof formData.ssn === 'string' &&
      'firstName' in formData &&
      typeof formData.firstName === 'string',
  );
