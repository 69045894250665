import { TFilters, TPayoutsParams } from '@core/redux/models/payouts';
import { isFilterKey } from '../../types';

export const getSearchParams = (params: TFilters, partnership_id: number): TPayoutsParams => {
  const result: TPayoutsParams = { partnership_id: partnership_id };
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      if (isFilterKey(key)) {
        result[key] = value;
      }
    }
  }

  return result;
};
