import { useEffect, useState, useRef } from 'react';
import { wsHost } from '@core/constants';

export const useWebSocket = (url: string) => {
  const fullUrl = `${wsHost}${url}`;

  const [isReady, setIsReady] = useState<boolean>(false);
  const [val, setVal] = useState<string | null>(null);
  const [error, setError] = useState<string>('');

  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (ws.current) {
      return;
    }

    const socket = new WebSocket(fullUrl);

    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => setVal(event.data);
    socket.onerror = (ev) => {
      if (ev instanceof ErrorEvent) {
        setError(ev.message);
      }
    };

    ws.current = socket;

    return () => {
      if (socket.readyState === 1) {
        socket.close();
      }
    };
  }, []);

  return [isReady, val, error, ws.current?.send.bind(ws.current)];
};
