import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Button from '@components/Button/Button';
import Pen from '@assets/images/pen.svg';
import Cancel from '@assets/images/cancel.svg';
import AgreeChange from '@assets/images/agreeChange.svg';
import Spinner from '@components/Spinner/Spinner';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import { ModuleAction } from '@core/redux/models/profile';
import * as styles from './MainButton.module.scss';
import { useModuleActionType } from '@core/utils/utils';
import { useActions } from '@core/hooks/actions';

type MainButtonProps = {
  action: ModuleAction;
  setAction: (arg: ModuleAction) => void;
  isLoading?: boolean;
  sendDisabled?: boolean;
  resetIsLoading?: boolean;
  refreshDataFunction: () => void;
  sendDataFunction: () => void;
  isSendError?: boolean;
  noSendDataMode?: boolean;
  classNameCancel?: string;
};

export default function MainButton(props: MainButtonProps) {
  const {
    action,
    setAction,
    isLoading,
    sendDisabled,
    resetIsLoading,
    refreshDataFunction,
    sendDataFunction,
    isSendError,
    noSendDataMode,
    classNameCancel,
  } = props;

  const [onSend, setOnSend] = useState<boolean>(false);
  const { setIsUpdateWorkBlocks } = useActions();
  const { isEdit, isChange } = useModuleActionType(action);

  const onClickEdit = useCallback(() => {
    setOnSend(false);
    setAction(ModuleAction.edit);
  }, [onSend]);

  const onClickSend = useCallback(async () => {
    await sendDataFunction();
    setOnSend(true);
    setIsUpdateWorkBlocks(true);
  }, [sendDataFunction, onSend]);

  const onClickCancel = useCallback(async () => {
    if (isChange) {
      await refreshDataFunction();
    } else {
      setOnSend(true);
    }
  }, [refreshDataFunction, isChange]);

  useEffect(() => {
    if (!isLoading && onSend && !isSendError) {
      setAction(ModuleAction.send);
    }
  }, [isLoading, onSend, isSendError]);

  useEffect(() => {
    if (!resetIsLoading) {
      setAction(ModuleAction.send);
    }
  }, [resetIsLoading]);

  return isEdit || isChange ? (
    <div className={styles.buttonGroup}>
      {!noSendDataMode && (
        <Button
          mode={ButtonMode.icon}
          bgColor={ButtonBgColor.white}
          className={cn(styles.size, isChange && styles.button)}
          onClick={onClickCancel}
          disabled={resetIsLoading}
        >
          {resetIsLoading ? (
            <Spinner color='var(--light_accent_main)' />
          ) : (
            <Cancel fill='var(--light_accent_negative)' />
          )}
        </Button>
      )}

      {(isChange || noSendDataMode) && (
        <Button
          mode={ButtonMode.icon}
          bgColor={ButtonBgColor.white}
          className={cn(styles.size, styles.button, { [styles.noSendButton]: noSendDataMode })}
          onClick={onClickSend}
          disabled={isLoading || sendDisabled}
        >
          {isLoading ? (
            <Spinner color='var(--light_accent_main)' />
          ) : (
            <AgreeChange fill='var(--light_accent_positive)' />
          )}
        </Button>
      )}
    </div>
  ) : (
    <Button
      mode={ButtonMode.icon}
      bgColor={ButtonBgColor.white}
      className={cn(styles.size, classNameCancel)}
      onClick={onClickEdit}
      disabled={isLoading}
    >
      <Pen fill='var(--light_accent_main)' />
    </Button>
  );
}
