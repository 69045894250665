import React from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import * as styles from './ConfirmModalPage.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import cn from 'classnames';

type ConfirmModalPageProps = {
  children?: React.ReactNode;
  setIsShown: (val: boolean) => void;
  header: string;
  headerClassName?: string;
  text: string;
  textClassName?: string;
  hint?: string;
  handleConfirm: () => void;
  confirmLoading?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export default function ConfirmModalPage(props: ConfirmModalPageProps) {
  const {
    setIsShown,
    text,
    hint,
    handleConfirm,
    header,
    children,
    headerClassName,
    textClassName,
    confirmLoading,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
  } = props;
  return (
    <ModalPage
      header={<div className={styles.title}>{header}</div>}
      headerClassName={headerClassName}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
      closeable={false}
    >
      <div className={cn(styles.text, textClassName)}>{text}</div>
      {hint && <div className={styles.hint}>{hint}</div>}
      {children}
      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.lightGrey}
          mode={ButtonMode.standard}
          onClick={handleConfirm}
          loading={confirmLoading}
        >
          {confirmButtonText}
        </Button>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.darkBlue}
          mode={ButtonMode.standard}
          onClick={() => setIsShown(false)}
        >
          {cancelButtonText}
        </Button>
      </div>
    </ModalPage>
  );
}
