import React, { useEffect } from 'react';
import Actions from './components/Actions/Actions';
import Spinner from '@components/Spinner/Spinner';
import { usePartershipUser } from '@core/hooks/currentUser';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import PaymentTable from './components/PaymentTable/PaymentTable';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import Claims from './components/Claims/Claims';
import { TServerError } from '@core/utils/error';
import * as styles from './Payouts.module.scss';

export default function Payouts() {
  const partnership = usePartershipUser();
  const { data, isLoading, error } = payoutsAPI.useGetWeeksQuery();

  const { setCurrentWeek, setError } = useActions();

  useEffect(() => {
    if (error) {
      setError(error as TServerError);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setCurrentWeek(data[0]);
    }
  }, [data]);

  const { currentWeek } = useAppSelector((state) => state.payoutsReducer);

  if (!partnership || !currentWeek || isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner size='large' color='var(--light_accent_main)' />
      </div>
    );
  }

  return (
    <>
      <Actions weeks={data} partnership={partnership} currentWeek={currentWeek} />
      <Claims />
      <PaymentTable partnership={partnership} currentWeek={currentWeek} />
    </>
  );
}
