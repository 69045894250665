// extracted by mini-css-extract-plugin
var _1 = "Registration-module__blue__F0aSH";
var _2 = "Registration-module__cards__nCKuU";
var _3 = "Registration-module__link__rkNn7";
var _4 = "Registration-module__text__Slv_1";
var _5 = "Registration-module__textBlock__Hj5VB";
var _6 = "Registration-module__title__H7OIq";
var _7 = "Registration-module__wrapper__E8_6J";
export { _1 as "blue", _2 as "cards", _3 as "link", _4 as "text", _5 as "textBlock", _6 as "title", _7 as "wrapper" }

    if(module.hot) {
      // 1733466104441
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  