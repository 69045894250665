import React, { useCallback, useMemo, useState } from 'react';
import { Chart } from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import cn from 'classnames';
import FilterIcon from '@assets/images/filter.svg';
import * as styles from './Kpi.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import FilterModal from './components/FilterModal/FilterModal';
import LeftSwipeIcon from '@assets/images/arrowLeft.svg';
import RightSwipeIcon from '@assets/images/arrowRight.svg';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { getChartData, weekSection } from './utils';
import { Line } from 'react-chartjs-2';
import { TWeeks } from '@core/redux/models/partnership';
import { useAppSelector } from '@core/hooks/redux';

type TProps = {
  partnership: TPartnershipElement;
  weeks: TWeeks;
};

Chart.register(CategoryScale);

export default function Kpi(props: TProps) {
  const { partnership, weeks } = props;
  const [weekIndex, setWeekIndex] = useState(0);
  const sectionList = weekSection(weeks, 3);

  const { appliance, workTypeId } = useAppSelector((state) => state.partnershipReducer);
  const { data, isFetching } = partnershipAPI.useGetKpigraphQuery({
    partnership_id: partnership.id,
    weeksList: sectionList[weekIndex],
    type_id: workTypeId ?? undefined,
  });

  const chartData = useMemo(() => {
    return data && getChartData(data);
  }, [data]);

  const handleNext = useCallback(() => {
    if (weekIndex - 1 >= 0) {
      setWeekIndex(weekIndex - 1);
    } else {
      setWeekIndex(sectionList.length - 1);
    }
  }, [weekIndex, sectionList]);

  const handlePrev = useCallback(() => {
    if (weekIndex + 1 < sectionList.length) {
      setWeekIndex(weekIndex + 1);
    } else {
      setWeekIndex(0);
    }
  }, [weekIndex, sectionList]);

  const [filterModal, setFilterModal] = useState<boolean>(false);

  const graphTitle = () => {
    const cluster = data?.clusterPlace ? data.clusterPlace : '-';
    const current = data?.currentPlace ? data.currentPlace : '-';
    return cluster || current ? `> ${cluster} (${current})` : 'no data';
  };

  return (
    <>
      <div className={styles.wrap}>
        <span className={styles.separator}></span>
        <div className={styles.header}>
          <span className={styles.text}>KPI:</span>
          <span className={styles.text}>{appliance}</span>
          <Button
            mode={ButtonMode.icon}
            bgColor={ButtonBgColor.transparent}
            onClick={() => setFilterModal(true)}
          >
            <FilterIcon fill='var(--light_label_primary)' />
          </Button>
        </div>
        <div className={styles.wrapper}>
          <Button
            mode={ButtonMode.icon}
            bgColor={ButtonBgColor.transparent}
            onClick={handlePrev}
            disabled={weekIndex === sectionList.length - 1 || isFetching}
            className={cn(styles.button, styles.buttonLeft)}
          >
            <LeftSwipeIcon fill='var(--light_label_tertiary)' />
          </Button>
          {isFetching ? '' : <div className={styles.title}>{graphTitle()}</div>}
          <Button
            mode={ButtonMode.icon}
            bgColor={ButtonBgColor.transparent}
            onClick={handleNext}
            disabled={weekIndex === 0 || isFetching}
            className={cn(styles.button, styles.buttonRight)}
          >
            <RightSwipeIcon fill='var(--light_label_tertiary)' />
          </Button>
        </div>
        <div className={cn(styles.graph, { [styles.graphFetching]: isFetching })}>
          {chartData && (
            <Line
              data={chartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const label = context.dataset.label || '';
                        if (!label) {
                          return '';
                        }
                        const list = label.split(':');

                        const name = list[1] ?? '';

                        if (context.parsed.y !== null) {
                          return `${name} ${context.parsed.y} (${list[0].split(',')[index]})`;
                        }
                      },
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
      {filterModal && <FilterModal setIsShown={setFilterModal} />}
    </>
  );
}
