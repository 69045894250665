// extracted by mini-css-extract-plugin
var _1 = "ReviewsExpert-module__nextButton__t1boK";
var _2 = "ReviewsExpert-module__prevButton__VYYW1";
var _3 = "ReviewsExpert-module__wrapper__na8We";
export { _1 as "nextButton", _2 as "prevButton", _3 as "wrapper" }

    if(module.hot) {
      // 1733466108338
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  