import React, { useState, useCallback } from 'react';
import { useCurrentUser } from '@core/hooks/currentUser';
import { profileAPI } from '@core/redux/services/profileService';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import Button from '@components/Button/Button';
import PlayIcon from '@assets/images/play.svg';
import StopIcon from '@assets/images/stop.svg';
import * as styles from './Account.module.scss';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import DeactivateModal from './DeactivateModal';

export default function Account() {
  const { technicianId } = useCurrentUser();
  const [modal, setModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);

  const { selfSuspended, isUpdateWorkBlocks } = useAppSelector((state) => state.generalInfoReducer);
  const { setIsUpdateWorkBlocks } = useActions();

  const [handleUpdateActivateStatus, { isLoading: updateLoading }] =
    profileAPI.useUpdateTechnicianActivateAccountStatusMutation();

  const handleUpdate = useCallback(async () => {
    const result = await handleUpdateActivateStatus({
      id: technicianId,
      selfSuspended: !selfSuspended,
    });
    if ('data' in result) {
      setIsUpdateWorkBlocks(true);
      setModal(false);
      return;
    }
    throw new Error('Something went wrong!');
  }, [selfSuspended]);

  return (
    <section className={styles.wrapper}>
      <span className={styles.separator}></span>
      <h2 className={styles.title}>Account settings:</h2>
      <Button
        onClick={() => setModal(true)}
        disabled={isUpdateWorkBlocks}
        className={styles.button}
        beforeIcon={
          selfSuspended ? (
            <PlayIcon fill='var(--light-background-primary)' />
          ) : (
            <StopIcon fill='var(--light-background-primary)' />
          )
        }
        bgColor={selfSuspended ? ButtonBgColor.green : ButtonBgColor.lightGrey}
        size={ButtonSize.m}
      >
        {selfSuspended ? 'Activate' : 'Deactivate'}
      </Button>
      {modal && (
        <ConfirmModalPage
          setIsShown={setModal}
          header={selfSuspended ? 'Activate Account?' : 'Deactivate account?'}
          text={
            selfSuspended
              ? 'Once reactivated, your profile will be visible to all companies.'
              : 'This action will disable your account across all companies. Are you sure you want to proceed?'
          }
          handleConfirm={
            selfSuspended
              ? handleUpdate
              : () => {
                  setDeactivateModal(true);
                  setModal(false);
                }
          }
          confirmLoading={selfSuspended ? updateLoading : undefined}
        />
      )}
      {deactivateModal && <DeactivateModal setIsShown={setDeactivateModal} />}
    </section>
  );
}
