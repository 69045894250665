export const defaultOptions: google.maps.MapOptions = {
  panControl: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  keyboardShortcuts: false,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapId: 'c5ea638c913b6c37',
};

export const styleDefault: google.maps.FeatureStyleOptions = {
  strokeColor: '#595b61',
  strokeOpacity: 0,
  strokeWeight: 1.0,
};

export const styleChange: google.maps.FeatureStyleOptions = {
  ...styleDefault,
  strokeOpacity: 2,
  fillOpacity: 0.01,
  fillColor: 'white',
};

export const styleActive: google.maps.FeatureStyleOptions = {
  ...styleDefault,
  fillColor: '#7399EE',
  fillOpacity: 0.6,
  strokeOpacity: 2,
};

export const styleNewActive: google.maps.FeatureStyleOptions = {
  ...styleDefault,
  fillColor: '#003EC8',
  fillOpacity: 0.6,
  strokeOpacity: 2,
};

export const styleDelete: google.maps.FeatureStyleOptions = {
  ...styleDefault,
  fillColor: '#CF5656',
  fillOpacity: 0.5,
  strokeOpacity: 2,
};

export const styleNewDelete: google.maps.FeatureStyleOptions = {
  ...styleDefault,
  fillColor: '#BE1717',
  fillOpacity: 0.6,
  strokeOpacity: 2,
};
