import React from 'react';
import * as styles from './ReviewsCardAgency.module.scss';

type ReviewsCardAgencyProps = {
  image: string;
  name: string;
  text: string;
  bgColor: string;
};

export default function ReviewsCardAgency(props: ReviewsCardAgencyProps) {
  const { image, name, text, bgColor } = props;

  return (
    <div className={styles.wrapper} style={{ backgroundColor: bgColor }}>
      <div className={styles.titleBlock}>
        <img src={image} alt='agencyLogo' className={styles.img} />
        <h3 className={styles.title}>{name}</h3>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
