// extracted by mini-css-extract-plugin
var _1 = "PageInfo-module__banner__KxGup";
var _2 = "PageInfo-module__wrapper__TaZpk";
export { _1 as "banner", _2 as "wrapper" }

    if(module.hot) {
      // 1733466102310
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  