import React, { ReactNode } from 'react';
import cn from 'classnames';
import * as styles from './Button.module.scss';
import Spinner from '@components/Spinner/Spinner';
import { ButtonBgColor, ButtonSize, ButtonMode } from './types';

type ButtonProps = {
  beforeIcon?: ReactNode;
  afterIcon?: ReactNode;
  loading?: boolean;
  children: ReactNode;
  type?: 'submit';
  mode?: ButtonMode;
  bgColor?: ButtonBgColor;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export default function Button(props: ButtonProps) {
  const {
    beforeIcon,
    afterIcon,
    loading,
    children,
    mode = ButtonMode.standard,
    size,
    bgColor = ButtonBgColor.grey,
    disabled,
    className,
    onMouseEnter,
    onMouseLeave,
    ...commonProps
  } = props;

  return (
    <button
      {...commonProps}
      className={cn(styles.common, styles[mode], styles[bgColor], size && styles[size], className)}
      disabled={loading || disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {loading && <Spinner color='var(--light-background-primary)' />}
      {!loading && (
        <>
          {!!beforeIcon && beforeIcon}
          {children}
          {!!afterIcon && afterIcon}
        </>
      )}
    </button>
  );
}
