import React, { useCallback, useState } from 'react';
import * as styles from './Postal.module.scss';
import { TCenterCoordinate, TWorkArea } from '@core/redux/models/serviceAreas';
import Timer from '@assets/images/timer.svg';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import ModalInfo from '@pages/Profile/components/ModalInfo/ModalInfo';
import { useActions } from '@core/hooks/actions';

type TPostalProps = {
  workArea: TWorkArea;
};

export default function Postal(props: TPostalProps) {
  const { workArea } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { setMapPosition, setFullScreen, setInfoArea, setZoom } = useActions();

  const changeModalState = useCallback(() => {
    return setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const showPostalOnMap = useCallback(() => {
    const coordinate: TCenterCoordinate = {
      lat: workArea.latitude,
      lng: workArea.longitude,
    };

    setMapPosition(coordinate);
    setZoom(11);
    setInfoArea(`${workArea.cityName}, ${workArea.state} ${workArea.code}`);
    setFullScreen(true);
  }, []);

  return (
    <>
      <Button mode={ButtonMode.standard} bgColor={ButtonBgColor.white} onClick={showPostalOnMap}>
        <p className={styles.postal}>{workArea.code}</p>
      </Button>
      {workArea.isDeleted && (
        <Button mode={ButtonMode.standard} bgColor={ButtonBgColor.white} onClick={changeModalState}>
          <Timer fill='var(--light_accent_neutral)' />
        </Button>
      )}
      {isModalOpen && typeof workArea.deleteTimeout === 'string' && (
        <ModalInfo time={workArea.deleteTimeout} setIsShown={changeModalState} />
      )}
    </>
  );
}
