import React from 'react';
import Container from '@layout/Container/Container';
import * as styles from './Header.module.scss';
import { useHeaderTitle } from './hooks';
import Navigation from './components/Navigation/Navigation';
import { useLocation } from 'react-router-dom';
import { ROUTES, noHeadresRoutes } from '@routes/constants';

function Header() {
  const title = useHeaderTitle();
  const { pathname } = useLocation();

  if (noHeadresRoutes.includes(pathname as ROUTES)) {
    return null;
  }

  return (
    <header className={styles.header}>
      <Container>
        <nav className={styles.content}>
          <div className={styles.title}>{title}</div>
          <Navigation />
        </nav>
      </Container>
    </header>
  );
}

export default Header;
