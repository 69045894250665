import BaseTable, { AutoResizer } from 'react-base-table';
import React from 'react';
import * as styles from './Table.module.scss';
import 'react-base-table/styles.css';
import { TableColumn } from './types';

type TProps<T> = {
  data: T[];
  columns: TableColumn<T>[];
};

export default function Table<T>(props: TProps<T>) {
  const { data, columns } = props;
  return (
    <div className={styles.table}>
      <div className={styles.layout}>
        <div className={styles.container}>
          <AutoResizer width={810} height={data.length ? 52 * data.length + 52 : 0}>
            {({ width, height }) => {
              return (
                <div style={{ height: height }}>
                  <BaseTable<T>
                    width={width}
                    height={height}
                    columns={columns}
                    data={data}
                    rowCount={data?.length}
                    rowHeight={52}
                    headerHeight={52}
                  />
                </div>
              );
            }}
          </AutoResizer>
        </div>
      </div>
    </div>
  );
}
