// extracted by mini-css-extract-plugin
var _1 = "Account-module__button__voe2v";
var _2 = "Account-module__separator__9C8_v";
var _3 = "Account-module__title__QJwpB";
var _4 = "Account-module__wrapper__c4kC1";
export { _1 as "button", _2 as "separator", _3 as "title", _4 as "wrapper" }

    if(module.hot) {
      // 1733466105011
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  