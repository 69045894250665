import { PartnershipStatus } from './partnership';

export enum Mode {
  total = 'total',
  changes = 'changes',
}

export enum FilterKeys {
  invoice_number = 'invoice_number',
  customer_name = 'customer_name',
  amount_min = 'amount_min',
  amount_max = 'amount_max',
  commission_max = 'commission_max',
  commission_min = 'commission_min',
  date_before = 'date_before',
  date_after = 'date_after',
}

export type TFilters = {
  invoice_number: string;
  customer_name: string;
  amount_min: string;
  amount_max: string;
  commission_min: string;
  commission_max: string;
  date_before: string;
  date_after: string;
};

export type TPartnership = TPartnershipElement[];

export type TPartnershipElement = {
  id: number;
  companyTitle: string;
  companyId: number;
  crm: string;
  email: string;
  knowledgeBaseUrl: string;
  status: PartnershipStatus;
};

export type TPartnershipResponse = {
  id: number;
  company: {
    id: number;
    title: string;
    crm_system: string;
    knowledge_base_url: string | null;
  };
  expert_crm_email: string | null;
  status: PartnershipStatus;
}[];

export type TWeek = {
  id: number;
  start: string;
  end: string;
  number: number;
  isPaymentWeek: boolean;
};
export type TWeeks = TWeek[];

export type TWeeksResponse = {
  id: number;
  start: string;
  end: string;
  number: number;
  is_payment_week: boolean;
}[];

export type TPayouts = {
  result: TPayout[];
  commissionSubtotal: number;
  changesSubtotal: number;
};

export type TPayout = {
  id: number;
  invoice: string;
  date: string;
  customer: string;
  amount: string;
  commission: string;
  commissionId: number;
  orderId: number;
  isRental: boolean;
  isCustomTransaction: boolean;
};

export type TPayoutsParams = {
  week_id?: number;
  partnership_id: number;
  start?: string;
  end?: string;
  invoice_number?: string;
  customer_name?: string;
  commission_min?: string;
  commission_max?: string;
  amount_min?: string;
  amount_max?: string;
  date_before?: string;
  date_after?: string;
};

export type TPayoutsResponse = {
  results: {
    id: number;
    invoice_number: string;
    scheduled_end: string;
    customer_name: string;
    total_amount: string;
    final_commission: string;
    final_commission_changes?: string;
    commission_id: number;
    order_id: number;
    is_rental: boolean;
    is_custom_transaction: boolean;
  }[];
  commission_subtotal: number;
  changes_subtotal: number;
};

export type TChangesPayout = TPayout & { finalCommissionChanges: string };

export type TChangesAmount = {
  result: TChangesPayout[];
  commissionSubtotal: number;
  changesSubtotal: number;
};

export type TChangesAmountParams = {
  week_id: number;
  partnership_id: number;
};

export type TOutstandingBalance = {
  balance: number;
};

export type TPayments = {
  amount: string;
}[];

export type TClaimResponse = {
  commission_id: number;
  created_at: string;
  id: number;
  invoice_number: string;
  is_active: boolean;
  messages: TMessageResponse[];
};

export type TClaimsResponse = TClaimResponse[];

export type TMessageResponse = {
  claim_id: number;
  created_at: string;
  created_by: number;
  created_by_name: string;
  text: string;
};

export type TClaimMessage = {
  claimId: number;
  createdBy: number;
  createdByName: string;
  createdAt: string;
  text: string;
};

export type TClaimMessages = TClaimMessage[];

export type TClaim = {
  commissionId: number;
  id: number;
  invoiceNumber: string;
  isActive: boolean;
  createdAt: string;
  messages: TClaimMessages;
};

export type TClaimsList = TClaim[];

export type TPayoutsHistory = {
  data: string;
  detail: string;
  result: boolean;
  status: string;
};
