import React, { useState, useCallback } from 'react';
import Button from '@components/Button/Button';
import PlayIcon from '@assets/images/play.svg';
import StopIcon from '@assets/images/stop.svg';
import * as styles from './ControlPanel.module.scss';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import DeactivateModal from './DeactivateModal';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { PartnershipStatus } from '@core/redux/models/partnership';
import { useCurrentUser } from '@core/hooks/currentUser';

type TProps = {
  partnership: TPartnershipElement;
};

export default function ControlPanel(props: TProps) {
  const { technicianId } = useCurrentUser();
  const { partnership } = props;

  const [modal, setModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [handleUnsuspend, { isLoading: updateLoading }] = partnershipAPI.useDeactivatedMutation();

  const handleUpdate = useCallback(async () => {
    const result = await handleUnsuspend({
      partnership_id: partnership.id,
      status: PartnershipStatus.active,
      technician_id: technicianId,
    });
    if ('data' in result) {
      setModal(false);
      return;
    }
    throw new Error('Something went wrong!');
  }, []);

  return (
    <section className={styles.wrapper}>
      <span className={styles.separator}></span>
      <h2 className={styles.title}>Control panel:</h2>
      <Button
        onClick={() => setModal(true)}
        className={styles.button}
        disabled={
          partnership.status !== PartnershipStatus.active &&
          partnership.status !== PartnershipStatus.deactivatedByExpert
        }
        beforeIcon={
          partnership.status !== PartnershipStatus.active ? (
            <PlayIcon fill='var(--light-background-primary)' />
          ) : (
            <StopIcon fill='var(--light-background-primary)' />
          )
        }
        bgColor={
          partnership.status !== PartnershipStatus.active
            ? ButtonBgColor.green
            : ButtonBgColor.lightGrey
        }
        size={ButtonSize.m}
      >
        {partnership.status !== PartnershipStatus.active ? 'Unsuspend' : 'Suspend partnership'}
      </Button>
      {modal && (
        <ConfirmModalPage
          setIsShown={setModal}
          header={
            partnership.status !== PartnershipStatus.active
              ? 'Unsuspend partnership?'
              : 'Suspend partnership?'
          }
          text={
            partnership.status !== PartnershipStatus.active
              ? "Are you sure you want to resume your work with the agency? Are you willing to receive leads? To proceed with your engagement with the company, you'll need the company's approval as well."
              : 'This action will suspend your partnership across all companies. Are you sure you want to proceed?'
          }
          handleConfirm={
            partnership.status !== PartnershipStatus.active
              ? handleUpdate
              : () => {
                  setDeactivateModal(true);
                  setModal(false);
                }
          }
          confirmLoading={
            partnership.status !== PartnershipStatus.active ? updateLoading : undefined
          }
        />
      )}
      {deactivateModal && (
        <DeactivateModal setIsShown={setDeactivateModal} partnership={partnership} />
      )}
    </section>
  );
}
