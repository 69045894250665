import * as styles from './App.module.scss';
import cn from 'classnames';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '@layout/Header/Header';
import Container from '@layout/Container/Container';
import { useRoutes } from '@routes/routes';
import { useAppSelector } from '@core/hooks/redux';
import Tooltip, { TooltipType } from '@components/Tooltip/Tooltip';
import MainPage from '@pages/MainPage/MainPage';
import { ROUTES } from '@routes/constants';
import RedirectPage from '@pages/RedirectPage/RedirectPage';

export default function App() {
  const { token } = useAppSelector((state) => state.authReducer);
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.REDIRECT_PAGE} element={<RedirectPage isAuth={isAuthenticated} />} />
        {!isAuthenticated && <Route path={ROUTES.MAIN} element={<MainPage />} />}
      </Routes>
      <div
        className={cn(styles.wrapper, {
          [styles.hideWrapper]: window.location.pathname === ROUTES.MAIN && !isAuthenticated,
        })}
      >
        {isAuthenticated && <Header />}
        <Container>{routes}</Container>
        <Tooltip type={TooltipType.error} />
      </div>
    </Router>
  );
}
