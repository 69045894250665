import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import React from 'react';
import * as styles from './TermsConditionsModal.module.scss';

type TermsConditionsModalProps = {
  setIsShown: (val: boolean) => void;
};

export default function TermsConditionsModal(props: TermsConditionsModalProps) {
  const { setIsShown } = props;
  return (
    <ModalPage
      header={<div>Terms and conditions</div>}
      mode={ModalPageMode.bottomSheet}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.transparent}
    >
      <p className={styles.p}>Last Updated: 19.01.2024</p>
      <h3 className={styles.h3}>1. Acceptance of Terms</h3>
      <p className={styles.p}>
        By using Expert Hub, including but not limited to registering for an account, you agree to
        comply with and be bound by the following Terms and Conditions. If you do not agree to these
        terms, please do not use Expert Hub.
      </p>
      <h3 className={styles.h3}>2. Account Registration</h3>
      <p className={styles.p}>
        a. To use Expert Hub, you must register for an account. You agree to provide accurate and
        complete information during the registration process, including your contact information and
        any required business information.
      </p>
      <p className={styles.p}>
        b. Experts must provide valid identification, proof of registration, and bank details for
        commission payouts.
      </p>
      <p className={styles.p}>
        c. Agencies may also be required to provide relevant business information during
        registration.
      </p>
      <p className={styles.p}>
        d. You are responsible for maintaining the confidentiality of your account credentials and
        are solely responsible for any activity that occurs under your account.
      </p>
      <h3 className={styles.h3}>3. User Responsibilities</h3>
      <p className={styles.p}>
        a. Experts and Agencies are solely responsible for the accuracy and legality of the
        information they provide on Expert Hub.
      </p>
      <p className={styles.p}>
        b. You agree not to use Expert Hub for any unlawful, fraudulent, or malicious purposes.{' '}
      </p>
      <p className={styles.p}>
        c. You agree not to engage in any activities that may disrupt or interfere with the
        functioning of Expert Hub.
      </p>
      <h3 className={styles.h3}>4. Privacy and Security</h3>
      <p className={styles.p}>
        a. Your use of Expert Hub is subject to our Privacy Policy, which outlines how we collect,
        use, and protect your personal information. By using Expert Hub, you consent to our data
        practices as described in the Privacy Policy.
      </p>
      <p className={styles.p}>
        b. While we take reasonable security measures, you understand and acknowledge that we do not
        guarantee the security of your personal information. It is your responsibility to take
        appropriate security measures to protect your personal information on Expert Hub.
      </p>
      <h3 className={styles.h3}>5. Agency-Expert Relationships</h3>
      <p className={styles.p}>
        Expert Hub serves as a platform to facilitate connections between Agencies and Experts.
        However, Expert Hub is not responsible for, and expressly disclaims any liability arising
        from, the relationships, interactions, agreements, or disputes between Agencies and Experts.
        This includes, but is not limited to, any issues related to commission payments, the quality
        of services provided, or the timeliness of payments. Users of Expert Hub are solely
        responsible for managing their relationships and resolving any disputes that may arise.
      </p>
      <h3 className={styles.h3}>6. Termination</h3>
      <p className={styles.p}>
        a. We reserve the right to terminate or suspend your access to Expert Hub for any violation
        of these Terms and Conditions or for any other reason at our discretion.
      </p>
      <h3 className={styles.h3}>7. Changes to Terms and Conditions</h3>
      <p className={styles.p}>
        a. We may update these Terms and Conditions to reflect changes in our practices or for other
        operational, legal, or regulatory reasons. We will notify you of any significant changes
        through the platform or via email.
      </p>
      <h3 className={styles.h3}>8. Contact Information</h3>
      <p className={styles.p}>
        If you have any questions or concerns about these Terms and Conditions or your use of Expert
        Hub, please contact us at <a href='mailto:info@experthub.app'>info@experthub.app</a>.
      </p>
    </ModalPage>
  );
}
