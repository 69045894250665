// extracted by mini-css-extract-plugin
var _1 = "DocumentsInfoModal-module__block__QzfSS";
var _2 = "DocumentsInfoModal-module__header__BXRPU";
var _3 = "DocumentsInfoModal-module__item__FavOJ";
var _4 = "DocumentsInfoModal-module__label__aAs5m";
var _5 = "DocumentsInfoModal-module__modal__zEHTN";
var _6 = "DocumentsInfoModal-module__module__I_oPO";
var _7 = "DocumentsInfoModal-module__separator__vN15B";
var _8 = "DocumentsInfoModal-module__title__k6Fbz";
var _9 = "DocumentsInfoModal-module__value__P00W6";
export { _1 as "block", _2 as "header", _3 as "item", _4 as "label", _5 as "modal", _6 as "module", _7 as "separator", _8 as "title", _9 as "value" }

    if(module.hot) {
      // 1733466109534
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  