import React from 'react';
import { Route } from 'react-router-dom';
import Registration from './components/Registration/Registration';
import Forgot from './components/Forgot/Forgot';
import Reset from './components/Reset/Reset';
import ConfirmPassword from './components/ConfirmPassword/ConfirmPassword';
import Login from './Login';
import { ROUTES } from '@routes/constants';

export const useAuthRoutes = () => {
  return (
    <>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.CONFIRM_PASSWORD} element={<ConfirmPassword />} />
      <Route path={ROUTES.REGISTRATION} element={<Registration />} />
      <Route path={ROUTES.FORGOT} element={<Forgot />} />
      <Route path={ROUTES.RESET} element={<Reset />} />
    </>
  );
};
