import { TWorkArea, TWorkAreasResponse, WorkAreaStatus } from '@core/redux/models/serviceAreas';

const DEFAULT_CITY_NAME = 'Other area';

export const createWorkArea = (data: TWorkAreasResponse): TWorkArea => {
  return {
    id: data.id,
    cityName: data.zip.city.name || DEFAULT_CITY_NAME,
    state: data.zip.city.state,
    code: data.zip.code,
    longitude: data.zip.longitude,
    latitude: data.zip.latitude,
    googleUid: data.zip.google_uid,
    isDeleted: data.is_deleting,
    deleteTimeout: data.delete_timeout,
    createdAt: data.created_at,
    status: data.is_deleting ? WorkAreaStatus.delete : WorkAreaStatus.active,
  };
};
