import { TScheduleItem, TScheduleTime } from '@core/redux/models/schedule';
import { ModuleAction } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNewRangeList } from '@core/utils/date';

type TScheduleState = {
  scheduleAction: ModuleAction;
  daySchedule: TScheduleItem;
};

const initialState: TScheduleState = {
  scheduleAction: ModuleAction.send,
  daySchedule: {
    id: NaN,
    name: '',
    jobs: 0,
    day: [],
  },
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setScheduleAction(state, action: PayloadAction<ModuleAction>) {
      state.scheduleAction = action.payload;
    },
    setDaySchedule(state, action: PayloadAction<TScheduleItem>) {
      state.daySchedule = action.payload;
    },
    setMaxJobs(state, action: PayloadAction<string>) {
      state.daySchedule.jobs = Number(action.payload);
      state.scheduleAction = ModuleAction.change;
    },
    setNewTimeRange(state, action: PayloadAction<TScheduleTime>) {
      getNewRangeList(state.daySchedule.day, action.payload);
      state.scheduleAction = ModuleAction.change;
    },
    deleteNewTimeRange(state, action: PayloadAction<number>) {
      state.daySchedule.day = state.daySchedule.day.filter((_, index) => index !== action.payload);
      state.scheduleAction = ModuleAction.change;
    },
    setFlagIsDeletedForRange(state, action: PayloadAction<number>) {
      state.daySchedule.day[action.payload].isDeleted = true;
      state.scheduleAction = ModuleAction.change;
    },
    deleteFlagIsDeletedForRange(state, action: PayloadAction<number>) {
      state.daySchedule.day[action.payload].isDeleted = false;
      state.scheduleAction = ModuleAction.change;
    },
  },
});

export const scheduleActions = scheduleSlice.actions;
export const scheduleReducer = scheduleSlice.reducer;
