import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@components/Button/Button';
import { ButtonSize, ButtonBgColor } from '@components/Button/types';
import { TAgencyRegistrationForm, TExpertRegistrationForm, TAccountType } from '../../types';
import * as styles from './Form.module.scss';
import TermsConditionsModal from '../Modals/TermsConditionsModal';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import { authAPI } from '@core/redux/services/authService';
import {
  parseHookFormMessage,
  parseServerError,
  TServerError,
  THookErrors,
} from '@core/utils/error';
import AgencyFields from './components/AgencyFields';
import ExpertFields from './components/ExpertFields';
import SupportMessage from '@pages/Login/components/SupportMessage/SupportMessage';

type FormProps = {
  setIsSend(isSend: boolean): void;
  setCurrentEmail(currentEmail: string): void;
  type: TAccountType;
};

export default function Form(props: FormProps) {
  const { setIsSend, setCurrentEmail, type } = props;

  const [termsConditionsModalOpen, setTermsConditionsModalOpen] = useState<boolean>(false);
  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState<boolean>(false);

  const defaultValues =
    type === TAccountType.agency
      ? {
          name: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
        }
      : {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
        };

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<TAgencyRegistrationForm | TExpertRegistrationForm>({
    defaultValues: defaultValues,
  });

  const [
    handleExpertRegistration,
    { isLoading: expertLoading, isSuccess: expertSuccess, error: expertError },
  ] = authAPI.useRegistrationExpertMutation();

  const [
    handleAgencyRegistration,
    { isLoading: agencyLoading, isSuccess: agencySuccess, error: agencyError },
  ] = authAPI.useRegistrationAgencyMutation();
  useEffect(() => {
    if (type === TAccountType.agency && agencySuccess) {
      setIsSend(true);
    }

    if (type === TAccountType.expert && expertSuccess) {
      setIsSend(true);
    }
  }, [expertSuccess, agencySuccess]);

  const onSubmit = async (data: TAgencyRegistrationForm | TExpertRegistrationForm) => {
    try {
      if (type === TAccountType.agency) {
        await handleAgencyRegistration(data as TAgencyRegistrationForm);
        setCurrentEmail(data.email);
      } else {
        await handleExpertRegistration(data as TExpertRegistrationForm);
        setCurrentEmail(data.email);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const hasFormErrors = !!Object.keys(formErrors).length;
  const serverError = type === TAccountType.expert ? expertError : agencyError;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          {type === TAccountType.agency && <AgencyFields control={control} />}
          {type === TAccountType.expert && <ExpertFields control={control} />}
        </div>
        <Button size={ButtonSize.l} type='submit' loading={agencyLoading || expertLoading}>
          Submit
        </Button>
        {hasFormErrors && (
          <div className={styles.error}>
            <p>{parseHookFormMessage(formErrors as THookErrors)}</p>
            <SupportMessage />
          </div>
        )}
        {!!serverError && !hasFormErrors && (
          <div className={styles.error}>{parseServerError(serverError as TServerError)}</div>
        )}
      </form>
      <div className={styles.info}>
        By continuing, you agree to
        <Button
          className={styles.link}
          bgColor={ButtonBgColor.transparent}
          onClick={() => setPrivacyPolicyModalOpen(true)}
        >
          Privacy Policy
        </Button>
        &nbsp;and&nbsp;
        <Button
          className={styles.link}
          bgColor={ButtonBgColor.transparent}
          onClick={() => setTermsConditionsModalOpen(true)}
        >
          Terms & Conditions
        </Button>
      </div>
      {termsConditionsModalOpen && (
        <TermsConditionsModal setIsShown={setTermsConditionsModalOpen} />
      )}
      {privacyPolicyModalOpen && <PrivacyPolicyModal setIsShown={setPrivacyPolicyModalOpen} />}
    </div>
  );
}
