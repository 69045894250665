// extracted by mini-css-extract-plugin
var _1 = "TermsConditionsModal-module__h3__Y5FR4";
var _2 = "TermsConditionsModal-module__p__VfXLe";
export { _1 as "h3", _2 as "p" }

    if(module.hot) {
      // 1733466109364
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  