import { Status } from '@core/redux/models/documents';
import { TContract } from '@core/redux/models/partnership';

export const isTDocumentInsurance = (document: unknown): document is TDocumentInsurance =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'expirationDate' in document &&
      typeof document.expirationDate === 'string' &&
      'companyName' in document &&
      typeof document.companyName === 'string',
  );

export const isTContract = (document: unknown): document is TContract =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'name' in document &&
      typeof document.name === 'string' &&
      'signUrl' in document &&
      typeof document.signUrl === 'string',
  );

export type TDocumentInsurance = {
  status: Status;
  file: File;
  startDate: string;
  expirationDate: string | null;
  companyName: string;
};

export type TDocumentFormData = {
  file: File;
  startDate: string;
  expirationDate: string;
  status: Status;
  companyName: string;
  partnership: string;
};
