import { Status } from './documents';

export type TPenaltyListResponse = TPenaltyResponse[];

export enum ContractStatus {
  signed = 'signed',
  pending = 'pending',
}

type TPenaltyResponse = {
  created_at: string;
  created_by: number;
  description: string;
  expiration_date: string;
  id: number;
  invoice_number: string;
  is_active: boolean;
  is_editable: boolean;
  template: {
    amount: number;
    id: number;
    is_base: boolean;
    text: string;
  };
};

export type TPenaltyList = TPenalty[];

export type TPenalty = {
  id: number;
  invoice: string;
  penalty: string;
  description: string;
  status: boolean;
  expirationDate: string;
};

export type TDocumentResponse = {
  id: number;
  status: Status;
  file: {
    name: string;
  };
  start_date: string;
  expiration_date: string | null;
  company_name: string;
  company: number;
  partnership: number;
  uploaded_by: number;
  type: {
    id: number;
    type: string;
  };
};

export type TDocument = {
  id?: number;
  typeId: number;
  status: Status;
  fileName: string;
  startDate: string;
  expirationDate: string | null;
  type?: string;
  companyName: string;
  company: number;
  partnership: number;
  uploadedBy: number;
};

export type TDocumentData = {
  file: File;
  startDate: string;
  expirationDate: string | null;
  status: Status;
  companyName: string;
  partnership: number;
  uploaded_by: number;
  type_id: number;
};

export type TContractResponse = {
  id: number;
  file: {
    name: string;
  } | null;
  certificate: {
    name: string;
  } | null;
  sign_url: string;
  status: string;
  created_at: string;
  signed_on: string;
};
export type TContract = {
  id: number;
  status: ContractStatus;
  signUrl: string;
  fileName: string;
  name: string;
  startDate: string;
  createdAt: string;
  type?: string;
};

export type TClaimMessage = {
  claimId: number;
  createdBy: number;
  createdByName: string;
  createdAt: string;
  text: string;
};

export type TClaimMessages = TClaimMessage[];

export type TClaim = {
  commissionId: number;
  id: number;
  invoiceNumber: string;
  isActive: boolean;
  createdAt: string;
  messages: TClaimMessages;
};

export type TClaimsList = TClaim[];

export type TClaimResponse = {
  commission_id: number;
  created_at: string;
  id: number;
  invoice_number: string;
  is_active: boolean;
  messages: TMessageResponse[];
};

export type TClaimsResponse = TClaimResponse[];

export type TMessageResponse = {
  claim_id: number;
  created_at: string;
  created_by: number;
  created_by_name: string;
  text: string;
};

export type TWeek = {
  id: number;
  start: string;
  end: string;
};
export type TWeeks = TWeek[];

export type TGraph = {
  dataset: TDataset[];
  isCluster: boolean;
  nickname: string;
};

export type TDataset = {
  amount: string;
  change: string;
  week: { start: string; end: string };
};

export type TContacts = Record<string, TContact[]>;

export type TContact = {
  email: string | null;
  full_name: string;
  phone: string | null;
};

export type TKpiResponse = {
  lagging: {
    is_cluster: boolean;
    nickname: string;
    dataset: [];
  };
  leading: {
    is_cluster: boolean;
    nickname: string;
    dataset: [];
  };
  dates: string[];
  percent_info: {
    current_place: string;
    cluster_place: string;
  };
  self: {
    is_cluster: boolean;
    nickname: string;
    dataset: [];
  };
};

export type TKpi = {
  self: TGraph;
  lagging: TGraph;
  leading: TGraph;
  dates: string[];
  currentPlace: string;
  clusterPlace: string;
};

export type TApplianceType = {
  id: number;
  title: TApplianceTypeOptions;
};

export enum TApplianceTypeOptions {
  Commercial = 'Commercial',
  Regular = 'Regular',
  Warranty = 'Warranty',
  'High-End' = 'High-End',
}

//TODO: перевести на enum
export type TAppliances = {
  id: number;
  title: string;
};

export type TWorkType = {
  id: number;
};

export type TpartnershipStatusResponse = {
  status: PartnershipStatus;
};

export type TPartnershipStatus = PartnershipStatus;

export enum PartnershipStatus {
  unknown = 'Unknown',
  pendingByExpert = 'Pending by expert',
  pendingByAgency = 'Pending by agency',
  rejectedByExpert = 'Rejected by expert',
  rejectedByAgency = 'Rejected by agency',
  handshaking = 'Handshaking',
  active = 'Active',
  suspended = 'Suspended',
  deactivatedByExpert = 'Deactivated by expert',
  deactivatedByAgency = 'Deactivated by agency',
}
