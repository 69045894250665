import { TServerError } from '@core/utils/error';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = TServerError | null;

type ErrorState = {
  error: TError;
};

const initialState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
  },
});

export const errorActions = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
