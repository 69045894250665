import { FILE_ENDPOINTS, host, telegramChatId, telegramToken } from '@core/constants';
import { RootState } from '../store/store';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const baseQuery: FetchBaseQueryArgs = {
  baseUrl: host,
  credentials: 'include',
  prepareHeaders: (headers: Headers, { getState, endpoint }) => {
    const token = (getState() as RootState).authReducer.token;

    if (!FILE_ENDPOINTS.includes(endpoint)) {
      headers.set('Content-Type', 'application/json');
    }

    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }
    return headers;
  },
};

export const customFetchBaseQuery =
  (baseQueryOptions: FetchBaseQueryArgs) =>
  async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: Record<string | number | symbol, unknown>,
  ) => {
    const result = await fetchBaseQuery(baseQueryOptions)(args, api, extraOptions);
    if (result.error && result.error.status !== 422) {
      const error = result.error;

      let errorText = '';

      if (
        typeof error.data === 'object' &&
        error.data !== null &&
        'detail' in error.data &&
        typeof error.data.detail === 'string'
      ) {
        errorText = error.data.detail;
      } else if (typeof error.data === 'object' && error.data !== null) {
        errorText = JSON.stringify(error.data);
      } else {
        errorText = 'unknown error';
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: telegramChatId,
          text: `\`\`\`javascript Service Frontend "${process.env.NODE_ENV}"\n
status: ${result.error.status}
message: ${errorText} 
page: ${window.location.href}
requested url: ${result.meta?.request?.url || 'unknown'}\`\`\``,
          parse_mode: 'Markdown',
        }),
      };
      await fetch(`https://api.telegram.org/bot${telegramToken}/sendMessage`, requestOptions);
      return result;
    }

    return result;
  };
