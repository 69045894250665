// extracted by mini-css-extract-plugin
var _1 = "Slider-module__container__KsdTN";
var _2 = "Slider-module__nextButton__WkzyO";
var _3 = "Slider-module__prevButton__Gk8yz";
var _4 = "Slider-module__slide__MCeNN";
var _5 = "Slider-module__sliderContainer__WcMWy";
var _6 = "Slider-module__sliderWrapper__szYqU";
export { _1 as "container", _2 as "nextButton", _3 as "prevButton", _4 as "slide", _5 as "sliderContainer", _6 as "sliderWrapper" }

    if(module.hot) {
      // 1733466108946
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  