// extracted by mini-css-extract-plugin
var _1 = "ReviewsAgency-module__nextButton__nP0PL";
var _2 = "ReviewsAgency-module__prevButton__RuG9Q";
var _3 = "ReviewsAgency-module__wrapper__PCOlY";
export { _1 as "nextButton", _2 as "prevButton", _3 as "wrapper" }

    if(module.hot) {
      // 1733466108905
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  