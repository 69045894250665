import React, { useCallback } from 'react';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { useActions } from '@core/hooks/actions';
import { TScheduleTimeWithIndex } from '@core/redux/models/schedule';

type DeleteRangeModalProps = {
  setIsShown: (val: boolean) => void;
  item: TScheduleTimeWithIndex | null;
};

export default function DeleteRangeModal(props: DeleteRangeModalProps) {
  const { setIsShown, item } = props;
  const { deleteNewTimeRange, setFlagIsDeletedForRange, deleteFlagIsDeletedForRange } =
    useActions();

  const handleDelete = useCallback(() => {
    if (!item) {
      return;
    }

    if (item.createdAt && !item.isDeleted) {
      setFlagIsDeletedForRange(item.index);
    } else if (item.createdAt && item.isDeleted) {
      deleteFlagIsDeletedForRange(item.index);
    } else {
      deleteNewTimeRange(item.index);
    }
    setIsShown(false);
  }, [item]);

  return (
    <>
      {item?.createdAt ? (
        <>
          {item.isDeleted ? (
            <ConfirmModalPage
              setIsShown={setIsShown}
              header='Cancel the deletion?'
              text='Are you sure you want to cancel the deletion of your work schedule?'
              handleConfirm={handleDelete}
            />
          ) : (
            <ConfirmModalPage
              setIsShown={setIsShown}
              header='Delete work schedule?'
              text='Are you sure you want to delete your work schedule? Changes will take effect in 7 days.'
              hint='You can undo this deletion within 7 days.'
              handleConfirm={handleDelete}
            />
          )}
        </>
      ) : (
        <ConfirmModalPage
          setIsShown={setIsShown}
          header='Delete work schedule?'
          text='Are you sure you want to delete your work schedule?'
          handleConfirm={handleDelete}
        />
      )}
    </>
  );
}
