import React from 'react';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import Schedule from './components/Schedule/Schedule';
import ServiceAreas from './components/ServiceAreas/ServiceAreas';
import JobTypes from './components/JobTypes/JobTypes';
import Documents from './components/Documents/Documents';
import Account from './components/Account/Account';
import MailingAddress from './components/MailingAddress/MailingAddress';
import BankDetails from './components/BankDetails/BankDetails';

export default function Profile() {
  return (
    <>
      <GeneralInfo />
      <MailingAddress />
      <BankDetails />
      <Schedule />
      <ServiceAreas />
      <JobTypes />
      <Documents />
      <Account />
    </>
  );
}
