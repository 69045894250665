import React, { ReactNode, useCallback, useState } from 'react';
import * as styles from './JobTypes.module.scss';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import { Skeleton } from '@mui/material';
import { profileAPI } from '@core/redux/services/profileService';
import { useActions } from '@core/hooks/actions';
import Picker from '../../../../features/Picker/Picker';
import MainButton from '../MainButton/MainButton';
import { useAppSelector } from '@core/hooks/redux';
import JobsChild from './components/JobsChild/JobsChild';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { TWorkTypeWithAppliances } from '@core/redux/models/jobTypes';
import TimeErrorModal from './components/TimesErrorModal/TimeErrorModal';
import { DiscardText } from '@core/utils/utils';
import { TServerError, parseServerError } from '@core/utils/error';
import { ModuleAction } from '@core/redux/models/profile';
import { useCurrentUser } from '@core/hooks/currentUser';
import ErrorModal from '@components/ErrorModal/ErrorModal';

export default function JobTypes() {
  const { technicianId } = useCurrentUser();
  const {
    jobTypesAction,
    selectedWorkTypeIndex,
    isTimeError,
    initialStateIsCheck,
    techniciansWorkTypesData,
    isCheck,
  } = useAppSelector((state) => state.jobTypesReducer);
  const { needDisabled } = useAppSelector((state) => state.profileReducer);
  const { setJobTypesAction, setWorkTypeIndex, setIsCheck, setIsTimeError } = useActions();
  const [isTimersError, setIsTimersError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const {
    data: workTypesV2,
    isFetching,
    isLoading: isWorkTypesLoading,
    refetch,
  } = profileAPI.useGetWorkTypesV2Query();

  const [
    handleUpdateTechniciansWorkTypes,
    {
      data: updateTechniciansWorkTypes,
      isLoading: isLoadingUpdateTechniciansWorkTypes,
      isError: isUpdateTechniciansWorkTypesError,
      error: updateTechniciansWorkTypesError,
    },
  ] = profileAPI.useUpdateTechniciansWorkTypesV2Mutation();

  const handleSendJobTypes = useCallback(async () => {
    const allTechniciansWorkTypes = [];
    for (const value of Object.values(techniciansWorkTypesData)) {
      allTechniciansWorkTypes.push(...value.items);
    }
    if (allTechniciansWorkTypes.some((workType): boolean => workType.dad === 0)) {
      setIsTimersError(true);
      setIsTimeError(true);
      return;
    }

    for (const [key, list] of Object.entries(techniciansWorkTypesData)) {
      try {
        const response = await handleUpdateTechniciansWorkTypes({
          technician_id: technicianId,
          type_id: Number(key),
          workTypeList: list.items.map((item) => {
            return {
              work_type_id: item.workType.id,
              dad: item.dad,
              is_deleting: !isCheck,
              is_active: isCheck,
            };
          }),
        });
        if (response && 'error' in response) setIsModalOpen(true);
      } catch (e) {
        setIsModalOpen(true);
      }
    }
    setIsTimeError(false);
  }, [updateTechniciansWorkTypes, techniciansWorkTypesData, selectedWorkTypeIndex]);

  const handleRefetch = useCallback(() => {
    setIsConfirmModal(true);
  }, []);

  const handleConfirm = useCallback(() => {
    setIsConfirmModal(false);
    setIsCheck({ value: initialStateIsCheck, isHandle: false });
    refetch();
  }, []);

  const listChildren = workTypesV2
    ? workTypesV2.map(
        (type: TWorkTypeWithAppliances): ReactNode => <JobsChild key={type.id} workType={type} />,
      )
    : null;

  const isSendError = isTimersError || isUpdateTechniciansWorkTypesError;

  return (
    <section className={styles.wrapper}>
      <TitleWithButton
        title={'Job types:'}
        disabled={needDisabled && jobTypesAction === ModuleAction.send}
      >
        {isWorkTypesLoading ? (
          <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
        ) : (
          <MainButton
            refreshDataFunction={handleRefetch}
            resetIsLoading={isFetching}
            isLoading={isLoadingUpdateTechniciansWorkTypes}
            sendDataFunction={handleSendJobTypes}
            setAction={setJobTypesAction}
            action={jobTypesAction}
            isSendError={isSendError}
          />
        )}
      </TitleWithButton>
      {isWorkTypesLoading || !listChildren?.length ? (
        <Skeleton height={250} width='100%' variant='rectangular' className={styles.skeletonForm} />
      ) : (
        <Picker
          listChildren={listChildren}
          action={jobTypesAction}
          position={selectedWorkTypeIndex}
          setPosition={setWorkTypeIndex}
        />
      )}
      {isTimeError && <TimeErrorModal />}
      {isConfirmModal && workTypesV2 && (
        <ConfirmModalPage
          header={'Discard editing job types?'}
          headerClassName={styles.modalHeader}
          text={DiscardText(workTypesV2[selectedWorkTypeIndex].title)}
          textClassName={styles.modalText}
          handleConfirm={handleConfirm}
          setIsShown={() => setIsConfirmModal(false)}
          confirmButtonText={'Discard'}
          cancelButtonText={'Cancel'}
        />
      )}
      {isModalOpen && isUpdateTechniciansWorkTypesError && (
        <ErrorModal
          setIsShown={setIsModalOpen}
          errorMessage={parseServerError(updateTechniciansWorkTypesError as TServerError)}
        />
      )}
    </section>
  );
}
