import { MONTH_NAMES } from '@core/constants';
import { TClaimsList } from '@core/redux/models/payouts';

export const getChats = (list: TClaimsList): { active: TClaimsList; close: TClaimsList } => {
  const active: TClaimsList = [];
  const close: TClaimsList = [];

  for (const chat of list) {
    if (chat.isActive) {
      active.push(chat);
    } else {
      close.push(chat);
    }
  }

  return { active, close };
};

export const getDoubleDigitTime = (value: number): string | number => {
  return value < 10 ? '0' + value : value;
};

export const getTime = (time: string): string => {
  const today = new Date();
  const timeDate = new Date(time);
  const currentTime = `${getDoubleDigitTime(timeDate.getHours())}:${getDoubleDigitTime(timeDate.getMinutes())}`;
  const todayWithOut = today.setHours(0, 0, 0, 0);
  const timeDateWithOut = timeDate.setHours(0, 0, 0, 0);

  if (todayWithOut === timeDateWithOut) {
    return currentTime;
  } else {
    return `${timeDate.getDate()} ${MONTH_NAMES[timeDate.getMonth()]} ${currentTime}`;
  }
};
