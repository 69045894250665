import React, { ReactNode, useCallback, useMemo, useRef, useState, ChangeEvent } from 'react';
import cn from 'classnames';
import PaperClip from '@assets/images/paper-clip.svg';
import * as styles from './FileInput.module.scss';

type TFileInputProps = {
  top?: ReactNode;

  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function FileUploader(props: TFileInputProps) {
  const { top, onChange, ...commonProps } = props;
  const [fileName, setFileName] = useState<string>('');
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  useMemo(() => {
    const files = hiddenFileInput.current?.files;
    if (files && files?.length > 0) {
      setFileName(files[0].name);
    }
  }, [hiddenFileInput.current?.files]);

  const handleClick = useCallback(() => {
    hiddenFileInput.current?.click();
  }, []);

  return (
    <>
      <div className={cn(styles.main, { [styles.hasTop]: !!top })}>
        {!!top && <span className={styles.top}>{top}</span>}
        <div className={styles.upload}>
          <input
            className={styles.uploadButton}
            value='Upload File'
            readOnly
            onClick={handleClick}
          />
          <PaperClip className={styles.icon} fill='var(--light-background-primary)' />
        </div>
        <label className={styles.label}>{fileName ? fileName : 'No file selected'}</label>
        <input
          {...commonProps}
          onChange={onChange}
          type='file'
          ref={hiddenFileInput}
          style={{ display: 'none' }}
        />
      </div>
      <div className={styles.text}>{'images, .pdf, .doc, .docx < 10mb'}</div>
    </>
  );
}
