// extracted by mini-css-extract-plugin
var _1 = "ContactsModal-module__block__QYnxn";
var _2 = "ContactsModal-module__button__gEodc";
var _3 = "ContactsModal-module__contactBlock__Ol0UN";
var _4 = "ContactsModal-module__email__k7k7o";
var _5 = "ContactsModal-module__header__obaER";
var _6 = "ContactsModal-module__item__osSIj";
var _7 = "ContactsModal-module__modal__c7dwk";
var _8 = "ContactsModal-module__separator__tlcl4";
var _9 = "ContactsModal-module__text__RXObr";
var _a = "ContactsModal-module__title__QBEkZ";
var _b = "ContactsModal-module__wrap__vFG2d";
export { _1 as "block", _2 as "button", _3 as "contactBlock", _4 as "email", _5 as "header", _6 as "item", _7 as "modal", _8 as "separator", _9 as "text", _a as "title", _b as "wrap" }

    if(module.hot) {
      // 1733466107907
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  