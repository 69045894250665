import React from 'react';
import cn from 'classnames';
import SpinnerImg from '@assets/images/spinner.svg';
import * as styles from './Spinner.module.scss';

type SpinnerProps = {
  size?: 'small' | 'medium' | 'large';
  className?: string;
  color?: string;
};

export default function Spinner(props: SpinnerProps) {
  const { size = 'small', color, className } = props;
  return (
    <div className={cn(styles.wrapper, styles[size], className)}>
      <SpinnerImg fill={color} className={cn(styles.spinner, styles[size])} />
    </div>
  );
}
