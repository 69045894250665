import React, { useState, useCallback } from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import * as styles from './DeactivateModal.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import Input from '@components/Input/Input';
import { InputType } from '@components/Input/types';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { PartnershipStatus } from '@core/redux/models/partnership';
import { useCurrentUser } from '@core/hooks/currentUser';

type DeactivateModalProps = {
  setIsShown: (val: boolean) => void;
  partnership: TPartnershipElement;
};

export default function DeactivateModal(props: DeactivateModalProps) {
  const { technicianId } = useCurrentUser();
  const { setIsShown, partnership } = props;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const [handleDeactivate, { isLoading: updateLoading }] = partnershipAPI.useDeactivatedMutation();

  const handleUpdate = useCallback(async () => {
    if (value.toLocaleLowerCase().trim() !== 'SUSPEND'.toLocaleLowerCase()) {
      setError('You need to enter SUSPEND.');
      return;
    }
    const result = await handleDeactivate({
      partnership_id: partnership.id,
      status: PartnershipStatus.deactivatedByExpert,
      technician_id: technicianId,
    });
    if ('data' in result) {
      setIsShown(false);
      return;
    }
    throw new Error('Something went wrong!');
  }, [value]);

  return (
    <ModalPage
      header={<div className={styles.title}>Suspend partnership?</div>}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
      closeable={false}
    >
      <div className={styles.text}>
        {'To suspend your partnership, enter "SUSPEND" in the designated field.'}
      </div>
      <Input
        name='value'
        type={InputType.text}
        className={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder='Enter "SUSPEND"'
      />
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.lightGrey}
          mode={ButtonMode.standard}
          onClick={handleUpdate}
          loading={updateLoading}
        >
          Confirm
        </Button>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.darkBlue}
          mode={ButtonMode.standard}
          onClick={() => setIsShown(false)}
        >
          Cancel
        </Button>
      </div>
    </ModalPage>
  );
}
