// extracted by mini-css-extract-plugin
var _1 = "HistoryModal-module__block__LRvrv";
var _2 = "HistoryModal-module__header__zDRte";
var _3 = "HistoryModal-module__modal__nG_5M";
var _4 = "HistoryModal-module__wrap__OdUfk";
export { _1 as "block", _2 as "header", _3 as "modal", _4 as "wrap" }

    if(module.hot) {
      // 1733466106763
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  