import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as styles from './Map.module.scss';
import { useAppSelector } from '@core/hooks/redux';
import {
  addStyleForPopUp,
  createCoordinate,
  createGoogleUidLists,
  createListActiveZips,
  removePopup,
  useValidateType,
} from './utils';
import cn from 'classnames';
import { useActions } from '@core/hooks/actions';
import {
  defaultOptions,
  styleActive,
  styleChange,
  styleDefault,
  styleDelete,
  styleNewActive,
  styleNewDelete,
} from './mapOptions';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import { TGoogleUidLists, ValidateType } from './types';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { createZip } from '../../utils';
import {
  TCenterCoordinate,
  TNewWorkArea,
  TWorkArea,
  WorkAreaStatus,
} from '@core/redux/models/serviceAreas';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { useModuleActionType, useWorkAreaStatusType } from '@core/utils/utils';
import Button from '@components/Button/Button';
import { ButtonSize, ButtonBgColor, ButtonMode } from '@components/Button/types';
import Search from '@assets/images/search.svg';
import Spinner from '@components/Spinner/Spinner';
import { ONLY_NUMBERS } from '@core/constants';

const TEXT_FOR_WORK_AREA =
  "Use the map to add or remove area!<br>Tap the zone to make changes.<br>If you can see the area you want to pick, don't search, just tap it.";
const ERROR_MESSAGE =
  'The entered ZIP code is invalid. Such code does not exist. Please try again.';

export default function Map() {
  const {
    changeWorkAreaStatus,
    changeStatusesForWorkAreas,
    addNewWorkArea,
    removeNewWorkArea,
    addNewWorkAreaAndChangeStatusesWorkAreas,
    setInfoArea,
    setMapPosition,
    setLoadingArea,
    setFullScreen,
    setZoom,
  } = useActions();
  const { serviceAreasAction, mapWorkAreas, center, zoom, infoArea, isFullScreen } = useAppSelector(
    (state) => state.serviceAreasReducer,
  );
  const { technicianId } = useCurrentUser();

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const map = useRef<google.maps.Map>();
  const featureLayerRef = useRef<google.maps.FeatureLayer>();
  const [validateType, setValidateType] = useState<ValidateType>();
  const [isModal, setIsModal] = useState<boolean>(false);
  const infoWindow = new google.maps.InfoWindow({ ariaLabel: 'popup-on-map' });
  const [postalCode, setPostalCode] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const [
    handleGetNewWorkArea,
    { data: googleWorkArea, isLoading: isLoadingNewWorkArea, isError: isErrorNewWorkArea },
  ] = profileAPI.useGetNewWorkAreaMutation();
  const [handleValidateWorkArea, { data: dataValidate, isLoading, isError: isErrorValidate }] =
    profileAPI.useValidateWorkAreaMutation();

  const { isEdit, isChange, isSend } = useModuleActionType(serviceAreasAction);

  const buttonIsDisable = isLoadingNewWorkArea || postalCode.length < 5;

  const MESSAGE_ABOUT_CONFLICT_WORK_AREAS = `The zone you are attempting to add conflicts with an already active ${
    dataValidate?.amount === 1 ? 'zone' : 'zones'
  }. Adding this zone will result in the removal of ${
    dataValidate?.amount === 1 ? 'zone' : 'zones'
  }:`;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (ONLY_NUMBERS.test(newValue) || newValue === '') {
        setPostalCode(newValue);
      }
    },
    [postalCode],
  );

  const handlePlaceClick = useCallback(async (event: google.maps.FeatureMouseEvent) => {
    const feature = event.features[0];

    if (
      'placeId' in feature &&
      typeof feature.placeId === 'string' &&
      'fetchPlace' in feature &&
      typeof feature.fetchPlace === 'function'
    ) {
      try {
        setLoadingArea(true);
        const place = await feature.fetchPlace();
        if (place.displayName) {
          setInfoArea(null);
          handleGetNewWorkArea({ code: place.displayName });
        }
      } catch (e) {
        setLoadingArea(false);
        console.warn(e);
      }
    }
  }, []);

  const handleNewActiveArea = useCallback(() => {
    if (googleWorkArea) {
      const checkWorkArea = mapWorkAreas.find(
        (workArea: TNewWorkArea | TWorkArea): boolean =>
          workArea.googleUid === googleWorkArea.googleUid,
      );

      if (checkWorkArea?.isDeleted) {
        changeWorkAreaStatus({ workArea: googleWorkArea, status: WorkAreaStatus.delete });
      } else {
        removeNewWorkArea(googleWorkArea);
      }
    }
  }, [mapWorkAreas, googleWorkArea]);

  const handleConfirm = useCallback(() => {
    if (validateType && dataValidate && googleWorkArea) {
      const { isDelete, isNewActive, isNewDelete } = useValidateType(validateType);

      switch (true) {
        case isDelete:
          changeStatusesForWorkAreas({
            newWorkArea: googleWorkArea,
            googleUidList: dataValidate.googleUidList,
            status: WorkAreaStatus.newActive,
          });
          break;
        case isNewActive:
          addNewWorkAreaAndChangeStatusesWorkAreas({
            newWorkArea: googleWorkArea,
            googleUidList: dataValidate.googleUidList,
            status: WorkAreaStatus.newActive,
          });
          break;
        case isNewDelete:
          changeStatusesForWorkAreas({
            newWorkArea: googleWorkArea,
            googleUidList: dataValidate.googleUidList,
            status: WorkAreaStatus.active,
          });
          break;
      }
    }

    setIsModal(false);
  }, [validateType, dataValidate]);

  const handleCancel = useCallback(() => {
    setIsModal(false);
  }, []);

  const handelSearch = useCallback(() => {
    if (postalCode.length < 5) return;
    const checkWorkArea = mapWorkAreas.find(
      (workArea: TNewWorkArea | TWorkArea): boolean => workArea.code === postalCode,
    );

    if (checkWorkArea) {
      const coordinate: TCenterCoordinate = createCoordinate(checkWorkArea);

      setMapPosition(coordinate);
      setZoom(11);
      setInfoArea(
        `${checkWorkArea.cityName}, ${checkWorkArea.state} ${checkWorkArea.code}<br>${TEXT_FOR_WORK_AREA}`,
      );
      setFullScreen(true);
    } else {
      setIsSearch(true);
      handleGetNewWorkArea({ code: postalCode });
    }
  }, [postalCode]);

  const applyStyleToSelected = useCallback(() => {
    if (!featureLayerRef.current) return;

    const {
      listActiveGoogleUid,
      listDeleteGoogleUid,
      listNewActiveGoogleUid,
      listNewDeleteGoogleUid,
    }: TGoogleUidLists = createGoogleUidLists(mapWorkAreas);

    featureLayerRef.current.style = (options) => {
      if ('placeId' in options.feature && typeof options.feature.placeId === 'string') {
        switch (true) {
          case listActiveGoogleUid.includes(options.feature.placeId):
            return styleActive;
          case listNewActiveGoogleUid.includes(options.feature.placeId):
            return styleNewActive;
          case listDeleteGoogleUid.includes(options.feature.placeId):
            return styleDelete;
          case listNewDeleteGoogleUid.includes(options.feature.placeId):
            return styleNewDelete;
          case isEdit || isChange:
            return styleChange;
          default:
            return styleDefault;
        }
      }
    };
    setLoadingArea(false);
  }, [serviceAreasAction, mapWorkAreas]);

  const handleNewGoogleWorkArea = useCallback(() => {
    switch (true) {
      case googleWorkArea && isSearch: {
        const coordinate: TCenterCoordinate = createCoordinate(googleWorkArea);

        setIsSearch(false);
        setMapPosition(coordinate);
        setZoom(11);
        setInfoArea(
          `${googleWorkArea.cityName}, ${googleWorkArea.state} ${googleWorkArea.code}<br>${TEXT_FOR_WORK_AREA}`,
        );
        setFullScreen(true);
        break;
      }
      case googleWorkArea && (isEdit || isChange): {
        const workArea = mapWorkAreas.find(
          (area: TNewWorkArea | TWorkArea): boolean => area.googleUid === googleWorkArea.googleUid,
        );
        const { isActive, isDelete, isNewActive, isNewDelete } = useWorkAreaStatusType(
          workArea?.status,
        );

        switch (true) {
          case isActive:
            changeWorkAreaStatus({ workArea: googleWorkArea, status: WorkAreaStatus.newDelete });
            break;
          case isDelete:
            setValidateType(ValidateType.delete);
            handleValidateWorkArea({
              active_zips: createListActiveZips(mapWorkAreas),
              new_zip: createZip(googleWorkArea),
              technician_id: technicianId,
            });
            break;
          case isNewActive:
            handleNewActiveArea();
            break;
          case isNewDelete:
            setValidateType(ValidateType.newDelete);
            handleValidateWorkArea({
              active_zips: createListActiveZips(mapWorkAreas),
              new_zip: createZip(googleWorkArea),
              technician_id: technicianId,
            });
            break;
          default:
            setValidateType(ValidateType.newActive);
            handleValidateWorkArea({
              active_zips: createListActiveZips(mapWorkAreas),
              new_zip: createZip(googleWorkArea),
              technician_id: technicianId,
            });
            break;
        }
        break;
      }
      case googleWorkArea && isSend: {
        const coordinate: TCenterCoordinate = createCoordinate(googleWorkArea);

        setMapPosition(coordinate);
        setZoom(11);
        setInfoArea(`${googleWorkArea.cityName}, ${googleWorkArea.state} ${googleWorkArea.code}`);
        setFullScreen(true);
        break;
      }
    }
  }, [googleWorkArea, isSearch, isEdit, isChange, isSend]);

  useEffect(() => {
    switch (true) {
      case !googleWorkArea && isSearch && !isLoadingNewWorkArea:
        setIsSearch(false);
        setIsModal(true);
        break;
      case googleWorkArea && !isLoadingNewWorkArea:
        handleNewGoogleWorkArea();
        break;
    }
  }, [googleWorkArea, isLoadingNewWorkArea]);

  useEffect(() => {
    const { isDelete, isNewActive, isNewDelete } = useValidateType(validateType);

    switch (true) {
      case dataValidate === null && isDelete && !!googleWorkArea:
        changeWorkAreaStatus({ workArea: googleWorkArea, status: WorkAreaStatus.newActive });
        break;
      case dataValidate === null && isNewActive && !!googleWorkArea:
        addNewWorkArea(googleWorkArea);
        break;
      case dataValidate === null && isNewDelete && !!googleWorkArea:
        changeWorkAreaStatus({ workArea: googleWorkArea, status: WorkAreaStatus.active });
        break;
      case !!dataValidate:
        setIsModal(true);
    }
  }, [dataValidate]);

  useEffect(() => {
    if (mapContainerRef.current !== null) {
      map.current = new google.maps.Map(mapContainerRef.current, {
        center,
        zoom,
        ...defaultOptions,
      });

      featureLayerRef.current = map.current.getFeatureLayer(google.maps.FeatureType.POSTAL_CODE);
    }
  }, [mapContainerRef]);

  useEffect(() => {
    if (map.current) {
      map.current.setCenter(center);
      map.current.setZoom(zoom);
    }
  }, [map.current, center, zoom]);

  useEffect(() => {
    if (featureLayerRef.current) {
      applyStyleToSelected();
    }
  }, [featureLayerRef.current, serviceAreasAction, mapWorkAreas, center, zoom]);

  useEffect(() => {
    if (infoArea !== null) {
      removePopup();
      infoWindow.setContent(infoArea);
      infoWindow.setPosition(center);
      infoWindow.open({
        map: map.current,
        shouldFocus: false,
      });
      setTimeout(() => addStyleForPopUp(), 50);
    }
  }, [infoArea]);

  useEffect(() => {
    if (featureLayerRef.current) {
      const clickListener = featureLayerRef.current.addListener(
        'click',
        async (event: google.maps.FeatureMouseEvent) => {
          event.stop();
          if (!isLoadingNewWorkArea && !isLoading) {
            await handlePlaceClick(event);
          }
        },
      );

      return () => {
        google.maps.event.removeListener(clickListener);
      };
    }
  }, [serviceAreasAction, featureLayerRef, handlePlaceClick, isLoadingNewWorkArea, isLoading]);

  return (
    <>
      <div
        ref={mapContainerRef}
        className={cn(
          styles.map,
          (isEdit || isChange) && styles.mapActive,
          isFullScreen && styles.mapFullScreen,
        )}
      ></div>
      {(isEdit || isChange) && (
        <div className={styles.wrapperInput}>
          <input
            type='text'
            value={postalCode}
            className={styles.input}
            onChange={handleChange}
            maxLength={5}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? 'XXXXX' : 'Search by zip code'}
          />
          <Button
            className={styles.button}
            size={ButtonSize.m}
            bgColor={ButtonBgColor.white}
            mode={ButtonMode.icon}
            onClick={handelSearch}
            disabled={buttonIsDisable}
          >
            {isLoadingNewWorkArea ? (
              <Spinner color='var(--light_accent_main)' />
            ) : (
              <Search fill='var(--light_accent_main)' />
            )}
          </Button>
        </div>
      )}
      {isModal && !!dataValidate && googleWorkArea && (
        <ConfirmModalPage
          setIsShown={handleCancel}
          handleConfirm={handleConfirm}
          header={`Add service area ${googleWorkArea.code}?`}
          headerClassName={styles.modalHeader}
          text={MESSAGE_ABOUT_CONFLICT_WORK_AREAS}
          textClassName={styles.modalText}
        >
          <ul className={styles.list}>
            {Object.keys(dataValidate.postalCodes).map(
              (city: string, index: number): React.JSX.Element => (
                <li key={city} className={styles.itemList}>
                  - {dataValidate.postalCodes[city].join(', ')} ({city})
                  {dataValidate.amountCities === index ? '.' : ';'}
                </li>
              ),
            )}
          </ul>
        </ConfirmModalPage>
      )}
      {isModal && isErrorNewWorkArea && (
        <ErrorModal
          setIsShown={setIsModal}
          errorMessage={ERROR_MESSAGE}
          title={'Invalid ZIP Code'}
        />
      )}
      {isModal && isErrorValidate && (
        <ErrorModal setIsShown={setIsModal} errorMessage={'Try again'} />
      )}
    </>
  );
}
