import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import Button from '@components/Button/Button';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { Status, TDocumentType } from '@core/redux/models/documents';
import DataPicker from '@components/DatePicker/DatePicker';
import { DATE_PLACEHOLDER, ONLY_LATIN_CHAR_PATTERN, FORM_TYPE } from '@core/constants';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import FileUploader from '@components/FileInput/FileInput';
import * as styles from './NewDocumentModal.module.scss';
import Input from '@components/Input/Input';
import { InputMode, InputType } from '@components/Input/types';
import { getDocumentTitle } from '@pages/Profile/components/Documents/utils';
import {
  getPatterError,
  parseHookFormMessage,
  parseServerError,
  THookErrors,
  TServerError,
} from '@core/utils/error';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TDocumentFormData } from '../../types';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { TDocument } from '@core/redux/models/partnership';
import { useCurrentUser } from '@core/hooks/currentUser';

type NewDocumentModalProps = {
  setIsShown: (val: boolean) => void;
  type: TDocumentType | undefined;
  partnership: TPartnershipElement;
};

export default function NewDocumentModal(props: NewDocumentModalProps) {
  const { setIsShown, type, partnership } = props;
  const { technicianId } = useCurrentUser();
  const { partnershipDocuments } = useAppSelector((state) => state.partnershipReducer);
  const { setPartnershipDocuments } = useActions();

  if (!type?.type) {
    throw new Error('Document type undefined!');
  }

  const currentType = FORM_TYPE[type?.type];
  const [minDate, setMinDate] = useState<string | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<TDocumentFormData>({
    defaultValues: {
      file: undefined,
      startDate: '',
      expirationDate: '',
      status: Status.pending,
      companyName: '',
    },
  });

  const [handleAddDocumentId, { isLoading, error: serverError }] =
    partnershipAPI.useAddDocumentInsuranceMutation();

  const onSubmit: SubmitHandler<TDocumentFormData> = async (formData) => {
    try {
      const result = await handleAddDocumentId({
        ...formData,
        status: Status.pending,
        partnership: partnership.id,
        uploaded_by: technicianId,
        type_id: type.id,
      });

      if ('data' in result) {
        setPartnershipDocuments({
          type: currentType,
          documents: [...partnershipDocuments[currentType], result.data as TDocument],
        });
        setIsShown(false);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const hasFormErrors = !!Object.keys(formErrors).length;

  return (
    <ModalPage
      header={
        <>
          <div className={styles.title}>{getDocumentTitle(type?.type)}:</div>
          <span className={styles.separator}></span>
        </>
      }
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.picker}>
          <Controller
            name='startDate'
            control={control}
            rules={{ required: 'Start date is required' }}
            render={({ field: { ref, ...field } }) => (
              <div className={styles.picker}>
                <DataPicker
                  placeholder={DATE_PLACEHOLDER}
                  top={'Document start date'}
                  {...field}
                  value={field.value}
                  maxdate={maxDate ? new Date(maxDate) : undefined}
                  setDate={setMinDate}
                />
              </div>
            )}
          />

          <Controller
            name='expirationDate'
            control={control}
            rules={{ required: 'Expiration date is required' }}
            render={({ field: { ref, ...field } }) => (
              <DataPicker
                placeholder={DATE_PLACEHOLDER}
                top={'Document expiration date'}
                {...field}
                value={field.value}
                mindate={minDate ? new Date(minDate) : undefined}
                setDate={setMaxDate}
              />
            )}
          />

          <span className={styles.separator}></span>
        </div>
        <div className={styles.separatorBlock}>
          <Controller
            name='file'
            control={control}
            rules={{ required: 'File is required' }}
            render={({ field: { value, onChange, ref, ...field } }) => (
              <FileUploader
                {...field}
                onChange={(event) => {
                  if (!event.target.files) return;
                  onChange(event.target.files[0]);
                }}
              />
            )}
          />
          <span className={styles.separator}></span>
        </div>

        <div className={styles.separatorBlock}>
          <Controller
            name='companyName'
            control={control}
            rules={{
              required: 'Company name is required',
              pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Business name'),
            }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Input
                {...field}
                className={styles.input}
                type={InputType.text}
                mode={InputMode.standard}
                placeholder='Business name'
                error={!!error}
                classNameError={styles.error}
              />
            )}
          />
        </div>
        <div className={styles.footer}>
          {hasFormErrors && (
            <div className={styles.errorMessage}>
              {parseHookFormMessage(formErrors as THookErrors)}
            </div>
          )}
          {!!serverError && (
            <div className={styles.errorMessage}>
              {parseServerError(serverError as TServerError)}
            </div>
          )}
          <Button
            className={styles.submit}
            size={ButtonSize.m}
            bgColor={ButtonBgColor.green}
            type='submit'
            loading={isLoading}
          >
            Send
          </Button>
        </div>
      </form>
    </ModalPage>
  );
}
