// extracted by mini-css-extract-plugin
var _1 = "CalculationModal-module__header__suzIi";
var _2 = "CalculationModal-module__modal__Vc3xY";
var _3 = "CalculationModal-module__wrap__dxfDu";
export { _1 as "header", _2 as "modal", _3 as "wrap" }

    if(module.hot) {
      // 1733466106766
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  