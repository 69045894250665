import React, { useCallback, useMemo, useState } from 'react';
import * as styles from './DrumSchedule.module.scss';
import cn from 'classnames';
import Drum from '@components/Drum/Drum';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import { showTime, timeError } from './utils';
import { TypeTime } from './types';

type DrumScheduleProps = {
  startTime: string;
  endTime: string;
  setValue: (arg: string) => void;
  setIsDrum: (val: boolean) => void;
  typeTime: TypeTime;
};

const IS_LOOPED = true;

export default function DrumSchedule(props: DrumScheduleProps) {
  const { startTime, endTime, setValue, setIsDrum, typeTime } = props;

  const [startHour, startMinute] = useMemo<string[]>(() => startTime.split(':'), [startTime]);
  const [endHour, endMinute] = useMemo<string[]>(() => endTime.split(':'), [endTime]);
  const [error, setError] = useState<string>('');
  const [hour, setHour] = useState<number>(
    typeTime === TypeTime.startTime ? Number(startHour) : Number(endHour),
  );
  const [minute, setMinute] = useState<number>(
    typeTime === TypeTime.startTime ? Number(startMinute) : Number(endMinute),
  );

  const adjustedHours = useMemo<number[]>(
    () => [...Array.from({ length: 24 }, (_, index) => index)],
    [],
  );

  const adjustedMinutes = useMemo<number[]>(
    () => [...Array.from({ length: 60 }, (_, index) => index)],
    [],
  );

  const handleSave = useCallback(() => {
    const newTime = hour * 100 + minute;
    const timeStart = Number(startHour) * 100 + Number(startMinute);
    const timeEnd = Number(endHour) * 100 + Number(endMinute);

    if (newTime < timeStart || newTime > timeEnd) {
      return setError(timeError(startTime, endTime));
    }

    setIsDrum(false);
    return setValue(`${showTime(Math.floor(hour))}:${showTime(Math.floor(minute))}`);
  }, [hour, minute, startHour, startMinute, endHour, endMinute, startTime, endTime]);

  return (
    <>
      <div className={styles.selector}>
        <Drum
          adjustedValues={adjustedHours}
          isLooped={IS_LOOPED}
          setSelectedValueIndex={setHour}
          selectedValueIndex={hour}
          indexTime={Number(startHour)}
          displayText={(value) => showTime(value)}
          classNameWrapper={styles.drumWrapper}
          classNameItem={styles.drumItem}
          classNameDrum={cn(styles.drum, styles.drumRight)}
        />
        <Drum
          adjustedValues={adjustedMinutes}
          isLooped={IS_LOOPED}
          setSelectedValueIndex={setMinute}
          selectedValueIndex={minute}
          indexTime={Number(startMinute)}
          displayText={(value) => showTime(value)}
          classNameWrapper={styles.drumWrapper}
          classNameItem={styles.drumItem}
          classNameDrum={cn(styles.drum, styles.drumLeft)}
        />
      </div>
      <div className={styles.buttonSaveWrapper}>
        <Button bgColor={ButtonBgColor.grey} className={styles.buttonSave} onClick={handleSave}>
          Save the time
        </Button>
      </div>
      {!!error && <div className={styles.error}>{error}</div>}
    </>
  );
}
