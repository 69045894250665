import React from 'react';
import { TDocument } from '@core/redux/models/documents';
import * as styles from '../DocumentsInfoModal.module.scss';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import { TContract } from '@core/redux/models/partnership';
import { isTContract, isTDocumentInsurance } from '../../../types';

type SubInformationProps = {
  document: TDocument | TContract;
};

export default function SubInformation(props: SubInformationProps) {
  const { document } = props;

  if (isTDocumentInsurance(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Document expiration date:</span>
              <span className={styles.value}>
                {document.expirationDate && getDdMonthYyyyFormatDate(document.expirationDate)}
              </span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Company name:</span>
              <span className={styles.value}>{document.companyName}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isTContract(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Certificate:</span>
              <span className={styles.value}>{document.name}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
