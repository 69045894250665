// extracted by mini-css-extract-plugin
var _1 = "ConfirmModalPage-module__button__Ke2nc";
var _2 = "ConfirmModalPage-module__buttonGroup__jMFd7";
var _3 = "ConfirmModalPage-module__hint__hctu3";
var _4 = "ConfirmModalPage-module__modal__IkQhT";
var _5 = "ConfirmModalPage-module__text__zvZ3j";
var _6 = "ConfirmModalPage-module__title__lUkDo";
export { _1 as "button", _2 as "buttonGroup", _3 as "hint", _4 as "modal", _5 as "text", _6 as "title" }

    if(module.hot) {
      // 1733466108978
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  