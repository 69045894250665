import React, { useEffect, useMemo } from 'react';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import Table from '@components/Table/Table';
import { columns } from './columns';
import { Skeleton } from '@mui/material';
import * as styles from './PaymentTable.module.scss';
import { Mode, TPartnershipElement, TPayoutsParams, TWeek } from '@core/redux/models/payouts';
import { getSearchParams } from '../Filters/utils';
import { TServerError } from '@core/utils/error';
import ClaimModal from '../Claims/components/ClaimModal/ClaimModal';
import HistoryModal from '../HistoryModal/HistoryModal';
import CalculationModal from '../CalculationModal/CalculationModal';

type TProps = {
  partnership: TPartnershipElement;
  currentWeek: TWeek;
};

export default function PaymentTable(props: TProps) {
  const { partnership, currentWeek } = props;
  const { mode, filters, isActiveSearch, claimModal, historyModal, calculationModal } =
    useAppSelector((state) => state.payoutsReducer);
  const { setError } = useActions();

  const payoutsParams = useMemo(() => {
    const params: TPayoutsParams = {
      partnership_id: partnership.id,
      week_id: currentWeek.id,
      start: currentWeek.start,
      end: currentWeek.end,
    };

    if (isActiveSearch) {
      return getSearchParams(filters, partnership.id);
    } else {
      return params;
    }
  }, [currentWeek, isActiveSearch]);

  const { data, isFetching, error: payoutsError } = payoutsAPI.useGetPayoutsQuery(payoutsParams);

  const {
    data: changesPayouts,
    isFetching: isFetchingChanges,
    error: changesError,
  } = payoutsAPI.useGetChangesAmountQuery({
    partnership_id: partnership.id,
    week_id: currentWeek.id,
  });

  useEffect(() => {
    if (payoutsError) {
      setError(payoutsError as TServerError);
    } else if (changesError) {
      setError(changesError as TServerError);
    }
  }, [payoutsError, changesError]);

  return (
    <>
      {isFetching || isFetchingChanges ? (
        <Skeleton height={320} width='100%' variant='rectangular' className={styles.skeleton} />
      ) : (
        <div className={styles.wrap}>
          {mode === Mode.total && data?.result && (
            <>
              {data.result.length ? (
                <Table data={data.result} columns={columns()} />
              ) : (
                <span className={styles.empty}>Nothing found!</span>
              )}
            </>
          )}
          {mode === Mode.changes && changesPayouts?.result && (
            <>
              {changesPayouts.result.length ? (
                <Table data={changesPayouts.result} columns={columns()} />
              ) : (
                <span className={styles.empty}>Nothing found!</span>
              )}
            </>
          )}
        </div>
      )}
      {claimModal && <ClaimModal />}
      {historyModal && <HistoryModal />}
      {calculationModal && <CalculationModal />}
    </>
  );
}
