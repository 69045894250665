import { ModuleAction, TAddressDataForm } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TMailingAddressState = {
  mailingAddressAction: ModuleAction;
  mailingAddressFormData: TAddressDataForm;
  hasMailingAddressFormError: boolean;
};

const initialState: TMailingAddressState = {
  mailingAddressAction: ModuleAction.send,
  mailingAddressFormData: {
    street: '',
    apartment: '',
    city: '',
    stateCode: '',
    postalCode: '',
  },
  hasMailingAddressFormError: false,
};

export const mailingAddressSlice = createSlice({
  name: 'mailingAddress',
  initialState,
  reducers: {
    setMailingAddressAction(state, action: PayloadAction<ModuleAction>) {
      state.mailingAddressAction = action.payload;
    },
    setMailingAddress(state, action: PayloadAction<TAddressDataForm>) {
      state.mailingAddressFormData = { ...action.payload };
    },
    setStreet(state, action: PayloadAction<string>) {
      state.mailingAddressFormData.street = action.payload;
      state.mailingAddressAction = ModuleAction.change;
    },
    setApartment(state, action: PayloadAction<string>) {
      state.mailingAddressFormData.apartment = action.payload;
      state.mailingAddressAction = ModuleAction.change;
    },
    setCity(state, action: PayloadAction<string>) {
      state.mailingAddressFormData.city = action.payload;
      state.mailingAddressAction = ModuleAction.change;
    },
    setStateCode(state, action: PayloadAction<string>) {
      state.mailingAddressFormData.stateCode = action.payload;
      state.mailingAddressAction = ModuleAction.change;
    },
    setPostalCode(state, action: PayloadAction<string>) {
      state.mailingAddressFormData.postalCode = action.payload;
      state.mailingAddressAction = ModuleAction.change;
    },
    setMailingAddressError(state, action: PayloadAction<Record<string, boolean>>) {
      state.hasMailingAddressFormError = Boolean(
        Object.values(action.payload).find((item) => item === true),
      );
    },
  },
});

export const mailingAddressActions = mailingAddressSlice.actions;
export const mailingAddressReducer = mailingAddressSlice.reducer;
