import React from 'react';
import { TDocument } from '@core/redux/models/documents';
import {
  isIdDocument,
  isProofCompanyRegistrationDocument,
  isSsnOrW9IndividualDocument,
  isW9BusinessDocument,
} from '@core/redux/services/types/documents';
import * as styles from '../DocumentsInfoModal.module.scss';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';

type SubInformationProps = {
  document: TDocument;
};

export default function SubInformation(props: SubInformationProps) {
  const { document } = props;

  if (isProofCompanyRegistrationDocument(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Owner first name:</span>
              <span className={styles.value}>{document.ownerFirstName}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Owner last name:</span>
              <span className={styles.value}>{document.ownerLastName}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Company name:</span>
              <span className={styles.value}>{document.companyName}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Your title in company:</span>
              <span className={styles.value}>{document.yourCompanyTitle}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Company address:</span>
              <span className={styles.value}>{document.companyAddress}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Company type:</span>
              <span className={styles.value}>{document.companyType}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isW9BusinessDocument(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>EIN:</span>
              <span className={styles.value}>{document.ein}</span>
            </div>
          </div>
          {document.businessName && (
            <div className={styles.block}>
              <div className={styles.item}>
                <span className={styles.label}>Business name:</span>
                <span className={styles.value}>{document.businessName}</span>
              </div>
            </div>
          )}
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isSsnOrW9IndividualDocument(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>SSN:</span>
              <span className={styles.value}>{document.ssn}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>First Name:</span>
              <span className={styles.value}>{document.firstName}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Last Name:</span>
              <span className={styles.value}>{document.lastName}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isIdDocument(document)) {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>First Name:</span>
              <span className={styles.value}>{document.firstName}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Last Name:</span>
              <span className={styles.value}>{document.lastName}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={styles.module}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.label}>Document start date:</span>
              <span className={styles.value}>{getDdMonthYyyyFormatDate(document.startDate)}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.label}>Document expiration date:</span>
              <span className={styles.value}>
                {document.expirationDate && getDdMonthYyyyFormatDate(document.expirationDate)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
