import React from 'react';
import GroupButton from '../GroupButtton/GroupButton';
import PaymentForm from '../PaymentForm/PaymentForm';
import Filters from '../Filters/Filters';
import * as styles from './Actions.module.scss';
import { TWeek, TPartnershipElement } from '@core/redux/models/payouts';

type TProps = {
  weeks: TWeek[] | undefined;
  partnership: TPartnershipElement;
  currentWeek: TWeek;
};

export default function Actions(props: TProps) {
  return (
    <div className={styles.wrap}>
      <span className={styles.separator}></span>
      <div className={styles.content}>
        <GroupButton />
        <PaymentForm {...props} />
        <Filters />
      </div>
      <span className={styles.separator}></span>
    </div>
  );
}
