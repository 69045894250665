import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModaPageState = {
  modalShonw: boolean;
};

const initialState: ModaPageState = {
  modalShonw: false,
};

export const modalPageSlice = createSlice({
  name: 'modalPage',
  initialState,
  reducers: {
    modalShownToggle(state, action: PayloadAction<boolean>) {
      state.modalShonw = action.payload;
    },
  },
});

export default modalPageSlice.reducer;
