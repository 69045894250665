import React, { useCallback, useState } from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import * as styles from './AddModal.module.scss';
import Input from '@components/Input/Input';
import { InputType } from '@components/Input/types';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import { useActions } from '@core/hooks/actions';

type TAddModalProps = {
  setModal: (val: boolean) => void;
  chats: string[];
};

export default function AddModal(props: TAddModalProps) {
  const { chats, setModal } = props;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { setClaimModal, setCustomInvoiceNumber } = useActions();

  const handleClick = useCallback(() => {
    if ((chats.length && chats.find((item) => item === value)) || !value.length) {
      setError('Claim with this invoice number exists!');
    } else {
      setCustomInvoiceNumber(value);
      setClaimModal(true);
      setModal(false);
    }
  }, [value, chats]);

  return (
    <ModalPage
      closeable={true}
      header='Create the claim'
      setIsShown={setModal}
      mode={ModalPageMode.modal}
      className={styles.modal}
      contentClassName={styles.content}
      headerClassName={styles.header}
    >
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={styles.input}
        name='invoice'
        type={InputType.text}
        placeholder='Enter the invoice number'
      />
      {error && <div className={styles.error}>{error}</div>}
      <Button
        size={ButtonSize.s}
        bgColor={ButtonBgColor.green}
        className={styles.button}
        onClick={handleClick}
      >
        Confirm
      </Button>
    </ModalPage>
  );
}
