import React, { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { Calendar } from 'react-calendar';
import CalendarIcon from '@assets/images/calendar.svg';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import Button from '@components/Button/Button';
import { ButtonMode, ButtonSize } from '@components/Button/types';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import * as styles from './DatePicker.module.scss';

type DatePickerProps = {
  className?: string;
  inputClassName?: string;
  blockClassName?: string;
  calendarClassName?: string;
  top?: ReactNode;
  value: string;
  placeholder: string;
  maxdate?: Date;
  mindate?: Date;
  setDate?: (arg: string) => void;
  onChange?: (e: unknown) => void;
  hasCalendarIcon?: boolean;
};

export default function DataPicker(props: DatePickerProps) {
  const {
    className,
    calendarClassName,
    blockClassName,
    inputClassName,
    top,
    placeholder,
    value,
    setDate,
    maxdate,
    mindate,
    hasCalendarIcon = true,
    ...commonProps
  } = props;
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  useEffect(() => {
    if (value && setDate) {
      setDate(value);
    }
  }, [value]);

  return (
    <>
      <div
        className={cn({ [styles.button]: top }, className)}
        onClick={() => setOpenCalendar(true)}
      >
        {top && <span className={styles.topLabel}>{top}</span>}
        <div className={cn(styles.block, blockClassName)}>
          <input
            {...commonProps}
            value={value ? getDdMonthYyyyFormatDate(value) : ''}
            className={cn(styles.input, inputClassName)}
            readOnly
            autoComplete='off'
            placeholder={placeholder}
          />
          {hasCalendarIcon && <CalendarIcon fill='var(--light-background-primary)' />}
        </div>
      </div>
      {openCalendar && (
        <ModalPage
          header={<span className={styles.header}>Date</span>}
          setIsShown={setOpenCalendar}
          mode={ModalPageMode.bottomSheet}
        >
          <div className={styles.wrapper}>
            <Calendar
              className={cn(styles.calendar, calendarClassName)}
              {...commonProps}
              locale='en-GB'
              maxDate={maxdate}
              minDate={mindate}
            />
          </div>
          <Button
            className={styles.apply}
            mode={ButtonMode.standard}
            size={ButtonSize.m}
            onClick={() => setOpenCalendar(false)}
          >
            Apply Date
          </Button>
        </ModalPage>
      )}
    </>
  );
}
