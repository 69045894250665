import { TWorkArea } from '@core/redux/models/serviceAreas';

export const isElementVisible = (
  index: number,
  isFullList: boolean,
  elementsList: string[] | TWorkArea[],
  indexOpenList?: number | null,
) => {
  if ((indexOpenList || indexOpenList === 0) && indexOpenList !== null)
    return indexOpenList === index;
  return isFullList || elementsList.length <= 5 || index < 5;
};
