import { ModuleAction, TTechnicianForm, TWorkBlock, WorkingAs } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TGeneralInfoState = {
  generalInfoAction: ModuleAction;
  generalInfoFormData: TTechnicianForm;
  hasGeneralInfoFormError: boolean;
  selfSuspended: boolean;
  workBlocks: TWorkBlock[];
  isUpdateWorkBlocks: boolean;
  workingAs: WorkingAs | '';
};

const initialState: TGeneralInfoState = {
  generalInfoAction: ModuleAction.send,
  generalInfoFormData: {
    expertId: 0,
    nickname: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
  hasGeneralInfoFormError: false,
  selfSuspended: false,
  workBlocks: [],
  isUpdateWorkBlocks: false,
  workingAs: '',
};

export const generalInfoSlice = createSlice({
  name: 'generalInfo',
  initialState,
  reducers: {
    setGeneralInfoAction(state, action: PayloadAction<ModuleAction>) {
      state.generalInfoAction = action.payload;
    },
    setGeneralFormData(state, action: PayloadAction<TTechnicianForm>) {
      state.generalInfoFormData = { ...action.payload };
    },
    setNickname(state, action: PayloadAction<string>) {
      state.generalInfoFormData.nickname = action.payload;
      state.generalInfoAction = ModuleAction.change;
    },
    setFirsName(state, action: PayloadAction<string>) {
      state.generalInfoFormData.firstName = action.payload;
      state.generalInfoAction = ModuleAction.change;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.generalInfoFormData.lastName = action.payload;
      state.generalInfoAction = ModuleAction.change;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.generalInfoFormData.phone = action.payload;
      state.generalInfoAction = ModuleAction.change;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.generalInfoFormData.email = action.payload;
      state.generalInfoAction = ModuleAction.change;
    },
    setGeneralInfoError(state, action: PayloadAction<Record<string, boolean>>) {
      state.hasGeneralInfoFormError = Boolean(
        Object.values(action.payload).find((item) => item === true),
      );
    },
    setUpdateActivateStatus(state, action: PayloadAction<boolean>) {
      state.selfSuspended = action.payload;
    },
    setWorkingAs(state, action: PayloadAction<WorkingAs>) {
      state.workingAs = action.payload;
    },
    setIsUpdateWorkBlocks(state, action: PayloadAction<boolean>) {
      state.isUpdateWorkBlocks = action.payload;
    },
    setWorkBlocks(state, action: PayloadAction<TWorkBlock[]>) {
      state.workBlocks = action.payload;
    },
  },
});

export const generalInfoActions = generalInfoSlice.actions;
export const generalInfoReducer = generalInfoSlice.reducer;
