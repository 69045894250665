import { ModuleAction } from '@core/redux/models/profile';
import { WorkAreaStatus } from '@core/redux/models/serviceAreas';
import { TechnicianWorkTypeStatus } from '@core/redux/models/jobTypes';

export const useModuleActionType = (type: ModuleAction) => {
  const isEdit = type === ModuleAction.edit;
  const isChange = type === ModuleAction.change;
  const isSend = type === ModuleAction.send;
  return { isEdit, isChange, isSend };
};

export const useWorkAreaStatusType = (type?: WorkAreaStatus) => {
  const isActive = type === WorkAreaStatus.active;
  const isDelete = type === WorkAreaStatus.delete;
  const isNewActive = type === WorkAreaStatus.newActive;
  const isNewDelete = type === WorkAreaStatus.newDelete;
  return { isActive, isDelete, isNewActive, isNewDelete };
};

export const useTechnicianWorkTypeStatusType = (type?: TechnicianWorkTypeStatus) => {
  const isNew = type === TechnicianWorkTypeStatus.new;
  const isOld = type === TechnicianWorkTypeStatus.old;
  return { isNew, isOld };
};

export const DiscardText = (text: string): string => {
  return `Are you sure you want to close edit mode and discard changes of ${text}?`;
};
