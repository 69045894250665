// extracted by mini-css-extract-plugin
var _1 = "MainInfoWebCard-module__text__HmqZJ";
var _2 = "MainInfoWebCard-module__title__XIl9V";
var _3 = "MainInfoWebCard-module__wrapper__v9wAh";
export { _1 as "text", _2 as "title", _3 as "wrapper" }

    if(module.hot) {
      // 1733466109639
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  