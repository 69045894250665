import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import IdIcon from '@assets/images/id.svg';
import PersonIcon from '@assets/images/person.svg';
import PhoneBookIcon from '@assets/images/phone-book.svg';
import PhoneIcon from '@assets/images/phone.svg';
import MailIcon from '@assets/images/mail.svg';
import * as styles from './FormMainInfo.module.scss';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import { TTechnician } from '@core/redux/models/profile';
import Input from '@components/Input/Input';
import { InputMode, InputType } from '@components/Input/types';
import PhoneInput from '@components/Input/PhoneInput';
import { getFormValidation } from '../utils';
import { useModuleActionType } from '@core/utils/utils';

type TFormMainInfoProps = {
  technician: TTechnician;
  isFetching: boolean;
};

export default function FormMainInfo(props: TFormMainInfoProps) {
  const { technician, isFetching } = props;

  const { generalInfoAction, generalInfoFormData } = useAppSelector(
    (state) => state.generalInfoReducer,
  );
  const { isSend } = useModuleActionType(generalInfoAction);

  const {
    setGeneralFormData,
    setNickname,
    setFirsName,
    setLastName,
    setPhone,
    setEmail,
    setGeneralInfoError,
  } = useActions();

  useEffect(() => {
    setGeneralFormData(technician);
  }, [isFetching]);

  const validation = useMemo(() => {
    return getFormValidation(generalInfoFormData);
  }, [generalInfoFormData]);

  useEffect(() => {
    setGeneralInfoError(getFormValidation(generalInfoFormData));
  }, [generalInfoFormData]);
  const { expertId, nickname, firstName, lastName, email, phone } = generalInfoFormData;

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.item, styles.separatorBig)}>
        <Input
          value={expertId}
          className={cn(styles.input, styles.nonChange, {
            [styles.disabled]: true,
          })}
          name='expertId'
          type={InputType.text}
          mode={InputMode.group}
          beforeIcon={<IdIcon fill='var(--light_label_tertiary)' />}
          readOnly
        />
      </div>

      <div className={styles.item}>
        <Input
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='nickname'
          placeholder='Nickname'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.nickname}
          classNameError={styles.error}
          beforeIcon={<PersonIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={styles.item}>
        <Input
          value={firstName}
          onChange={(e) => setFirsName(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='firstName'
          placeholder='First name'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.firstName}
          classNameError={styles.error}
          beforeIcon={<PhoneBookIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={cn(styles.item, styles.separatorBig)}>
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='lastName'
          placeholder='Last name'
          type={InputType.text}
          mode={InputMode.group}
          error={validation.lastName}
          classNameError={styles.error}
          beforeIcon={<PhoneBookIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={cn(styles.separator, styles.mainSeparator)}></span>
      </div>
      <div className={styles.item}>
        <PhoneInput
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='phone'
          placeholder='Phone'
          separator={false}
          type={InputType.text}
          mode={InputMode.group}
          error={validation.phone}
          classNameError={styles.error}
          beforeIcon={<PhoneIcon fill='var(--light_label_tertiary)' />}
        />
        <span className={styles.separator}></span>
      </div>
      <div className={styles.item}>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={cn(styles.input, {
            [styles.disabled]: isSend,
          })}
          name='email'
          placeholder='Email'
          type={InputType.email}
          mode={InputMode.group}
          error={validation.email}
          classNameError={styles.error}
          beforeIcon={<MailIcon fill='var(--light_label_tertiary)' />}
        />
      </div>
    </div>
  );
}
