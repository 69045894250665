// extracted by mini-css-extract-plugin
var _1 = "Login-module__blue__LYUlS";
var _2 = "Login-module__error__kxZFR";
var _3 = "Login-module__form__i44Lx";
var _4 = "Login-module__header__mQtp9";
var _5 = "Login-module__link__Rcwbh";
var _6 = "Login-module__red__rs9XF";
var _7 = "Login-module__text__IaKq5";
var _8 = "Login-module__textBlock__LASPd";
var _9 = "Login-module__title__H69Ev";
var _a = "Login-module__wrapper__fh7R2";
export { _1 as "blue", _2 as "error", _3 as "form", _4 as "header", _5 as "link", _6 as "red", _7 as "text", _8 as "textBlock", _9 as "title", _a as "wrapper" }

    if(module.hot) {
      // 1733466103624
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  