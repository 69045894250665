import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { authActions } from '../redux/store/reducers/AuthSlice';
import { userActions } from '../redux/store/reducers/UserSlice';
import { serviceAreasActions } from '@core/redux/store/reducers/profile/ServiceAreasSlice';
import { scheduleActions } from '../redux/store/reducers/profile/ScheduleSlice';
import { generalInfoActions } from '@core/redux/store/reducers/profile/GeneralInfoSlice';
import { jobTypesActions } from '@core/redux/store/reducers/profile/JobTypesSlice';
import { documentsActions } from '@core/redux/store/reducers/profile/DocumentSlice';
import { mailingAddressActions } from '@core/redux/store/reducers/profile/MailingAddressSlice';
import { bankDetailsActions } from '@core/redux/store/reducers/profile/BankDetailsSlice';
import { payoutsActions } from '@core/redux/store/reducers/payouts/PayoutsSilce';
import { errorActions } from '@core/redux/store/reducers/ErrorSlice';
import { partnershipActions } from '@core/redux/store/reducers/partnership/PartnershipSilce';
import { profileActions } from '@core/redux/store/reducers/profile/ProfileSlice';

const actions = {
  ...authActions,
  ...userActions,
  ...serviceAreasActions,
  ...scheduleActions,
  ...generalInfoActions,
  ...jobTypesActions,
  ...profileActions,
  ...documentsActions,
  ...mailingAddressActions,
  ...bankDetailsActions,
  ...payoutsActions,
  ...partnershipActions,
  ...errorActions,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
