import { TUserState } from '@core/redux/models/user';
import { jwtDecode } from 'jwt-decode';
import { useAppSelector } from './redux';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { useActions } from './actions';
import { useEffect } from 'react';
import { TServerError } from '@core/utils/error';

type MyToken = {
  user_id: number;
  technician_id: number;
  permissions: string[];
};

export const useCurrentUser = (): TUserState => {
  const { token } = useAppSelector((state) => state.authReducer);
  const decode = jwtDecode<MyToken>(token);
  const userId = decode.user_id;
  const technicianId = decode.technician_id;
  const permissions = [...decode.permissions];
  return { userId, technicianId, permissions };
};

export const usePartershipUser = (): TPartnershipElement | null => {
  const { setError } = useActions();
  const { technicianId } = useCurrentUser();
  const { data, error } = payoutsAPI.useGetPartnershipsQuery({ technician_id: technicianId });

  useEffect(() => {
    if (error) {
      setError(error as TServerError);
    }
  }, [error]);

  // костылим 1 элемент пока не готова логика для all и  других компаний
  const item = data ? data[0] : null;
  return item;
};
