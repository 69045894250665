// extracted by mini-css-extract-plugin
var _1 = "ClaimModal-module__autor__UTwNc";
var _2 = "ClaimModal-module__chat__Iq2jR";
var _3 = "ClaimModal-module__header__kWEY9";
var _4 = "ClaimModal-module__message__C7Bu4";
var _5 = "ClaimModal-module__messageBlock__tFVaF";
var _6 = "ClaimModal-module__messages__ISJaF";
var _7 = "ClaimModal-module__modal__mPtYk";
var _8 = "ClaimModal-module__modalButton__NRAH4";
var _9 = "ClaimModal-module__text__Vp7WB";
var _a = "ClaimModal-module__textTitle__eMffB";
var _b = "ClaimModal-module__title__mwMdY";
export { _1 as "autor", _2 as "chat", _3 as "header", _4 as "message", _5 as "messageBlock", _6 as "messages", _7 as "modal", _8 as "modalButton", _9 as "text", _a as "textTitle", _b as "title" }

    if(module.hot) {
      // 1733466109437
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  