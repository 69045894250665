import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TProfileState = {
  needDisabled: boolean;
};

const initialState: TProfileState = {
  needDisabled: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setNeedDisabled(state, action: PayloadAction<boolean>) {
      state.needDisabled = action.payload;
    },
  },
});

export const profileActions = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
