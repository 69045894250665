import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import React, { useState } from 'react';
import * as styles from './GroupButton.module.scss';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import { usePartershipUser } from '@core/hooks/currentUser';

export default function GroupButton() {
  const item = usePartershipUser();
  const [modal, setModal] = useState<boolean>(false);

  return (
    <>
      <Button
        className={styles.button}
        onClick={() => setModal(true)}
        bgColor={ButtonBgColor.blue}
        size={ButtonSize.s}
        disabled={true}
        loading={item === null}
      >
        M-Appliance Group
      </Button>
      {modal && (
        <ModalPage
          className={styles.modal}
          mode={ModalPageMode.bottomSheet}
          setIsShown={setModal}
          closeable={false}
          header='Agencies'
          headerClassName={styles.header}
        >
          <div className={styles.block}>
            <div className={styles.item} key={`${item?.id}-${item?.companyTitle}`}>
              {item?.companyTitle}
            </div>
          </div>
        </ModalPage>
      )}
    </>
  );
}
