import React, { ReactNode, useEffect, useRef, useState } from 'react';
import * as styles from './Slider.module.scss';
import cn from 'classnames';

type SliderProps = {
  list: ReactNode[];
  slidesToShow: number;
  showNavigation?: boolean;
  gap?: number;
  wrapperClass?: string;
  prevButtonClass?: string;
  nextButtonClass?: string;
  prevIcon?: ReactNode;
  nextIcon?: ReactNode;
};

export default function Slider(props: SliderProps) {
  const {
    list,
    wrapperClass,
    prevButtonClass,
    nextButtonClass,
    slidesToShow,
    showNavigation = true,
    gap = 20,
    prevIcon,
    nextIcon,
  } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slideWidth, setSlideWidth] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setSlideWidth((wrapperRef.current.offsetWidth - gap * (slidesToShow - 1)) / slidesToShow);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [wrapperRef, slidesToShow]);

  const nextSlide = () => {
    if (currentSlide < list.length - slidesToShow) {
      setCurrentSlide((previousSlide) => previousSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((previousSlide) => previousSlide - 1);
    }
  };

  return (
    <div className={cn(styles.container, wrapperClass)}>
      <div className={styles.sliderContainer}>
        <div
          className={cn(styles.sliderWrapper, wrapperClass)}
          ref={wrapperRef}
          style={{
            transform: `translateX(-${currentSlide * (slideWidth + gap)}px)`,
            gap: `${gap}px`,
          }}
        >
          {list.map(
            (item, index): JSX.Element => (
              <div key={index} className={styles.slide} style={{ width: `${slideWidth}px` }}>
                {item}
              </div>
            ),
          )}
        </div>
      </div>
      {showNavigation && (
        <>
          <button
            className={cn(styles.prevButton, prevButtonClass)}
            onClick={prevSlide}
            disabled={currentSlide === 0}
          >
            {prevIcon || '<'}
          </button>
          <button
            className={cn(styles.nextButton, nextButtonClass)}
            onClick={nextSlide}
            disabled={currentSlide >= list.length - slidesToShow}
          >
            {nextIcon || '>'}
          </button>
        </>
      )}
    </div>
  );
}
