import React from 'react';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import * as styles from './CalculationModal.module.scss';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import Spinner from '@components/Spinner/Spinner';

export default function CalculationModal() {
  const { payoutRow } = useAppSelector((state) => state.payoutsReducer);
  const { data, isLoading } = payoutsAPI.useGetCalculationQuery({
    comission_id: payoutRow.commissionId,
  });
  const { setCalculationModal } = useActions();
  const list = data && JSON.parse(data).logs;
  return (
    <ModalPage
      className={styles.modal}
      header={<div className={styles.header}>Calculations</div>}
      setIsShown={setCalculationModal}
      mode={ModalPageMode.bottomSheet}
      closeable
    >
      <div className={styles.wrap}>
        {isLoading ? (
          <Spinner size={'large'} />
        ) : (
          <>
            {list ? <div dangerouslySetInnerHTML={{ __html: list }} /> : <div>Nothing found</div>}
          </>
        )}
      </div>
    </ModalPage>
  );
}
