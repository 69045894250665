import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import * as styles from './ContractList.module.scss';
import InfoIcon from '@assets/images/info.svg';
import FileIcon from '@assets/images/file.svg';
import Button from '@components/Button/Button';
import { host } from '@core/constants';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import DocumentsInfoModal from '../DocumentsInfoModal/DocumentsInfoModal';
import { ContractStatus, TContract } from '@core/redux/models/partnership';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { partnershipAPI } from '@core/redux/services/partnershipService';

type TPropsContractList = {
  contracts?: TContract[];
  partnership: TPartnershipElement;
};

const statusFill = {
  pending: 'var(--light_accent_neutral)',
  signed: 'var(--light_accent_positive)',
};

export default function ContractList(props: TPropsContractList) {
  const { contracts, partnership } = props;

  const [contractId, setContractId] = useState<number>();
  const [documentInfoModal, setDocumentInfoModal] = useState<boolean>(false);

  const [handleLogin] = partnershipAPI.useCreateSignUrlMutation();

  const handleInfoDocument = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }
      setDocumentInfoModal(true);
      setContractId(id);
    },
    [contractId],
  );

  const handleSign = useCallback(
    async (contract: TContract) => {
      const resp = await handleLogin({ signUrl: contract.signUrl });
      if ('data' in resp) {
        const contractView = (
          redirectURL: string,
          contractStatus: ContractStatus,
          contractID: number,
          psh: number,
        ) => {
          return `${host}/contract/?redirect=${redirectURL}&status=${contractStatus}&psh=${psh}&contract=${contractID}`;
        };
        window.location.href = contractView(
          encodeURIComponent(resp.data.sign_url),
          contract.status,
          contract.id,
          partnership.id,
        );
      }
    },
    [partnership],
  );

  return (
    <ul className={styles.documentsList}>
      {!contracts?.length ? (
        <li key={`contracts-no-documents`} className={styles.documentItem}>
          N/A
        </li>
      ) : (
        contracts.map((contract) => {
          return (
            <li key={`${contract.id}-${contract.fileName}`} className={styles.documentItem}>
              <a
                className={cn(styles.document, {
                  [styles.documentDisabled]: contract.status === ContractStatus.pending,
                })}
                href={`${host}/documents/${contract.fileName}`}
                target='_blank'
                rel='noreferrer'
              >
                <FileIcon fill='var(--light_accent_main)' />
                <h3 className={styles.documentName}>
                  {getDdMonthYyyyFormatDate(contract.startDate ?? contract.createdAt)}
                </h3>
              </a>
              {contract.status === ContractStatus.signed ? (
                <Button
                  className={cn(styles.button, styles[contract.status])}
                  onClick={() => handleInfoDocument(contract.id)}
                  afterIcon={
                    <div className={styles.wrapper}>
                      <div className={styles.iconWrapper}>
                        <InfoIcon fill={statusFill[contract.status]} />
                      </div>
                    </div>
                  }
                  bgColor={ButtonBgColor.transparent}
                >
                  {contract.status}
                </Button>
              ) : (
                <Button
                  onClick={() => handleSign(contract)}
                  bgColor={ButtonBgColor.blue}
                  mode={ButtonMode.standard}
                  size={ButtonSize.s}
                >
                  Read and sign now
                </Button>
              )}

              {contract.id === contractId && documentInfoModal && (
                <DocumentsInfoModal setIsShown={setDocumentInfoModal} document={contract} />
              )}
            </li>
          );
        })
      )}
    </ul>
  );
}
