import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducers/UserSlice';
import { authReducer } from './reducers/AuthSlice';
import { scheduleReducer } from './reducers/profile/ScheduleSlice';
import { documentsReducer } from './reducers/profile/DocumentSlice';
import { generalInfoReducer } from './reducers/profile/GeneralInfoSlice';
import { jobTypesReducer } from './reducers/profile/JobTypesSlice';
import modalPageReducer from './reducers/ModalPageSlice';
import { authAPI } from '../services/authService';
import { profileAPI } from '../services/profileService';
import { payoutsAPI } from '../services/payoutsService';
import { serviceAreasReducer } from './reducers/profile/ServiceAreasSlice';
import { mailingAddressReducer } from './reducers/profile/MailingAddressSlice';
import { bankDetailsReducer } from './reducers/profile/BankDetailsSlice';
import { payoutsReducer } from './reducers/payouts/PayoutsSilce';
import { errorReducer } from './reducers/ErrorSlice';
import { partnershipAPI } from '../services/partnershipService';
import { partnershipReducer } from './reducers/partnership/PartnershipSilce';
import { profileReducer } from './reducers/profile/ProfileSlice';

const rootReducer = combineReducers({
  userReducer,
  authReducer,
  scheduleReducer,
  generalInfoReducer,
  profileReducer,
  jobTypesReducer,
  documentsReducer,
  modalPageReducer,
  serviceAreasReducer,
  mailingAddressReducer,
  bankDetailsReducer,
  payoutsReducer,
  partnershipReducer,
  errorReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [profileAPI.reducerPath]: profileAPI.reducer,
  [payoutsAPI.reducerPath]: payoutsAPI.reducer,
  [partnershipAPI.reducerPath]: partnershipAPI.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(authAPI.middleware)
        .concat(profileAPI.middleware)
        .concat(payoutsAPI.middleware)
        .concat(partnershipAPI.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
