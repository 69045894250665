// extracted by mini-css-extract-plugin
var _1 = "PenaltyModal-module__block__L3uy6";
var _2 = "PenaltyModal-module__header__NxTWl";
var _3 = "PenaltyModal-module__line__JaOc0";
var _4 = "PenaltyModal-module__lineTitle__wHBmc";
var _5 = "PenaltyModal-module__modal__xCmty";
var _6 = "PenaltyModal-module__module__UMwbi";
var _7 = "PenaltyModal-module__separator__xjMyQ";
var _8 = "PenaltyModal-module__text__A0Mfb";
var _9 = "PenaltyModal-module__title___1JXZ";
export { _1 as "block", _2 as "header", _3 as "line", _4 as "lineTitle", _5 as "modal", _6 as "module", _7 as "separator", _8 as "text", _9 as "title" }

    if(module.hot) {
      // 1733466107682
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  