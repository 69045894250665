import React, { useCallback, useMemo, useState } from 'react';
import * as styles from './MailingAddress.module.scss';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import AddressForm from './AddressForm/AddressForm';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import MainButton from '../MainButton/MainButton';
import { TServerError, parseServerError } from '@core/utils/error';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { checkingChangesValue, getFormValidation } from '../GeneralInfo/utils';
import { DiscardText } from '@core/utils/utils';
import { ModuleAction } from '@core/redux/models/profile';
import { getState, getStateCode } from './utils';

export default function MailingAddress() {
  const { technicianId } = useCurrentUser();

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { setMailingAddressAction, setMailingAddress } = useActions();
  const { mailingAddressAction, mailingAddressFormData, hasMailingAddressFormError } =
    useAppSelector((state) => state.mailingAddressReducer);
  const { needDisabled } = useAppSelector((state) => state.profileReducer);

  const {
    data: addressData,
    isLoading: addressIsLoading,
    refetch,
    isFetching,
  } = profileAPI.useGetAddressQuery({ id: technicianId });

  const [handleUpdateAddress, { error: addressUpdateError, isLoading: isUpdateAddressLoading }] =
    profileAPI.useUpdateAddressMutation();

  const [handleNewTechnicianAddress, { error: newAddressError, isLoading: isNewAddressLoading }] =
    profileAPI.useUpdateAddressDataMutation();

  const isLoadingUpdate = isUpdateAddressLoading || isNewAddressLoading;
  const updateError = addressUpdateError || newAddressError;

  const handleConfirm = useCallback(() => {
    setConfirmModal(false);
    refetch();
  }, []);
  const validation = useMemo(() => {
    const { apartment, ...checkFields } = mailingAddressFormData;
    return getFormValidation(checkFields);
  }, [mailingAddressFormData]);

  const submitMailingAddress = useCallback(async () => {
    try {
      if (addressData) {
        const { id, googleUid, ...addressFields } = addressData;
        const changeAddressValue =
          addressData && checkingChangesValue(mailingAddressFormData, addressFields);
        if (!changeAddressValue) {
          return;
        }

        const result = await handleUpdateAddress({
          ...mailingAddressFormData,
          stateCode: getStateCode(mailingAddressFormData.stateCode),
        });
        if (result && 'error' in result) {
          setErrorModal(true);
        } else {
          const response = await handleNewTechnicianAddress({
            id: technicianId,
            address_new: result.data.id,
          });
          if (response && 'error' in response) {
            setErrorModal(true);
            return;
          }
          setMailingAddress({
            street: result.data.street,
            apartment: result.data.apartment,
            city: result.data.city,
            stateCode: getState(result.data.stateCode),
            postalCode: result.data.postalCode,
          });
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }, [mailingAddressFormData, addressData]);

  return (
    <>
      <section className={styles.wrapper}>
        <TitleWithButton
          title={'Mailing address:'}
          disabled={needDisabled && mailingAddressAction === ModuleAction.send}
        >
          {addressIsLoading ? (
            <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
          ) : (
            <MainButton
              action={mailingAddressAction}
              setAction={setMailingAddressAction}
              refreshDataFunction={() => setConfirmModal(true)}
              resetIsLoading={isFetching}
              isLoading={isLoadingUpdate}
              sendDataFunction={submitMailingAddress}
              isSendError={!!updateError}
              sendDisabled={hasMailingAddressFormError}
            />
          )}
        </TitleWithButton>
        {addressIsLoading ? (
          <Skeleton
            width='100%'
            height={224}
            variant='rectangular'
            className={styles.skeletonForm}
          />
        ) : (
          <>
            {!!addressData && (
              <AddressForm
                addressData={addressData}
                isFetching={isFetching}
                validation={validation}
              />
            )}
          </>
        )}
      </section>
      {!!updateError && errorModal && (
        <ErrorModal
          setIsShown={setErrorModal}
          errorMessage={parseServerError(updateError as TServerError)}
        />
      )}
      {confirmModal && (
        <ConfirmModalPage
          header={'Discard editing mailing address?'}
          headerClassName={styles.modalHeader}
          text={DiscardText('mailing address')}
          textClassName={styles.modalText}
          handleConfirm={handleConfirm}
          setIsShown={() => setConfirmModal(false)}
          confirmButtonText={'Discard'}
          cancelButtonText={'Cancel'}
        />
      )}
    </>
  );
}
