import cn from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import Button from '@components/Button/Button';
import Close from '@assets/images/close.svg';
import { ModalPageMode, ModalPageOverlayColor } from './types';
import * as styles from './ModalPage.module.scss';
import { ButtonMode } from '@components/Button/types';

type ModalPageProps = {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mode: ModalPageMode;
  closeable?: boolean;
  title?: string;
  className?: string;
  headerClassName?: string;
  footerClassName?: string;
  contentClassName?: string;
  setIsShown: (val: boolean) => void;
  overlayColor?: ModalPageOverlayColor;
};

export default function ModalPage(props: ModalPageProps) {
  const {
    mode,
    children,
    overlayColor = ModalPageOverlayColor.standard,
    className,
    headerClassName,
    footerClassName,
    contentClassName,
    header,
    footer,
    setIsShown,
    closeable = true,
  } = props;

  const [active, setActive] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    void setIsShown(false);
  }, [setIsShown]);

  useEffect(() => {
    setActive(!active);
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [document.body]);

  return (
    <div
      className={cn(styles.overlay, styles[overlayColor], styles[mode])}
      onClick={() => {
        handleClose();
      }}
    >
      <div
        className={cn(
          styles.container,
          active && styles.active,
          styles[overlayColor],
          styles[mode],
          className,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!!header && (
          <div className={cn(styles.header, headerClassName)}>
            {header}
            {closeable && (
              <Button onClick={handleClose} mode={ButtonMode.icon} className={styles.close}>
                <Close fill='var(--light_accent_main)' />
              </Button>
            )}
          </div>
        )}
        <div className={cn(styles.content, contentClassName)}>{children}</div>
        {!!footer && <div className={cn(styles.footer, footerClassName)}>{footer}</div>}
      </div>
    </div>
  );
}
