import {
  TIdDocumentResponse,
  TDocumentProofCompanyRegistrationResponse,
  TDocumentId,
  TDocumentproofCompanyRegistration,
  TDocumentW9IndividualResponse,
  TDocumentSsnResponse,
  TSsnDocument,
  TW9IndividualDocument,
  TW9BusinessDocument,
  TDocumentW9BusinessResponse,
} from '@core/redux/models/documents';

export const isIdDocumentsResponse = (
  response: Array<unknown>,
): response is TIdDocumentResponse[] =>
  Boolean(
    response.find(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        'first_name' in item &&
        typeof item.first_name === 'string' &&
        'last_name' in item &&
        typeof item.last_name === 'string',
    ),
  );

export const isProofCompanyRegistrationDocumentResponse = (
  response: Array<unknown>,
): response is TDocumentProofCompanyRegistrationResponse[] =>
  Boolean(
    response.find(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        'owner_first_name' in item &&
        typeof item.owner_first_name === 'string' &&
        'owner_last_name' in item &&
        typeof item.owner_last_name === 'string' &&
        'contractor_company' in item &&
        typeof item.contractor_company === 'object',
    ),
  );

export const isSsnOrW9IndividualResponse = (
  response: Array<unknown>,
): response is TDocumentW9IndividualResponse[] | TDocumentSsnResponse[] =>
  Boolean(
    response.find(
      (item) =>
        typeof item === 'object' && item !== null && 'ssn' in item && typeof item.ssn === 'string',
    ),
  );

export const isW9BusinessResponse = (
  response: Array<unknown>,
): response is TDocumentW9BusinessResponse[] =>
  Boolean(
    response.find(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        'ein' in item &&
        typeof item.ein === 'string' &&
        'company_name' in item,
    ),
  );

export const isIdDocument = (document: unknown): document is TDocumentId =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'firstName' in document &&
      typeof document.firstName === 'string' &&
      'lastName' in document &&
      typeof document.lastName === 'string',
  );

export const isProofCompanyRegistrationDocument = (
  document: unknown,
): document is TDocumentproofCompanyRegistration =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'ownerFirstName' in document &&
      typeof document.ownerFirstName === 'string' &&
      'ownerLastName' in document &&
      typeof document.ownerLastName === 'string' &&
      'yourCompanyTitle' in document &&
      typeof document.yourCompanyTitle === 'string' &&
      'companyAddress' in document &&
      typeof document.companyAddress === 'string',
  );

export const isSsnOrW9IndividualDocument = (
  document: unknown,
): document is TSsnDocument | TW9IndividualDocument =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'ssn' in document &&
      typeof document.ssn === 'string' &&
      'firstName' in document &&
      typeof document.firstName === 'string' &&
      'lastName' in document &&
      typeof document.lastName === 'string',
  );

export const isW9BusinessDocument = (document: unknown): document is TW9BusinessDocument =>
  Boolean(
    typeof document === 'object' &&
      document !== null &&
      'ein' in document &&
      typeof document.ein === 'string' &&
      'businessName' in document,
  );
