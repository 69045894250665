// extracted by mini-css-extract-plugin
var _1 = "ReviewsCardAgency-module__img__AY2VG";
var _2 = "ReviewsCardAgency-module__text__iL030";
var _3 = "ReviewsCardAgency-module__title__Co_WK";
var _4 = "ReviewsCardAgency-module__titleBlock__NUC_6";
var _5 = "ReviewsCardAgency-module__wrapper__A7sev";
export { _1 as "img", _2 as "text", _3 as "title", _4 as "titleBlock", _5 as "wrapper" }

    if(module.hot) {
      // 1733466109655
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  