import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import * as styles from './DocumentsList.module.scss';
import InfoIcon from '@assets/images/info.svg';
import Minus from '@assets/images/minus.svg';
import FileIcon from '@assets/images/file.svg';
import { Status, TDocumentType } from '@core/redux/models/documents';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import { W9_DOCUMENT_LINK, host, FORM_TYPE } from '@core/constants';
import DocumentDeleteModal from '../DocumentDeleteModal/DocumentDeleteModal';
import DocumentsInfoModal from '../DocumentsInfoModal/DocumentsInfoModal';
import { useModuleActionType } from '@core/utils/utils';
import { DocumentType } from '../../types';
import { getDocumentStatus } from '../../utils';

type TPropsDocumentsList = {
  type: TDocumentType;
};

const statusFill = {
  declined: 'var(--light_accent_negative)',
  pending: 'var(--light_accent_neutral)',
  approved: 'var(--light_accent_positive)',
  inactive: 'var(--light_label_tertiary)',
  disabledDeclined: 'var(--light_label_tertiary)',
  disabledApproved: 'var(--light_label_tertiary)',
  expiringApproved: 'var(--light_accent_neutral)',
};

export default function DocumentsList(props: TPropsDocumentsList) {
  const { technicianId } = useCurrentUser();
  const { documentsAction, documents } = useAppSelector((state) => state.documentsReducer);
  const { setDocuments } = useActions();
  const { isSend } = useModuleActionType(documentsAction);

  const [documentInfoModal, setDocumentInfoModal] = useState<boolean>(false);
  const [documentDeleteModal, setDocumentDeleteModal] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<number>();

  const { type } = props;
  const { data: documentsList, isFetching } = profileAPI.useGetDocumentsListQuery({
    id: technicianId,
    url: type.url,
  });

  const currentType = FORM_TYPE[type?.type];
  useEffect(() => {
    if (documentsList) {
      setDocuments({ type: currentType, documents: documentsList });
    }
  }, [documentsList]);

  const handleDeleteDocument = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }
      setDocumentDeleteModal(true);
      setDocumentId(id);
    },
    [documentId],
  );

  const handleInfoDocument = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }
      setDocumentInfoModal(true);
      setDocumentId(id);
    },
    [documentId],
  );

  return (
    <ul className={styles.documentsList}>
      {isFetching ? (
        <Skeleton height={20} width='100%' variant='rectangular' className={styles.skeletonItem} />
      ) : (
        <>
          {!documents[currentType]?.length ? (
            <li key={`${type.type}-no-documents`} className={styles.documentItem}>
              N/A
            </li>
          ) : (
            documents[currentType].map((document) => {
              const currentStatus = getDocumentStatus(document, documents[currentType]);
              return (
                <li
                  key={`${type.type}-${document.fileName}-${document.id}`}
                  className={styles.documentItem}
                >
                  <a
                    className={styles.document}
                    href={`${host}/documents/${document.fileName}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FileIcon fill='var(--light_accent_main)' />
                    <h3 className={styles.documentName}>
                      {getDdMonthYyyyFormatDate(document.startDate)}
                    </h3>
                  </a>
                  <Button
                    className={cn(styles.button, styles[currentStatus], {
                      [styles.buttonDisabled]: !isSend && document.status !== Status.pending,
                    })}
                    onClick={() =>
                      isSend ? handleInfoDocument(document.id) : handleDeleteDocument(document.id)
                    }
                    afterIcon={
                      <div className={styles.wrapper}>
                        <div className={cn(styles.iconWrapper, { [styles.hidden]: !isSend })}>
                          <InfoIcon fill={statusFill[currentStatus]} />
                        </div>
                        <div className={cn(styles.iconWrapper, { [styles.hidden]: isSend })}>
                          {document.status === Status.pending ? (
                            <Minus fill='var(--light_accent_main)' />
                          ) : (
                            <Minus fill='var(--light-background-primary)' />
                          )}
                        </div>
                      </div>
                    }
                    bgColor={ButtonBgColor.transparent}
                  >
                    {document.status}
                  </Button>

                  {document.id === documentId && documentInfoModal && (
                    <DocumentsInfoModal setIsShown={setDocumentInfoModal} document={document} />
                  )}
                  {document.id === documentId && documentDeleteModal && (
                    <DocumentDeleteModal
                      setIsShown={setDocumentDeleteModal}
                      id={documentId}
                      url={type.url}
                      type={type.type}
                    />
                  )}
                </li>
              );
            })
          )}
          {(currentType === DocumentType.w9Business ||
            currentType === DocumentType.w9Individual) && (
            <a
              className={styles.documentLink}
              href={W9_DOCUMENT_LINK}
              target='_blank'
              rel='noreferrer'
            >
              Download form
            </a>
          )}
        </>
      )}
    </ul>
  );
}
