import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import * as styles from './ClaimsList.module.scss';
import ArrowIcon from '@assets/images/arrowLeftStroke.svg';
import PlusIcon from '@assets/images/plus.svg';
import SearchIcon from '@assets/images/search.svg';
import Button from '@components/Button/Button';
import { useAppSelector } from '@core/hooks/redux';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import { ROUTES } from '@routes/constants';
import Input from '@components/Input/Input';
import { InputType } from '@components/Input/types';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import { useActions } from '@core/hooks/actions';
import { TServerError } from '@core/utils/error';
import AddModal from '../AddModal/AddModal';
import { Skeleton } from '@mui/material';
import { getChats, getTime } from './utils';
import ClaimModal from '../ClaimModal/ClaimModal';
import { TPartnershipElement } from '@core/redux/models/payouts';

type TProps = {
  partnership: TPartnershipElement;
};

export default function ClaimsList(props: TProps) {
  const { partnership } = props;
  const [search, setSearch] = useState<string>('');
  const [modal, setModal] = useState(false);

  const { setError, setClaimModal, setCustomInvoiceNumber, setActiveClaim } = useActions();
  const { data, error, isFetching } = payoutsAPI.useGetClaimsQuery({
    partnership_id: partnership.id,
  });

  const { claimModal } = useAppSelector((state) => state.payoutsReducer);

  const list = useMemo(() => {
    if (!data?.length) {
      return;
    }
    return data.filter((item) => item.invoiceNumber.includes(search));
  }, [data, search]);

  useEffect(() => {
    setError(error as TServerError);
  }, [error]);

  const chatsList = useMemo(() => data?.map((item) => item.invoiceNumber), [data]);
  const getClaimsList = () => {
    if (!list?.length) {
      return (
        <div className={styles.emptyText}>
          No claims were found with this invoice number.
          <div className={styles.emptyAction} onClick={() => setModal(true)}>
            Create a new one.
          </div>
        </div>
      );
    }
    const { active, close } = getChats(list);
    return (
      <>
        {!!active.length && (
          <div className={styles.list}>
            {active.map((item) => (
              <div
                className={styles.item}
                key={`${item.commissionId}-${item.invoiceNumber}`}
                onClick={() => {
                  setClaimModal(true);
                  setCustomInvoiceNumber(item.invoiceNumber);
                }}
              >
                <div className={styles.itemHeader}>
                  <span>{`Claim for ${item.invoiceNumber}`}</span>
                  <span className={styles.time}>
                    {getTime(item.messages[item.messages.length - 1].createdAt)}
                  </span>
                </div>
                <div className={styles.itemText}>
                  {item.messages[item.messages.length - 1].text}
                </div>
              </div>
            ))}
          </div>
        )}
        {!!active.length && !!close.length && <span className={styles.separator}></span>}
        {!!close.length && (
          <div className={cn(styles.list)}>
            {close.map((item) => (
              <div
                className={cn(styles.item, { [styles.close]: !item.isActive })}
                key={`${item.commissionId}-${item.invoiceNumber}`}
                onClick={() => {
                  setClaimModal(true);
                  setCustomInvoiceNumber(item.invoiceNumber);
                  setActiveClaim(item.isActive);
                }}
              >
                <div className={styles.itemHeader}>
                  <span>{`Claim for ${item.invoiceNumber}`}</span>
                  <span className={styles.time}>
                    {getTime(item.messages[item.messages.length - 1].createdAt)}
                  </span>
                </div>
                <div className={styles.itemText}>{item.messages[0]?.text || ''}</div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <NavLink to={ROUTES.PAYOUTS} className={styles.link}>
              <Button
                size={ButtonSize.s}
                beforeIcon={<ArrowIcon fill='var(--light_label_secondary)' />}
                bgColor={ButtonBgColor.transparent}
                className={styles.headerButton}
              >
                Payouts
              </Button>
            </NavLink>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.action}>
            <Input
              className={styles.search}
              type={InputType.text}
              name='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              beforeIcon={
                <SearchIcon className={styles.searchIcon} fill='var(--light_label_tertiary)' />
              }
            />
            <Button
              mode={ButtonMode.icon}
              bgColor={ButtonBgColor.blue}
              className={styles.addButton}
              onClick={() => setModal(true)}
            >
              <PlusIcon fill='var(--light-background-primary)' />
            </Button>
          </div>
          <div className={styles.chats}>
            {isFetching ? (
              <div>
                <Skeleton
                  height={64}
                  width='100%'
                  variant='rectangular'
                  className={styles.skeleton}
                />
                <Skeleton
                  height={64}
                  width='100%'
                  variant='rectangular'
                  className={styles.skeleton}
                />
              </div>
            ) : (
              getClaimsList()
            )}
          </div>
        </div>
      </>
      {modal && <AddModal setModal={setModal} chats={chatsList || []} />}
      {claimModal && <ClaimModal />}
    </>
  );
}
