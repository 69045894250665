// extracted by mini-css-extract-plugin
var _1 = "Tooltip-module__animation__lTDef";
var _2 = "Tooltip-module__error__njWc8";
var _3 = "Tooltip-module__wrap__ntZAu";
export { _1 as "animation", _2 as "error", _3 as "wrap" }

    if(module.hot) {
      // 1733466103620
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  