import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { Skeleton } from '@mui/material';
import { profileAPI } from '@core/redux/services/profileService';
import { WorkingAs } from '@core/redux/models/profile';
import { TDocumentType } from '@core/redux/models/documents';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import PlusIcon from '@assets/images/plus.svg';
import ContractErrorIcon from '@assets/images/inputError.svg';
import * as styles from './Documents.module.scss';
import DocumentsList from './components/DocumentsList/DocumentsList';
import NewDocumentModal from './components/NewDocumentModal/NewDocumentModal';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import ContractList from './components/ContractList/ContractList';
import { ContractStatus } from '@core/redux/models/partnership';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import MainButton from '@pages/Partnership/MainButton/MainButton';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import { useModuleActionType } from '@core/utils/utils';

type TProps = {
  partnership: TPartnershipElement;
};

export default function Documents(props: TProps) {
  const { partnership } = props;
  const { data, isLoading } = profileAPI.useGetDocumentTypesQuery({ type: WorkingAs.Agency });
  const { data: contracts, isLoading: isContractsLoading } = partnershipAPI.useGetContractListQuery(
    { partnership_id: partnership.id },
  );

  const { partnershipDocumentsAction } = useAppSelector((state) => state.partnershipReducer);
  const { setPartnershipDocumentsAction } = useActions();
  const { isSend } = useModuleActionType(partnershipDocumentsAction);

  const [newDocumentModal, setNewDocumentModal] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<TDocumentType>();

  const handleNewDocumentModal = useCallback((type: TDocumentType) => {
    if (type) {
      setNewDocumentModal(true);
      setDocumentType(type);
    }
  }, []);

  return (
    <div className={styles.wrap}>
      <span className={styles.separator}></span>
      <TitleWithButton title={'Documents:'}>
        <MainButton
          refreshDataFunction={() => {}}
          resetIsLoading={false}
          sendDataFunction={() => {}}
          setAction={setPartnershipDocumentsAction}
          action={partnershipDocumentsAction}
          noSendDataMode
        />
      </TitleWithButton>
      <div className={styles.content}>
        <>
          {isLoading ? (
            <Skeleton
              height={120}
              width='100%'
              variant='rectangular'
              className={styles.skeletonItem}
            />
          ) : (
            <ul className={styles.documentsWrapper}>
              {data?.map((type) => (
                <li key={`${type.type}`} className={styles.documentType}>
                  <div className={styles.documentHeader}>
                    <div className={styles.wrapperTitle}>
                      <h2 className={styles.title}>{type.type}</h2>
                    </div>
                    <Button
                      mode={ButtonMode.icon}
                      bgColor={ButtonBgColor.transparent}
                      onClick={() => handleNewDocumentModal(type)}
                      className={cn(styles.button, { [styles.buttonHidden]: isSend })}
                    >
                      <PlusIcon fill='var(--light_accent_main)' />
                    </Button>
                  </div>
                  <DocumentsList type={type} partnership={partnership} />
                </li>
              ))}
            </ul>
          )}
          {isContractsLoading ? (
            <Skeleton
              height={120}
              width='100%'
              variant='rectangular'
              className={styles.skeletonItem}
            />
          ) : (
            <ul className={styles.documentsWrapper}>
              <li key={`contract-list`} className={styles.documentType}>
                <div className={styles.documentHeader}>
                  <div className={styles.wrapperTitle}>
                    <h2 className={styles.title}>Contracts</h2>
                    {!!contracts?.find((item) => item.status === ContractStatus.pending) && (
                      <ContractErrorIcon
                        className={styles.contactIcon}
                        fill='var(--light_accent_negative)'
                      />
                    )}
                  </div>
                </div>
                <ContractList contracts={contracts} partnership={partnership} />
              </li>
            </ul>
          )}
        </>
      </div>
      {newDocumentModal && (
        <NewDocumentModal
          setIsShown={setNewDocumentModal}
          type={documentType}
          partnership={partnership}
        />
      )}
    </div>
  );
}
