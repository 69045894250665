// extracted by mini-css-extract-plugin
var _1 = "Reset-module__blue__tLQrd";
var _2 = "Reset-module__content__Tva_C";
var _3 = "Reset-module__error__hvGQz";
var _4 = "Reset-module__form__zM9t1";
var _5 = "Reset-module__sendWrapped__aNdF1";
var _6 = "Reset-module__text__Agfo9";
var _7 = "Reset-module__textBlock__Bwp94";
var _8 = "Reset-module__title__ojwOK";
var _9 = "Reset-module__wrapper__YvnS1";
export { _1 as "blue", _2 as "content", _3 as "error", _4 as "form", _5 as "sendWrapped", _6 as "text", _7 as "textBlock", _8 as "title", _9 as "wrapper" }

    if(module.hot) {
      // 1733466104631
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  