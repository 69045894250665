// extracted by mini-css-extract-plugin
var _1 = "Header-module__button__ecXiT";
var _2 = "Header-module__hidden__LXkJy";
var _3 = "Header-module__mainLine__xcVCo";
var _4 = "Header-module__opacity__nJoOD";
var _5 = "Header-module__positionChange__mCME2";
var _6 = "Header-module__text__mZ8mK";
var _7 = "Header-module__wrapper__oAwCO";
export { _1 as "button", _2 as "hidden", _3 as "mainLine", _4 as "opacity", _5 as "positionChange", _6 as "text", _7 as "wrapper" }

    if(module.hot) {
      // 1733466104133
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  