import * as styles from './Login.module.scss';
import React, { useEffect } from 'react';
import cn from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextLogo from '@assets/images/textLogo.svg';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import { ButtonSize } from '@components/Button/types';
import { InputType } from '@components/Input/types';
import { authAPI } from '@core/redux/services/authService';
import { useActions } from '@core/hooks/actions';
import {
  parseHookFormMessage,
  parseServerError,
  TServerError,
  THookErrors,
} from '@core/utils/error';
import SupportMessage from './components/SupportMessage/SupportMessage';

type LoginData = {
  username: string;
  password: string;
};

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<LoginData>({ reValidateMode: 'onChange' });

  const [handleLogin, { data, isLoading, error: serverError }] = authAPI.useLoginMutation();
  const { setCredentials } = useActions();

  //const navigate = useNavigate();
  useEffect(() => {
    if (data?.token) {
      setCredentials(data.token);
      //navigate(data.next);
      window.location.href = data.next;
    }
  }, [data?.token]);

  const hasFormErrors = !!Object.keys(formErrors).length;

  const onSubmit: SubmitHandler<LoginData> = async (formData) => {
    try {
      handleLogin(formData);
    } catch (e) {
      console.warn(e);
    }
  };

  const { ref: usernameRef, ...field } = register('username', {
    required: 'Email address is required field',
  });

  const { ref: passwordRef, ...fieldPassword } = register('password', {
    required: 'Password is required field',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <NavLink to={ROUTES.MAIN} className={styles.link}>
          <TextLogo fill='var(--light_label_primary)' />
        </NavLink>
        <title className={styles.title}>Log in</title>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...field}
          type={InputType.text}
          innerRef={usernameRef}
          placeholder='Email address'
          error={Boolean(formErrors.username)}
        />
        <Input
          {...fieldPassword}
          type={InputType.password}
          placeholder='Password'
          innerRef={passwordRef}
          error={Boolean(formErrors.password)}
        />
        <Button size={ButtonSize.l} type='submit' loading={isLoading}>
          Log in
        </Button>
        {hasFormErrors && (
          <div className={styles.error}>
            <p>{parseHookFormMessage(formErrors as THookErrors)}</p>
            <SupportMessage />
          </div>
        )}
        {!!serverError && !hasFormErrors && (
          <div className={styles.error}>{parseServerError(serverError as TServerError)}</div>
        )}
      </form>
      <div className={styles.textBlock}>
        <NavLink to={ROUTES.REGISTRATION} className={cn(styles.text, styles.blue)}>
          Register
        </NavLink>
        <NavLink to={ROUTES.FORGOT} className={cn(styles.text, styles.red)}>
          Forgot password?
        </NavLink>
      </div>
    </div>
  );
}
