import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@components/Button/Button';
import { ButtonSize } from '@components/Button/types';
import Input from '@components/Input/Input';
import TextLogo from '@assets/images/textLogo.svg';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import { authAPI } from '@core/redux/services/authService';
import InformationPage from '../InformationPage/InformationPage';
import * as styles from './Forgot.module.scss';
import { InputType } from '@components/Input/types';
import {
  parseHookFormMessage,
  parseServerError,
  TServerError,
  THookErrors,
} from '@core/utils/error';
import { EMAIL_PATTERN } from '@core/constants';
import SupportMessage from '../SupportMessage/SupportMessage';

type ForgotForm = {
  email: string;
};

export default function Forgot() {
  const [isSend, setIsSend] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [handleForgotEmail, { isLoading, isSuccess, error: serverError }] =
    authAPI.usePasswordResetMutation();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<ForgotForm>();

  useEffect(() => {
    if (isSuccess) {
      setIsSend(true);
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<ForgotForm> = async (data) => {
    try {
      if (data) {
        await handleForgotEmail(data);
        setCurrentEmail(data.email);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { ref: emailRef, ...field } = register('email', {
    required: 'Email is required field',
    pattern: {
      value: EMAIL_PATTERN,
      message: 'Invalid email address',
    },
  });

  const hasFormErrors = !!Object.keys(formErrors).length;

  return (
    <>
      {!isSend ? (
        <div className={styles.wrapper}>
          <TextLogo fill='var(--light_label_primary)' />
          <title className={styles.title}>Enter your email</title>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...field}
              type={InputType.text}
              innerRef={emailRef}
              placeholder='Email'
              error={Boolean(formErrors.email)}
            />
            <Button size={ButtonSize.l} type='submit' loading={isLoading}>
              Reset password
            </Button>
            {hasFormErrors && (
              <div className={styles.error}>
                <p>{parseHookFormMessage(formErrors as THookErrors)}</p>
                <SupportMessage />
              </div>
            )}
            {!!serverError && !hasFormErrors && (
              <div className={styles.error}>{parseServerError(serverError as TServerError)}</div>
            )}
          </form>
          <div className={styles.textBlock}>
            <NavLink to={ROUTES.LOGIN} className={cn(styles.text, styles.blue)}>
              Back to login
            </NavLink>
          </div>
        </div>
      ) : (
        <InformationPage
          text={`We have sent an email with a verification link to ${currentEmail}. Please check your inbox and follow the link within 30 days to complete your profile verification. If you do not verify within this period, your profile will be deactivated and you will need to register again.`}
          linkText={'Back to login'}
          route={ROUTES.LOGIN}
        />
      )}
    </>
  );
}
