const URLS: { [key: string]: string } = {
  production: 'https://experthub.app',
  development: 'https://test.experthub.app',
  'development-local': 'http://localhost:8000',
};

const name = process.env.NODE_ENV || 'production';

export const oldFrontCookie = process.env.NODE_ENV === 'production' ? 'expert' : 'test.expert';

// For telegram bot
export const telegramToken = process.env.TOKEN;
export const telegramChatId = -1001997696598;

export const host = URLS[name];
export const wsHost =
  name === 'production' ? 'wss://experthub.app' :
  name === 'development-local' ? 'ws://localhost:8000' :
  'wss://test.experthub.app';


export const FILE_ENDPOINTS = [
  'addDocumentW9Business',
  'addDocumentW9Individual',
  'addDocumentSsn',
  'addDocumentId',
  'addDocumentCompanyRegistration',
  'addDocumentRepairLicense',
  'addDocumentInsurance',
];

export const W9_DOCUMENT_LINK = 'https://www.irs.gov/pub/irs-pdf/fw9.pdf';

export const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ONLY_LATIN_CHAR_PATTERN = /^[[a-zA-Z0-9 ]+\s*$/;
export const ONLY_NUMBERS = /[0-9_\\.]+$/;
export const ONLY_WHOLE_NUMBERS = /[0-9]/;

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DATE_PLACEHOLDER = 'Add date';

export const FORM_TYPE: Record<string, string> = {
  'Appliance repair license': 'appliance-repair-license',
  'General liability insurance': 'General liability insurance',
  ID: 'id',
  'Proof of company registration': 'proof-of-company-registration',
  'W9-Business': 'w9-business',
  'W9-Individual': 'w9-individual',
  SSN: 'ssn',
};

export const STATE_CODE = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AS: 'American Samoa',
  DC: 'District of Columbia',
  FM: 'Federated States of Micronesia',
  GU: 'Guam',
  MH: 'Marshall Islands',
  MP: 'Northern Mariana Islands',
  PW: 'Palau',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands',
  AE: 'Armed Forces Africa',
  AA: 'Armed Forces Americas',
  AP: 'Armed Forces Pacific',
};
