// extracted by mini-css-extract-plugin
var _1 = "ErrorModal-module__button__mt63n";
var _2 = "ErrorModal-module__header__jvCWM";
var _3 = "ErrorModal-module__text___QteC";
var _4 = "ErrorModal-module__wrapper__wtmrA";
export { _1 as "button", _2 as "header", _3 as "text", _4 as "wrapper" }

    if(module.hot) {
      // 1733466103853
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  