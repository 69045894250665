import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import { useAuthRoutes } from '@pages/Login/routes';
import RedirectPage from '@pages/RedirectPage/RedirectPage';
// import Page404 from '@pages/Page404/Page404';
import Profile from '@pages/Profile/Profile';
// import Jobs from '@pages/Jobs/Jobs';
import Payouts from '@pages/Payouts/Payouts';
import Partnership from '@pages/Partnership/Partnership';
// import Agencies from '@pages/Agencies/Agencies';
import { PrivateRoute } from './privateRoutes';
import { host } from '@core/constants';
import ClaimsWrapper from '@pages/Payouts/components/Claims/components/ClaimsWrapper';

export const useRoutes = (isAuth: boolean) => {
  const authRoutes = useAuthRoutes();
  const agencies = `${host}/profile/agencies/`;
  const jobs = `${host}/profile/jobs/`;

  return (
    <Routes>
      <Route path={ROUTES.REDIRECT_PAGE} element={<RedirectPage isAuth={isAuth} />} />
      <Route element={<PrivateRoute isAuth={isAuth} />}>
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.PAYOUTS} element={<Payouts />} />
        <Route path={ROUTES.PARTNERSHIP} element={<Partnership />} />
        <Route path={ROUTES.CLAIMS} element={<ClaimsWrapper />} />
        <Route path={ROUTES.AGENCIES} element={<RedirectPage link={agencies} />} />
        <Route path={ROUTES.JOBS} element={<RedirectPage link={jobs} />} />
        {/* <Route path='/' element={<Navigate to={ROUTES.JOBS} />} />
        <Route path={ROUTES.JOBS} element={<Jobs />} />
        <Route path={ROUTES.AGENCIES} element={<Agencies />} />
        <Route path='*' element={<Page404 />} /> */}
        <Route path='*' element={isAuth && <Navigate to='/redirect' />} />
      </Route>
      {!isAuth && authRoutes}
    </Routes>
  );
};
