import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery, customFetchBaseQuery } from './mainService';
import {
  TWeeks,
  TWeeksResponse,
  TPayouts,
  TPayoutsParams,
  TPayoutsResponse,
  TChangesAmountParams,
  TChangesAmount,
  TPartnership,
  TPartnershipResponse,
  TPayments,
  TOutstandingBalance,
  TClaimsList,
  TClaimsResponse,
  TClaimMessage,
  TMessageResponse,
  TClaim,
  TClaimResponse,
} from '../models/payouts';
import { getQueryParams } from './utils/utils';

enum ApiPaths {
  GET_PARTNERSHIPS = '/api/technicians/technician_id/partnerships/',
  GET_PAYOUTS = '/api/partnerships/partnership_id/payouts/',
  GET_WEEKS = '/api/timing/weeks/',
  GET_CHANGES_AMOUNT = '/api/partnerships/partnership_id/payout-changes/',
  GET_OUTSTANDING_BALANCE = '/api/partnerships/partnership_id/payout-balance/',
  GET_PAYMENTS = '/api/partnerships/partnership_id/payments/',
  GET_ClAIMS = '/api/partnerships/partnership_id/commission-claims/',
  CLAIM_MESSAGE = 'api/partnerships/partnership_id/commission-claims/messages/',
  GET_HISTORY = '/api/partnerships/partnership_id/orders/invoice/history/',
  GET_CALCULATION = '/api/commissions/commissions_id/logs/',
}

export const payoutsAPI = createApi({
  reducerPath: 'payoutsAPI',
  baseQuery: customFetchBaseQuery(baseQuery),
  endpoints: (build) => ({
    getPartnerships: build.query<TPartnership, { technician_id: number }>({
      query: ({ technician_id }) =>
        `${ApiPaths.GET_PARTNERSHIPS.replace(/technician_id/gi, String(technician_id))}`,
      transformResponse: (response: TPartnershipResponse) =>
        response.map((item) => ({
          id: item.id,
          companyTitle: item.company.title,
          companyId: item.company.id,
          crm: item.company.crm_system,
          email: item.expert_crm_email ?? '',
          knowledgeBaseUrl: item.company.knowledge_base_url ?? '',
          status: item.status,
        })),
    }),
    getPayouts: build.query<TPayouts, TPayoutsParams>({
      query: ({ partnership_id, ...params }) => {
        const queryParams = getQueryParams(params);
        return {
          url: `${ApiPaths.GET_PAYOUTS.replace(
            /partnership_id/gi,
            String(partnership_id),
          )}${queryParams}`,
        };
      },
      transformResponse: (response: TPayoutsResponse) => ({
        result: response.results.map((item) => ({
          id: item.id,
          invoice: item.invoice_number,
          date: item.scheduled_end,
          customer: item.customer_name,
          amount: item.total_amount,
          commission: item.final_commission,
          commissionId: item.commission_id,
          orderId: item.order_id,
          isRental: item.is_rental,
          isCustomTransaction: item.is_custom_transaction,
        })),
        commissionSubtotal: response.commission_subtotal,
        changesSubtotal: response.changes_subtotal,
      }),
    }),
    getChangesAmount: build.query<TChangesAmount, TChangesAmountParams>({
      query: ({ partnership_id, week_id }) =>
        `${ApiPaths.GET_CHANGES_AMOUNT.replace(
          /partnership_id/gi,
          String(partnership_id),
        )}?week_id=${week_id}`,
      transformResponse: (response: TPayoutsResponse) => ({
        result: response.results.map((item) => ({
          id: item.id,
          invoice: item.invoice_number,
          date: item.scheduled_end,
          customer: item.customer_name,
          amount: item.total_amount,
          commission: item.final_commission,
          commissionId: item.commission_id,
          orderId: item.order_id,
          isRental: item.is_rental,
          isCustomTransaction: item.is_custom_transaction,
          finalCommissionChanges: item.final_commission_changes || '$0.00',
        })),
        commissionSubtotal: response.commission_subtotal,
        changesSubtotal: response.changes_subtotal,
      }),
    }),
    getWeeks: build.query<TWeeks, void>({
      query: () => `${ApiPaths.GET_WEEKS}`,
      transformResponse: (response: TWeeksResponse) =>
        response.map((item) => ({
          ...item,
          isPaymentWeek: item.is_payment_week,
        })),
    }),
    getOutstandingBalance: build.query<
      TOutstandingBalance,
      { partnership_id: number; week_id: number }
    >({
      query: ({ partnership_id, week_id }) =>
        `${ApiPaths.GET_OUTSTANDING_BALANCE.replace(/partnership_id/gi, String(partnership_id))}?week_id=${week_id}`,
    }),
    getPayments: build.query<TPayments, { partnership_id: number; week_id: number }>({
      query: ({ partnership_id, week_id }) =>
        `${ApiPaths.GET_PAYMENTS.replace(/partnership_id/gi, String(partnership_id))}?week_id=${week_id}`,
    }),
    getClaims: build.query<TClaimsList, { partnership_id: number; invoiceNumber?: string }>({
      query: ({ partnership_id, invoiceNumber }) =>
        `${ApiPaths.GET_ClAIMS.replace(/partnership_id/gi, String(partnership_id))}${invoiceNumber ? '?invoice_number=' + invoiceNumber : ''}`,
      transformResponse: (response: TClaimsResponse) => {
        return response.map((item) => ({
          id: item.id,
          commissionId: item.commission_id,
          invoiceNumber: item.invoice_number,
          isActive: item.is_active,
          createdAt: item.created_at,
          messages: item.messages.map((message) => ({
            claimId: message.claim_id,
            createdBy: message.created_by,
            createdByName: message.created_by_name,
            createdAt: message.created_at,
            text: message.text,
          })),
        }));
      },
    }),
    closeClaim: build.mutation<
      TClaim,
      { partnership_id: number; is_active: boolean; claim_id: number }
    >({
      query: ({ partnership_id, is_active, claim_id }) => {
        return {
          url: `${ApiPaths.GET_ClAIMS.replace(/partnership_id/gi, String(partnership_id))}/${claim_id}/`,
          method: 'PATCH',
          body: {
            is_active,
            partnership: partnership_id,
          },
        };
      },
      async onQueryStarted({ partnership_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: claim } = await queryFulfilled;
          dispatch(
            payoutsAPI.util.updateQueryData('getClaims', { partnership_id }, (draft) => {
              return draft.map((item) => {
                if (item.id === claim.id) {
                  return claim;
                } else {
                  return item;
                }
              });
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
      transformResponse: (response: TClaimResponse) => {
        return {
          id: response.id,
          commissionId: response.commission_id,
          invoiceNumber: response.invoice_number,
          isActive: response.is_active,
          createdAt: response.created_at,
          messages: response.messages.map((message) => ({
            claimId: message.claim_id,
            createdBy: message.created_by,
            createdByName: message.created_by_name,
            createdAt: message.created_at,
            text: message.text,
          })),
        };
      },
    }),
    getClaimsMessages: build.query<
      TClaimMessage[],
      { partnership_id: number; invoiceNumber?: string }
    >({
      query: ({ partnership_id, invoiceNumber }) =>
        `${ApiPaths.CLAIM_MESSAGE.replace(/partnership_id/gi, String(partnership_id))}?invoice_number=${invoiceNumber}`,
      transformResponse: (response: TMessageResponse[]) => {
        return response.map((item) => ({
          claimId: item.claim_id,
          createdBy: item.created_by,
          createdByName: item.created_by_name,
          createdAt: item.created_at,
          text: item.text,
        }));
      },
    }),
    sendClaimMessage: build.mutation<
      TClaimMessage,
      { partnership_id: number; invoiceNumber: string; text: string }
    >({
      query: ({ partnership_id, invoiceNumber, text }) => {
        return {
          url: `${ApiPaths.CLAIM_MESSAGE.replace(/partnership_id/gi, String(partnership_id))}`,
          method: 'POST',
          body: {
            invoice_number: invoiceNumber,
            text,
          },
        };
      },
      async onQueryStarted({ invoiceNumber, partnership_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: message } = await queryFulfilled;
          dispatch(
            payoutsAPI.util.updateQueryData(
              'getClaimsMessages',
              { invoiceNumber, partnership_id },
              (draft) => {
                return [message, ...draft];
              },
            ),
          );
        } catch (e) {
          console.log(e);
        }
      },
      transformResponse: (response: TMessageResponse): TClaimMessage => {
        return {
          claimId: response.claim_id,
          createdBy: response.created_by,
          createdByName: response.created_by_name,
          createdAt: response.created_at,
          text: response.text,
        };
      },
    }),
    getHistory: build.query<string, { invoice: string; partnership_id: number }>({
      query: ({ invoice, partnership_id }) => {
        return {
          url: `${ApiPaths.GET_HISTORY.replace(/partnership_id/gi, String(partnership_id)).replace(/invoice/gi, invoice)}`,
          method: 'GET',
          responseHandler: 'text',
        };
      },
    }),
    getCalculation: build.query<string, { comission_id: number }>({
      query: ({ comission_id }) => {
        return {
          url: `${ApiPaths.GET_CALCULATION.replace(/commissions_id/gi, String(comission_id))}`,
          method: 'GET',
          responseHandler: 'text',
        };
      },
    }),
  }),
});
