import React from 'react';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { TDocument } from '@core/redux/models/documents';
import SubInformation from './SubInformation/SubInformation';
import * as styles from './DocumentsInfoModal.module.scss';

type DocumentsInfoModalProps = {
  setIsShown: (val: boolean) => void;
  document: TDocument;
};

export default function DocumentsInfoModal(props: DocumentsInfoModalProps) {
  const { setIsShown, document } = props;
  const { type, fileName, status } = document;

  return (
    <ModalPage
      header={
        <>
          <div className={styles.title}>Document details:&nbsp;{type}</div>
          <span className={styles.separator}></span>
        </>
      }
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
    >
      <div className={styles.module}>
        <div className={styles.block}>
          <div className={styles.item}>
            <span className={styles.label}>Document:</span>
            <span className={styles.value}>{fileName}</span>
          </div>
        </div>
        <span className={styles.separator}></span>
      </div>
      <div className={styles.module}>
        <div className={styles.block}>
          <div className={styles.item}>
            <span className={styles.label}>Status:</span>
            <span className={styles.value}>{status}</span>
          </div>
        </div>
        <span className={styles.separator}></span>
      </div>
      <SubInformation document={document} />
    </ModalPage>
  );
}
