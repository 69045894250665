import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as styles from './Drum.module.scss';
import cn from 'classnames';

type DrumProps = {
  adjustedValues: number[];
  isLooped: boolean;
  setSelectedValueIndex: (arg: number) => void;
  selectedValueIndex: number;
  indexTime: number;
  displayText: (value: number) => string;
  classNameWrapper?: string;
  classNameItem?: string;
  classNameDrum?: string;
};

const MIN_VALUE_INDEX = 3;

export default function Drum(props: DrumProps) {
  const {
    adjustedValues,
    isLooped,
    setSelectedValueIndex,
    selectedValueIndex,
    indexTime,
    displayText,
    classNameWrapper,
    classNameItem,
    classNameDrum,
  } = props;

  const [startY, setStartY] = useState<number>(0);
  const [rotation, setRotation] = useState<number>(0);

  const drumRef = useRef<HTMLDivElement>(null);

  const MAX_VALUE_INDEX = adjustedValues.length - 4;

  useEffect(() => {
    const drumElement = drumRef.current;
    const handleWheelEvent = (event: WheelEvent) => {
      event.preventDefault();
      const deltaY = event.deltaY * 0.1;
      let newIndex;
      if (isLooped) {
        newIndex = (selectedValueIndex + Math.sign(deltaY) / 30) % adjustedValues.length;
        if (newIndex < 0) {
          newIndex = adjustedValues.length - 1;
        }
      } else {
        newIndex = selectedValueIndex + Math.sign(deltaY) / 30;
        if (newIndex < MIN_VALUE_INDEX || Math.floor(newIndex) > MAX_VALUE_INDEX) {
          return;
        }
      }
      setSelectedValueIndex(newIndex);
    };

    drumElement?.addEventListener('wheel', handleWheelEvent, { passive: false });
    return () => drumElement?.removeEventListener('wheel', handleWheelEvent);
  }, [selectedValueIndex]);

  const handleTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const currentY = event.touches[0].clientY;
      const distanceY = startY - currentY;
      const speedCoefficient = 1.25;
      let newIndex;
      setStartY(currentY);

      const delta = (rotation + distanceY * speedCoefficient) / 30;
      if (isLooped) {
        if (delta >= 0) {
          newIndex = Math.round((Math.round(delta) + indexTime) % adjustedValues.length);
        } else {
          newIndex = Math.round(Math.abs(adjustedValues.length + delta) % adjustedValues.length);
        }
      } else {
        newIndex = Math.round(delta) + indexTime + MIN_VALUE_INDEX;

        if (newIndex < MIN_VALUE_INDEX || newIndex > MAX_VALUE_INDEX) {
          return;
        }
      }

      setRotation((prevRotation) => prevRotation + distanceY * speedCoefficient);
      setSelectedValueIndex(newIndex);
    },
    [startY, rotation, selectedValueIndex],
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setStartY(event.touches[0].clientY);
    },
    [selectedValueIndex],
  );

  const displayValues = useMemo(() => {
    if (isLooped) {
      const totalElements = 7;
      const elements: number[] = [];
      const tailCount = 3 - Math.round(selectedValueIndex);
      if (tailCount > 0) {
        elements.push(...adjustedValues.slice(-tailCount));
      }

      const fromStart = adjustedValues.slice(selectedValueIndex - 3, selectedValueIndex + 4);
      elements.push(...fromStart);

      if (elements.length < totalElements) {
        const neededFromStart = totalElements - elements.length;
        elements.push(...adjustedValues.slice(0, neededFromStart));
      }
      return elements;
    } else {
      return adjustedValues.slice(selectedValueIndex - MIN_VALUE_INDEX, selectedValueIndex + 4);
    }
  }, [selectedValueIndex, adjustedValues]);

  return (
    <div ref={drumRef} className={cn(styles.drumWrapper, classNameWrapper)}>
      <ul className={cn(styles.drum, classNameDrum)}>
        {displayValues.map(
          (value: number, index: number): React.JSX.Element => (
            <li key={`${value}-${index}`} className={cn(styles.drumItem, classNameItem)}>
              {displayText(value)}
            </li>
          ),
        )}
      </ul>
      <div
        className={styles.drumTouch}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      ></div>
    </div>
  );
}
