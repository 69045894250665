import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '@core/hooks/redux';
import * as styles from './Tooltip.module.scss';
import { getMessage } from './utils';
//new type
export enum TooltipType {
  error = 'error',
}

type TTooltip = {
  type: TooltipType;
};

export default function Tooltip(props: TTooltip) {
  const { type } = props;

  const { error } = useAppSelector((state) => state.errorReducer);

  if (!error) {
    return null;
  }
  return <div className={cn(styles.wrap, styles[type])}>{getMessage(error)}</div>;
}
