import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUserState } from '../../models/user';

const initialState: TUserState = {
  userId: NaN,
  technicianId: NaN,
  permissions: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<TUserState>) {
      state.userId = action.payload.userId;
      state.technicianId = action.payload.technicianId;
      state.permissions = [...action.payload.permissions];
    },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
