// extracted by mini-css-extract-plugin
var _1 = "Forgot-module__blue__Z4Wvf";
var _2 = "Forgot-module__error__K9yft";
var _3 = "Forgot-module__form__WMFM3";
var _4 = "Forgot-module__sendWrapped__wAWO_";
var _5 = "Forgot-module__text__LJqZM";
var _6 = "Forgot-module__textBlock__TABAR";
var _7 = "Forgot-module__title__FId9_";
var _8 = "Forgot-module__wrapper___vZMA";
export { _1 as "blue", _2 as "error", _3 as "form", _4 as "sendWrapped", _5 as "text", _6 as "textBlock", _7 as "title", _8 as "wrapper" }

    if(module.hot) {
      // 1733466104548
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  