// extracted by mini-css-extract-plugin
var _1 = "Penalty-module__button__WCuDI";
var _2 = "Penalty-module__card__cwDQi";
var _3 = "Penalty-module__cardSeparator__MziAy";
var _4 = "Penalty-module__content__NsFUK";
var _5 = "Penalty-module__empty__zLK38";
var _6 = "Penalty-module__header__yp_86";
var _7 = "Penalty-module__inactive__rP2cD";
var _8 = "Penalty-module__line__RfzAM";
var _9 = "Penalty-module__list__LlBFR";
var _a = "Penalty-module__penalty__kjGHA";
var _b = "Penalty-module__search__LLzsb";
var _c = "Penalty-module__searchIcon__MJX_p";
var _d = "Penalty-module__separator__zF5AD";
var _e = "Penalty-module__switchBlock__q817k";
var _f = "Penalty-module__switchIcon__pOJBx";
var _10 = "Penalty-module__switchText___DBr6";
var _11 = "Penalty-module__text__fLIzX";
var _12 = "Penalty-module__wrap__AIZM5";
var _13 = "Penalty-module__wrapperButton__YdsQD";
var _14 = "Penalty-module__wrapperButtonHidden__pc3rr";
export { _1 as "button", _2 as "card", _3 as "cardSeparator", _4 as "content", _5 as "empty", _6 as "header", _7 as "inactive", _8 as "line", _9 as "list", _a as "penalty", _b as "search", _c as "searchIcon", _d as "separator", _e as "switchBlock", _f as "switchIcon", _10 as "switchText", _11 as "text", _12 as "wrap", _13 as "wrapperButton", _14 as "wrapperButtonHidden" }

    if(module.hot) {
      // 1733466104557
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  