import React, { useState } from 'react';
import * as styles from './FinalBlock.module.scss';
import { NavLink } from 'react-router-dom';
import LogoWeb from '@assets/images/LogoWeb.svg';
import { ROUTES } from '@routes/constants';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import cn from 'classnames';
import FinalBlockAgency from '@assets/images/finalBlockAgency.png';
import FinalBlockExpert from '@assets/images/finalBlockExpert.png';

const MAIN_TEXT_EXPERT = 'Sign up today and start working now!';
const MAIN_TEXT_AGENCY = 'Sign up today and hire experts now!';

type FinalBlockProps = {
  isExpert: boolean;
};

export default function FinalBlock(props: FinalBlockProps) {
  const { isExpert } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.textBlock}>
          <h2 className={cn(styles.title, isExpert ? styles.supplemental : styles.inert)}>
            Ready to Get Started?
          </h2>
          <p className={styles.mainText}>{isExpert ? MAIN_TEXT_EXPERT : MAIN_TEXT_AGENCY}</p>
          <NavLink to={ROUTES.REGISTRATION} className={styles.link}>
            <Button
              className={styles.button}
              bgColor={
                isFocus
                  ? ButtonBgColor.grey
                  : isExpert
                    ? ButtonBgColor.supplemental
                    : ButtonBgColor.inert
              }
              onMouseEnter={() => setIsFocus(true)}
              onMouseLeave={() => setIsFocus(false)}
            >
              Register now
            </Button>
          </NavLink>
        </div>
        <div
          className={styles.pictureBlock}
          style={{
            backgroundImage: isExpert ? `url(${FinalBlockExpert})` : `url(${FinalBlockAgency})`,
          }}
        ></div>
      </section>
      <section className={cn(styles.wrapper, styles.androidBlock)}>
        <LogoWeb />
        <div className={styles.androidText}>Android App</div>
        <a
          className={styles.androidLink}
          href='https://experthub.app/android'
          target='_blank'
          rel='noreferrer'
        >
          Download
        </a>
      </section>
    </>
  );
}
