// extracted by mini-css-extract-plugin
var _1 = "ReviewsCardExpert-module__img__m6JN4";
var _2 = "ReviewsCardExpert-module__text__pqHjq";
var _3 = "ReviewsCardExpert-module__title__oF54F";
var _4 = "ReviewsCardExpert-module__wrapper__JQw53";
export { _1 as "img", _2 as "text", _3 as "title", _4 as "wrapper" }

    if(module.hot) {
      // 1733466109635
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  