import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Container from '@layout/Container/Container';
import { useAppSelector } from '@core/hooks/redux';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import Menu from '@assets/images/menu.svg';
import Case from '@assets/images/case.svg';
import Payouts from '@assets/images/card.svg';
import Agency from '@assets/images/properties.svg';
import Profile from '@assets/images/person.svg';
import LogOut from '@assets/images/logOut.svg';
import Logo from '@assets/images/logo.svg';
import Cancel from '@assets/images/cancel.svg';
import * as styles from './Navigation.module.scss';
import { useActions } from '@core/hooks/actions';
import LogOutModal from '../LogoutModal/LogoutModal';
import ErrorModal from '@components/ErrorModal/ErrorModal';

function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleToggle = useCallback(() => setIsActive(!isActive), [isActive]);
  const [modal, setModal] = useState(false);

  const { selfSuspended, workBlocks } = useAppSelector((state) => state.generalInfoReducer);
  const { selfSuspended: selfSuspendedPartnership, workBlocks: workBlocksPartnership } =
    useAppSelector((state) => state.partnershipReducer);
  const isActiveProfile = !selfSuspended && !workBlocks?.length;
  const isActivePartnership = !selfSuspendedPartnership && !workBlocksPartnership?.length;
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const { logOut, setError } = useActions();

  useEffect(() => {
    if (!isActiveProfile && isActive) {
      setError({
        status: 404,
        data: {
          detail: 'Your account has been suspended, go to your profile to find out the reasons.',
        },
      });
    }
  }, [isActiveProfile, isActive]);

  return (
    <>
      {!isActive && (
        <div className={styles.menuBlock}>
          <Menu className={styles.menuIcon} onClick={handleToggle} />
          {!isActivePartnership && <span className={styles.errorBadge}></span>}
        </div>
      )}
      {isActive && (
        <div className={styles.menu}>
          <Container>
            <div className={styles.header}>
              <Logo />
              <Cancel className={styles.menuIcon} onClick={handleToggle} />
            </div>
            <div className={styles.content}>
              <div onClick={!isActiveProfile ? () => setModal(true) : undefined}>
                <NavLink
                  className={cn(styles.item, { [styles.profileError]: !isActiveProfile })}
                  to={ROUTES.JOBS}
                  onClick={() => setIsActive(false)}
                >
                  <Case
                    fill={
                      !isActiveProfile
                        ? 'var(--light_accent_negative)'
                        : 'var(--light_label_primary)'
                    }
                  />
                  <span className={cn(styles.title, { [styles.profileError]: !isActiveProfile })}>
                    Jobs
                  </span>
                </NavLink>
                <NavLink
                  className={cn(styles.item, { [styles.profileError]: !isActiveProfile })}
                  to={ROUTES.PAYOUTS}
                  onClick={() => setIsActive(false)}
                >
                  <Payouts
                    fill={
                      !isActiveProfile
                        ? 'var(--light_accent_negative)'
                        : 'var(--light_label_primary)'
                    }
                  />
                  <span className={cn(styles.title, { [styles.profileError]: !isActiveProfile })}>
                    Payouts
                  </span>
                </NavLink>
                <NavLink
                  className={cn(styles.item, { [styles.profileError]: !isActiveProfile })}
                  to={ROUTES.PARTNERSHIP}
                  onClick={() => setIsActive(false)}
                >
                  <Agency
                    fill={
                      !isActiveProfile
                        ? 'var(--light_accent_negative)'
                        : 'var(--light_label_primary)'
                    }
                  />
                  <div className={styles.link}>
                    <span className={cn(styles.title, { [styles.profileError]: !isActiveProfile })}>
                      Partnership (New)
                    </span>
                    {!isActivePartnership && <span className={styles.errorBadge}></span>}
                  </div>
                </NavLink>
                <NavLink
                  className={cn(styles.item, { [styles.profileError]: !isActiveProfile })}
                  to={ROUTES.AGENCIES}
                  onClick={() => setIsActive(false)}
                >
                  <Agency
                    fill={
                      !isActiveProfile
                        ? 'var(--light_accent_negative)'
                        : 'var(--light_label_primary)'
                    }
                  />
                  <span className={cn(styles.title, { [styles.profileError]: !isActiveProfile })}>
                    Agency (Old)
                  </span>
                </NavLink>
              </div>
              <NavLink
                className={styles.item}
                to={ROUTES.PROFILE}
                onClick={() => setIsActive(false)}
              >
                <Profile fill='var(--light_label_primary)' />
                <span className={styles.title}>Profile</span>
              </NavLink>
              <div className={styles.item} onClick={() => setLogoutModal(true)}>
                <LogOut fill='var(--light_label_primary)' />
                <span className={styles.title}>Log out</span>
              </div>
            </div>
          </Container>
        </div>
      )}
      {modal && (
        <ErrorModal
          setIsShown={setModal}
          title='Your account has been suspended!'
          errorMessage='Your account has been suspended, go to your profile to find out the reasons.'
        />
      )}
      {logoutModal && (
        <LogOutModal setIsShown={setLogoutModal} setIsActive={setIsActive} logout={logOut} />
      )}
    </>
  );
}

export default Navigation;
