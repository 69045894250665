import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery, customFetchBaseQuery } from './mainService';
import {
  TAddressData,
  TAddressResponse,
  TAddressDataForm,
  TTechnician,
  TTechnicianData,
  TTechnicianPatchData,
  TTechnicianResponse,
  TAccountActivateStatusData,
  TWorkingAs,
  TWorkingAsData,
  TWorkBlock,
} from '../models/profile';
import { createTechnician, createAddress } from './utils/profileUtils';
import {
  TBankDetails,
  TBankDetailsResponse,
  TBankDetailsData,
  AccountType,
  FinanceType,
} from '../models/bankDetails';
import {
  TSchedule,
  TScheduleResponse,
  TScheduleRequestData,
  TScheduleUpdateData,
} from '../models/schedule';
import { formatDateToHhMm } from '@core/utils/date';
import { serviceAreasService } from './serviceAreasService';
import { jobTypesService } from './jobTypesService';
import { documentsService } from './documentsService';

enum ApiPaths {
  TECHNICIAN_DATA = '/api/technicians/',
  ADDRESS = '/api/location/addresses/',
  DEFAULT_SCHEDULE = '/api/workflow/default-schedules/',
  BANK_DETAILS = '/api/technicians/technician_id/bank-details/',
  WORK_BLOCKS = '/api/technicians/technician_id/work-blocks/',
}

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery: customFetchBaseQuery(baseQuery),
  endpoints: (build) => ({
    ...documentsService(build),
    ...serviceAreasService(build),
    ...jobTypesService(build),
    getTechnicianData: build.query<TTechnicianData, { id: number }>({
      query: ({ id }) => `${ApiPaths.TECHNICIAN_DATA}${id}/`,
      transformResponse: (response: TTechnicianResponse) => {
        const technicianData = {
          technician: createTechnician(response),
          isActive: response.is_active,
          workingAs: response.working_as,
        };

        return technicianData;
      },
    }),
    updateTechnicianData: build.mutation<TTechnician, TTechnicianPatchData>({
      query: ({ id, body }: TTechnicianPatchData) => {
        return {
          url: `${ApiPaths.TECHNICIAN_DATA}${id}/`,
          method: 'PATCH',
          body: body,
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const patchResult = dispatch(
            profileAPI.util.updateQueryData('getTechnicianData', { id }, (draft) => {
              console.log('data', data);
              console.log('draft', JSON.stringify(draft));
            }),
          );
          console.log('patchResult', patchResult);
        } catch {
          console.log('error');
        }
      },
      transformResponse: (response: TTechnicianResponse): TTechnician => {
        return createTechnician(response);
      },
    }),
    updateTechnicianActivateAccountStatus: build.mutation<
      TTechnicianResponse,
      TAccountActivateStatusData
    >({
      query: ({ id, selfSuspended }: TAccountActivateStatusData) => {
        return {
          url: `${ApiPaths.TECHNICIAN_DATA}${id}/`,
          method: 'PATCH',
          body: { self_suspended: selfSuspended },
        };
      },
    }),
    updateWorkingAsData: build.mutation<TWorkingAs, TWorkingAsData>({
      query: ({ id, workingAs }: TWorkingAsData) => {
        return {
          url: `${ApiPaths.TECHNICIAN_DATA}${id}/`,
          method: 'PATCH',
          body: { working_as: workingAs },
        };
      },
      transformResponse: (response: TTechnicianResponse): TWorkingAs => {
        return { workingAs: response.working_as };
      },
    }),
    updateAddressData: build.mutation<void, { id: number; address_new: number }>({
      query: ({ id, address_new }) => {
        return {
          url: `${ApiPaths.TECHNICIAN_DATA}${id}/`,
          method: 'PATCH',
          body: { address_new },
        };
      },
    }),
    getAddress: build.query<TAddressData, { id: number }>({
      query: ({ id }) => `${ApiPaths.ADDRESS}?technician_id=${id}`,
      transformResponse: (response: TAddressResponse): TAddressData => {
        return createAddress(response);
      },
    }),
    updateAddress: build.mutation<TAddressData, TAddressDataForm>({
      query: (body: TAddressDataForm) => {
        return {
          url: ApiPaths.ADDRESS,
          method: 'POST',
          body: {
            street: body.street,
            apartment: body.apartment,
            city: body.city,
            state_code: body.stateCode,
            postal_code: body.postalCode,
          },
        };
      },
      transformResponse: (response: TAddressResponse): TAddressData => {
        return createAddress(response);
      },
    }),
    getSchedule: build.mutation<TSchedule, TScheduleRequestData>({
      query: (data) => {
        return {
          url: ApiPaths.DEFAULT_SCHEDULE,
          method: 'POST',
          body: {
            technician_id: data.id,
          },
        };
      },
      transformResponse: (response: TScheduleResponse): TSchedule => {
        const data = response.days;
        const scheduleList = data.map((item) => {
          return {
            id: item.id,
            name: item.week_day_name,
            jobs: item.jobs_amount,
            day: item.time_shifts.map((times) => ({
              id: times.id,
              start: formatDateToHhMm(times.start_time),
              end: formatDateToHhMm(times.end_time),
              createdAt: times.created_at,
              isDeleted: times.is_deleting,
              deleteTimeout: times.delete_timeout,
            })),
          };
        });
        return { id: response.id, list: scheduleList };
      },
    }),
    updateSchedule: build.mutation<TSchedule, TScheduleUpdateData>({
      query: (data) => {
        const scheduleList = data.list.map((item) => {
          return {
            id: item.id,
            week_day_name: item.name,
            jobs_amount: item.jobs,
            time_shifts: item.day.map((times) => ({
              id: times.id,
              start_time: times.start,
              end_time: times.end,
              created_at: times.createdAt,
              is_deleting: times.isDeleted || false,
              delete_timeout: times.deleteTimeout || null,
            })),
          };
        });
        return {
          url: `${ApiPaths.DEFAULT_SCHEDULE}${data.id}/`,
          method: 'PUT',
          body: {
            technician_id: data.technicianId,
            days: scheduleList,
          },
        };
      },
      transformResponse: (response: TScheduleResponse): TSchedule => {
        const data = response.days;
        const scheduleList = data.map((item) => {
          return {
            id: item.id,
            name: item.week_day_name,
            jobs: item.jobs_amount,
            day: item.time_shifts.map((times) => ({
              id: times.id,
              start: formatDateToHhMm(times.start_time),
              end: formatDateToHhMm(times.end_time),
              createdAt: times.created_at,
              isDeleted: times.is_deleting,
              deleteTimeout: times.delete_timeout,
            })),
          };
        });
        return { id: response.id, list: scheduleList };
      },
    }),
    getBankDetails: build.query<TBankDetails, { id: number }>({
      query: ({ id }) => ApiPaths.BANK_DETAILS.replace(/technician_id/gi, String(id)),
      transformResponse: (response: TBankDetailsResponse[]): TBankDetails =>
        response.length
          ? {
              id: response[0].id,
              createdAt: response[0].created_at,
              accountType: response[0].account_general_type,
              financeType: response[0].account_finance_type,
              accountNumber: response[0].account_number,
              routingNumber: response[0].routing_number,
            }
          : {
              id: NaN,
              createdAt: '',
              accountType: AccountType.business,
              financeType: FinanceType.checking,
              accountNumber: '',
              routingNumber: '',
            },
    }),
    updateBankDetails: build.mutation<TBankDetails, TBankDetailsData>({
      query: (data) => {
        return {
          url: ApiPaths.BANK_DETAILS.replace(/technician_id/gi, String(data.id)),
          method: 'POST',
          body: {
            account_general_type: data.accountType,
            account_finance_type: data.financeType,
            account_number: Number(data.accountNumber),
            routing_number: Number(data.routingNumber),
          },
        };
      },
      transformResponse: (response: TBankDetailsResponse): TBankDetails => ({
        id: response.id,
        createdAt: response.created_at,
        accountType: response.account_general_type,
        financeType: response.account_finance_type,
        accountNumber: response.account_number,
        routingNumber: response.routing_number,
      }),
    }),
    getWorkBlocks: build.query<TWorkBlock[], { id: number }>({
      query: ({ id }) => ApiPaths.WORK_BLOCKS.replace(/technician_id/gi, String(id)),
    }),
  }),
});
