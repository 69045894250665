import React, { useCallback, useState } from 'react';
import * as styles from './ListPostals.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import ArrowDown from '@assets/images/arrowDown.svg';
import Timer from '@assets/images/timer.svg';
import { TWorkArea } from '@core/redux/models/serviceAreas';
import Postal from './Postal/Postal';
import cn from 'classnames';
import { isElementVisible } from '../utils';

type TListPostalsProps = {
  name: string;
  workAreas: TWorkArea[];
  isDelete: boolean;
  indexCity: number;
  setIndexOpenList: (val: number | null) => void;
};

export default function ListPostals(props: TListPostalsProps) {
  const { name, workAreas, isDelete, indexCity, setIndexOpenList } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFullPostalList, setIsFullPostalList] = useState<boolean>(false);

  const toggleList = useCallback(() => {
    setIndexOpenList(isOpen ? null : indexCity);
    setIsOpen(!isOpen);
  }, [isOpen]);

  const togglePostalList = useCallback(() => {
    setIsFullPostalList(!isFullPostalList);
  }, [isFullPostalList]);

  return (
    <div className={styles.listPostal}>
      <Button
        className={styles.button}
        mode={ButtonMode.standard}
        bgColor={ButtonBgColor.white}
        onClick={toggleList}
      >
        <div className={styles.wrapperCity}>
          <p className={styles.cityName}>{name}</p>
          <div className={styles.wrapperIcons}>
            {!isOpen && isDelete && <Timer fill='var(--light_accent_neutral)' />}
            <ArrowDown
              fill='var(--light_label_secondary)'
              className={cn(styles.arrow, isOpen ? styles.arrowRotated : '')}
            />
          </div>
        </div>
      </Button>
      <div className={isOpen ? styles.listPostalsOpen : styles.listPostalsClosed}>
        <ul className={styles.wrapperPostals}>
          {workAreas.map(
            (workArea: TWorkArea, index: number): React.JSX.Element => (
              <li
                key={workArea.googleUid}
                className={cn(
                  styles.choicer,
                  isElementVisible(index, isFullPostalList, workAreas)
                    ? styles.visible
                    : styles.hidden,
                )}
              >
                <Postal workArea={workArea} />
              </li>
            ),
          )}
        </ul>
        {workAreas.length > 5 && (
          <div className={cn(styles.wrapperButton, { [styles.wrapperButtonHidden]: !isOpen })}>
            <Button
              bgColor={ButtonBgColor.transparent}
              className={styles.buttonShow}
              onClick={togglePostalList}
            >
              {isFullPostalList ? 'Show less' : 'Show more'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
