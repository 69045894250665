// extracted by mini-css-extract-plugin
var _1 = "Documents-module__button__W3kDr";
var _2 = "Documents-module__buttonHidden__c8S45";
var _3 = "Documents-module__contactIcon__SzzSg";
var _4 = "Documents-module__content__SpJxS";
var _5 = "Documents-module__documentHeader__iEpUO";
var _6 = "Documents-module__documentType__Ibmq9";
var _7 = "Documents-module__documentsWrapper__Q3N6z";
var _8 = "Documents-module__header__laoW0";
var _9 = "Documents-module__separator__IPXoH";
var _a = "Documents-module__skeletonItem__TqfWS";
var _b = "Documents-module__skeletonWorking__qX49O";
var _c = "Documents-module__text__CtiOH";
var _d = "Documents-module__title__iUPaj";
var _e = "Documents-module__wrap__YmZ8P";
var _f = "Documents-module__wrapperTitle__YDTav";
export { _1 as "button", _2 as "buttonHidden", _3 as "contactIcon", _4 as "content", _5 as "documentHeader", _6 as "documentType", _7 as "documentsWrapper", _8 as "header", _9 as "separator", _a as "skeletonItem", _b as "skeletonWorking", _c as "text", _d as "title", _e as "wrap", _f as "wrapperTitle" }

    if(module.hot) {
      // 1733466104999
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  