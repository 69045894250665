export enum TechnicianWorkTypeStatus {
  new = 'new',
  old = 'old',
}

export type TApplianceType = {
  id: number;
  title: string;
};

export type TApplianceTypes = TApplianceType[];

export type TAppliance = {
  id: number;
  title: string;
};

type TAppliances = TAppliance[];

export type TApplianceGroup = {
  id: number;
  title: string;
  appliances: TAppliances;
};

export type TApplianceGroups = TApplianceGroup[];

export type TWorkType = {
  appliance: number;
  appliance_title: string;
  code: string;
  id: number;
};

export type TWorkTypes = TWorkType[];

export type TCommunicationTypeWithAppliances = {
  applianceIdList: number[];
  type: TApplianceType;
};

export type TWorkTypesWithAppliances = TWorkTypeWithAppliances[];

export type TWorkTypeWithAppliances = {
  id: number;
  title: string;
  types: TApplianceTypes;
};

export type TWorkTypesResponse = {
  appliance_types: { id: number; title: string }[];
  id: number;
  kind: string;
  title: string;
}[];

export type TCommunicationTypesWithAppliances = {
  [key: number]: TCommunicationTypeWithAppliances;
};

export type TTechniciansWorkTypesResponse = {
  id: number;
  work_type: TWorkType;
  dad: number;
  is_active: boolean;
  is_deleting: boolean;
  delete_timeout: string | null;
}[];

export type TTechniciansWorkTypesV2 = {
  items: TTechniciansWorkTypeV2[];
  isActiveList: boolean;
  isDeletingList: boolean;
  deleteTimeoutList: null | string;
};

export type TTechniciansWorkTypeV2 = {
  id: number;
  workType: TWorkType;
  dad: number;
  deleteTimeout: null | string;
  isDeleting: boolean;
  isActive: boolean;
  typeId: number;
};

export type TTechniciansSubWorkType = {
  id?: number;
  workType: TWorkType;
  dad: number;
  deleteTimeout: null | string;
  isDeleting: boolean;
};

export type TTechniciansWorkSubTypes = {
  [key: string]: TTechniciansSubWorkType;
};

export type TTechniciansWorkType = {
  techniciansWorkSubTypes: TTechniciansWorkSubTypes;
  isDeleting: boolean;
  deleteTimeout: null | string;
  status: TechnicianWorkTypeStatus;
};

export type TTechniciansWorkTypes = {
  [key: string]: TTechniciansWorkType;
};

export type TUpdateTechniciansWorkType = {
  work_type_id: number;
  dad: number;
  is_deleting: boolean;
  is_active: boolean;
};

export type TUpdateTechniciansWorkTypesRequest = {
  technician_id: number;
  type_id: number;
  workTypeList: TUpdateTechniciansWorkType[];
};

export type TWorkDAD = {
  dad: number;
  groupId: number;
  subGroupId: number;
  subGroupTitle: string;
  typeId: number;
  typeTitle: string;
};

export type TWorkDADs = TWorkDAD[];

export type TUpdateDAD = {
  dad: number;
  workId: number;
};

export type TAddTechniciansWorkType = {
  key: string;
  newTechniciansWorkType: TTechniciansWorkType;
};

export type TDrumOption = {
  indexTime: number;
  workId: number;
  isDrum: boolean;
};
