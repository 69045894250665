import { TApplianceTypeOptions, TDocument } from '@core/redux/models/partnership';
import { ModuleAction, TWorkBlock } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TPartnershipState = {
  partnershipDocumentsAction: ModuleAction;
  partnershipDocuments: Record<string, TDocument[]>;
  selfSuspended: boolean;
  workBlocks: TWorkBlock[];
  isUpdateWorkBlocks: boolean;
  appliance: string;
  applianceType: TApplianceTypeOptions | '';
  workTypeId: number;
};

const initialState: TPartnershipState = {
  partnershipDocumentsAction: ModuleAction.send,
  partnershipDocuments: {},
  selfSuspended: false,
  workBlocks: [],
  isUpdateWorkBlocks: false,
  appliance: '',
  applianceType: '',
  workTypeId: NaN,
};

export const partnershipSlice = createSlice({
  name: 'partnership',
  initialState,
  reducers: {
    setPartnershipUpdateActivateStatus(state, action: PayloadAction<boolean>) {
      state.selfSuspended = action.payload;
    },
    setPartnershipIsUpdateWorkBlocks(state, action: PayloadAction<boolean>) {
      state.isUpdateWorkBlocks = action.payload;
    },
    setPartnershipWorkBlocks(state, action: PayloadAction<TWorkBlock[]>) {
      state.workBlocks = action.payload;
    },
    setPartnershipDocumentsAction(state, action: PayloadAction<ModuleAction>) {
      state.partnershipDocumentsAction = action.payload;
    },
    setPartnershipDocuments(
      state,
      action: PayloadAction<{ type: string; documents: TDocument[] }>,
    ) {
      const test = action.payload.type;
      state.partnershipDocuments[test] = [...action.payload.documents];
    },
    deletePartnershipDocument(state, action: PayloadAction<{ type: string; id: number }>) {
      state.partnershipDocuments[action.payload.type] = state.partnershipDocuments[
        action.payload.type
      ].filter((item) => item.id !== action.payload.id);
    },
    setAppliance(state, action: PayloadAction<string>) {
      state.appliance = action.payload;
    },
    setApplianceType(state, action: PayloadAction<TApplianceTypeOptions | ''>) {
      state.applianceType = action.payload;
    },
    setWorkTypeId(state, action: PayloadAction<number>) {
      state.workTypeId = action.payload;
    },
  },
});

export const partnershipActions = partnershipSlice.actions;
export const partnershipReducer = partnershipSlice.reducer;
