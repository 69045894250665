// extracted by mini-css-extract-plugin
var _1 = "DocumentsInfoModal-module__block__AvWcE";
var _2 = "DocumentsInfoModal-module__header__p6r21";
var _3 = "DocumentsInfoModal-module__item__AcnU2";
var _4 = "DocumentsInfoModal-module__label__lYBdU";
var _5 = "DocumentsInfoModal-module__modal__qGwrM";
var _6 = "DocumentsInfoModal-module__module__edkbq";
var _7 = "DocumentsInfoModal-module__separator__ag820";
var _8 = "DocumentsInfoModal-module__title__l0w9Q";
var _9 = "DocumentsInfoModal-module__value__IMl7k";
export { _1 as "block", _2 as "header", _3 as "item", _4 as "label", _5 as "modal", _6 as "module", _7 as "separator", _8 as "title", _9 as "value" }

    if(module.hot) {
      // 1733466109484
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  