import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useActions } from '@core/hooks/actions';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import { useAppSelector } from '@core/hooks/redux';
import { payoutsAPI } from '@core/redux/services/payoutsService';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import ArrowUpIcon from '@assets/images/arrow-up.svg';
import * as styles from './ClaimModal.module.scss';
import { useCurrentUser, usePartershipUser } from '@core/hooks/currentUser';
import { TServerError } from '@core/utils/error';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';

export default function ClaimModal() {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { userId } = useCurrentUser();
  const partnership = usePartershipUser();
  const [value, setValue] = useState<string>('');

  const [trigger, { data, error: claimsError }] = payoutsAPI.useLazyGetClaimsMessagesQuery({
    pollingInterval: 10000,
  });

  const { payoutRow, customInvoiceNumber, activeClaim } = useAppSelector(
    (state) => state.payoutsReducer,
  );
  const [isActiveClaim] = useState<boolean>(activeClaim);
  const { setClaimModal, setError } = useActions();

  const currentInvoiceNumber = customInvoiceNumber ? customInvoiceNumber : payoutRow.invoice;

  useEffect(() => {
    if (partnership && currentInvoiceNumber) {
      trigger({
        partnership_id: partnership.id,
        invoiceNumber: currentInvoiceNumber,
      });
    }
  }, [partnership, payoutRow.invoice]);

  const [sendMessage, { error: sendMessageError, isLoading: isLoadingSendMessage }] =
    payoutsAPI.useSendClaimMessageMutation();
  const [closeClaim, { isLoading: isLoadingCloseClaim }] = payoutsAPI.useCloseClaimMutation();

  const handleSendMessage = useCallback(async () => {
    if (!partnership || !value) {
      return;
    }
    try {
      const result = await sendMessage({
        partnership_id: partnership.id,
        invoiceNumber: currentInvoiceNumber,
        text: value.trim(),
      });
      setValue('');
      return result;
    } catch (e) {
      console.log(e);
    }
  }, [partnership, value, payoutRow]);

  const handleConfirm = useCallback(async () => {
    if (!partnership || !data) {
      return;
    }
    try {
      const claimId = data[0].claimId;
      const result = await closeClaim({
        partnership_id: partnership.id,
        is_active: false,
        claim_id: claimId,
      });
      if ('data' in result) {
        setConfirmModal(false);
        setClaimModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [partnership, data]);

  useEffect(() => {
    if (claimsError) {
      setError(claimsError as TServerError);
    }
  }, [claimsError]);

  return (
    <ModalPage
      className={styles.modal}
      header={
        <div className={styles.header}>
          <div className={styles.title}>Claim for {currentInvoiceNumber}</div>
          <Button
            className={styles.modalButton}
            size={ButtonSize.s}
            disabled={!data?.length || !data[0].claimId || !isActiveClaim}
            bgColor={ButtonBgColor.grey}
            onClick={() => setConfirmModal(true)}
          >
            {isActiveClaim ? 'Close claim' : 'Closed'}
          </Button>
        </div>
      }
      setIsShown={setClaimModal}
      mode={ModalPageMode.bottomSheet}
      closeable
    >
      <div className={styles.chat}>
        <div className={styles.messages}>
          {!!data &&
            !!data.length &&
            data.map((item) => (
              <div key={`message-${item.createdBy}-${Math.random()}`}>
                <div className={cn(styles.text, { [styles.autor]: item.createdBy === userId })}>
                  <div className={styles.textTitle}>{item.createdByName}</div>
                  {item.text}
                </div>
              </div>
            ))}
          {sendMessageError && <div>Message was not send</div>}
        </div>
        <div className={styles.messageBlock}>
          <textarea
            className={styles.message}
            placeholder='Message...'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {!!value.length && (
            <Button
              mode={ButtonMode.icon}
              bgColor={ButtonBgColor.green}
              onClick={handleSendMessage}
              disabled={isLoadingSendMessage}
            >
              <ArrowUpIcon fill='var(--light-background-primary)' />
            </Button>
          )}
        </div>
      </div>
      {confirmModal && (
        <ConfirmModalPage
          setIsShown={setConfirmModal}
          text='Are you sure you want to close the claim?'
          header={`Close claim for ${currentInvoiceNumber}?`}
          handleConfirm={handleConfirm}
          confirmLoading={isLoadingCloseClaim}
        />
      )}
    </ModalPage>
  );
}
