// extracted by mini-css-extract-plugin
var _1 = "ClaimsWrapper-module__spinner__maMxP";
export { _1 as "spinner" }

    if(module.hot) {
      // 1733466103438
      var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  