import { TDrumOption, TTechniciansWorkTypesV2, TUpdateDAD } from '@core/redux/models/jobTypes';
import { ModuleAction } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TJobTypesState = {
  jobTypesAction: ModuleAction;
  selectedWorkTypeIndex: number;
  techniciansWorkTypes: TTechniciansWorkTypesV2;
  isTimeError: boolean;
  position: number;
  drumOption: TDrumOption;
  isCheck: boolean;
  initialStateIsCheck: boolean;
  techniciansWorkTypesData: { [key: number]: TTechniciansWorkTypesV2 };
};

const initialState: TJobTypesState = {
  jobTypesAction: ModuleAction.send,
  selectedWorkTypeIndex: 0,
  techniciansWorkTypes: {
    items: [],
    isActiveList: false,
    isDeletingList: false,
    deleteTimeoutList: null,
  },
  isTimeError: false,
  position: 0,
  drumOption: {
    indexTime: 0,
    workId: 0,
    isDrum: false,
  },
  isCheck: false,
  techniciansWorkTypesData: {},
  initialStateIsCheck: false,
};

export const jobTypesSlice = createSlice({
  name: 'jobTypes',
  initialState,
  reducers: {
    setJobTypesAction(state, action: PayloadAction<ModuleAction>) {
      state.jobTypesAction = action.payload;
    },
    setTechniciansWorkTypes(
      state,
      action: PayloadAction<{ list: TTechniciansWorkTypesV2; type: number }>,
    ) {
      state.techniciansWorkTypesData = {
        ...state.techniciansWorkTypesData,
        [action.payload.type]: action.payload.list,
      };
    },
    setIsDeletingList(state, action: PayloadAction<boolean>) {
      state.techniciansWorkTypes.isDeletingList = action.payload;
      state.jobTypesAction = ModuleAction.change;
    },
    setIsTimeError(state, action: PayloadAction<boolean>) {
      state.isTimeError = action.payload;
    },
    updateWorkDAD(state, action: PayloadAction<{ dad: TUpdateDAD; type: number }>) {
      state.techniciansWorkTypesData[action.payload.type].items = state.techniciansWorkTypesData[
        action.payload.type
      ].items.map((item) => {
        if (item.id === action.payload.dad.workId)
          return {
            ...item,
            dad: action.payload.dad.dad,
          };
        return {
          ...item,
        };
      });
    },
    setWorkTypeIndex(state, action: PayloadAction<number>) {
      state.selectedWorkTypeIndex = action.payload;
      state.techniciansWorkTypesData = {};
    },
    setDrumOption(state, action: PayloadAction<TDrumOption>) {
      state.drumOption = action.payload;
    },

    setIsCheck(state, action: PayloadAction<{ value: boolean; isHandle: boolean }>) {
      state.isCheck = action.payload.value;
      if (action.payload.isHandle) {
        state.jobTypesAction = ModuleAction.change;
      }
    },
    setDiscardChanges(
      state,
      action: PayloadAction<{ list: TTechniciansWorkTypesV2; type: number }>,
    ) {
      state.techniciansWorkTypesData[action.payload.type].items = action.payload.list.items;
    },
    setinitialStateIsCheck(state, action: PayloadAction<boolean>) {
      state.initialStateIsCheck = action.payload;
    },
  },
});

export const jobTypesActions = jobTypesSlice.actions;
export const jobTypesReducer = jobTypesSlice.reducer;
