import React, { useState } from 'react';
import Done from '@assets/images/done20.svg';
import Input from '@components/Input/Input';
import DataPicker from '@components/DatePicker/DatePicker';
import FileUploader from '@components/FileInput/FileInput';
import { DATE_PLACEHOLDER, ONLY_LATIN_CHAR_PATTERN } from '@core/constants';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { InputMode, InputType } from '@components/Input/types';
import { Controller, Control } from 'react-hook-form';
import { TDocumentFormData, CompanyType } from '../../../../types';
import Address from '../Address/Address';
import { getPatterError } from '@core/utils/error';
import * as formStyles from '../../NewDocumentModal.module.scss';
import * as styles from './CompanyRegistrationField.module.scss';

type TCompanyRegistrationFieldProps = {
  control: Control<TDocumentFormData>;
};

export default function CompanyRegistrationField(props: TCompanyRegistrationFieldProps) {
  const [dba, setDba] = useState<boolean>(false);
  const { control } = props;
  return (
    <>
      <div className={formStyles.separatorBlock}>
        <Address control={control} />
        <Controller
          name='titleInCompany'
          control={control}
          rules={{
            required: 'This is required field',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Your title in the Business'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              className={formStyles.input}
              type={InputType.text}
              mode={InputMode.standard}
              placeholder='Your title in the Business'
              error={!!error}
              classNameError={formStyles.error}
            />
          )}
        />
        <Controller
          name='ownerFirstName'
          control={control}
          rules={{
            required: 'Owner first name is required field',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Owner first name'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              className={formStyles.input}
              type={InputType.text}
              mode={InputMode.standard}
              placeholder='Owner first name'
              error={!!error}
              classNameError={formStyles.error}
            />
          )}
        />
        <Controller
          name='ownerLastName'
          control={control}
          rules={{
            required: 'Owner last name is required field',
            pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Owner last name'),
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              className={formStyles.input}
              type={InputType.text}
              mode={InputMode.standard}
              placeholder='Owner last name'
              error={!!error}
              classNameError={formStyles.error}
            />
          )}
        />
        <div className={styles.selectBlock}>
          <Controller
            name='companyType'
            control={control}
            render={({ field: { ref, ...field } }) => (
              <CustomSelect
                className={styles.select}
                classNameSelect={styles.selectInput}
                classNameList={styles.selectList}
                value={field.value}
                onChange={field.onChange}
                options={[CompanyType.llc, CompanyType.corporation]}
                hasPlaceholder='Business Type'
              />
            )}
          />
          <Controller
            name='dba'
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <label className={styles.financeLabel}>
                  <input
                    className={styles.radioInput}
                    type='checkbox'
                    name='dba'
                    onChange={() => {
                      field.onChange(!field.value);
                      setDba(!dba);
                    }}
                    checked={field.value}
                  />
                  <span className={styles.customRadio}>
                    <Done fill='var(--light-background-primary)' />
                  </span>
                  <span>DBA</span>
                </label>
              );
            }}
          />
        </div>
        <span className={formStyles.separator}></span>
      </div>
      {dba && (
        <div className={formStyles.separatorBlock}>
          <Controller
            name='dbaCompanyName'
            control={control}
            rules={{
              required: 'DBA business name is required field',
              pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'DBA company name'),
            }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Input
                {...field}
                className={formStyles.input}
                type={InputType.text}
                mode={InputMode.standard}
                placeholder='DBA business name'
                error={!!error}
                classNameError={formStyles.error}
              />
            )}
          />
          <Controller
            name='dbaStartDate'
            control={control}
            rules={{
              required: 'DBA Start Date is required field',
            }}
            render={({ field: { ref, ...field } }) => (
              <DataPicker
                placeholder={DATE_PLACEHOLDER}
                top={'DBA start date'}
                {...field}
                value={field.value}
              />
            )}
          />
          <Controller
            name='dbaExpiration'
            control={control}
            rules={{
              required: 'DBA Exparation Date is required field',
            }}
            render={({ field: { ref, ...field } }) => (
              <DataPicker
                placeholder={DATE_PLACEHOLDER}
                top={'DBA expiration'}
                {...field}
                value={field.value}
              />
            )}
          />
          <Controller
            name='dbaFile'
            control={control}
            render={({ field: { ref, ...field } }) => (
              <FileUploader top={<span>Upload DBA file</span>} {...field} />
            )}
          />
          <span className={formStyles.separator}></span>
        </div>
      )}
    </>
  );
}
