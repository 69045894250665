import React, { useCallback } from 'react';
import { useActions } from '@core/hooks/actions';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { FORM_TYPE } from '@core/constants';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TPartnershipElement } from '@core/redux/models/payouts';

type TDocumentDeleteModalProps = {
  setIsShown: (val: boolean) => void;
  id: number | undefined;
  url: string;
  type: string;
  partnership: TPartnershipElement;
};

export default function DocumentDeleteModal(props: TDocumentDeleteModalProps) {
  const { setIsShown, id, url, type, partnership } = props;

  const { deletePartnershipDocument } = useActions();
  const currentType = FORM_TYPE[type];

  const [handleDeleteDocument, { isLoading, error }] = partnershipAPI.useDeleteDocumentMutation();

  const handleDelete = useCallback(async () => {
    if (!id) {
      throw new Error("document delete method doesn't have id");
    }
    const result = await handleDeleteDocument({ id, url, partnership_id: partnership.id });

    if ('data' in result) {
      deletePartnershipDocument({ type: currentType, id });
      setIsShown(false);
    }
  }, [id, url]);

  return (
    <>
      <ConfirmModalPage
        setIsShown={setIsShown}
        header={error ? 'error' : 'Delete document'}
        text={error ? 'error' : 'Are you sure you want to delete your document?'}
        handleConfirm={handleDelete}
        confirmLoading={isLoading}
      />
    </>
  );
}
