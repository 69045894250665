import React from 'react';
import WorkInfoCard from './WorkInfoCard/WorkInfoCard';

const FIRST_EXPERT_TITLE = 'Create Your Profile';
const SECOND_EXPERT_TITLE = 'Choose Your Work Area';
const THIRD_EXPERT_TITLE = 'Get Verified';
const FOURTH_EXPERT_TITLE = 'Start Earning';

const FIRST_EXPERT_TEXT =
  'Set up your profile in just a few minutes. Input your basic information, select the types of appliances you specialize in, and upload your credentials. Your profile will serve as your digital resume, showcasing your skills to potential employers.';
const SECOND_EXPERT_TEXT =
  'Select the geographic areas where you’re willing to work and specify your preferred job types. Whether you want to focus on specific district or cover a wider region, Expert Hub lets you customize your work preferences.';
const THIRD_EXPERT_TEXT =
  'Complete verification process to confirm your qualifications. Upload necessary documents such as your General Liability Insurance and any required state licenses. Once verified, you’ll be visible to companies looking for professionals like you.';
const FOURTH_EXPERT_TEXT =
  'Once verified, you’ll start receiving local job offers and can begin working immediately. Payments are handled directly by companies, while Expert Hub tracks all numbers and job history on the platform, ensuring full transparency.';

export default function WorkInfoExpert() {
  return (
    <>
      <WorkInfoCard number={'01'} title={FIRST_EXPERT_TITLE} text={FIRST_EXPERT_TEXT} />
      <WorkInfoCard number={'02'} title={SECOND_EXPERT_TITLE} text={SECOND_EXPERT_TEXT} />
      <WorkInfoCard number={'03'} title={THIRD_EXPERT_TITLE} text={THIRD_EXPERT_TEXT} />
      <WorkInfoCard number={'04'} title={FOURTH_EXPERT_TITLE} text={FOURTH_EXPERT_TEXT} />
    </>
  );
}
