import React, { ReactNode } from 'react';
import { useAppSelector } from '@core/hooks/redux';
import { useModuleActionType } from '@core/utils/utils';
import { TApplianceType, TWorkTypeWithAppliances } from '@core/redux/models/jobTypes';
import * as styles from './JobsChild.module.scss';
import TechniciansJobsType from '../TechniciansJobsType/TechniciansJobsType';

type TChild = {
  workType: TWorkTypeWithAppliances;
};

export default function JobsChild(props: TChild) {
  const { workType } = props;
  const { jobTypesAction } = useAppSelector((state) => state.jobTypesReducer);
  const { isSend } = useModuleActionType(jobTypesAction);
  return (
    <>
      <div key={`${workType.title}-${workType.id}`} className={styles.wrapper}>
        <h2 className={styles.title}>{workType.title}</h2>
        <ul className={styles.typesWrapper}>
          {!workType.types.length && isSend ? (
            <li className={styles.notSelected}>Not selected</li>
          ) : (
            workType.types.map(
              (type: TApplianceType): ReactNode => (
                <div key={`${type.id}-${type.title}`}>
                  <TechniciansJobsType label={type.title} type={type} title={workType.title} />
                </div>
              ),
            )
          )}
        </ul>
      </div>
    </>
  );
}
