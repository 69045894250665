import { ModuleAction } from '@core/redux/models/profile';
import {
  TServiceAreasState,
  TWorkArea,
  TNewWorkArea,
  TCenterCoordinate,
  TAfterValidate,
  WorkAreaStatus,
  TChangeWorkAreaStatus,
} from '@core/redux/models/serviceAreas';
import { useWorkAreaStatusType } from '@core/utils/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TServiceAreasState = {
  serviceAreasAction: ModuleAction.send,
  cityPostals: {},
  mapWorkAreas: [],
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 1,
  isFullScreen: false,
  infoArea: null,
  isLoadingArea: false,
};

export const serviceAreasSlice = createSlice({
  name: 'serviceAreas',
  initialState,
  reducers: {
    setWorkAreasData(state, action: PayloadAction<TWorkArea[]>) {
      state.cityPostals = {};
      state.mapWorkAreas = action.payload;
      action.payload.forEach((workArea) => {
        const { cityName, isDeleted } = workArea;

        if (!state.cityPostals[cityName]) {
          state.cityPostals[cityName] = {
            workAreas: [workArea],
            isDelete: isDeleted,
          };
        } else {
          state.cityPostals[cityName].workAreas = [
            ...state.cityPostals[cityName].workAreas,
            workArea,
          ];
        }

        if (isDeleted) {
          state.cityPostals[cityName].isDelete = isDeleted;
        }
      });
    },
    setServiceAreasAction(state, action: PayloadAction<ModuleAction>) {
      state.serviceAreasAction = action.payload;
    },
    setMapPosition(state, action: PayloadAction<TCenterCoordinate>) {
      state.center = action.payload;
    },
    setZoom(state, action: PayloadAction<number>) {
      state.zoom = action.payload;
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      state.isFullScreen = action.payload;
    },
    setInfoArea(state, action: PayloadAction<string | null>) {
      state.infoArea = action.payload;
    },
    setLoadingArea(state, action: PayloadAction<boolean>) {
      state.isLoadingArea = action.payload;
    },
    changeWorkAreaStatus(state, action: PayloadAction<TChangeWorkAreaStatus>) {
      state.mapWorkAreas = state.mapWorkAreas.map(
        (workArea: TNewWorkArea | TWorkArea): TNewWorkArea | TWorkArea => {
          if (action.payload.workArea.googleUid === workArea.googleUid) {
            workArea.status = action.payload.status;
          }

          return workArea;
        },
      );
      state.serviceAreasAction = ModuleAction.change;
    },
    changeStatusesForWorkAreas(state, action: PayloadAction<TAfterValidate>) {
      const defaultList: (TNewWorkArea | TWorkArea)[] = [];

      state.mapWorkAreas = state.mapWorkAreas.reduce(
        (
          list: (TNewWorkArea | TWorkArea)[],
          workArea: TNewWorkArea | TWorkArea,
        ): (TNewWorkArea | TWorkArea)[] => {
          const isInList = action.payload.googleUidList.includes(workArea.googleUid);
          const { isActive } = useWorkAreaStatusType(workArea.status);

          switch (true) {
            case action.payload.newWorkArea.googleUid === workArea.googleUid:
              workArea.status = action.payload.status;
              list = [...list, workArea];
              break;
            case !isInList:
              list = [...list, workArea];
              break;
            case isInList && isActive:
              workArea.status = WorkAreaStatus.newDelete;
              list = [...list, workArea];
              break;
            case isInList && workArea.isDeleted:
              workArea.status = WorkAreaStatus.delete;
              list = [...list, workArea];
              break;
          }

          return list;
        },
        defaultList,
      );
      state.serviceAreasAction = ModuleAction.change;
    },
    addNewWorkAreaAndChangeStatusesWorkAreas(state, action: PayloadAction<TAfterValidate>) {
      const defaultList: (TNewWorkArea | TWorkArea)[] = [];
      const newWorkArea: TNewWorkArea = {
        ...action.payload.newWorkArea,
        status: action.payload.status,
      };

      state.mapWorkAreas = [
        ...state.mapWorkAreas.reduce(
          (
            list: (TNewWorkArea | TWorkArea)[],
            workArea: TNewWorkArea | TWorkArea,
          ): (TNewWorkArea | TWorkArea)[] => {
            const isInList = action.payload.googleUidList.includes(workArea.googleUid);
            const { isActive } = useWorkAreaStatusType(workArea.status);

            switch (true) {
              case !isInList:
                list = [...list, workArea];
                break;
              case isInList && isActive:
                workArea.status = WorkAreaStatus.newDelete;
                list = [...list, workArea];
                break;
              case isInList && workArea.isDeleted:
                workArea.status = WorkAreaStatus.delete;
                list = [...list, workArea];
                break;
            }

            return list;
          },
          defaultList,
        ),
        newWorkArea,
      ];
      state.serviceAreasAction = ModuleAction.change;
    },
    addNewWorkArea(state, action: PayloadAction<TNewWorkArea>) {
      const newWorkAreaWithStatus = {
        ...action.payload,
        status: WorkAreaStatus.newActive,
      };

      state.mapWorkAreas = [...state.mapWorkAreas, newWorkAreaWithStatus];
      state.serviceAreasAction = ModuleAction.change;
    },
    removeNewWorkArea(state, action: PayloadAction<TNewWorkArea>) {
      state.mapWorkAreas = state.mapWorkAreas.filter(
        (workArea: TNewWorkArea | TWorkArea): boolean =>
          workArea.googleUid !== action.payload.googleUid,
      );
      state.serviceAreasAction = ModuleAction.change;
    },
  },
});

export const serviceAreasActions = serviceAreasSlice.actions;
export const serviceAreasReducer = serviceAreasSlice.reducer;
