import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './constants';

type TPrivateRoute = {
  isAuth: boolean;
};

export const PrivateRoute = (props: TPrivateRoute) => {
  const { isAuth } = props;
  return isAuth ? <Outlet /> : <Navigate to={ROUTES.MAIN} />;
};
