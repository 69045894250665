import React from 'react';
import { getDdMonthYyyyFormatDate } from '@core/utils/date';
import { TChangesPayout, TPayout } from '@core/redux/models/payouts';
import { TableColumn } from '@components/Table/types';
import { isTChangesPayout } from '../../types';
import { finalComissionNumbers, numbersWithCurrency } from '@core/utils/numbers';
import * as styles from './PaymentTable.module.scss';
import FileIcon from '@assets/images/file_with_border.svg';
import CountIcon from '@assets/images/count.svg';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import Claim from '../Claims/components/Claim/Claim';
import { useActions } from '@core/hooks/actions';

export const columns = (): TableColumn<TPayout | TChangesPayout>[] => {
  const { setPayoutRow, setHistoryModal, setCalculationModal } = useActions();
  return [
    { key: 'invoice', dataKey: 'invoice', title: 'Invoice', minWidth: 80, width: 80 },
    {
      key: 'date',
      dataKey: 'date',
      title: 'Order date',
      minWidth: 110,
      width: 110,
      cellRenderer: (row: { cellData: string }) => {
        return <span>{getDdMonthYyyyFormatDate(row.cellData)}</span>;
      },
    },
    {
      key: 'customer',
      dataKey: 'customer',
      title: 'Customer',
      minWidth: 110,
      width: 110,
      cellRender: (row: { cellData: string; rowData: TPayout }) => {
        if (row.rowData.isCustomTransaction) {
          return row.rowData.isRental ? 'Rental' : 'Custom transaction';
        }
        return row.cellData;
      },
    },
    {
      key: 'amount',
      dataKey: 'amount',
      title: 'Amount',
      minWidth: 90,
      width: 90,
      cellRenderer: (row: { cellData: string }) => <span>{'$' + row.cellData}</span>,
    },
    {
      key: 'commission',
      dataKey: 'commission',
      title: 'Commission',
      minWidth: 130,
      width: 130,
      cellRenderer: (row: { cellData: string; rowData: TPayout | TChangesPayout }) =>
        isTChangesPayout(row.rowData) ? (
          <span>
            {`${numbersWithCurrency(row.cellData)}`}&nbsp;
            <span
              className={styles.finalComissionChanges}
            >{`(${finalComissionNumbers(row.rowData.finalCommissionChanges)})`}</span>
          </span>
        ) : (
          <span>{'$' + row.cellData}</span>
        ),
    },
    {
      key: 'buttons',
      dataKey: 'buttons',
      title: 'buttons',
      minWidth: 300,
      width: 300,
      headerRenderer: () => <div>History | Calculation | Claim</div>,
      cellRenderer: (row: { rowData: TPayout }) => (
        <div className={styles.buttonBlock}>
          <Button
            onClick={() => {
              setHistoryModal(true);
              setPayoutRow(row.rowData);
            }}
            className={styles.button}
            mode={ButtonMode.icon}
            bgColor={ButtonBgColor.lightGrey}
          >
            <FileIcon fill='var(--light_accent_main)' />
          </Button>
          <Button
            onClick={() => {
              setCalculationModal(true);
              setPayoutRow(row.rowData);
            }}
            className={styles.button}
            mode={ButtonMode.icon}
            bgColor={ButtonBgColor.lightGrey}
          >
            <CountIcon fill='var(--light_accent_neutral)' />
          </Button>
          <Claim row={row.rowData} />
        </div>
      ),
    },
  ];
};
