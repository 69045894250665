import React from 'react';
import Button from '@components/Button/Button';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '@core/redux/services/authService';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import { ROUTES } from '@routes/constants';
import * as styles from './Logout.module.scss';

type LogOutModalProps = {
  setIsShown: (val: boolean) => void;
  logout: (val: string) => void;
  setIsActive: (val: boolean) => void;
};

export default function LogoutModal(props: LogOutModalProps) {
  const navigate = useNavigate();
  const [trigger] = authAPI.useLazyLogoutQuery();
  const { setIsShown, setIsActive, logout } = props;
  return (
    <ModalPage
      header={<div>Logout?</div>}
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      closeable={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.text}>Do you want to log out?</div>
        <Button
          bgColor={ButtonBgColor.red}
          size={ButtonSize.l}
          className={styles.button}
          onClick={() => {
            logout('');
            trigger();
            setIsActive(false);
            navigate(ROUTES.MAIN);
          }}
        >
          Logout
        </Button>
      </div>
    </ModalPage>
  );
}
