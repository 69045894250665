export type TTechnicianResponse = {
  id: number;
  nickname: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  address_new: number;
  is_active: boolean;
  working_as: WorkingAs;
};

export type TTechnicianRequest = {
  id: number;
  nickname: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  address_new: number;
};

export type TTechnician = {
  expertId: number;
  nickname: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export type TTechnicianForm = TTechnician;

export type TTechnicianData = {
  technician: TTechnician;
  isActive: boolean;
  workingAs: WorkingAs;
};

export enum WorkingAs {
  'Business' = 'Business',
  'Individual' = 'Individual',
  'Agency' = 'Agency',
}

export type TWorkingAs = {
  workingAs: WorkingAs;
};
export type TWorkingAsData = {
  id: number;
  workingAs: WorkingAs;
};

export type TAccountActivateStatus = {
  selfSuspended: boolean;
};

export type TAccountActivateStatusData = {
  id: number;
  selfSuspended: boolean;
};

export type TWorkBlock = {
  id: number;
  title: string;
  code: string;
};

export type TTechnicianPatchData = {
  id: number;
  body: Partial<TTechnicianRequest>;
};

export type TGetDocumentTypes = {
  id: number;
  type: string;
};

export type TAddressResponse = {
  id: number;
  street: string;
  apartment: string;
  zip_code: {
    city: {
      name: string;
      state: string;
    };
    code: string;
    suffix: string;
    google_uid: string;
  };
};

export type TAddressData = {
  id: number;
  street: string;
  apartment?: string;
  city: string;
  stateCode: string;
  postalCode: string;
  googleUid: string;
};

export type TAddressDataForm = Omit<TAddressData, 'id' | 'googleUid'>;

export enum ModuleAction {
  edit = 'edit',
  change = 'change',
  send = 'send',
}
