import React, { useCallback, useState, useEffect } from 'react';
import * as styles from './TechniciansJobsType.module.scss';
import cn from 'classnames';
import Done from '@assets/images/done20.svg';
import Timer from '@assets/images/timer.svg';
import ArrowDown from '@assets/images/arrowDown.svg';
import { useAppSelector } from '@core/hooks/redux';
import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import { TApplianceType, TTechniciansWorkTypeV2 } from '@core/redux/models/jobTypes';
import { useActions } from '@core/hooks/actions';
import ModalInfo from '@pages/Profile/components/ModalInfo/ModalInfo';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { useModuleActionType } from '@core/utils/utils';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import Spinner from '@components/Spinner/Spinner';
import DiagnosticDurationModal from '../DiagnosticDurationModal/DiagnosticDurationModal';

type JobTypeProps = {
  label: string;
  type: TApplianceType;
  title: string;
};

export default function TechniciansJobsType(props: JobTypeProps) {
  const { label, type, title } = props;
  const { technicianId } = useCurrentUser();
  const [drumModal, setDrumModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [currentTypeId, setCurrentTypeId] = useState<number>();

  const {
    data: techniciansWorkTypesV2,
    isLoading,
    isFetching,
  } = profileAPI.useGetTechniciansWorkTypesV2Query({
    technician_id: technicianId,
    type_id: type.id,
  });

  const {
    jobTypesAction,
    techniciansWorkTypes,
    isCheck,
    techniciansWorkTypesData,
    drumOption,
    initialStateIsCheck,
  } = useAppSelector((state) => state.jobTypesReducer);

  const {
    setDrumOption,
    setTechniciansWorkTypes,
    setIsCheck,
    setIsDeletingList,
    setinitialStateIsCheck,
  } = useActions();

  useEffect(() => {
    setinitialStateIsCheck(!!techniciansWorkTypesV2?.isActiveList);
  }, [initialStateIsCheck]);

  useEffect(() => {
    if (techniciansWorkTypesV2 && !isFetching) {
      setTechniciansWorkTypes({ list: techniciansWorkTypesV2, type: type.id });
    }
  }, [techniciansWorkTypesV2, isFetching]);

  useEffect(() => {
    setIsCheck({ value: techniciansWorkTypesData[type.id]?.isActiveList, isHandle: false });
  }, [techniciansWorkTypes]);

  const isDelete = techniciansWorkTypesData[type.id]?.isDeletingList;
  const isValidTimeout = typeof techniciansWorkTypesData[type.id]?.deleteTimeoutList === 'string';

  const { isEdit, isChange, isSend } = useModuleActionType(jobTypesAction);
  const handleTime = useCallback(
    (workId: number, indexTime: number | undefined, typeId: number) => {
      setCurrentTypeId(typeId);
      setDrumModal(true);
      setDrumOption({
        indexTime: indexTime ? indexTime - 10 : 0,
        workId,
        isDrum: true,
      });
    },
    [currentTypeId],
  );

  const handleConfirm = useCallback(() => {
    setIsCheck({ value: !isCheck, isHandle: true });
    setIsModalOpen(false);
  }, [isCheck]);

  const TEXT_FOR_RESTORE_WORK_TYPE = `Are you sure you want to cancel the deletion of job type “${title}”?`;
  const TEXT_FOR_DELETE_WORK_TYPE = `Are you sure you want to delete job type “${title}”? Changes will take effect in 7 days.`;

  const toggleListOpen = useCallback(() => {
    setIsListOpen(!isListOpen);
  }, [isListOpen]);

  const handleButton = useCallback(() => {
    if (!isEdit && techniciansWorkTypesData[type.id]?.deleteTimeoutList) {
      setIsModalOpen(true);
    } else if ((isEdit || isChange) && techniciansWorkTypesData[type.id]?.deleteTimeoutList) {
      setIsModalOpen(true);
    } else {
      if (techniciansWorkTypesV2?.isActiveList) {
        setIsDeletingList(true);
      }
      setIsCheck({ value: !isCheck, isHandle: true });
    }
  }, [isCheck, techniciansWorkTypesV2, techniciansWorkTypesData, isEdit]);

  return (
    <li className={cn(styles.type, { [styles.hidden]: false })}>
      <div className={styles.wrapper}>
        <p className={styles.text}>{label}</p>
        {isLoading ? (
          <Spinner size='small' />
        ) : (
          <div className={styles.buttonWrapper}>
            <Button
              mode={ButtonMode.icon}
              bgColor={ButtonBgColor.transparent}
              className={cn(styles.button, { [styles.hidden]: !isDelete && isSend })}
              onClick={handleButton}
            >
              {isCheck && (isEdit || isChange) && (
                <span
                  className={cn(
                    styles.customCheck,
                    styles.customCheckSelect,
                    (isEdit || isChange) && styles.customCheckSelectActive,
                  )}
                >
                  <Done className={styles.done} fill='var(--light-background-primary)' />
                </span>
              )}

              {isDelete && !isCheck && <Timer fill='var(--light_accent_neutral)' />}

              {!isCheck && !isDelete && (
                <span
                  className={cn(
                    styles.customCheck,
                    (isEdit || isChange) && styles.customCheckActive,
                  )}
                ></span>
              )}
            </Button>
            <Button
              mode={ButtonMode.icon}
              bgColor={ButtonBgColor.transparent}
              className={cn(styles.button, { [styles.hidden]: !isSend })}
              onClick={toggleListOpen}
            >
              <ArrowDown
                fill='var(--light_label_secondary)'
                className={cn(styles.arrow, { [styles.arrowRotated]: isListOpen })}
              />
            </Button>
          </div>
        )}
      </div>
      {(isLoading && isListOpen) ||
        (isLoading && isEdit && (
          <div className={styles.spinner}>
            <Spinner size='small' />
          </div>
        ))}
      {!isLoading && techniciansWorkTypesData[type.id]?.items?.length && (
        <ul
          className={cn(
            styles.wrapperSubTypes,
            { [styles.hidden]: isSend && !isListOpen },
            { [styles.separator]: !isSend || isListOpen },
          )}
        >
          {techniciansWorkTypesData[type.id].items.map(
            (techniciansWorkType: TTechniciansWorkTypeV2) => {
              const { workType, dad, id, typeId } = techniciansWorkType;
              return (
                <li key={`${workType.appliance}-${workType.code}-${id}`} className={styles.subType}>
                  <p className={styles.subTypeText}>{workType.appliance_title}</p>
                  <Button
                    bgColor={dad === 0 ? ButtonBgColor.red : ButtonBgColor.slightlyDarkWhite}
                    className={styles.time}
                    onClick={() => handleTime(id, dad, typeId)}
                    disabled={isSend}
                  >
                    {dad} min
                  </Button>
                </li>
              );
            },
          )}
        </ul>
      )}

      {isModalOpen && (isEdit || isChange) && (
        <ConfirmModalPage
          header={
            techniciansWorkTypesData[type.id].isDeletingList
              ? 'Cancel the deletion?'
              : 'Delete job type?'
          }
          headerClassName={styles.modalHeader}
          text={
            techniciansWorkTypesData[type.id].isDeletingList
              ? TEXT_FOR_RESTORE_WORK_TYPE
              : TEXT_FOR_DELETE_WORK_TYPE
          }
          textClassName={styles.modalText}
          handleConfirm={() => handleConfirm()}
          setIsShown={() => setIsModalOpen(false)}
          {...(techniciansWorkTypesData[type.id].isDeletingList && {
            hint: 'You can undo this deletion within 7 days.',
          })}
        />
      )}
      {isModalOpen && isSend && isValidTimeout && (
        <ModalInfo
          time={techniciansWorkTypesData[type.id].deleteTimeoutList as string}
          setIsShown={setIsModalOpen}
        />
      )}
      {currentTypeId === type.id && drumOption.isDrum && drumModal && (
        <DiagnosticDurationModal setIsShown={setDrumModal} type={currentTypeId} />
      )}
    </li>
  );
}
