// extracted by mini-css-extract-plugin
var _1 = "Kpi-module__button__mfjo2";
var _2 = "Kpi-module__buttonLeft__ki2dg";
var _3 = "Kpi-module__buttonRight__As2SC";
var _4 = "Kpi-module__graph__XIEIp";
var _5 = "Kpi-module__graphFetching__yavJ1";
var _6 = "Kpi-module__header__rf8aT";
var _7 = "Kpi-module__optionButton__Hcr8Z";
var _8 = "Kpi-module__separator__XXVlN";
var _9 = "Kpi-module__text__wUV7s";
var _a = "Kpi-module__title__FJxlX";
var _b = "Kpi-module__wrap__ypyMc";
var _c = "Kpi-module__wrapper__J_fG5";
export { _1 as "button", _2 as "buttonLeft", _3 as "buttonRight", _4 as "graph", _5 as "graphFetching", _6 as "header", _7 as "optionButton", _8 as "separator", _9 as "text", _a as "title", _b as "wrap", _c as "wrapper" }

    if(module.hot) {
      // 1733466104545
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  