export type TBankDetailsResponse = {
  id: number;
  created_at: string;
  account_general_type: AccountType;
  account_finance_type: FinanceType;
  account_number: string;
  routing_number: string;
};

export type TBankDetails = {
  id: number;
  createdAt: string;
  accountType: AccountType;
  financeType: FinanceType;
  accountNumber: string;
  routingNumber: string;
};

export enum AccountType {
  business = 'Business',
  personal = 'Personal',
}

export enum FinanceType {
  checking = 'Checking',
  savings = 'Savings',
}

export type TBankDetailsData = {
  id: number;
  accountType: AccountType;
  financeType: FinanceType;
  accountNumber: string;
  routingNumber: string;
};

export type TBankDetailsForm = {
  accountType: AccountType;
  financeType: FinanceType;
  accountNumber: string;
  routingNumber: string;
};
