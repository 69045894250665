import React, { ReactNode } from 'react';
import ReviewsCardExpert from '../ReviewsCardExpert/ReviewsCardExpert';
import FirstExpert from '@assets/images/firstExpert.png';
import SecondExpert from '@assets/images/secondExpert.png';
import ThirdExpert from '@assets/images/thirdExpert.png';
import Slider from '@components/Slider/Slider';
import * as styles from './ReviewsExpert.module.scss';
import { useMobileDetect } from '@core/hooks/windowSize';
import ArrowLeftStrokeWeb from '@assets/images/arrowLeftStrokeWeb.svg';
import ArrowRightStrokeWeb from '@assets/images/arrowRightStrokeWeb.svg';

const FIRST_REVIEW_TEXT =
  'Expert Hub has completely transformed how I find work. The process is incredibly intuitive, allowing me to set up my profile in minutes. I love that I can pick jobs that are close to home, which means I spend less time commuting and more time working. Since joining, my monthly earnings have nearly doubled, and I’ve been able to secure steady work without the usual hassle of searching for jobs. The platform truly understands the needs of technicians like me.';
const SECOND_REVIEW_TEXT =
  'Setting up my profile on Expert Hub was a breeze. The verification process was straightforward, and I was approved in just a couple of days. What I love most is how consistently I receive job offers that match my skills and preferences. Before, I used to struggle with gaps in my schedule, but now I’m always busy with work that I actually enjoy. It’s the perfect solution for technicians who want to take control of their careers.';
const THIRD_REVIEW_TEXT =
  'Managing documents and payments used to be a nightmare for me, but Expert Hub has made it so simple. All my paperwork is stored securely in one place, and I can easily update or share it with companies. The payment process is fast and transparent, so I never have to worry about delays. Thanks to Expert Hub, I can focus on doing what I do best—providing top-notch service to my clients—without getting bogged down by administrative tasks.';

const listReview: ReactNode[] = [
  <ReviewsCardExpert
    name={'James H.'}
    text={FIRST_REVIEW_TEXT}
    bgColor={'#595B61'}
    image={FirstExpert}
    key={1}
  />,
  <ReviewsCardExpert
    name={'Ivan S.'}
    text={SECOND_REVIEW_TEXT}
    bgColor={'#3E4958'}
    image={SecondExpert}
    key={2}
  />,
  <ReviewsCardExpert
    name={'Daniel M.'}
    text={THIRD_REVIEW_TEXT}
    bgColor={'#4872AB'}
    image={ThirdExpert}
    key={3}
  />,
];

export default function ReviewsExpert() {
  const isMobile = useMobileDetect();

  return (
    <Slider
      list={listReview}
      showNavigation={isMobile ? true : false}
      slidesToShow={isMobile ? 1 : 3}
      wrapperClass={styles.wrapper}
      prevButtonClass={isMobile && styles.prevButton}
      nextButtonClass={isMobile && styles.nextButton}
      nextIcon={<ArrowRightStrokeWeb fill={'var(--light_label_secondary)'} />}
      prevIcon={<ArrowLeftStrokeWeb fill={'var(--light_label_secondary)'} />}
    />
  );
}
