import React from 'react';
import * as styles from './ModalInfo.module.scss';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode } from '@components/ModalPage/types';
import { timeLeft } from '@core/utils/date';
import Info from '@assets/images/info.svg';

type ModalInfoProps = {
  time: string;
  setIsShown: (val: boolean) => void;
};

export default function ModalInfo(props: ModalInfoProps) {
  const { time, setIsShown } = props;

  return (
    <ModalPage
      className={styles.modal}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      headerClassName={styles.header}
      header={
        <div className={styles.wrapper}>
          <Info fill='var(--light_label_secondary)' />
          <p className={styles.text}>Changes will take effect in {timeLeft(time)}.</p>
        </div>
      }
    >
      <></>
    </ModalPage>
  );
}
