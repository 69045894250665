// extracted by mini-css-extract-plugin
var _1 = "Form-module__error__GbpmW";
var _2 = "Form-module__form__DYF1N";
var _3 = "Form-module__info___xM_s";
var _4 = "Form-module__input__vdD4v";
var _5 = "Form-module__inputs__B_BoD";
var _6 = "Form-module__inputsBlock__U1SbS";
var _7 = "Form-module__link__htinC";
var _8 = "Form-module__wrapper__n8udg";
export { _1 as "error", _2 as "form", _3 as "info", _4 as "input", _5 as "inputs", _6 as "inputsBlock", _7 as "link", _8 as "wrapper" }

    if(module.hot) {
      // 1733466107674
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  