// extracted by mini-css-extract-plugin
var _1 = "ConfirmPassword-module__blue__Eg_hw";
var _2 = "ConfirmPassword-module__errorWrapper__wOwBz";
var _3 = "ConfirmPassword-module__successWrapper__zJ2FO";
var _4 = "ConfirmPassword-module__text__lmogg";
var _5 = "ConfirmPassword-module__textBlock__FLIYp";
var _6 = "ConfirmPassword-module__title__k7nxD";
var _7 = "ConfirmPassword-module__wrapper__yjQXK";
export { _1 as "blue", _2 as "errorWrapper", _3 as "successWrapper", _4 as "text", _5 as "textBlock", _6 as "title", _7 as "wrapper" }

    if(module.hot) {
      // 1733466104535
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  