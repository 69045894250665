import { TDocument } from '@core/redux/models/documents';
import { ModuleAction } from '@core/redux/models/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TDocumentsState = {
  documentsAction: ModuleAction;
  documents: Record<string, TDocument[]>;
};

const initialState: TDocumentsState = {
  documentsAction: ModuleAction.send,
  documents: {},
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsAction(state, action: PayloadAction<ModuleAction>) {
      state.documentsAction = action.payload;
    },
    setDocuments(state, action: PayloadAction<{ type: string; documents: TDocument[] }>) {
      const test = action.payload.type;
      state.documents[test] = [...action.payload.documents];
    },
    deleteDocument(state, action: PayloadAction<{ type: string; id: number }>) {
      state.documents[action.payload.type] = state.documents[action.payload.type].filter(
        (item) => item.id !== action.payload.id,
      );
    },
  },
});

export const documentsActions = documentsSlice.actions;
export const documentsReducer = documentsSlice.reducer;
