import React from 'react';
import * as styles from './WorkInfoCard.module.scss';

type WorkInfoCardProps = {
  number: string;
  title: string;
  text: string;
};

export default function WorkInfoCard(props: WorkInfoCardProps) {
  const { number, title, text } = props;
  return (
    <div className={styles.wrapper}>
      <p className={styles.number}>{number}</p>
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
