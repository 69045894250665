import React from 'react';
import WorkInfoCard from './WorkInfoCard/WorkInfoCard';

const FIRST_AGENCY_TITLE = 'Register Your Agency';
const SECOND_AGENCY_TITLE = 'Post Job Offers';
const THIRD_AGENCY_TITLE = 'Manage Experts';
const FOURTH_AGENCY_TITLE = 'Hire with Confidence';

const FIRST_AGENCY_TEXT =
  'Sign up your agency on Expert Hub and start connecting with local experts. Provide your agency’s details, and set up your company profile. This profile will help experts understand who you are and the type of work you offer.';
const SECOND_AGENCY_TEXT =
  'Easily create and post job offers tailored to your specific needs. Specify the required skills, location, and job duration. Your offers will be visible to qualified experts in the selected areas, ensuring you attract the right talent.';
const THIRD_AGENCY_TEXT =
  'Keep track of your hired experts and monitor the status of ongoing jobs all in one place. Expert Hub’s management tools allow you to oversee your projects efficiently, ensuring everything runs smoothly and on time.';
const FOURTH_AGENCY_TEXT =
  'Rest assured knowing that you’re hiring only verified and qualified experts. All candidates on Expert Hub undergo a thorough verification process, so you can hire with confidence and reduce the risk of delays or issues on the job.';

export default function WorkInfoAgency() {
  return (
    <>
      <WorkInfoCard number={'01'} title={FIRST_AGENCY_TITLE} text={FIRST_AGENCY_TEXT} />
      <WorkInfoCard number={'02'} title={SECOND_AGENCY_TITLE} text={SECOND_AGENCY_TEXT} />
      <WorkInfoCard number={'03'} title={THIRD_AGENCY_TITLE} text={THIRD_AGENCY_TEXT} />
      <WorkInfoCard number={'04'} title={FOURTH_AGENCY_TITLE} text={FOURTH_AGENCY_TEXT} />
    </>
  );
}
