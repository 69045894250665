// extracted by mini-css-extract-plugin
var _1 = "ClaimsList-module__action__aJF4t";
var _2 = "ClaimsList-module__addButton__NTJnv";
var _3 = "ClaimsList-module__chats__C3Ked";
var _4 = "ClaimsList-module__close__la_bx";
var _5 = "ClaimsList-module__content__ycYNz";
var _6 = "ClaimsList-module__emptyAction__v0lDk";
var _7 = "ClaimsList-module__emptyText__A5qyM";
var _8 = "ClaimsList-module__header__AQZ6r";
var _9 = "ClaimsList-module__headerButton__YunXB";
var _a = "ClaimsList-module__item__Nc3LI";
var _b = "ClaimsList-module__itemHeader__VUymn";
var _c = "ClaimsList-module__itemText__M0ZtY";
var _d = "ClaimsList-module__link__l4Bij";
var _e = "ClaimsList-module__list__QcqQz";
var _f = "ClaimsList-module__search__BgUmV";
var _10 = "ClaimsList-module__searchIcon__NA2lc";
var _11 = "ClaimsList-module__separator__Fab8O";
var _12 = "ClaimsList-module__skeleton__wStb4";
var _13 = "ClaimsList-module__time__erqq5";
var _14 = "ClaimsList-module__wrap__eXQXf";
export { _1 as "action", _2 as "addButton", _3 as "chats", _4 as "close", _5 as "content", _6 as "emptyAction", _7 as "emptyText", _8 as "header", _9 as "headerButton", _a as "item", _b as "itemHeader", _c as "itemText", _d as "link", _e as "list", _f as "search", _10 as "searchIcon", _11 as "separator", _12 as "skeleton", _13 as "time", _14 as "wrap" }

    if(module.hot) {
      // 1733466106633
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  