import React, { ReactNode } from 'react';
import * as styles from './TitleWithButton.module.scss';
import cn from 'classnames';

type TitleWithButtonProps = {
  children: ReactNode;
  title: string;
  isStatic?: boolean;
  disabled?: boolean;
};

export default function TitleWithButton(props: TitleWithButtonProps) {
  const { title, children, isStatic, disabled } = props;
  return (
    <div className={cn(styles.wrapper, { [styles.isStatic]: isStatic })}>
      <span className={styles.separator}></span>
      <h2 className={cn(styles.title, { [styles.disabled]: disabled })}>{title}</h2>
      {children}
    </div>
  );
}
