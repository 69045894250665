// extracted by mini-css-extract-plugin
var _1 = "BankDetails-module__button__PMKVV";
var _2 = "BankDetails-module__buttonConfirmModal__P_BK6";
var _3 = "BankDetails-module__buttonText__rCaCM";
var _4 = "BankDetails-module__modalHeader__W7BYk";
var _5 = "BankDetails-module__modalText__PbLh1";
var _6 = "BankDetails-module__skeleton__lgQ8p";
var _7 = "BankDetails-module__skeletonAddressButton__t1upB";
var _8 = "BankDetails-module__skeletonForm__tGxKP";
var _9 = "BankDetails-module__wrapper__m7d2e";
export { _1 as "button", _2 as "buttonConfirmModal", _3 as "buttonText", _4 as "modalHeader", _5 as "modalText", _6 as "skeleton", _7 as "skeletonAddressButton", _8 as "skeletonForm", _9 as "wrapper" }

    if(module.hot) {
      // 1733466105091
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  