import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useActions } from '@core/hooks/actions';
import { authAPI } from '@core/redux/services/authService';
import { ROUTES } from '@routes/constants';
import { host, oldFrontCookie } from '@core/constants';

type RedirectProps = {
  link?: string;
  isAuth?: boolean;
};

export default function RedirectPage(props: RedirectProps) {
  const { link, isAuth } = props;
  const { logOut } = useActions();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [trigger] = authAPI.useLazyLogoutQuery();

  useEffect(() => {
    try {
      if (link) {
        window.location.href = link;
      } else {
        if (!cookies.get(oldFrontCookie) || !isAuth) {
          logOut('');
          trigger();
          navigate(ROUTES.MAIN);
        } else {
          if (window.location.hostname === 'localhost') {
            window.location.href = 'localhost:3000/account/profile';
          }
          window.location.href = host + '/control/';
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  return <section>Redirecting...</section>;
}
