// extracted by mini-css-extract-plugin
var _1 = "TimeErrorModal-module__button__CYViB";
var _2 = "TimeErrorModal-module__errorMessage__i2WXe";
var _3 = "TimeErrorModal-module__header__EpXfY";
var _4 = "TimeErrorModal-module__title__M6aug";
var _5 = "TimeErrorModal-module__wrapper__L68MN";
export { _1 as "button", _2 as "errorMessage", _3 as "header", _4 as "title", _5 as "wrapper" }

    if(module.hot) {
      // 1733466108592
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  