// extracted by mini-css-extract-plugin
var _1 = "DocumentsList-module__approved__eMMXw";
var _2 = "DocumentsList-module__button__nIzY9";
var _3 = "DocumentsList-module__buttonDisabled__d4dtc";
var _4 = "DocumentsList-module__declined__jjAg7";
var _5 = "DocumentsList-module__disabledApproved__trNCu";
var _6 = "DocumentsList-module__disabledDeclined__sOU1F";
var _7 = "DocumentsList-module__document__dBe0s";
var _8 = "DocumentsList-module__documentItem__bFRtZ";
var _9 = "DocumentsList-module__documentLink__JM0xu";
var _a = "DocumentsList-module__documentName__Qtab1";
var _b = "DocumentsList-module__documentsList__TJCNc";
var _c = "DocumentsList-module__expiringApproved__wzWVm";
var _d = "DocumentsList-module__hidden__ilnA5";
var _e = "DocumentsList-module__iconWrapper__WXFwa";
var _f = "DocumentsList-module__inactive__jaPJD";
var _10 = "DocumentsList-module__pending__XrUjK";
var _11 = "DocumentsList-module__skeletonItem__ZN2pw";
var _12 = "DocumentsList-module__wrapper__x6bF2";
export { _1 as "approved", _2 as "button", _3 as "buttonDisabled", _4 as "declined", _5 as "disabledApproved", _6 as "disabledDeclined", _7 as "document", _8 as "documentItem", _9 as "documentLink", _a as "documentName", _b as "documentsList", _c as "expiringApproved", _d as "hidden", _e as "iconWrapper", _f as "inactive", _10 as "pending", _11 as "skeletonItem", _12 as "wrapper" }

    if(module.hot) {
      // 1733466108658
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  