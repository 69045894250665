import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import ConfirmModalPage from '@components/ConfirmModalPage/ConfirmModalPage';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import * as styles from './Schedule.module.scss';
import TitleWithButton from '@components/TitleWithButton/TitleWithButton';
import MainButton from '../MainButton/MainButton';
import Picker from '../../../../features/Picker/Picker';
import ScheduleChild from './components/Child/Child';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import { getMergedDaySchedule } from './utils';
import { TScheduleItem } from '@core/redux/models/schedule';
import { TServerError, parseServerError } from '@core/utils/error';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { useCurrentDay } from '@core/utils/date';
import { DiscardText } from '@core/utils/utils';
import { ModuleAction } from '@core/redux/models/profile';

export default function Schedule() {
  const { technicianId } = useCurrentUser();
  const { setScheduleAction } = useActions();
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [scheduleList, setScheduleList] = useState<TScheduleItem[]>([]);
  const currentDay = useCurrentDay();

  const { scheduleAction, daySchedule } = useAppSelector((state) => state.scheduleReducer);
  const { needDisabled } = useAppSelector((state) => state.profileReducer);

  const [getData, { data: scheduleData, isLoading }] = profileAPI.useGetScheduleMutation();
  const [handleSaveSchedule, { isLoading: isLoadingUpdate, error: serverError }] =
    profileAPI.useUpdateScheduleMutation();
  const listId = scheduleData?.id;

  useEffect(() => {
    if (scheduleData) setScheduleList(scheduleData.list);
  }, [scheduleData]);

  useEffect(() => {
    getData({ id: technicianId });
  }, []);

  const refetch = () => {
    getData({ id: technicianId });
  };

  const handleConfirm = useCallback(() => {
    setConfirmModal(false);
    refetch();
  }, []);

  const handleSendSchedule = useCallback(async () => {
    const mergedDaySchedule = getMergedDaySchedule(daySchedule.day);

    if (!scheduleList?.length || !listId) {
      return;
    }

    const list = scheduleList.map((item: TScheduleItem) => {
      if (item.name !== daySchedule.name) {
        return item;
      } else {
        return {
          id: item.id,
          jobs: daySchedule.jobs,
          name: item.name,
          day: mergedDaySchedule,
        };
      }
    });

    if (list.length) {
      const result = await handleSaveSchedule({
        id: listId,
        technicianId: technicianId,
        list: list as TScheduleItem[],
      });

      if (result && 'error' in result) {
        setErrorModal(true);
        return;
      }

      setScheduleList(result.data.list);
    }
  }, [daySchedule, scheduleList, isLoadingUpdate]);

  const listChildren = scheduleList?.map((item) => {
    return <ScheduleChild item={item} key={`${item.name}`} action={scheduleAction} />;
  });

  return (
    <section className={styles.defaultSchedule}>
      <TitleWithButton
        title={'Default schedule:'}
        disabled={needDisabled && scheduleAction === ModuleAction.send}
      >
        {isLoading && !listChildren?.length ? (
          <Skeleton height={34} width='62px' variant='rectangular' className={styles.skeleton} />
        ) : (
          <MainButton
            refreshDataFunction={() => setConfirmModal(true)}
            resetIsLoading={isLoading}
            isLoading={isLoadingUpdate}
            sendDataFunction={handleSendSchedule}
            isSendError={!!serverError}
            setAction={setScheduleAction}
            action={scheduleAction}
            sendDisabled={daySchedule.jobs <= 0 || daySchedule.day.length === 0}
          />
        )}
      </TitleWithButton>
      {isLoading && !listChildren.length ? (
        <Skeleton height={118} width='100%' variant='rectangular' className={styles.skeletonForm} />
      ) : (
        <Picker listChildren={listChildren} action={scheduleAction} position={currentDay} />
      )}
      {!!serverError && errorModal && (
        <ErrorModal
          setIsShown={setErrorModal}
          errorMessage={parseServerError(serverError as TServerError)}
        />
      )}
      {confirmModal && (
        <ConfirmModalPage
          header={'Discard editing schedule?'}
          headerClassName={styles.modalHeader}
          text={DiscardText(daySchedule.name)}
          textClassName={styles.modalText}
          handleConfirm={handleConfirm}
          setIsShown={() => setConfirmModal(false)}
          confirmButtonText={'Discard'}
          cancelButtonText={'Cancel'}
        />
      )}
    </section>
  );
}
