import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { TScheduleItem, TScheduleTime, TScheduleTimeWithIndex } from '@core/redux/models/schedule';
import { ModuleAction } from '@core/redux/models/profile';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import Button from '@components/Button/Button';
import Plus from '@assets/images/plus.svg';
import Trash from '@assets/images/trash.svg';
import Gear from '@assets/images/half-gear.svg';
import Watch from '@assets/images/stopwatch.svg';
import ModalInfo from '@pages/Profile/components/ModalInfo/ModalInfo';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import JobsModal from '../JobsModal/JobsModal';
import DeleteRangeModal from '../DeleteRangeModal/DeleteRangeModal';
import AddRangeModal from '../AddRangeModal/AddRangeModal';
import * as styles from '../../Schedule.module.scss';
import { getIsTimeBarrierTop } from '../../utils';
import { useModuleActionType } from '@core/utils/utils';

type ScheduleChildProps = {
  item: TScheduleItem;
  action: ModuleAction;
};

export default function ScheduleChild(props: ScheduleChildProps) {
  const { item, action } = props;
  const { daySchedule } = useAppSelector((state) => state.scheduleReducer);
  const { setDaySchedule } = useActions();

  const { isSend, isEdit, isChange } = useModuleActionType(action);

  useEffect(() => {
    setDaySchedule(item);
  }, [item]);

  const { name, jobs, day } = daySchedule;
  const [jobsModal, setJobsModal] = useState<boolean>(false);
  const [deleteTimeInfoModal, setDeleteTimeInfoModal] = useState<string>();
  const [deleteRangeModal, setDeleteRangeModal] = useState<boolean>(false);
  const [addRangeModal, setAddRangeModal] = useState<boolean>(false);
  const [timeWindow, setTimeWindow] = useState<TScheduleTime>({ start: '00:00', end: '24:00' });
  const [deleteItem, setDeleteItem] = useState<TScheduleTimeWithIndex | null>(null);

  const currentJobs =
    day.length !== 0 ? (
      <span>
        (max jobs <span className={styles.jobs}>{jobs}</span>)
      </span>
    ) : (
      ''
    );
  const handleAddRange = useCallback(
    (prev?: TScheduleTime | undefined, next?: TScheduleTime | undefined) => {
      setTimeWindow({
        start: prev?.end || '00:00',
        end: next?.start || '23:59',
      });
      setAddRangeModal(true);
    },
    [timeWindow],
  );
  const handleDeleteRange = useCallback(
    (currentItem: TScheduleTimeWithIndex | null) => {
      if (currentItem !== null) {
        setDeleteItem(currentItem);
      }

      setDeleteRangeModal(true);
    },
    [deleteItem],
  );

  return (
    <>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {name}&nbsp;{currentJobs}
        </h2>
        <div className={styles.listWrapper}>
          <span className={styles.separator}></span>
          <ul className={cn(styles.timerWrapper, { [styles.isSend]: isSend })}>
            {day.length === 0 ? (
              <li key={`${name}-empty`}>
                {isEdit || isChange ? (
                  <div className={styles.scheduleEditBlock}>
                    <span className={cn(styles.timeEditMode, styles.isAdd)}>Add range</span>
                    <Button
                      className={cn({ [styles.buttonDisabled]: isSend })}
                      mode={ButtonMode.icon}
                      bgColor={ButtonBgColor.transparent}
                      onClick={() => handleAddRange()}
                      disabled={isSend}
                    >
                      <Plus fill='var(--light_accent_main)' />
                    </Button>
                  </div>
                ) : (
                  <p className={cn(styles.timeEditMode, styles.time)}>Day off</p>
                )}
              </li>
            ) : (
              <>
                <li key={`${name}-add-range`}>
                  {!getIsTimeBarrierTop('00:00', day[0].start) ? (
                    <div
                      className={cn(styles.scheduleEditBlock, styles.addRange, {
                        [styles.hidden]: isSend,
                      })}
                    >
                      <span className={cn(styles.timeEditMode, styles.isAdd)}>Add range</span>
                      <Button
                        className={cn({ [styles.buttonDisabled]: isSend })}
                        mode={ButtonMode.icon}
                        bgColor={ButtonBgColor.transparent}
                        onClick={() => handleAddRange({ start: '00:00', end: '00:00' }, day[0])}
                        disabled={isSend}
                      >
                        <Plus fill='var(--light_accent_main)' />
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>

                {day.map((time, index) => {
                  return (
                    <li
                      key={`${name}-${time.start}`}
                      className={cn(styles.timeWrapper, { [styles.isEdit]: !isSend })}
                    >
                      <>
                        <div className={styles.scheduleEditBlock}>
                          <span
                            className={cn(
                              styles.timeEditMode,
                              {
                                [styles.timeIsDeleted]: time.isDeleted && !isSend,
                              },
                              {
                                [styles.isEdit]: !isSend,
                              },
                            )}
                          >
                            {time.start} - {time.end}
                          </span>
                          <div className={styles.buttonWrapper}>
                            {time.isDeleted && (
                              <Button
                                mode={ButtonMode.icon}
                                bgColor={ButtonBgColor.transparent}
                                onClick={() => {
                                  setDeleteTimeInfoModal(time.deleteTimeout);
                                }}
                              >
                                <Watch fill='var(--light_accent_neutral)' />
                              </Button>
                            )}
                            <Button
                              mode={ButtonMode.icon}
                              bgColor={ButtonBgColor.transparent}
                              onClick={() => handleDeleteRange({ ...time, index })}
                              className={cn(styles.deleteButton, { [styles.hidden]: isSend })}
                            >
                              <Trash fill='var(--light_accent_main)' />
                            </Button>
                          </div>
                        </div>
                        {(
                          day.length - 1 === index
                            ? !getIsTimeBarrierTop(day[index].end, '23:59')
                            : !getIsTimeBarrierTop(day[index].end, day[index + 1]?.start)
                        ) ? (
                          <div
                            className={cn(styles.scheduleEditBlock, styles.addRange, {
                              [styles.hidden]: isSend,
                            })}
                          >
                            <span className={cn(styles.timeEditMode, styles.isAdd)}>Add range</span>
                            <Button
                              className={cn({ [styles.buttonDisabled]: isSend })}
                              mode={ButtonMode.icon}
                              bgColor={ButtonBgColor.transparent}
                              onClick={() => handleAddRange(day[index], day[index + 1])}
                              disabled={isSend}
                            >
                              <Plus fill='var(--light_accent_main)' />
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>
        {day.length > 0 && (
          <Button
            className={cn(styles.jobsButton, { [styles.hidden]: isSend })}
            mode={ButtonMode.standard}
            onClick={() => setJobsModal(true)}
            afterIcon={
              <div className={styles.jobsButtonIcon}>
                <Gear fill='var(--light_label_tertiary)' />
                <span className={styles.jobsButtonText}>{jobs}</span>
              </div>
            }
          >
            Max jobs
          </Button>
        )}
      </div>
      {jobsModal && <JobsModal setIsShown={setJobsModal} />}
      {deleteRangeModal && <DeleteRangeModal setIsShown={setDeleteRangeModal} item={deleteItem} />}
      {addRangeModal && <AddRangeModal setIsShown={setAddRangeModal} timeWindow={timeWindow} />}
      {deleteTimeInfoModal && (
        <ModalInfo
          time={deleteTimeInfoModal}
          setIsShown={() => setDeleteTimeInfoModal(undefined)}
        />
      )}
    </>
  );
}
