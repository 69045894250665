// extracted by mini-css-extract-plugin
var _1 = "CustomSelect-module__hidden__ROeF8";
var _2 = "CustomSelect-module__icon__uwokv";
var _3 = "CustomSelect-module__iconRotated__akrZb";
var _4 = "CustomSelect-module__open__uNwtv";
var _5 = "CustomSelect-module__placeholder__pPwnm";
var _6 = "CustomSelect-module__select__FLQmQ";
var _7 = "CustomSelect-module__selectHead__eaS8P";
var _8 = "CustomSelect-module__selectItem__JfYWl";
var _9 = "CustomSelect-module__selectList__nVgXW";
export { _1 as "hidden", _2 as "icon", _3 as "iconRotated", _4 as "open", _5 as "placeholder", _6 as "select", _7 as "selectHead", _8 as "selectItem", _9 as "selectList" }

    if(module.hot) {
      // 1733466109024
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  