import React from 'react';
import * as styles from './ReviewsCardExpert.module.scss';

type ReviewsCardExpertProps = {
  image: string;
  name: string;
  text: string;
  bgColor: string;
};

export default function ReviewsCardExpert(props: ReviewsCardExpertProps) {
  const { image, name, text, bgColor } = props;

  return (
    <div className={styles.wrapper} style={{ backgroundColor: bgColor }}>
      <h3 className={styles.title}>{name}</h3>
      <p className={styles.text}>{text}</p>
      <img src={image} alt='expertPhoto' className={styles.img} />
    </div>
  );
}
