// extracted by mini-css-extract-plugin
var _1 = "Reviews-module__inert__biSfK";
var _2 = "Reviews-module__reviewsBlock__lUD02";
var _3 = "Reviews-module__supplemental__ceIwh";
var _4 = "Reviews-module__title__eC0QA";
var _5 = "Reviews-module__wrapper__QDzzR";
export { _1 as "inert", _2 as "reviewsBlock", _3 as "supplemental", _4 as "title", _5 as "wrapper" }

    if(module.hot) {
      // 1733466104060
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  