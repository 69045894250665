import React from 'react';
import cn from 'classnames';
import TextLogo from '@assets/images/textLogo.svg';
import { NavLink } from 'react-router-dom';
import * as styles from './InformationPage.module.scss';

type InformationPageProps = {
  text: string;
  linkText: string;
  route: string;
};

export default function InformationPage({ text, linkText, route }: InformationPageProps) {
  return (
    <div className={styles.wrapped}>
      <TextLogo fill='var(--light_label_primary)' />
      <title className={cn(styles.title, styles.text)}>{text}</title>
      <div className={styles.textBlock}>
        <NavLink to={route} className={cn(styles.text, styles.blue)}>
          {linkText}
        </NavLink>
      </div>
    </div>
  );
}
