export enum ROUTES {
  //JOBS = 'account/jobs',
  // PAYOUTS = 'account//payouts',
  // AGENCIES = 'account//agencies',
  JOBS = 'profile/jobs',
  PAYOUTS = '/account/payouts',
  CLAIMS = '/account/payouts/claims',
  AGENCIES = 'profile/agencies/',
  PROFILE = 'account/profile',
  PARTNERSHIP = 'account/partnership',
  REDIRECT_PAGE = '/redirect',
  // LOGIN = '/login',
  // CONFIRM_PASSWORD = '/login/:id/:token/',
  // FORGOT = '/forgot',
  // REGISTRATION = '/registration',
  // RESET = '/reset/:id/:token',
  // RESET = '/reset/:id/:token',
  LOGIN = '/auth/login',
  CONFIRM_PASSWORD = '/auth/login/:id/:token/',
  FORGOT = '/auth/forgot',
  REGISTRATION = '/auth/registration',
  RESET = '/auth/reset/:id/:token',
  MAIN = '/',
}

export const noHeadresRoutes = [ROUTES.CLAIMS];
