import React, { useState } from 'react';
import * as styles from './MainInfoWeb.module.scss';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@routes/constants';
import MainInfoWebExpert from './components/MainInfoWebExpert';
import MainInfoWebAgency from './components/MainInfoWebAgency';

type MainInfoProps = {
  isExpert: boolean;
};

const MAIN_TEXT_EXPERT =
  'Empowering experts to find local jobs, manage their profiles, and streamline their workflow effortlessly.';
const MAIN_TEXT_AGENCY =
  'Connecting agencies with qualified experts for faster, more efficient hiring and project management.';

export default function MainInfo(props: MainInfoProps) {
  const { isExpert } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <section className={styles.wrapper}>
      <div className={styles.textBlock}>
        <h2 className={cn(styles.title, isExpert ? styles.supplemental : styles.inert)}>
          Clear purpose. Strong values.
        </h2>
        <p className={styles.mainText}>{isExpert ? MAIN_TEXT_EXPERT : MAIN_TEXT_AGENCY}</p>
        <NavLink to={ROUTES.REGISTRATION} className={styles.link}>
          <Button
            className={styles.button}
            bgColor={
              isFocus
                ? ButtonBgColor.grey
                : isExpert
                  ? ButtonBgColor.supplemental
                  : ButtonBgColor.inert
            }
            onMouseEnter={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}
          >
            Register now
          </Button>
        </NavLink>
      </div>
      <div className={styles.pictureBlock}>
        {isExpert ? <MainInfoWebExpert /> : <MainInfoWebAgency />}
      </div>
      <NavLink to={ROUTES.REGISTRATION} className={styles.link}>
        <Button
          className={styles.button}
          bgColor={
            isFocus
              ? ButtonBgColor.grey
              : isExpert
                ? ButtonBgColor.supplemental
                : ButtonBgColor.inert
          }
          onMouseEnter={() => setIsFocus(true)}
          onMouseLeave={() => setIsFocus(false)}
        >
          Register now
        </Button>
      </NavLink>
    </section>
  );
}
