import { APROVED_EXPIRING_PERIOD } from './constants';
import { DocumentType } from './types';
import { Status, TDocument } from '@core/redux/models/documents';

export const getDefaultFormValue = ({ ...args }: Record<string, boolean>) => {
  switch (true) {
    case args.isW9Business:
      return { startDate: '', file: undefined, companyName: '', ein: '' };
    case args.isW9Individual:
      return {
        startDate: '',
        file: undefined,
        ssn: '',
        firstName: '',
        lastName: '',
      };
    case args.isRepairLicense:
      return { startDate: '', expirationDate: '', file: undefined };
    case args.isId:
      return {
        startDate: '',
        expirationDate: '',
        file: undefined,
        firstName: '',
        lastName: '',
        birthDate: '',
      };
    case args.isSsn:
      return {
        startDate: '',
        expirationDate: '',
        file: undefined,
        ssn: '',
        firstName: '',
        lastName: '',
      };

    case args.isProofCompanyRegistration:
      return {
        startDate: '',
        file: undefined,
        companyName: '',
        street: '',
        apartment: '',
        city: '',
        stateCode: '',
        postalCode: '',
        titleInCompany: '',
        ownerFirstName: '',
        ownerLastName: '',
        companyType: '',
        dba: false,
        dbaCompanyName: '',
        dbaStartDate: '',
        dbaExpiration: '',
        dbaFile: undefined,
      };
  }
};

export const getDocumentTitle = (type: string): string => {
  if (type === 'W9-Business' || type === 'W9-Individual') {
    return 'W9';
  } else {
    return type;
  }
};

export const useDocumentType = (type: string) => {
  const isW9Business = type === DocumentType.w9Business;
  const isW9Individual = type === DocumentType.w9Individual;
  const isSsn = type === DocumentType.ssn;
  const isRepairLicense = type === DocumentType.repairLicense;
  const isId = type === DocumentType.id;
  const isProofCompanyRegistration = type === DocumentType.companyRegistration;
  return {
    isW9Business,
    isW9Individual,
    isSsn,
    isRepairLicense,
    isId,
    isProofCompanyRegistration,
  };
};

export const getDocumentStatus = (document: TDocument, documentsList: TDocument[]) => {
  const today = new Date();
  const dateEnd = document.expirationDate ? new Date(document.expirationDate) : null;
  if (
    documentsList &&
    documentsList?.length > 1 &&
    documentsList?.find((item) => item.status != Status.declined) &&
    document.status === Status.declined
  ) {
    return 'disabledDeclined';
  } else if (
    document.status === Status.approved &&
    dateEnd &&
    dateEnd.getTime() < today.getTime()
  ) {
    return 'disabledApproved';
  } else if (
    document.status === Status.approved &&
    dateEnd &&
    dateEnd.getTime() - today.getTime() <= APROVED_EXPIRING_PERIOD
  ) {
    return 'expiringApproved';
  } else {
    return document.status;
  }
};
