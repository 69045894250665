import React from 'react';
import CaseWeb from '@assets/images/caseWeb.svg';
import StarWeb from '@assets/images/starWeb.svg';
import SettingsWeb from '@assets/images/settingsWeb.svg';
import LockWeb from '@assets/images/lockWeb.svg';
import MainCardFiveImage from '@assets/images/MainCardFive.png';
import MainCardSixImage from '@assets/images/MainCardSix.png';
import MainCardSevenImage from '@assets/images/MainCardSeven.png';
import MainCardEightImage from '@assets/images/MainCardEight.png';
import MainCardFiveImageMobile from '@assets/images/MainCardFiveImageMobile.png';
import MainCardSixImageMobile from '@assets/images/MainCardSixImageMobile.png';
import MainCardSevenImageMobile from '@assets/images/MainCardSevenImageMobile.png';
import MainCardEightImageMobile from '@assets/images/MainCardEightImageMobile.png';

import MainInfoWebCard from './MainInfoWebCard/MainInfoWebCard';
import { useMobileDetect } from '@core/hooks/windowSize';

const FIRST_AGENCY_TITLE = 'Access to Qualified Experts';
const SECOND_AGENCY_TITLE = 'Streamlined Job Posting';
const THIRD_AGENCY_TITLE = 'Efficient Management';
const FOURTH_AGENCY_TITLE = 'Secure Hiring Process';

const FIRST_AGENCY_TEXT =
  'Our database is filled with thoroughly vetted professionals, ensuring you only connect with skilled and reliable contractors.';
const SECOND_AGENCY_TEXT =
  'Create and manage job postings, we ensure that your vacancies are seen by the right candidates, reducing time-to-hire.';
const THIRD_AGENCY_TEXT =
  'Keep track of expert assignments, monitor job progress, and ensure that all projects are completed on time.';
const FOURTH_AGENCY_TEXT =
  'Our platform provides a safe environment for agencies to hire experts, with all  documentation and verification done upfront.';

export default function MainInfoWebAgency() {
  const isMobile = useMobileDetect();

  return (
    <>
      <MainInfoWebCard
        icon={<StarWeb fill='var(--light-background-primary)' />}
        title={FIRST_AGENCY_TITLE}
        text={FIRST_AGENCY_TEXT}
        image={isMobile ? MainCardFiveImageMobile : MainCardFiveImage}
      />
      <MainInfoWebCard
        icon={<CaseWeb fill='var(--light-background-primary)' />}
        title={SECOND_AGENCY_TITLE}
        text={SECOND_AGENCY_TEXT}
        image={isMobile ? MainCardSixImageMobile : MainCardSixImage}
      />
      <MainInfoWebCard
        icon={<SettingsWeb fill='var(--light-background-primary)' />}
        title={THIRD_AGENCY_TITLE}
        text={THIRD_AGENCY_TEXT}
        image={isMobile ? MainCardSevenImageMobile : MainCardSevenImage}
      />
      <MainInfoWebCard
        icon={<LockWeb fill='var(--light-background-primary)' />}
        title={FOURTH_AGENCY_TITLE}
        text={FOURTH_AGENCY_TEXT}
        image={isMobile ? MainCardEightImageMobile : MainCardEightImage}
      />
    </>
  );
}
