// extracted by mini-css-extract-plugin
var _1 = "MailingAddress-module__button__KqS0J";
var _2 = "MailingAddress-module__buttonConfirmModal__RMJX2";
var _3 = "MailingAddress-module__buttonText__U5iSD";
var _4 = "MailingAddress-module__modalHeader__XmXK0";
var _5 = "MailingAddress-module__modalText__utPgq";
var _6 = "MailingAddress-module__skeleton__aIYbD";
var _7 = "MailingAddress-module__skeletonAddressButton__GToMj";
var _8 = "MailingAddress-module__skeletonForm__IAOue";
var _9 = "MailingAddress-module__wrapper__QvTwf";
export { _1 as "button", _2 as "buttonConfirmModal", _3 as "buttonText", _4 as "modalHeader", _5 as "modalText", _6 as "skeleton", _7 as "skeletonAddressButton", _8 as "skeletonForm", _9 as "wrapper" }

    if(module.hot) {
      // 1733466105087
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  