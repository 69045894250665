import React from 'react';
import cn from 'classnames';
import Button from '@components/Button/Button';
import { ButtonBgColor } from '@components/Button/types';
import * as styles from './ItemBlock.module.scss';

type ItemBlockProps = {
  title: string;
  textMain: string;
  textSecond: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
  buttonText: string;
  bgColor: ButtonBgColor;
  classNameItem: string;
  classNameItemOpen: string;
  classNameItemClose: string;
  isFocus: boolean;
  isAnotherFocus: boolean;
  isOpen: boolean;
  isAnotherOpen: boolean;
  isHidden: boolean;
};

export default function ItemBlock(props: ItemBlockProps) {
  const {
    title,
    textMain,
    textSecond,
    isFocus,
    isAnotherFocus,
    isOpen,
    isAnotherOpen,
    isHidden,
    onMouseEnter,
    onMouseLeave,
    onClick,
    buttonText,
    bgColor,
    classNameItem,
    classNameItemOpen,
    classNameItemClose,
  } = props;

  return (
    <div
      className={cn(styles.item, styles[classNameItem], {
        [styles.itemFocused]: isFocus,
        [styles.itemUnfocused]: isAnotherFocus,
        [styles.itemOpen]: isOpen,
        [styles[classNameItemOpen]]: isOpen,
        [styles.itemClose]: isAnotherOpen,
        [styles[classNameItemClose]]: isAnotherOpen,
        [styles.hidden]: isHidden,
      })}
    >
      <h2 className={cn(styles.title, { [styles.titleUnfocused]: isAnotherFocus })}>{title}</h2>
      <p className={cn(styles.text, { [styles.hidden]: isOpen })}>{textMain}</p>
      <p
        className={cn(styles.text, {
          [styles.hidden]: !isOpen,
          [styles.unHidden]: isOpen,
        })}
      >
        {textSecond}
      </p>
      <Button
        className={cn(styles.button, {
          [styles.textWhite]: isFocus,
          [styles.buttonUnfocused]: isAnotherFocus,
        })}
        bgColor={bgColor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
