// extracted by mini-css-extract-plugin
var _1 = "GeneralInfo-module__button__Um2y8";
var _2 = "GeneralInfo-module__buttonConfirmModal__lcmFy";
var _3 = "GeneralInfo-module__buttonText__boAkC";
var _4 = "GeneralInfo-module__modalHeader__xMTvu";
var _5 = "GeneralInfo-module__modalText__zHWu5";
var _6 = "GeneralInfo-module__skeleton__AlSW4";
var _7 = "GeneralInfo-module__skeletonAddressButton__bZL4K";
var _8 = "GeneralInfo-module__skeletonForm__b0tZa";
var _9 = "GeneralInfo-module__wrapper__OV1zD";
export { _1 as "button", _2 as "buttonConfirmModal", _3 as "buttonText", _4 as "modalHeader", _5 as "modalText", _6 as "skeleton", _7 as "skeletonAddressButton", _8 as "skeletonForm", _9 as "wrapper" }

    if(module.hot) {
      // 1733466105015
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  