import {
  TZip,
  TWorkAreasPostRequest,
  TNewWorkArea,
  TWorkArea,
} from '@core/redux/models/serviceAreas';

export const createZip = (workArea: TNewWorkArea | TWorkArea): TZip => {
  return {
    city: {
      name: workArea.cityName,
      state: workArea.state,
    },
    code: workArea.code,
    longitude: workArea.longitude,
    latitude: workArea.latitude,
    google_uid: workArea.googleUid,
  };
};

export const createWorkAreaForRequest = (
  newWorkArea: TNewWorkArea,
  technicianId: number,
): TWorkAreasPostRequest => {
  return {
    zip: createZip(newWorkArea),
    technician_id: technicianId,
  };
};
