import Button from '@components/Button/Button';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import React, { useCallback } from 'react';
import cn from 'classnames';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import * as styles from './Filters.module.scss';
import FilterIcon from '@assets/images/filter.svg';
import ActiveFilterIcon from '@assets/images/filter_fill.svg';
import DataPicker from '@components/DatePicker/DatePicker';
import { FilterKeys } from '@core/redux/models/payouts';
import { isFilterKey } from '../../types';
import { getDdMmYyyyFormatDate } from '@core/utils/date';

export default function Filters() {
  const { setIsFilterBlockActive, setFilters, setIsActiveSearch } = useActions();
  const { isFilterBlockActive, filters, isActiveSearch } = useAppSelector(
    (state) => state.payoutsReducer,
  );
  const isActiveSearchButton = !Object.values(filters).find((item) => item !== '');

  const handleClick = useCallback(() => {
    setIsActiveSearch(true);
  }, [isActiveSearch]);

  const handleClear = useCallback(() => {
    setIsActiveSearch(false);
    for (const key of Object.keys(filters)) {
      if (isFilterKey(key)) {
        setFilters({ key: key, value: '' });
      }
    }
  }, [isActiveSearch, filters]);

  return (
    <>
      <div className={cn(styles.wrap, { [styles.wrapActive]: isFilterBlockActive })}>
        <div className={styles.filterHeader}>
          <Button
            className={styles.titleButton}
            beforeIcon={
              isActiveSearch ? (
                <ActiveFilterIcon fill='var(--light_accent_main)' />
              ) : (
                <FilterIcon fill='var(--light_accent_main)' />
              )
            }
            size={ButtonSize.s}
          >
            Filter
          </Button>
          <Button
            className={styles.clearButton}
            size={ButtonSize.s}
            onClick={handleClear}
            disabled={!isActiveSearch}
          >
            Clear
          </Button>
        </div>

        <div className={styles.form}>
          <div className={styles.item}>
            <input
              placeholder='By invoice'
              className={styles.input}
              value={filters.invoice_number}
              onChange={(e) => {
                setFilters({ key: FilterKeys.invoice_number, value: e.target.value });
                setIsActiveSearch(false);
              }}
            />
            <span className={styles.separator}></span>
          </div>

          <div className={styles.item}>
            <input
              placeholder='By customer name'
              className={styles.input}
              value={filters.customer_name}
              onChange={(e) => {
                setFilters({ key: FilterKeys.customer_name, value: e.target.value });
                setIsActiveSearch(false);
              }}
            />
            <span className={styles.separator}></span>
          </div>

          <div className={styles.doubleFilters}>
            <div className={styles.item}>
              <input
                placeholder='Amount (min)'
                className={styles.doubleFilter}
                value={filters.amount_min}
                onChange={(e) => {
                  setFilters({ key: FilterKeys.amount_min, value: e.target.value });
                  setIsActiveSearch(false);
                }}
              />
            </div>
            <span className={styles.doubleFiltersSeparator}></span>
            <div className={styles.item}>
              <input
                placeholder='Amount (max)'
                className={cn(styles.doubleFilter, styles.right)}
                value={filters.amount_max}
                onChange={(e) => {
                  setFilters({ key: FilterKeys.amount_max, value: e.target.value });
                  setIsActiveSearch(false);
                }}
              />
            </div>
            <span className={styles.separator}></span>
          </div>
          <div className={styles.doubleFilters}>
            <div className={styles.item}>
              <input
                placeholder='Commission (min)'
                value={filters.commission_min}
                className={styles.doubleFilter}
                onChange={(e) => {
                  setFilters({ key: FilterKeys.commission_min, value: e.target.value });
                  setIsActiveSearch(false);
                }}
              />
            </div>
            <span className={styles.doubleFiltersSeparator}></span>
            <div className={styles.item}>
              <input
                placeholder='Commission (max)'
                value={filters.commission_max}
                className={cn(styles.doubleFilter, styles.right)}
                onChange={(e) => {
                  setFilters({ key: FilterKeys.commission_max, value: e.target.value });
                  setIsActiveSearch(false);
                }}
              />
            </div>
            <span className={styles.separator}></span>
          </div>

          <div className={styles.doubleFilters}>
            <DataPicker
              className={styles.item}
              value={filters.date_before}
              placeholder='Date (from)'
              hasCalendarIcon={false}
              inputClassName={styles.doubleFilter}
              blockClassName={styles.dateBlock}
              onChange={(date) => {
                setFilters({
                  key: FilterKeys.date_before,
                  value: getDdMmYyyyFormatDate(date as string),
                });
                setIsActiveSearch(false);
              }}
            />
            <span className={styles.doubleFiltersSeparator}></span>
            <DataPicker
              value={filters.date_after}
              onChange={(date) => {
                setFilters({
                  key: FilterKeys.date_after,
                  value: getDdMmYyyyFormatDate(date as string),
                });
                setIsActiveSearch(false);
              }}
              placeholder='Date (to)'
              hasCalendarIcon={false}
              inputClassName={cn(styles.doubleFilter, styles.right)}
              blockClassName={styles.dateBlock}
            />
          </div>
        </div>
        <Button
          className={styles.button}
          size={ButtonSize.s}
          bgColor={ButtonBgColor.grey}
          disabled={isActiveSearchButton}
          onClick={handleClick}
        >
          Search
        </Button>
      </div>

      {!isFilterBlockActive && (
        <Button
          className={styles.button}
          beforeIcon={<FilterIcon fill='var(--light-background-primary)' />}
          onClick={() => setIsFilterBlockActive(true)}
          bgColor={ButtonBgColor.grey}
          size={ButtonSize.s}
        >
          Filter
        </Button>
      )}
    </>
  );
}
