import { Mode, TWeek, TFilters, TPayout, FilterKeys } from '@core/redux/models/payouts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TPayoutsState = {
  mode: Mode;
  isFilterBlockActive: boolean;
  currentWeek: TWeek | null;
  filters: TFilters;
  isActiveSearch: boolean;
  isClearFilters: boolean;
  activeWeek: TWeek | null;
  outstandingBalanceWeek: TWeek | null;
  customInvoiceNumber: string;
  activeClaim: boolean;
  claimModal: boolean;
  historyModal: boolean;
  calculationModal: boolean;
  payoutRow: TPayout;
};

const initialState: TPayoutsState = {
  mode: Mode.total,
  isFilterBlockActive: false,
  currentWeek: null,
  filters: {
    invoice_number: '',
    customer_name: '',
    amount_min: '',
    amount_max: '',
    commission_min: '',
    commission_max: '',
    date_before: '',
    date_after: '',
  },
  isActiveSearch: false,
  isClearFilters: false,
  activeWeek: null,
  outstandingBalanceWeek: null,
  customInvoiceNumber: '',
  activeClaim: true,
  claimModal: false,
  historyModal: false,
  calculationModal: false,
  payoutRow: {
    id: NaN,
    invoice: '',
    date: '',
    customer: '',
    amount: '',
    commission: '',
    commissionId: NaN,
    orderId: NaN,
    isRental: false,
    isCustomTransaction: false,
  },
};

export const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<Mode>) {
      state.mode = action.payload;
    },
    setIsFilterBlockActive(state, action: PayloadAction<boolean>) {
      state.isFilterBlockActive = action.payload;
    },
    setCurrentWeek(state, action: PayloadAction<TWeek>) {
      state.currentWeek = action.payload;
    },
    setActiveWeek(state, action: PayloadAction<TWeek>) {
      state.activeWeek = action.payload;
    },
    setOutstandingBalanceWeek(state, action: PayloadAction<TWeek>) {
      state.outstandingBalanceWeek = action.payload;
    },
    setFilters(state, action: PayloadAction<{ key: FilterKeys; value: string }>) {
      const key = action.payload.key;
      state.filters[key] = action.payload.value;
    },
    setIsActiveSearch(state, action: PayloadAction<boolean>) {
      state.isActiveSearch = action.payload;
    },
    setIsClearFilters(state, action: PayloadAction<boolean>) {
      state.isClearFilters = action.payload;
    },
    setClaimModal(state, action: PayloadAction<boolean>) {
      state.claimModal = action.payload;
    },
    setHistoryModal(state, action: PayloadAction<boolean>) {
      state.historyModal = action.payload;
    },
    setCalculationModal(state, action: PayloadAction<boolean>) {
      state.calculationModal = action.payload;
    },
    setPayoutRow(state, action: PayloadAction<TPayout>) {
      state.payoutRow = action.payload;
    },
    setCustomInvoiceNumber(state, action: PayloadAction<string>) {
      state.customInvoiceNumber = action.payload;
    },
    setActiveClaim(state, action: PayloadAction<boolean>) {
      state.activeClaim = action.payload;
    },
  },
});

export const payoutsActions = payoutsSlice.actions;
export const payoutsReducer = payoutsSlice.reducer;
