import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import * as styles from './Picker.module.scss';
import cn from 'classnames';
import Button from '@components/Button/Button';
import LeftSwipe from '@assets/images/arrowLeft.svg';
import RightSwipe from '@assets/images/arrowRight.svg';
import { ButtonBgColor, ButtonMode } from '@components/Button/types';
import { ModuleAction } from '@core/redux/models/profile';
import { useModuleActionType } from '@core/utils/utils';

type PickerProps = {
  listChildren: ReactNode[];
  action: ModuleAction;
  position?: number;
  setPosition?: (arg: number) => void;
};

export default function Picker(props: PickerProps) {
  const { listChildren, action, position, setPosition } = props;
  const { isEdit, isChange } = useModuleActionType(action);

  const [itemIndex, setItemIndex] = useState<number>(position || 0);

  const leftButtonClick = useCallback(() => {
    if (itemIndex - 1 >= 0) {
      setItemIndex(itemIndex - 1);
    } else {
      setItemIndex(listChildren.length - 1);
    }
  }, [itemIndex, listChildren]);

  const rightButtonClick = useCallback(() => {
    if (itemIndex + 1 < listChildren.length) {
      setItemIndex(itemIndex + 1);
    } else {
      setItemIndex(0);
    }
  }, [itemIndex, listChildren]);

  useEffect(() => {
    if (setPosition) setPosition(itemIndex);
  }, [itemIndex]);

  return (
    <div className={styles.wrapper}>
      {listChildren[itemIndex]}
      <div className={cn(styles.button, styles.buttonLeft)}>
        <Button
          mode={ButtonMode.icon}
          bgColor={ButtonBgColor.transparent}
          onClick={leftButtonClick}
          disabled={isEdit || isChange}
          className={styles.buttonSize}
        >
          <LeftSwipe fill='var(--light_label_tertiary)' />
        </Button>
      </div>
      <div className={cn(styles.button, styles.buttonRight)}>
        <Button
          mode={ButtonMode.icon}
          bgColor={ButtonBgColor.transparent}
          onClick={rightButtonClick}
          disabled={isEdit || isChange}
          className={styles.buttonSize}
        >
          <RightSwipe fill='var(--light_label_tertiary)' />
        </Button>
      </div>
    </div>
  );
}
