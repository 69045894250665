// extracted by mini-css-extract-plugin
var _1 = "NewDocumentModal-module__docLink__qKfZh";
var _2 = "NewDocumentModal-module__emptyMargin__dnvAO";
var _3 = "NewDocumentModal-module__error__u0vKA";
var _4 = "NewDocumentModal-module__errorMessage__TGFDB";
var _5 = "NewDocumentModal-module__fileInput__yyMna";
var _6 = "NewDocumentModal-module__footer__Jue0p";
var _7 = "NewDocumentModal-module__form__g6xu0";
var _8 = "NewDocumentModal-module__header__wH3Lx";
var _9 = "NewDocumentModal-module__input__aY1h9";
var _a = "NewDocumentModal-module__modal__JlVvh";
var _b = "NewDocumentModal-module__picker__EphrX";
var _c = "NewDocumentModal-module__separator__u1tdK";
var _d = "NewDocumentModal-module__separatorBlock__AGUr2";
var _e = "NewDocumentModal-module__submit__gaHnO";
var _f = "NewDocumentModal-module__title__SU0T8";
var _10 = "NewDocumentModal-module__upload__DeCNn";
export { _1 as "docLink", _2 as "emptyMargin", _3 as "error", _4 as "errorMessage", _5 as "fileInput", _6 as "footer", _7 as "form", _8 as "header", _9 as "input", _a as "modal", _b as "picker", _c as "separator", _d as "separatorBlock", _e as "submit", _f as "title", _10 as "upload" }

    if(module.hot) {
      // 1733466108743
      var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  