import React, { useState, useCallback } from 'react';
import { profileAPI } from '@core/redux/services/profileService';
import { useCurrentUser } from '@core/hooks/currentUser';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import * as styles from './DeactivateModal.module.scss';
import Button from '@components/Button/Button';
import { useAppSelector } from '@core/hooks/redux';
import { useActions } from '@core/hooks/actions';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import Input from '@components/Input/Input';
import { InputType } from '@components/Input/types';

type DeactivateModalProps = {
  setIsShown: (val: boolean) => void;
};

export default function DeactivateModal(props: DeactivateModalProps) {
  const { setIsShown } = props;
  const { technicianId } = useCurrentUser();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { selfSuspended } = useAppSelector((state) => state.generalInfoReducer);
  const { setIsUpdateWorkBlocks } = useActions();

  const [handleUpdateActivateStatus, { isLoading: updateLoading }] =
    profileAPI.useUpdateTechnicianActivateAccountStatusMutation();

  const handleUpdate = useCallback(async () => {
    if (value.toLocaleLowerCase().trim() !== 'DEACTIVATE'.toLocaleLowerCase()) {
      setError('You need to enter “DEACTIVATE”.');
      return;
    }
    const result = await handleUpdateActivateStatus({
      id: technicianId,
      selfSuspended: !selfSuspended,
    });
    if ('data' in result) {
      setIsUpdateWorkBlocks(true);
      setIsShown(false);
      return;
    }
    throw new Error('Something went wrong!');
  }, [selfSuspended, value]);

  return (
    <ModalPage
      header={<div className={styles.title}>Deactivate account?</div>}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
      closeable={false}
    >
      <div className={styles.text}>
        {'To deactivate your account, enter "DEACTIVATE" in the designated field.'}
      </div>
      <Input
        name='value'
        type={InputType.text}
        className={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder='Enter "DEACTIVATE"'
      />
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.lightGrey}
          mode={ButtonMode.standard}
          onClick={handleUpdate}
          loading={updateLoading}
        >
          Confirm
        </Button>
        <Button
          className={styles.button}
          size={ButtonSize.m}
          bgColor={ButtonBgColor.darkBlue}
          mode={ButtonMode.standard}
          onClick={() => setIsShown(false)}
        >
          Cancel
        </Button>
      </div>
    </ModalPage>
  );
}
