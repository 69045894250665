export enum ValidateType {
  delete = 'delete',
  newActive = 'newActive',
  newDelete = 'newDelete',
}

export type TGoogleUidLists = {
  listActiveGoogleUid: string[];
  listDeleteGoogleUid: string[];
  listNewActiveGoogleUid: string[];
  listNewDeleteGoogleUid: string[];
};
