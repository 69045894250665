// extracted by mini-css-extract-plugin
var _1 = "MainPage-module__hidden__gul8J";
var _2 = "MainPage-module__wrapper__yjPSo";
export { _1 as "hidden", _2 as "wrapper" }

    if(module.hot) {
      // 1733466102292
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  