import React from 'react';
import DoneMarkWeb from '@assets/images/doneMarkWeb.svg';
import DollarWeb from '@assets/images/dollarWeb.svg';
import PersonWeb from '@assets/images/personWeb.svg';
import CaseWeb from '@assets/images/caseWeb.svg';
import MainCardOneImage from '@assets/images/MainCardOne.png';
import MainCardTwoImage from '@assets/images/MainCardTwo.png';
import MainCardThreeImage from '@assets/images/MainCardThree.png';
import MainCardFourImage from '@assets/images/MainCardFour.png';
import MainCardOneImageMobile from '@assets/images/MainCardOneImageMobile.png';
import MainCardTwoImageMobile from '@assets/images/MainCardTwoImageMobile.png';
import MainCardThreeImageMobile from '@assets/images/MainCardThreeImageMobile.png';
import MainCardFourImageMobile from '@assets/images/MainCardFourImageMobile.png';
import MainInfoWebCard from './MainInfoWebCard/MainInfoWebCard';
import { useMobileDetect } from '@core/hooks/windowSize';

const FIRST_EXPERT_TITLE = 'Seamless Profile Creation';
const SECOND_EXPERT_TITLE = 'Effortless Payment Process';
const THIRD_EXPERT_TITLE = 'Instant Access to Local Jobs';
const FOURTH_EXPERT_TITLE = 'Secure Verification Process';

const FIRST_EXPERT_TEXT =
  'Choose your work area, set your availability, and specify the types of appliances you work with.';
const SECOND_EXPERT_TEXT =
  'Track your earnings, manage invoices, and receive payments directly to your account.';
const THIRD_EXPERT_TEXT =
  'Find work in your area as soon as they become available without the hassle of searching for clients.';
const FOURTH_EXPERT_TEXT =
  'Straightforward verification process that ensures your credentials are valid and up to date.';

export default function MainInfoWebExpert() {
  const isMobile = useMobileDetect();

  return (
    <>
      <MainInfoWebCard
        icon={<PersonWeb fill='var(--light-background-primary)' />}
        title={FIRST_EXPERT_TITLE}
        text={FIRST_EXPERT_TEXT}
        image={isMobile ? MainCardOneImageMobile : MainCardOneImage}
      />
      <MainInfoWebCard
        icon={<DoneMarkWeb fill='var(--light-background-primary)' />}
        title={SECOND_EXPERT_TITLE}
        text={SECOND_EXPERT_TEXT}
        image={isMobile ? MainCardTwoImageMobile : MainCardTwoImage}
      />
      <MainInfoWebCard
        icon={<CaseWeb fill='var(--light-background-primary)' />}
        title={THIRD_EXPERT_TITLE}
        text={THIRD_EXPERT_TEXT}
        image={isMobile ? MainCardThreeImageMobile : MainCardThreeImage}
      />
      <MainInfoWebCard
        icon={<DollarWeb fill='var(--light-background-primary)' />}
        title={FOURTH_EXPERT_TITLE}
        text={FOURTH_EXPERT_TEXT}
        image={isMobile ? MainCardFourImageMobile : MainCardFourImage}
      />
    </>
  );
}
