import React from 'react';
import cn from 'classnames';
import Input from '@components/Input/Input';
import { Controller, Control } from 'react-hook-form';
import { TDocumentFormData } from '../../../../types';
import { InputMode, InputType } from '@components/Input/types';
import { getPatterError } from '@core/utils/error';
import { ONLY_LATIN_CHAR_PATTERN, ONLY_NUMBERS } from '@core/constants';
import * as formStyles from '../../NewDocumentModal.module.scss';

type TAddressProps = {
  control: Control<TDocumentFormData>;
};

export default function Address(props: TAddressProps) {
  const { control } = props;
  return (
    <>
      <Controller
        name='street'
        control={control}
        rules={{
          required: 'Street is required field',
          pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Street'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            {...field}
            className={cn(formStyles.input, formStyles.emptyMargin)}
            type={InputType.text}
            mode={InputMode.standard}
            placeholder='Street'
            error={!!error}
            classNameError={cn(formStyles.error, formStyles.emptyMargin)}
          />
        )}
      />
      <Controller
        name='apartment'
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            {...field}
            className={formStyles.input}
            type={InputType.text}
            mode={InputMode.standard}
            placeholder='Apartment'
            error={!!error}
            classNameError={formStyles.error}
          />
        )}
      />
      <Controller
        name='city'
        control={control}
        rules={{
          required: 'City is required field',
          pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'City'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            {...field}
            className={formStyles.input}
            type={InputType.text}
            mode={InputMode.standard}
            placeholder='City'
            error={!!error}
            classNameError={formStyles.error}
          />
        )}
      />
      <Controller
        name='stateCode'
        control={control}
        rules={{
          required: 'State Code is required field',
          pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'State code'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            {...field}
            className={formStyles.input}
            type={InputType.text}
            mode={InputMode.standard}
            placeholder='State code'
            error={!!error}
            classNameError={formStyles.error}
          />
        )}
      />
      <Controller
        name='postalCode'
        control={control}
        rules={{
          required: 'Postal code is required field',
          pattern: getPatterError(ONLY_NUMBERS, 'Postal code'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            {...field}
            className={formStyles.input}
            type={InputType.text}
            mode={InputMode.standard}
            placeholder='Postal code'
            error={!!error}
            classNameError={formStyles.error}
          />
        )}
      />
    </>
  );
}
