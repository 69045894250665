import { TServerError } from '@core/utils/error';

export const getMessage = (error: TServerError): string => {
  if ('status' in error && error.status === 401) {
    return 'Invalid authorization, please re-login';
  } else if (
    ('status' in error && error.status === 500) ||
    ('status' in error && error.status === 502)
  ) {
    return 'We have some problems, we know about it and are already working on fixing them';
  } else if ('status' in error) {
    if (typeof error.data?.detail === 'object') {
      return Object.entries(error.data?.detail)[0].join(' ');
    }
    return error.data?.detail;
  } else {
    return 'Something went wrong!';
  }
};
