import React, { ReactNode } from 'react';
import * as styles from './MainInfoWebCard.module.scss';

type CardProps = {
  image: string;
  icon: ReactNode;
  title: string;
  text: string;
};

export default function Card(props: CardProps) {
  const { image, icon, title, text } = props;

  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${image})` }}>
      {icon}
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
