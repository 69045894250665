import React from 'react';
import { Controller, Control } from 'react-hook-form';
import Input from '@components/Input/Input';
import { InputMode, InputType, SeparatorMode } from '@components/Input/types';
import { TAgencyRegistrationForm, TExpertRegistrationForm } from '../../../types';
import * as styles from '../Form.module.scss';
import PhoneInput from '@components/Input/PhoneInput';
import { EMAIL_PATTERN, ONLY_LATIN_CHAR_PATTERN } from '@core/constants';
import { getPatterError } from '@core/utils/error';

type ExpertFieldsProps = {
  control: Control<TAgencyRegistrationForm | TExpertRegistrationForm>;
};

export default function ExpertFields(props: ExpertFieldsProps) {
  const { control } = props;
  return (
    <>
      <Controller
        name='email'
        control={control}
        rules={{
          required: 'Email address is required',
          pattern: getPatterError(EMAIL_PATTERN, 'email'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            className={styles.input}
            {...field}
            name='email'
            placeholder='Email address'
            type={InputType.text}
            innerRef={ref}
            mode={InputMode.group}
            error={!!error}
            separator
            separatorMode={SeparatorMode.short}
          />
        )}
      />
      <Controller
        name='phone'
        control={control}
        rules={{ required: 'Phone number is required' }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <PhoneInput
            className={styles.input}
            {...field}
            name='phone'
            placeholder='Phone number'
            type={InputType.text}
            innerRef={ref}
            mode={InputMode.group}
            error={!!error}
            separator
            separatorMode={SeparatorMode.all}
          />
        )}
      />
      <Controller
        name='firstName'
        control={control}
        rules={{
          required: 'First name is required',
          pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'First Name'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            className={styles.input}
            {...field}
            name='firstName'
            placeholder='First name'
            type={InputType.text}
            innerRef={ref}
            mode={InputMode.group}
            error={!!error}
            separator
            separatorMode={SeparatorMode.short}
          />
        )}
      />
      <Controller
        name='lastName'
        control={control}
        rules={{
          required: 'Last name is required',
          pattern: getPatterError(ONLY_LATIN_CHAR_PATTERN, 'Last Name'),
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Input
            className={styles.input}
            {...field}
            name='lastName'
            placeholder='Last name'
            type={InputType.text}
            innerRef={ref}
            mode={InputMode.group}
            error={!!error}
          />
        )}
      />
    </>
  );
}
