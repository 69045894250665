// extracted by mini-css-extract-plugin
var _1 = "TitleWithButton-module__disabled__Ow0fN";
var _2 = "TitleWithButton-module__isStatic__wtBDL";
var _3 = "TitleWithButton-module__separator__IuG05";
var _4 = "TitleWithButton-module__title__Vu404";
var _5 = "TitleWithButton-module__wrapper__epELY";
export { _1 as "disabled", _2 as "isStatic", _3 as "separator", _4 as "title", _5 as "wrapper" }

    if(module.hot) {
      // 1733466108981
      var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  