import React, { ReactNode } from 'react';
import Button from '@components/Button/Button';
import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import * as styles from './ErrorModal.module.scss';

type ErrorModalProps = {
  setIsShown: (val: boolean) => void;
  errorMessage: ReactNode;
  title?: string;
};

export default function ErrorModal(props: ErrorModalProps) {
  const { setIsShown, errorMessage, title = 'Uncorrected field value' } = props;
  return (
    <ModalPage
      header={<div>{title}</div>}
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      closeable={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.text}>{errorMessage}</div>
        <Button
          bgColor={ButtonBgColor.red}
          size={ButtonSize.m}
          className={styles.button}
          onClick={() => {
            setIsShown(false);
          }}
        >
          OK
        </Button>
      </div>
    </ModalPage>
  );
}
