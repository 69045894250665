// extracted by mini-css-extract-plugin
var _1 = "ModalInfo-module__header__JNMJB";
var _2 = "ModalInfo-module__modal__n7nn9";
var _3 = "ModalInfo-module__text__txjP4";
var _4 = "ModalInfo-module__wrapper__Asj_D";
export { _1 as "header", _2 as "modal", _3 as "text", _4 as "wrapper" }

    if(module.hot) {
      // 1733466109301
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  