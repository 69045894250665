import ModalPage from '@components/ModalPage/ModalPage';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import React from 'react';

type PrivacyPolicyModalProps = {
  setIsShown: (val: boolean) => void;
};

export default function PrivacyPolicyModal(props: PrivacyPolicyModalProps) {
  const { setIsShown } = props;
  return (
    <ModalPage
      header={<div>Privacy Policy</div>}
      mode={ModalPageMode.bottomSheet}
      setIsShown={setIsShown}
      overlayColor={ModalPageOverlayColor.transparent}
    >
      <p>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
        a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the
        word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from
        sections 1.10.32 and 1.10.33 of (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the Renaissance. The
        first line of Lorem Ipsum, comes from a line in section 1.10.32. The standard chunk of Lorem
        Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and
        1.10.33 from by Cicero are also reproduced in their exact original form, accompanied by
        English versions from the 1914 translation by H. Rackham. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Donec vel dui lobortis, hendrerit velit id, finibus velit. Nunc
        pellentesque felis at arcu euismod, sed ornare justo semper. Maecenas mattis pharetra
        consectetur. Ut gravida lectus neque, eget placerat nibh semper eu. Proin cursus ullamcorper
        volutpat. Duis eros lacus, mattis eu rutrum quis, euismod commodo ante. Mauris quis elit
        nulla. Nulla facilisi. Nulla in nulla euismod, imperdiet odio eget, lacinia diam. Vivamus
        porttitor sed urna id placerat. Donec ornare neque nec molestie vulputate. Donec consectetur
        magna ac urna ornare, fringilla molestie massa vehicula. Etiam hendrerit nisi sodales ligula
        porttitor condimentum. Sed felis ante, ultrices eu consectetur id, tempus eu felis. Integer
        hendrerit quis nibh eu viverra.
      </p>
      <p>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
        a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the
        word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from
        sections 1.10.32 and 1.10.33 of (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the Renaissance. The
        first line of Lorem Ipsum, , comes from a line in section 1.10.32. The standard chunk of
        Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32
        and 1.10.33 from by Cicero are also reproduced in their exact original form, accompanied by
        English versions from the 1914 translation by H. Rackham. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Donec vel dui lobortis, hendrerit velit id, finibus velit. Nunc
        pellentesque felis at arcu euismod, sed ornare justo semper. Maecenas mattis pharetra
        consectetur. Ut gravida lectus neque, eget placerat nibh semper eu. Proin cursus ullamcorper
        volutpat. Duis eros lacus, mattis eu rutrum quis, euismod commodo ante. Mauris quis elit
        nulla. Nulla facilisi. Nulla in nulla euismod, imperdiet odio eget, lacinia diam. Vivamus
        porttitor sed urna id placerat. Donec ornare neque nec molestie vulputate. Donec consectetur
        magna ac urna ornare, fringilla molestie massa vehicula. Etiam hendrerit nisi sodales ligula
        porttitor condimentum. Sed felis ante, ultrices eu consectetur id, tempus eu felis. Integer
        hendrerit quis nibh eu viverra.
      </p>
      <p>
        Etiam orci mauris, pretium eu nisi in, iaculis maximus urna. Nulla finibus, tortor vitae
        rutrum pellentesque, mauris orci semper arcu, dapibus laoreet libero lorem sit amet est.
        Cras a massa nunc. Sed sed aliquet enim, vehicula laoreet purus. Etiam commodo dui ac purus
        laoreet rhoncus. Aliquam et mauris felis. Phasellus ac nisi egestas, vehicula neque non,
        viverra nisl. Duis vitae leo vitae dui fermentum sagittis sit amet ac ligula. Nam bibendum
        orci ut vulputate sodales. Fusce semper venenatis ipsum, in convallis ipsum ullamcorper ut.
        Pellentesque quam magna, iaculis id sem malesuada, euismod vehicula felis. Maecenas vehicula
        magna vehicula pretium venenatis. Aenean porttitor maximus quam, sit amet tempus nunc
        tincidunt tincidunt.
      </p>
    </ModalPage>
  );
}
