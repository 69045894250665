import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import {
  TMapPosition,
  TMapPositionResponse,
  TNewWorkArea,
  TWorkArea,
  TWorkAreaValidateResponse,
  TWorkAreasPostRequest,
  TWorkAreasResponse,
  TWorkAreasValidateRequest,
  TZip,
  WorkAreaStatus,
} from '../models/serviceAreas';
import { createWorkArea } from './utils/serviceAreasUtils';

enum ServiceAreasApiPaths {
  WORK_AREAS = '/api/workflow/service-areas/',
  MAP_POSITION = '/api/workflow/service-areas/map-center/',
  ZIP = '/api/location/zips/from-code/',
}

export const serviceAreasService = (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
  getWorkAreas: build.query<TWorkArea[], { id: number }>({
    query: ({ id }) => `${ServiceAreasApiPaths.WORK_AREAS}?technician_id=${id}`,
    transformResponse: (response: TWorkAreasResponse[]) => {
      return response.map((elem: TWorkAreasResponse): TWorkArea => {
        return createWorkArea(elem);
      });
    },
  }),
  addNewWorkArea: build.mutation<TWorkArea, TWorkAreasPostRequest>({
    query: (body) => {
      return {
        url: ServiceAreasApiPaths.WORK_AREAS,
        method: 'POST',
        body: body,
      };
    },
    transformResponse: (response: TWorkAreasResponse) => {
      return createWorkArea(response);
    },
  }),
  deleteWorkArea: build.mutation<TWorkArea, { id: number }>({
    query: ({ id }) => {
      return {
        url: `${ServiceAreasApiPaths.WORK_AREAS}${id}/`,
        method: 'DELETE',
      };
    },
    transformResponse: (response: TWorkAreasResponse) => {
      return createWorkArea(response);
    },
  }),
  updateWorkArea: build.mutation<TWorkArea, { id: number }>({
    query: ({ id }) => {
      return {
        url: `${ServiceAreasApiPaths.WORK_AREAS}${id}/`,
        method: 'PATCH',
        body: {
          is_deleting: false,
          delete_timeout: null,
        },
      };
    },
    transformResponse: (response: TWorkAreasResponse) => {
      return createWorkArea(response);
    },
  }),
  validateWorkArea: build.mutation<TWorkAreaValidateResponse | null, TWorkAreasValidateRequest>({
    query: (body) => {
      return {
        url: `${ServiceAreasApiPaths.WORK_AREAS}validate/`,
        method: 'POST',
        body: body,
      };
    },
    transformResponse: (response: TZip[]) => {
      if (response.length === 0) return null;
      const defaultPostalCodesValidate: TWorkAreaValidateResponse = {
        postalCodes: {},
        googleUidList: [],
        amount: 0,
        amountCities: -1,
      };

      return response.reduce(
        (postalCodesValidate: TWorkAreaValidateResponse, zip: TZip): TWorkAreaValidateResponse => {
          if (postalCodesValidate.postalCodes[zip.city.name]) {
            postalCodesValidate.postalCodes[zip.city.name] = [
              ...postalCodesValidate.postalCodes[zip.city.name],
              zip.code,
            ];
          } else {
            postalCodesValidate.postalCodes[zip.city.name] = [zip.code];
            postalCodesValidate.amountCities += 1;
          }
          postalCodesValidate.googleUidList = [
            ...postalCodesValidate.googleUidList,
            zip.google_uid,
          ];
          postalCodesValidate.amount += 1;

          return postalCodesValidate;
        },
        defaultPostalCodesValidate,
      );
    },
  }),
  getMapPosition: build.query<TMapPosition, { id: number }>({
    query: ({ id }) => `${ServiceAreasApiPaths.MAP_POSITION}?technician_id=${id}`,
    transformResponse: (response: TMapPositionResponse) => {
      return {
        center: {
          lat: response.lat,
          lng: response.lng,
        },
        zoom: response.zoom,
      };
    },
  }),
  getNewWorkArea: build.mutation<TNewWorkArea, { code: string }>({
    query: ({ code }) => {
      return {
        url: ServiceAreasApiPaths.ZIP,
        method: 'POST',
        body: { code },
      };
    },
    transformResponse: (response: TZip) => {
      return {
        cityName: response.city.name,
        state: response.city.state,
        code: response.code,
        longitude: response.longitude,
        latitude: response.latitude,
        googleUid: response.google_uid,
        isDeleted: false,
        deleteTimeout: null,
        status: WorkAreaStatus.answerGoogle,
      };
    },
  }),
});
