// extracted by mini-css-extract-plugin
var _1 = "Navigation-module__content__Fiw2L";
var _2 = "Navigation-module__errorBadge__f0KV5";
var _3 = "Navigation-module__header__f6Yvm";
var _4 = "Navigation-module__item__c56sz";
var _5 = "Navigation-module__link__BuAc3";
var _6 = "Navigation-module__menu__EkAzX";
var _7 = "Navigation-module__menuBlock__g7QU7";
var _8 = "Navigation-module__menuIcon__wZ3nh";
var _9 = "Navigation-module__profileError__DFdsg";
var _a = "Navigation-module__title__PH6q0";
export { _1 as "content", _2 as "errorBadge", _3 as "header", _4 as "item", _5 as "link", _6 as "menu", _7 as "menuBlock", _8 as "menuIcon", _9 as "profileError", _a as "title" }

    if(module.hot) {
      // 1733466103066
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  