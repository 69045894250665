// extracted by mini-css-extract-plugin
var _1 = "PaymentForm-module__active__v_5lR";
var _2 = "PaymentForm-module__description__GQ407";
var _3 = "PaymentForm-module__green__KUohY";
var _4 = "PaymentForm-module__grey__DGHPZ";
var _5 = "PaymentForm-module__info__UKPsM";
var _6 = "PaymentForm-module__item__A2pLR";
var _7 = "PaymentForm-module__paymentButton__uDbs9";
var _8 = "PaymentForm-module__red__kHlhJ";
var _9 = "PaymentForm-module__separator__bn82Z";
var _a = "PaymentForm-module__skeleton__DirfA";
var _b = "PaymentForm-module__title__VLHlI";
var _c = "PaymentForm-module__total__TBlcH";
var _d = "PaymentForm-module__totalButton__wn162";
var _e = "PaymentForm-module__value__LcFLP";
var _f = "PaymentForm-module__weekButton__Aw1g0";
var _10 = "PaymentForm-module__weekIcon___FvSR";
var _11 = "PaymentForm-module__wrap__Rcxt0";
var _12 = "PaymentForm-module__wrapActive__rvC0P";
export { _1 as "active", _2 as "description", _3 as "green", _4 as "grey", _5 as "info", _6 as "item", _7 as "paymentButton", _8 as "red", _9 as "separator", _a as "skeleton", _b as "title", _c as "total", _d as "totalButton", _e as "value", _f as "weekButton", _10 as "weekIcon", _11 as "wrap", _12 as "wrapActive" }

    if(module.hot) {
      // 1733466106782
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  