import { TBankDetailsForm } from '@core/redux/models/bankDetails';
import { TTechnicianForm, TTechnician, TAddressDataForm } from '@core/redux/models/profile';

export const checkingChangesValue = (
  formData: Omit<TTechnicianForm, 'expertId'> | TBankDetailsForm | TAddressDataForm,
  data: TTechnician | TBankDetailsForm | TAddressDataForm,
): boolean => {
  return JSON.stringify(formData) !== JSON.stringify(data);
};

export const getFormValidation = (
  formData: TTechnician | TBankDetailsForm | Omit<TAddressDataForm, 'apartment'>,
): Record<string, boolean> => {
  const list: Record<string, boolean> = {};
  for (const [key, value] of Object.entries(formData)) {
    if (!String(value).length) {
      list[key] = true;
    } else {
      list[key] = false;
    }
  }

  return list;
};
