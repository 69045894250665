import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useActions } from '@core/hooks/actions';
import { useAppSelector } from '@core/hooks/redux';
import { Skeleton } from '@mui/material';
import Button from '@components/Button/Button';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import StatusError from '@assets/images/inputError.svg';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import { useWebSocket } from '@core/hooks/webSocket';
import * as styles from './Status.module.scss';
import { TPartnershipElement } from '@core/redux/models/payouts';
import { TWorkBlock } from '@core/redux/models/profile';

type TProps = {
  partnership: TPartnershipElement;
};

export default function Status(props: TProps) {
  const { partnership } = props;
  const [statusErrorModal, setStatusErrorModal] = useState<boolean>(false);
  const { selfSuspended, isUpdateWorkBlocks, workBlocks } = useAppSelector(
    (state) => state.partnershipReducer,
  );
  const {
    setPartnershipUpdateActivateStatus,
    setPartnershipIsUpdateWorkBlocks,
    setPartnershipWorkBlocks,
  } = useActions();
  const [workBlocksData, setWorkBlocksData] = useState([]);

  const [isReady, val, wsError] = useWebSocket(`/ws/partnerships/${partnership.id}/work-blocks/`);

  useEffect(() => {
    setWorkBlocksData(JSON.parse(String(val)));
  }, [isReady, val]);

  useEffect(() => {
    if (isUpdateWorkBlocks) {
      setPartnershipIsUpdateWorkBlocks(false);
    }
  }, [isUpdateWorkBlocks]);

  useEffect(() => {
    const suspended = workBlocksData?.find(
      (workBlock: TWorkBlock): boolean => workBlock.code === 'self',
    );

    if (suspended) {
      setPartnershipUpdateActivateStatus(true);
      setPartnershipWorkBlocks([suspended]);
      return;
    }

    if (selfSuspended) setPartnershipUpdateActivateStatus(false);
    setPartnershipWorkBlocks(workBlocksData);
    return;
  }, [workBlocksData]);

  const error = wsError
    ? 'Something went wrong!'
    : workBlocks?.map((item) => (
        <div className={styles.statusTitle} key={item.title}>
          {item.title}
        </div>
      ));

  const isLoading = !isReady;
  const isActive = !!workBlocks && !workBlocks.length;

  return (
    <div className={styles.wrap}>
      <span className={styles.separator}></span>
      <div className={styles.header}>
        <span className={styles.text}>Partnership status:</span>
        {isLoading ? (
          <Skeleton height={34} width={150} variant='rectangular' className={styles.skeleton} />
        ) : (
          <Button
            className={cn(styles.button, { [styles.buttonDisabled]: !selfSuspended && isActive })}
            mode={ButtonMode.standard}
            size={ButtonSize.m}
            bgColor={!selfSuspended && isActive ? ButtonBgColor.transparent : ButtonBgColor.white}
            onClick={() => {
              if (isActive) return;
              setStatusErrorModal(true);
            }}
            beforeIcon={!isActive ? <StatusError fill='var(--light_accent_negative)' /> : undefined}
          >
            {!selfSuspended && isActive ? 'Active' : 'Suspended'}
          </Button>
        )}

        {statusErrorModal && (
          <ErrorModal
            setIsShown={setStatusErrorModal}
            title='Your account is suspended'
            errorMessage={error}
          />
        )}
      </div>
    </div>
  );
}
