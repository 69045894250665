import React, { ReactNode, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import * as styles from './Info.module.scss';
import { TPartnershipElement } from '@core/redux/models/payouts';
import Button from '@components/Button/Button';
import DollarIcon from '@assets/images/dollar.svg';
import InfoIcon from '@assets/images/info.svg';
import IconArrowRight from '@assets/images/arrowRightClean.svg';
import { ButtonBgColor, ButtonSize } from '@components/Button/types';
import { ROUTES } from '@routes/constants';
import ContactsModal from './components/ContactsModal/ContactsModal';
import { partnershipAPI } from '@core/redux/services/partnershipService';
import { TContact } from '@core/redux/models/partnership';

type TProps = {
  partnership: TPartnershipElement;
};

export default function Info(props: TProps) {
  const { partnership } = props;
  const { data: contacts } = partnershipAPI.useGetContactsQuery({
    company_id: partnership.companyId,
  });

  const [contactsModal, setContactsModal] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [contactId, setContactId] = useState<string>('');
  const [contactList, setContactList] = useState<TContact[]>();

  const data: Record<string, ReactNode>[] = [
    { key: 'Agency:', value: partnership.companyTitle },
    { key: 'CRM:', value: partnership.crm },
    { key: 'Login:', value: partnership.email ? partnership.email : 'N/A' },
    {
      key: 'Contacts:',
      value:
        contacts && Object.keys(contacts).length ? (
          <div className={styles.linkButton} onClick={() => setShowMore(!showMore)}>
            {!showMore ? 'More' : 'Less'}
          </div>
        ) : (
          'N/A'
        ),
    },
  ];

  const handleContactModal = useCallback(
    (id?: string, value?: TContact[]) => {
      if (!id || !value) {
        return;
      }
      setContactsModal(true);
      setContactId(id);
      setContactList(value);
    },
    [contactId, contactList],
  );

  return (
    <div className={styles.wrap}>
      <span className={styles.separator}></span>
      <div className={styles.header}>
        <span className={styles.text}>General information:</span>
      </div>
      <div className={styles.block}>
        {data.map((item, index) => (
          <div className={styles.line} key={`${item.value}-${index}`}>
            <span className={styles.key}>{item.key}</span>
            <span className={styles.value}>{item.value}</span>
          </div>
        ))}
        {showMore &&
          contacts &&
          Object.entries(contacts).map(([key, value]) => (
            <div
              className={styles.line}
              key={`${key}-contacts`}
              onClick={() => handleContactModal(key, value)}
            >
              <span className={styles.key}>{key}:</span>
              <span className={styles.value}>
                <IconArrowRight fill='var(--light_label_secondary)' />
              </span>
            </div>
          ))}
      </div>
      <div className={styles.buttonBlock}>
        {partnership.knowledgeBaseUrl && (
          <NavLink to={partnership.knowledgeBaseUrl} className={styles.link}>
            <Button
              className={styles.button}
              size={ButtonSize.m}
              bgColor={ButtonBgColor.grey}
              beforeIcon={<InfoIcon fill='var(--light-background-primary)' />}
            >
              Payouts
            </Button>
          </NavLink>
        )}
        <NavLink to={ROUTES.PAYOUTS} className={styles.link}>
          <Button
            className={styles.button}
            size={ButtonSize.m}
            bgColor={ButtonBgColor.blue}
            beforeIcon={<DollarIcon fill='var(--light-background-primary)' />}
          >
            Payouts
          </Button>
        </NavLink>
      </div>
      {contactsModal && contactList?.length && (
        <ContactsModal setIsShown={setContactsModal} list={contactList} />
      )}
    </div>
  );
}
