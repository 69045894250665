import React, { useCallback, useState } from 'react';
import { useActions } from '@core/hooks/actions';
import ModalPage from '@components/ModalPage/ModalPage';
import Cancel from '@assets/images/cancel.svg';
import Done from '@assets/images/done32.svg';
import { ModalPageMode, ModalPageOverlayColor } from '@components/ModalPage/types';
import * as styles from './AddRangeModal.module.scss';
import { useTimeOdds } from '@core/utils/date';
import { timeValidation } from './utils';
import { TScheduleTime } from '@core/redux/models/schedule';
import Button from '@components/Button/Button';
import cn from 'classnames';
import DrumSchedule from '../DrumSchedule/DrumSchedule';
import { TypeTime } from '../DrumSchedule/types';

type ConfirmModalPageProps = {
  setIsShown: (val: boolean) => void;
  timeWindow: TScheduleTime;
};

type TDrumProps = {
  title: string;
  startTime: string;
  endTime: string;
  setValue: (time: string) => void;
  typeTime: TypeTime;
};

export default function ConfirmModalPage(props: ConfirmModalPageProps) {
  const { setIsShown, timeWindow } = props;
  const { setNewTimeRange } = useActions();
  const [start, setStart] = useState(timeWindow.start);
  const [end, setEnd] = useState(timeWindow.end);
  const [error, setError] = useState('');
  const [isDrum, setIsDrum] = useState<boolean>(false);
  const [drumProps, setDrumProps] = useState<TDrumProps>({
    title: '',
    startTime: '',
    endTime: '',
    setValue: () => {},
    typeTime: TypeTime.startTime,
  });
  const timeOdds = useTimeOdds(start, end);

  const handleClickDone = useCallback(() => {
    const validationMessage = timeValidation(start, end);
    if (validationMessage.length) {
      setError(validationMessage);
    } else {
      setNewTimeRange({ start, end });
      setIsShown(false);
    }
  }, [start, end, error]);

  return (
    <ModalPage
      header={isDrum ? <p className={styles.title}>{drumProps.title}</p> : undefined}
      headerClassName={styles.header}
      mode={ModalPageMode.modal}
      setIsShown={isDrum ? setIsDrum : setIsShown}
      overlayColor={ModalPageOverlayColor.standard}
      className={styles.modal}
      closeable={isDrum}
    >
      <div className={cn({ [styles.block]: !isDrum })}>
        {isDrum ? (
          <DrumSchedule
            startTime={drumProps.startTime}
            endTime={drumProps.endTime}
            setValue={drumProps.setValue}
            setIsDrum={setIsDrum}
            typeTime={drumProps.typeTime}
          />
        ) : (
          <>
            <div className={styles.wrap}>
              <Cancel fill='var(--light_accent_main)' onClick={() => setIsShown(false)} />
              <div className={styles.apply}>
                <div className={styles.text}>Apply time:</div>
                <div className={styles.applyTime}>{timeOdds}&nbsp;h</div>
              </div>
              <Done fill='var(--light_accent_main)' onClick={() => handleClickDone()} />
            </div>
            <div className={styles.inputs}>
              <Button
                className={styles.button}
                onClick={() => {
                  setIsDrum(true);
                  setDrumProps({
                    title: 'Start time',
                    startTime: timeWindow.start,
                    endTime: timeWindow.end,
                    setValue: setStart,
                    typeTime: TypeTime.startTime,
                  });
                }}
              >
                {start}
              </Button>
              <span className={styles.separator}></span>
              <Button
                className={styles.button}
                onClick={() => {
                  setIsDrum(true);
                  setDrumProps({
                    title: 'End time',
                    startTime: timeWindow.start,
                    endTime: timeWindow.end,
                    setValue: setEnd,
                    typeTime: TypeTime.endTime,
                  });
                }}
              >
                {end}
              </Button>
            </div>
          </>
        )}
        {!!error && <div className={styles.error}>{error}</div>}
      </div>
    </ModalPage>
  );
}
