import React, { ReactNode } from 'react';
import ReviewsCardAgency from '../ReviewsCardAgency/ReviewsCardAgency';
import FirstAgency from '@assets/images/firstAgency.png';
import SecondAgency from '@assets/images/secondAgency.png';
import ThirdAgency from '@assets/images/thirdAgency.png';
import Slider from '@components/Slider/Slider';
import * as styles from './ReviewsAgency.module.scss';
import { useMobileDetect } from '@core/hooks/windowSize';
import ArrowLeftStrokeWeb from '@assets/images/arrowLeftStrokeWeb.svg';
import ArrowRightStrokeWeb from '@assets/images/arrowRightStrokeWeb.svg';

const FIRST_REVIEW_TEXT =
  'Expert Hub has been a game changer for our hiring process. Before, finding qualified technicians was always hit or miss, and we often struggled with last-minute cancellations. Now, we can connect with top talent in our area in a matter of hours. The platform is intuitive, and the technicians we’ve hired through Expert Hub have been reliable and professional. It’s made a significant impact on our business, allowing us to meet client demands more efficiently.';
const SECOND_REVIEW_TEXT =
  'The ability to post job offers and manage our team of technicians all in one place has saved us an incredible amount of time and resources. We no longer have to juggle multiple platforms or deal with endless paperwork. Expert Hub’s streamlined system lets us fill positions quickly with the right people, which keeps our projects on track and our clients happy. It’s become an indispensable part of how we operate.';
const THIRD_REVIEW_TEXT =
  'What really sets Expert Hub apart is the quality of technicians we’ve been able to hire. Knowing that each technician is thoroughly verified gives us the confidence to bring them onto our projects without hesitation. The platform’s user-friendly interface and responsive support team make the entire process—from posting jobs to managing ongoing work—smooth and hassle-free. We’ve seen a noticeable improvement in our service delivery since we started using Expert Hub.';

const listReview: ReactNode[] = [
  <ReviewsCardAgency
    image={FirstAgency}
    name={'M-Appliance Group'}
    text={FIRST_REVIEW_TEXT}
    bgColor={'#595B61'}
    key={1}
  />,
  <ReviewsCardAgency
    image={SecondAgency}
    name={'Chicago Appliance Repair'}
    text={SECOND_REVIEW_TEXT}
    bgColor={'#3E4958'}
    key={2}
  />,
  <ReviewsCardAgency
    image={ThirdAgency}
    name={'Bay Master Appliance'}
    text={THIRD_REVIEW_TEXT}
    bgColor={'#97ADB6'}
    key={3}
  />,
];

export default function ReviewsAgency() {
  const isMobile = useMobileDetect();

  return (
    <Slider
      list={listReview}
      showNavigation={isMobile ? true : false}
      slidesToShow={isMobile ? 1 : 3}
      wrapperClass={styles.wrapper}
      prevButtonClass={isMobile && styles.prevButton}
      nextButtonClass={isMobile && styles.nextButton}
      nextIcon={<ArrowRightStrokeWeb fill={'var(--light_label_secondary)'} />}
      prevIcon={<ArrowLeftStrokeWeb fill={'var(--light_label_secondary)'} />}
    />
  );
}
