// extracted by mini-css-extract-plugin
var _1 = "WorkInfoCard-module__number__n9gSv";
var _2 = "WorkInfoCard-module__text__zcJiu";
var _3 = "WorkInfoCard-module__title__zE1Cj";
var _4 = "WorkInfoCard-module__wrapper__UDcc8";
export { _1 as "number", _2 as "text", _3 as "title", _4 as "wrapper" }

    if(module.hot) {
      // 1733466109632
      var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  