import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '@core/hooks/redux';
import Button from '@components/Button/Button';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import StatusError from '@assets/images/inputError.svg';
import { ButtonBgColor, ButtonMode, ButtonSize } from '@components/Button/types';
import { TWorkBlock } from '@core/redux/models/profile';
import * as styles from './Status.module.scss';
import { useCurrentUser } from '@core/hooks/currentUser';
import { useActions } from '@core/hooks/actions';
import { useWebSocket } from '@core/hooks/webSocket';

export default function Status() {
  const { technicianId } = useCurrentUser();
  const [statusErrorModal, setStatusErrorModal] = useState<boolean>(false);
  const { selfSuspended, isUpdateWorkBlocks, workBlocks } = useAppSelector(
    (state) => state.generalInfoReducer,
  );
  const { setUpdateActivateStatus, setIsUpdateWorkBlocks, setWorkBlocks } = useActions();

  const [isReady, val, wsError] = useWebSocket(`/ws/experts/${technicianId}/work-blocks/`);
  const [workBlocksData, setWorkBlocksData] = useState([]);

  useEffect(() => {
    setWorkBlocksData(JSON.parse(String(val)));
  }, [isReady, val]);

  useEffect(() => {
    if (isUpdateWorkBlocks) {
      setIsUpdateWorkBlocks(false);
    }
  }, [isUpdateWorkBlocks]);

  useEffect(() => {
    const suspended = workBlocksData?.find(
      (workBlock: TWorkBlock): boolean => workBlock.code === 'self',
    );

    if (suspended) {
      setUpdateActivateStatus(true);
      setWorkBlocks([suspended]);
      return;
    }

    if (selfSuspended) setUpdateActivateStatus(false);
    setWorkBlocks(workBlocksData);
    return;
  }, [workBlocksData]);

  const error = wsError
    ? 'Something went wrong!'
    : workBlocks?.map((item) => (
        <div className={styles.statusTitle} key={item.title}>
          {item.title}
        </div>
      ));

  const isLoading = !isReady;
  const isActive = !!workBlocks && !workBlocks.length;

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}>Status:</h2>
      {isLoading ? (
        <Skeleton height={34} width={150} variant='rectangular' className={styles.skeleton} />
      ) : (
        <Button
          className={cn(styles.button, { [styles.buttonDisabled]: !selfSuspended && isActive })}
          mode={ButtonMode.standard}
          size={ButtonSize.m}
          bgColor={!selfSuspended && isActive ? ButtonBgColor.transparent : ButtonBgColor.white}
          onClick={() => {
            if (isActive) return;
            setStatusErrorModal(true);
          }}
          beforeIcon={!isActive ? <StatusError fill='var(--light_accent_negative)' /> : undefined}
        >
          {!selfSuspended && isActive ? 'Active' : 'Suspended'}
        </Button>
      )}

      {statusErrorModal && (
        <ErrorModal
          setIsShown={setStatusErrorModal}
          title='Your account is suspended'
          errorMessage={error}
        />
      )}
      <span className={styles.separator}></span>
    </section>
  );
}
