import React, { useState } from 'react';
import * as styles from './Intro.module.scss';
import { ButtonBgColor } from '@components/Button/types';
import HeaderWeb from './components/Header/Header';
import ItemBlock from './components/ItemBlock/ItemBlock';
import { TypeWork } from '@pages/MainPage/types';

const EXPERTS_TEXT_MAIN = 'Start Earning Today with Expert Hub';
const EXPERTS_TEXT_SECOND =
  'Your next job is just around the corner—register now and connect with local companies.';
const AGENCY_TEXT_MAIN = 'Find Skilled Experts with Expert Hub';
const AGENCY_TEXT_SECOND = 'Connect with verified local contractors and get the job done right.';

type IntroProps = {
  open: TypeWork | null;
  hidden: TypeWork | null;
  handleClick: (type: TypeWork) => void;
  handleClickBack: () => void;
};

export default function Intro(props: IntroProps) {
  const { open, hidden, handleClick, handleClickBack } = props;
  const [focused, setFocused] = useState<TypeWork | null>(null);

  return (
    <div className={styles.wrapper}>
      <HeaderWeb openWork={open} isFocus={!!focused} clickBack={handleClickBack} />
      <ItemBlock
        title='Find Local Jobs'
        textMain={EXPERTS_TEXT_MAIN}
        textSecond={EXPERTS_TEXT_SECOND}
        isFocus={focused === TypeWork.EXPERT}
        isAnotherFocus={focused === TypeWork.AGENCY}
        isOpen={open === TypeWork.EXPERT}
        isAnotherOpen={open === TypeWork.AGENCY}
        isHidden={hidden === TypeWork.AGENCY}
        onMouseEnter={() => setFocused(TypeWork.EXPERT)}
        onMouseLeave={() => setFocused(null)}
        onClick={() => handleClick(TypeWork.EXPERT)}
        buttonText={open === TypeWork.EXPERT ? 'Learn more' : 'I’m an Expert'}
        bgColor={focused === TypeWork.EXPERT ? ButtonBgColor.supplemental : ButtonBgColor.white}
        classNameItem='itemOne'
        classNameItemOpen='itemOneOpen'
        classNameItemClose='itemOneClose'
      />

      <ItemBlock
        title='Hire Experts'
        textMain={AGENCY_TEXT_MAIN}
        textSecond={AGENCY_TEXT_SECOND}
        isFocus={focused === TypeWork.AGENCY}
        isAnotherFocus={focused === TypeWork.EXPERT}
        isOpen={open === TypeWork.AGENCY}
        isAnotherOpen={open === TypeWork.EXPERT}
        isHidden={hidden === TypeWork.EXPERT}
        onMouseEnter={() => setFocused(TypeWork.AGENCY)}
        onMouseLeave={() => setFocused(null)}
        onClick={() => handleClick(TypeWork.AGENCY)}
        buttonText={open === TypeWork.AGENCY ? 'Learn more' : 'I’m an Agency'}
        bgColor={focused === TypeWork.AGENCY ? ButtonBgColor.inert : ButtonBgColor.white}
        classNameItem='itemTwo'
        classNameItemOpen='itemTwoOpen'
        classNameItemClose='itemTwoClose'
      />
    </div>
  );
}
