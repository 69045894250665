// extracted by mini-css-extract-plugin
var _1 = "InformationPage-module__blue__I8FG2";
var _2 = "InformationPage-module__text____TkJ";
var _3 = "InformationPage-module__textBlock__U7rWm";
var _4 = "InformationPage-module__title__C6Z1o";
var _5 = "InformationPage-module__wrapped__dk4jn";
export { _1 as "blue", _2 as "text", _3 as "textBlock", _4 as "title", _5 as "wrapped" }

    if(module.hot) {
      // 1733466106769
      var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"locals":true});
      module.hot.dispose(cssReload);
      
    }
  