import { STATE_CODE } from '@core/constants';

export const getState = (code: string): string => {
  const stateList = Object.entries(STATE_CODE).find(([key]) => key === code);
  return stateList?.length ? stateList[1] : code;
};

export const getStateCode = (state: string): string => {
  const stateList = Object.entries(STATE_CODE).find(([, value]) => value === state);
  return stateList?.length ? stateList[0] : state;
};
